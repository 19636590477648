export const POST_SHIFT_CENTER = "POST_SHIFT_CENTER";
export const CANCEL_SHIFT_REQUEST = "CANCEL_SHIFT_REQUEST";

export const shiftCenter = (data, onSuccess) => ({
  type: POST_SHIFT_CENTER,
  payload: { ...data, onSuccess }
})
  ;
export const cancelShiftingRequest = request => ({
  type: CANCEL_SHIFT_REQUEST,
  payload: request
});
