import { FETCH_ACTIVITY_SUCCESS } from "./action";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = {
  dailyFeed: [],
  loading: true
};
const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_SUCCESS: {
      return {
        ...state,
        dailyFeed: action.payload,
        loading: false
      };
    }
    case SET_COMPONENT_LOADING + 'ACTIVITY': {
      return {
        ...state,
        loading: action.value
      }
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
export default activityReducer;
