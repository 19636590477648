export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";

export const fetchNotification = (startIndex = 0) => ({
  type: FETCH_NOTIFICATION,
  payload: {
    startIndex
  }
});

export const fetchNotificationSuccess = payload => ({
  type: FETCH_NOTIFICATION_SUCCESS,
  payload
});
