import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import { Card, CardBody, Col, CardHeader } from "reactstrap";

import { CardTitle } from "reactstrap";

const statusArr = [];
statusArr[2] = "Open";
statusArr[3] = "Pending";
statusArr[4] = "Resolved";
statusArr[5] = "Closed";

class TicketItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    return (
      <Col md="12" className="mb-4">
        <Card className="border-0 shadow">
          <CardHeader style={{ background: "#efefef", fontFamily: "baloo" }}>
            <div className="d-flex">
              <div className="mr-auto">
                {moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  DEFAULT_FORMAT
                )}
              </div>
              <div>Status - {statusArr[item.status]}</div>
            </div>
          </CardHeader>
          <CardBody className="py-3">
            <CardTitle>{item.subject}</CardTitle>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TicketItem);
