import newsLogic from "../../features/News/redux/logic";
import holidayLogic from "../../features/Holidays/redux/logic";
import blogsLogic from "../../features/Blogs/redux/logic";
import loginLogic from "../../features/Login/redux/logic";
import requestTypesLogic from "../../features/Support/redux/logic";
import foodLogic from "../../features/FoodMenu/redux/logic";
import activityCalenderLogic from "../../features/ActivityCalendar/redux/logic";
import chatLogic from "../../features/Conversation/redux/logic";
import cctvLogic from "../../features/Cctv/redux/logic";
import outDoorActivityLogic from "../../features/OutdoorActivity/redux/logic";
import myCenterLogic from "../../features/MyCenter/redux/logic";
import updateParentLogic from "../../features/MyProfile/redux/logic";
import ticketLogic from "../../features/Tickets/redux/logic";
import cancelTicketLogic from "../../features/PastRequest/redux/logic";
import breakLogic from "../../features/Break/redux/logic";
import shiftCenterLogic from "../../features/ShiftCenter/redux/logic";
import changeProgramLogic from "../../features/ProgramChange/redux/logic";
import extendDaycareLogic from "../../features/ExtendedDaycare/redux/logic";
import growthLogic from "../../features/Growth/redux/logic";
import transportLogic from "../../features/Transport/redux/logic";
import withdrawChildLogic from "../../features/WithdrawChild/redux/logic";
import financeLogic from "../../features/Finance/redux/logic";
import photosLogic from "../../features/PhotoGallery/redux/logic";
import SurveyLogic from "../../features/Survey/redux/logic";
import childTimelineLogic from "../../features/ChildTimeline/redux/logic";
import notificationLogic from "../../features/Notification/redux/logic";
import guardianLogic from "../../features/Guardian/redux/logic";
import globalLogic from "./logic";
import fetchAttendanceLogic from "../../features/Attendance/redux/logic";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_FORMAT = "MMM DD, YYYY";
export const ITEM_PER_PAGE = 50;
export default [
  ...blogsLogic,
  ...loginLogic,
  ...cctvLogic,
  ...requestTypesLogic,
  ...ticketLogic,
  ...cancelTicketLogic,
  ...foodLogic,
  ...changeProgramLogic,
  ...extendDaycareLogic,
  ...activityCalenderLogic,
  ...outDoorActivityLogic,
  ...myCenterLogic,
  ...updateParentLogic,
  ...newsLogic,
  ...holidayLogic,
  ...globalLogic,
  ...chatLogic,
  ...breakLogic,
  ...shiftCenterLogic,
  ...growthLogic,
  ...transportLogic,
  ...withdrawChildLogic,
  ...financeLogic,
  ...photosLogic,
  ...SurveyLogic,
  ...childTimelineLogic,
  ...notificationLogic,
  ...guardianLogic,
  ...fetchAttendanceLogic
];
