import { FETCH_SURVEY_SUCCESS, CHECK_BOX_ANSWER } from "./action";
import { CHANGE_LOGGEDIN_CHILD } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";

const initialState = {
  questions: []
};
const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SURVEY_SUCCESS: {
      return {
        ...state,
        questions: action.payload
      };
    }
    case CHECK_BOX_ANSWER: {
      return {
        ...state,
        checkboxAnswer: action.payload
      };
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }

    default:
      return state;
  }
};
export default surveyReducer;
