import React from "react";
import { Col } from "reactstrap";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";

export const HalfWidthTile = ({
  backgroundColor,
  borderColor,
  pendingRequest = false,
  image,
  heading = "",
  value = "",
  pendingHeading,
  pendingValue,
  icon,
  path,
  index
}) => (
  <Col sm="6" md="12" lg="6" xl="4" className="mb-3">
    <Link
      to={`/${pendingRequest ? "pastRequestTab" : path}`}
      style={{ color: "#757575" }}
    >
      <Media className="mt-1 rounded py-3 py-md-5 px-3 h-100 shadow border border-success">
        <Media left middle className="align-self-center mr-3">
          <span
            className="badge-success badge badge-secondary badge-pill"
            style={{ width: "100px", height: "100px" }}
          >
            {typeof icon !== "undefined" && (
              <i
                className={`${icon} text-center`}
                style={{ fontSize: "55px", lineHeight: "1.6em" }}
              />
            )}
          </span>
        </Media>
        <Media body className="my-auto">
          <Media
            heading
            style={{ fontSize: "1.3em", fontFamily: "Baloo", color: "#4b4c4c" }}
          >
            {pendingRequest === true && typeof pendingHeading !== "undefined"
              ? pendingHeading
              : heading}
          </Media>
          {pendingRequest === true && typeof pendingValue !== "undefined"
            ? pendingValue
            : value}
        </Media>
      </Media>
    </Link>
  </Col>
);

export const FullWidthTile = ({
  backgroundColor,
  image = "",
  heading = "",
  value = "",
  icon,
  path
}) => (
  <Link to={`/${path}`} style={{ color: "#757575" }}>
    <Media
      className="mt-1 rounded py-3 py-md-5 px-3 h-100 shadow "
      style={{ border: "1px solid #27ade4" }}
    >
      <Media left middle className="align-self-center mr-3">
        {typeof icon !== "undefined" && (
          <span
            className=" badge badge-pill"
            style={{
              width: "100px",
              height: "100px",
              background: "#27ade4",
              color: "#ffffff"
            }}
          >
            <i
              className={`${icon} text-center`}
              style={{
                fontSize: "55px",
                lineHeight: "1.6em"
              }}
            />
          </span>
        )}
      </Media>
      <Media body className="my-auto">
        <Media heading style={{ fontSize: "1.3em", fontFamily: "Baloo" }}>
          {heading}
        </Media>
        {value}
      </Media>
    </Media>
  </Link>
);
