import React from "react";
import { Card, CardHeader, CardBody, CardTitle, CardImg, Row } from "reactstrap";
import { connect } from "react-redux";
import { setNotification } from "../redux/action";

class FootprintNotification extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.notification && this.props.notification.subtitle) {
      setTimeout(() => {
        this.props.setNotification();
      }, 8000);
    }
  }


  removeHtmlTags = (message) => {
    if (typeof message === 'string') {
      const regex = /(<([^>]+)>)/ig;
      message = message.replace(regex, '');
      return message;
    }
    return message;
  }

  render() {
    const { notification } = this.props;

    if (typeof notification !== "undefined" && typeof notification.subtitle === "string") {
      const { subtitle, title } = notification;
      return (
        <div
          onClick={() => {
            window.location = "/notification";
            this.props.setNotification();
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "80%", // Adjust the width as needed
              maxWidth: 400, // Add a maximum width if desired
            }}
          >
            <Card className="border-0 shadow h-100">
              <CardHeader
                className="border-0 bg-teal"
                style={{ fontFamily: "Baloo", color: "#fff" }}
              >
                {typeof title === "string" && title !== ""
                  ? title
                  : "Footprints Childcare"}
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <CardImg
                    style={{ width: 100, height: 100 }}
                    width={100}
                    height={100}
                    src={require("../../assets/splash-nico-hi.png")}
                    alt="Card image cap"
                  />
                  <CardTitle
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 280,
                      fontSize: "13px",
                      justifyContent: "center",
                      height: 50,
                      // whiteSpace: "nowrap",
                    }}
                    className="my-auto pr-3"
                  >
                    {typeof subtitle === "string" && subtitle !== ""
                      ? subtitle
                      : "Footprints Childcare"}
                  </CardTitle>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  notification: state.global.notification || {}
});

const mapDispatchToProps = dispatch => ({
  setNotification: (title, subtitle, onClick) => dispatch(setNotification(title, subtitle, onClick)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FootprintNotification);
