

export const FETCH_CHAT = 'FETCH_CHAT';
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SEND_CHAT_MESSAGE_OFFLINE = 'SEND_CHAT_MESSAGE_OFFLINE';

export const fetchChat = (startIndex = 0) => ({
    type: FETCH_CHAT,
    payload: { startIndex },
  });
  
  
  
  export const fetchChatSuccess = (conversationList, startIndex, moreData) => ({
    type: FETCH_CHAT_SUCCESS,
    payload: {
      conversationList,
      startIndex,
      moreData,
    },
  });
  
  export const sendChatMessage = (message,onSuccess) => ({
    type: SEND_CHAT_MESSAGE_OFFLINE,
    payload: {
      message,
      onSuccess
    },
    meta: {
      retry: true,
    },
  });
  