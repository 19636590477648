import React from "react";
import { connect } from "react-redux";
// import { Table } from "reactstrap";
import { Col, Card, CardHeader, CardBody, CardText, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Table } from "reactstrap";
import "chart.js/dist/Chart.css"; 
class MesurementItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      currentIndex: 0
    };
  }

  render() {
    const { item } = this.props;
    const { dropdownOpen,currentIndex } = this.state;
    const toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });
    const selectedYearData = item[currentIndex].data.sort((a, b) => (
      a.month - b.month
    ));
    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    
    const onClickHandler =(year)=>{
      let index= item.findIndex((ele)=>ele.year===year);
      // console.log("Change : ",index);
      if(index >= 0) this.setState({ currentIndex : index, dropdownOpen : false });
    }
    // console.log("Measurement index => ",currentIndex,"Current Array => ", item[currentIndex]);
    // console.log("DATA => ", item);
    return (
      <Col>
        <Card className="my-3 border-0 shadow">
          <CardHeader
            className="text-white"
            style={{ fontFamily: "Baloo", background: "#00BFA5" }}
          >
            <i className="icon-calender fs-16"></i>{" "}
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              style={{ cursor: "pointer" }}
            >
              <DropdownToggle caret size="lg" data-toggle="dropdown" tag="span">
                {item[currentIndex].year}
              </DropdownToggle>
              <DropdownMenu>
                {item.map((ele) => (
                  <DropdownItem
                    key={ele.year}
                    onClick={() => onClickHandler(ele.year)}
                  >
                    {ele.year}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </CardHeader>
          <CardBody style={{padding: '.5rem'}}>
            <Table>
              <thead className="border">
                <tr>
                  <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                    Month
                  </th>
                  <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                    Height
                  </th>
                  <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody className="border">
                {selectedYearData.map((ele, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                      <CardText>{monthArray[ele.month]}</CardText>
                    </td>
                    <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                      {" "}
                      <CardText>{ele.height} cm</CardText>
                    </td>
                    <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                      {" "}
                      <CardText>{ele.weight} kg</CardText>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MesurementItem);
