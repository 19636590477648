import React, { Component } from "react";
import { CustomInput } from "reactstrap";

export default class FootprintDropdown extends Component {
  static defaultProps = {
    value: undefined,
    placeholder: {
      label: "Select an item...",
      value: null
    }
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  onChange = event => { };
  render() {
    //
    const { data, label = 'Select an option' } = this.props;
    return (
      <CustomInput
        id="dropdown"
        type="select"
        name="customSelect"
        onChange={event => {
          if (this.props.onChange) {
            this.props.onChange(JSON.parse(event.target.value));
          }
        }}
      >
        <option value='' disabled={this.props.value}>{label}</option>
        {data.map((item, index) => (
          <option value={item.value} key={index}>{item.label}</option>
        ))}
      </CustomInput>
    );
  }
}
