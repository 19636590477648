import { FETCH_TEACHERS_SUCCESS } from "./action";
import { CHANGE_LOGGEDIN_CHILD, LOGOUT } from "../../Login/redux/action";
import { SET_COMPONENT_LOADING } from "../../../global/redux/action";

const initialState = {
  teachers: [],
  loading:true
};
const transformTeachers = teachers => {
  if (Array.isArray(teachers) && teachers.length > 0) {
    return teachers.map(item => ({
      ...item,
      fullImgUrl: item.User_Image
    }));
  }
  return [];
};
const myCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEACHERS_SUCCESS: {
      return {
        ...state,
        teachers: transformTeachers(action.payload),
        loading: false,
      };
    }

    case SET_COMPONENT_LOADING + "MYCENTER" : {
      return {
        ...state,
        loading: action.value
      }
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
export default myCenterReducer;
