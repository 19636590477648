import React, { Component } from "react";
import { Button } from "reactstrap";

export default class FootprintInputButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { text = "Submit", onClick = () => {} } = this.props;
    return (
      <Button
        className="btn-block btn-lg bg-purple border-0"
        style={{ color: "white" }}
        onClick={() => {
          if (this.props.onClick) {
            onClick();
          }
        }}
      >
        {text}
      </Button>
    );
  }
}
