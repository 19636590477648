import React from "react";
import { connect } from "react-redux";
import { fetchTeachers } from "./redux/action";
import { getCenterList } from "../../global/redux/action";

import MyCenterItem from "./item";

import { Jumbotron, Container } from "reactstrap";

import { Row } from "reactstrap";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent"

import { InnerHOC } from "../../global/HOComponet/innerPage";
import CenterHeadInfo from "./centerHead";
class MyCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("[MyCenter] Fetching Center data...");
    this.props.fetchTeachers(() => {
      this.props.getCenterList();
    });
  }

  render() {
    // console.log(this.props.centerHeadInfo);
    return (
      <div>
        <Jumbotron
          fluid
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.55) 59%, rgba(0, 0, 0, 0.65) 100%),url(" +
              require("../../assets/mycenter.jpg") +
              ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginBottom: 0,
            // height: "400px"
          }}
        >
          <Container fluid className="text-center">
            <div
              className="profile-img mx-auto d-block rounded-circle mb-3 bg-white"
              style={{
                backgroundImage:
                  "url(" + require("../../assets/left-nico.png") + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <h1
              className="display-3 text-white"
              style={{ fontFamily: "baloo" }}
            >
              Footprints Play School
            </h1>
            {typeof this.props.centerName !== "undefined" && (
              <h4 className="text-white">
                {this.props.centerName}, {this.props.city}
              </h4>
            )}
          </Container>
        </Jumbotron>

        <InnerHOC heading="My Center">
          <Row>
            {this.props.centerHeadInfo?.image ? <CenterHeadInfo item={ this.props.centerHeadInfo } teachersPath={this.props.teachersPath} /> : null}
            {this.props.teachers.map((item, index) => (
              <MyCenterItem key={index} item={item} index={index} />
            ))}
            {(this.props.teachers.length === 0 || typeof this.props.teachers === "undefined") ?(
                <ListEmptyComponent
                  heading={this.props.loading ? "Loading..." : "No Data Found"}
                />
              ) : null}
          </Row>
        </InnerHOC>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  teachers: state.myCenter.teachers,
  user: state.userState.user.activeChildDetail,
  centerName: state.global.myCenter && state.global.myCenter.Center_Name,
  centerHeadInfo: {
    name: state.global?.myCenter?.Center_Head_Name || "",
    image: state.global?.myCenter?.Center_Head_Photo || "",
    phone: state.global?.myCenter?.Center_Head_Contact || ""
  },
  city: state.global.myCenter && state.global.myCenter.Center_City,
  loading: state.myCenter?.loading,
  teachersPath: state.constants.teachersPath
});

const mapDispatchToProps = dispatch => ({
  getCenterList: () => dispatch(getCenterList()),

  fetchTeachers: onSuccess => dispatch(fetchTeachers(onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(MyCenter);
