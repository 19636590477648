import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { showPasswordOverlay } from "../../global/redux/action";

import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
  CustomInput
} from "reactstrap";
import { getCenterList } from "../../global/redux/action";
import { changeProgram } from "./redux/action";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import moment from "moment";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
import DefaultConstants from "../../defaultConstants";
// import DefaultConstants from "../../defaultConstants";

class ChangeProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: undefined,
      selectedProgram: undefined
    };
  }
  componentDidMount() {
    console.log("[Program Change] Fetching Center List...");
    this.props.getCenterList();
  }

  validate = () => {
    if (this.state.holidaySelected === true) {
      this.setState({ error: "The Selected Date Cannot Be a Holiday" });
      return false;
    } else if (this.state.selectedDate === undefined) {
      this.setState({ error: "Please Select Date" });
      return false;
    } else if (this.state.selectedProgram === undefined || this.state.selectedProgram === '') {
      this.setState({ error: "Please Select Program" });
      return false;
    } else {
      return true;
    }
  };

  submit = type => {
    // alert(this.state.selectedProgram);
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, isMatched => {
      //   if (isMatched) {
          this.props.changeProgram(
            this.state.selectedDate,
            this.state.selectedProgram,
            () => {
              this.props.history.replace("PastRequestTab");
            }
          );
        } else {
        }
      // });
    // }
  };
  render() {
    const { programList } = this.props;
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em"
            }}
          >
            Change Program
          </CardHeader>
          <CardBody>
            <Card body className="border-0 shadow">
              <Alert color="warning">
                {this.props.constants.changeProgramTitle || DefaultConstants.changeProgramTitle}
              </Alert>
              <Form>
                <FormGroup>
                  <Label for="programCurrent">Current Program</Label>
                  {typeof this.props.result !== "undefined" &&
                    typeof this.props.result.label === "string" && (
                      <Input
                        type="text"
                        name="programCurrent"
                        id="programCurrent"
                        placeholder={this.props.result.label}
                        disabled
                      />
                    )}
                </FormGroup>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="newCenterSelect">New Program</Label>

                      <CustomInput
                        id={"program"}
                        type="select"
                        onChange={event => {
                          let selectedValue = event.target.value;
                          this.setState(
                            {
                              selectedProgram: selectedValue,
                              error: undefined
                            },
                            () => { }
                          );
                        }}
                      >
                        <option value=''>
                          Select Program
                        </option>
                        {programList.map((item, i) => {
                          return (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="startDate">From Date</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FootprintDatePicker
                          holidaySelected={selected => {
                            this.setState({
                              error: "The selected Date Cannot Be a Holiday",
                              holidaySelected: selected
                            });
                          }}
                          inputProps={{ placeholder: "Select Date" }}
                          minimumDate={moment()}
                          //defaultDate={this.state.selectedDate}
                          onChange={date => {
                            this.setState({
                              holidaySelected: false,
                              selectedDate: moment(date).format(DEFAULT_FORMAT),
                              error: undefined
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  {typeof this.state.error !== "undefined" && (
                    <Label style={{ color: "red" }}>{this.state.error}</Label>
                  )}
                </FormGroup>
                <FormGroup className="mb-0 text-center">
                  <FootprintInputButton
                  style={{ color: "white" }}
                    className="btn btn-primary"
                    onClick={() => {
                      this.submit();
                    }}
                  >
                    Submit
                  </FootprintInputButton>
                </FormGroup>
              </Form>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = ({global, userState, constants, support }) => {
  let currentProgram = userState?.user?.activeChildDetail?.length;
  // alert(currentProgram);

  const result = global.programList.find(
    item => item.value === currentProgram
  );

  const programsAvailable = support?.requestType?.programs_available;
  // console.log("Program => ",programsAvailable);
  // console.log("Program gobal list => ", global.programList);
  let programList = [];

  if (programsAvailable) {
    let validateList = programsAvailable.split(",")
    programList = global.programList.filter(
      (program) => validateList.indexOf(program.value) >= 0
    );
  }
  if(!programList || programList.length === 0){
    programList = global.programList.filter(
      (item) => item.value !== currentProgram
    );
  }

  // console.log("Program List => ", programList);

  return {
    centerList: global.centerList,
    currentProgram,
    constants: constants,
    programList,
    result
  };
};

const mapDispatchToProps = dispatch => ({
  getCenterList: () => dispatch(getCenterList()),
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  changeProgram: (selectedDate, selectedProgram, onSuccess) =>
    dispatch(changeProgram(selectedDate, selectedProgram, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeProgram);
