import React from "react";
// import { connect } from 'react-redux';
import Carousel, { Modal, ModalGateway } from "react-images";

class ChatItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }
  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };
  displayPhotos = ({ item }) => {
    //console.log('itms', item)

    return (
      <div>
        <img
          src={item.thumb}
          alt="TeacherImages"
          onClick={this.toggleModal}
          style={{ width: "50px", height: "50px" }}
        />

        <ModalGateway>
          {this.state.modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel
                components={{ Footer: '' }}
                views={[
                  {
                    src: item.message,
                    width: 2,
                    height: 2,
                    thumbnail: item.thumb,
                  },
                ]}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  };
  render() {
    // const { modalIsOpen } = this.state;

    const { item } = this.props;
    // console.log("Item=> ",item);
    let msg = item.message.replace(/\\(["'\\/])/g, "$1");
    // console.log("Message=> ",msg);
    return (
      <div className="ml-2">
        {item.who !== "teacher" && (
          <div className="d-flex justify-content-end mb-4">
            <div className="msg_cotainer py-2 px-3 px-lg-4 py-lg-3 shadow position-relative">
              {item.message}
              <span className="msg_time"> {item.time}</span>
            </div>
          </div>
        )}
        {item.who === "teacher" && (
          <div className="d-flex justify-content-start mb-4">
            <div className="msg_cotainer py-2 px-3 px-lg-4 py-lg-3 py-lg-3 shadow position-relative">
              {item.type === "Image" && (
                <div>
                  {/* <Carousel views={images} components={{ Footer: customFooter }} /> */}

                  <this.displayPhotos item={item} />
                </div>
              )}
              {item.type !== "Image" && msg}
              <span className="msg_time"> {item.time}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ChatItem;
