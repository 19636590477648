import React from "react";
import { connect } from "react-redux";
import { fetchMeasurements } from "../redux/action";
import { Row } from "reactstrap";
import MeasurementItem from "./item";
import ListEmptyComponent from "../../../global/utils/ListEmptyComponent";
import moment from "moment";

class Mesurement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    console.log("[Growth][Measurement] Fetching Data...");
    this.props.fetchMeasurements();
  }

  render() {
    const { measurements, loading } = this.props;
    // console.log("Measurement: ",measurements);
    const yearData = measurements.reduce((acc, curr) => {
      let index = acc.findIndex((ele) => ele.year === moment(curr?.date).year());
      if (index >= 0) {
        acc[index].data = [...acc[index].data,
          {
            height: curr.height,
            weight: curr.weight,
            month: moment(curr?.date).month(),
          },
        ];
        return acc;
      } else {
        return [
          ...acc,
          {
            year: moment(curr?.date).year(),
            data: [{height: curr.height,
            weight: curr.weight,
            month: moment(curr?.date).month()}]
          },
        ];
      }
    
    }, []);
  //  console.log("Year List: ",yearData)
    if (Array.isArray(measurements) && measurements.length > 0) {
      return (
        <div>
          <Row>
            {/* {yearData.map((item, index) => ( */}
              <MeasurementItem
                history={this.props.history}
                item={yearData}
                // key={index}
                // index={index}
              />
            {/* ))} */}
          </Row>
        </div>
      );
    } else {
      return <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} />;
    }
  }
}
const mapStateToProps = state => ({
  measurements: state.growth.measurements,
  loading: state.growth.measureLoading
});

const mapDispatchToProps = dispatch => ({
  fetchMeasurements: () => dispatch(fetchMeasurements())
});
export default connect(mapStateToProps, mapDispatchToProps)(Mesurement);
