import { FETCH_ACTIVITY, fetchActivitySuccess } from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchActivityCalenderLogic = createLogic({
  type: FETCH_ACTIVITY,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('ACTIVITY', true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/planner/getPlanner`;
      const body = {
        date: action.payload.date,
        plan_date: [
          moment(action.payload.date).format(SERVER_DATE_FORMAT),
          moment(action.payload.date).format(SERVER_DATE_FORMAT)
        ],
        plan_type: "all"
      };
      console.log("[Activity Calendar] Calling API: /planner/getPlanner, payload: ", body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Activity Calendar] Received response: ",response);
          if (response.status === "ok") {
            const month = moment(action.payload.date).format("MMMM");
            // const month = "November";
            dispatch(fetchActivitySuccess(response.response.Daily[month]));
            dispatch(setLoading(false));
            dispatch(onErrLoading("ACTIVITY", false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('ACTIVITY', false));
            done();
          }
        },
        error => {
          console.log("[Activity Calendar] Error: ",error);
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('ACTIVITY', false));
          setContext("Activity Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Activity Calendar] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('ACTIVITY', false));
      setContext("Activity Error", error);
      done();
    }
  }
});
export default [fetchActivityCalenderLogic];
