import {
  OUTDOOR_ACTIVITY,
  getOutDoorActivity,
  getOutDoorActivitySuccess,
  POST_EVENT_ACTION_REQUIRED
} from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";

export const fetchOutDoorActivityLogic = createLogic({
  type: OUTDOOR_ACTIVITY,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(onErrLoading('OUTDOOR', true));
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType.toLowerCase();
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/centerevents/my_events`;
      const body = {
        //child_id: "342",
        // consent_by: "father"
        //child_id: getState().userState.user.activeChildDetail.id,
        consent_by: parentType,
      };
      console.log("[Outdoor Activity] Calling API: /centerevents/my_events, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,

        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Outdoor Activity] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(getOutDoorActivitySuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('OUTDOOR', false))
            done();
          }
        },
        (error) => {
          console.log("[Outdoor Activity] Error: ",error);
          dispatch(setLoading(false));
          //console.log("Error: ", error.message);
          dispatch(onErrLoading('OUTDOOR', false))
          done();
        }
      );
    } catch (error) {
      console.log("[Outdoor Activity] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('OUTDOOR', false))
      done();
    }
  },
});

export const postEventActionReq = createLogic({
  type: POST_EVENT_ACTION_REQUIRED,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType;
      const consent_by = parentType;

      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/centerevents/event_act`;
      const body = {
        event_id: action.payload.eventId,
        consent_by: consent_by,
        status: action.payload.type,
      };
      console.log("[Post Outdoor Activity] Calling Api: /centerevents/event_act, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        successReportType: "Alert",
        errorReportType: "Alert",
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Post Outdoor Activity] Received Response: ",response);
          if (response.status === "ok") {
            // console.log("Logic.js ", response.message);
            // alert("Success", response.message);
            dispatch(getOutDoorActivity());
            dispatch(setLoading(false));

            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          }
        },
        (error) => {
          console.log("[Post Outdoor Activity] Error: ",error);
          dispatch(getOutDoorActivity());

          dispatch(setLoading(false));

          done();
        }
      );
    } catch (error) {
      console.log("[Post Outdoor Activity] Error: ",error);
      dispatch(getOutDoorActivity());

      dispatch(setError("Some Error Occured", error.message));

      dispatch(setLoading(false));
      done();
    }
  },
});
export default [fetchOutDoorActivityLogic, postEventActionReq];
