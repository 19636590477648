import React from "react";
import { PulseLoader } from "react-spinners";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
// import Theme from "../../theme";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const SpinnerLoading = (props) => {
  let { loading } = props;
  
  // loading=true;
  return (
    <Modal
      isOpen={loading}
      className="modal-xl spinner-modal h-100"
      backdrop={true}
      style={{
        display: "grid",
        placeItems: "center",
        marginTop: 0,
        marginBottom: 0,
      }}
      fade={false}
      data-dismiss="modal"
    >
      <PulseLoader
        color={"#19ade4"}
        loading={loading}
        cssOverride={override}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.global.showLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerLoading);
