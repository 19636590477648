import React from "react";
import nico from "../../assets/splash-nico-hi.png";
import { connect } from "react-redux";
import { signIn } from "./redux/action";
// import { Link } from "react-router-dom";

import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Card, CardBody, CardTitle } from "reactstrap";
import blogStyle from "../Blogs/style";
import manifest from "../../manifest.json";
import { showModal } from "../../global/redux/action";
// import Constants from "../../constants";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // email: "",
      // password: "",
      isLogin: false,
      error: "",
      email: props?.defaultPhone ? "" : props?.defaultEmail,
      password: props?.defaultPassword,
      phone: props?.defaultPhone,
      // hashString: "",
    };
  }
  keyPressed = (event) => {
    if (event.key === "Enter") {
      // console.log("set focus");
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
    }
  };
  handleChange = (event) => {
    const value = event.target.value;
    let other = event.target.name==='phone' ? 'email' : 'phone';
    this.setState({ [event.target.name]: value , [other]:"" ,error:"" });
  };
  validate = () => {
    if (this.state.phone === "" && this.state.email === '') {
      this.setState({ error: "Please Enter Phone number or Email Id" });
      // this.props.showModal("Error", "Please Enter Phone number or Email Id")
      return false;
    } 
    else if (this.state.phone && (!this.state.phone.match(/^[0-9]+$/) || this.state.phone.length!==10)) {
      this.setState({ error: "Please Enter valid number" });
      // this.props.showModal("Error", "Please Enter valid number")
      return false;
    } 
    else {
      return true;
    }
  };
  login = () => {
    localStorage.setItem("auth_token",'');
    this.props.login(
      this.state.phone , this.state.email,'',
      (response) => {
        // if (response && response.survey && response.survey.survey_id > 0) {
        //   this.props.history.replace({
        //     pathname: `survey/${response.survey.survey_id}`,
        //   });
        // } else {
          //this.props.history.push("dashboard");
          this.props.history.replace("verifyUser");
        // }
      },
      (error) => {
        // this.setState({ error });
      }
    );
  };

  async componentDidMount() {
    console.log("[Login] Enter login details...");
    // if (Constants.env.platform === 'android' && Constants.env.web === 'no') {
      // await window.cordova.plugins.SMSRetriever.getHashString((res) => {
      //   // console.log("Hash String => ", res);
      //   if (res) {
      //     this.setState({ ...this.state, hashString: res });
      //   }
      // });
    // }
  }


  render() {
    const { error } = this.state;
    const { innerWidth: width, innerHeight: height } = window;
    return (
      <div>
        <Card style={blogStyle.cardView}>
          <img
            className={height > 741 ? "" : "mr-auto ml-auto"}
            top="true"
            width={height < 741 ? "55%" : height < 900 ? "90%" : "100%"}
            src={nico}
            alt="Card image cap"
          />
          <CardBody>
            <CardTitle>
              <h5
                style={
                  height > 741
                    ? { fontFamily: "baloo" }
                    : { fontFamily: "baloo", fontSize: "1rem" }
                }
              >
                Login to your account
              </h5>
            </CardTitle>
            <Form style={height < 741 ? { lineHeight: "0.5rem" } : {}}>
              {/* <FormGroup>
                <Label for="exampleEmail" style={blogStyle.label}>
                  Email
                </Label>
                <Input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  onKeyPress={this.keyPressed}
                  placeholder="Your email"
                  style={blogStyle.input}
                  ref="email"
                /> */}
              {/* <FormFeedback valid>Sweet! that name is available</FormFeedback>
              <FormText>Example help text that remains unchanged.</FormText> */}
              {/* </FormGroup> */}
              <FormGroup>
                <Label for="examplePassword" style={blogStyle.label}>
                  Phone Number
                </Label>
                <Input
                  type="number"
                  name="phone"
                  placeholder="Your phone number"
                  value={this.state.phone}
                  onChange={this.handleChange}
                  style={blogStyle.input}
                  onKeyPress={this.keyPressed}
                  ref="phone"
                />

                {/* <FormFeedback valid>Oh noes! that name is already taken</FormFeedback> */}
                {/* <FormText>Example help text that remains unchanged.</FormText> */}
              </FormGroup>
              <h6
                style={{
                  ...blogStyle.label,
                  textAlign: "center",
                }}
              >
                OR
              </h6>
              <FormGroup>
                <Label for="examplePassword" style={blogStyle.label}>
                  Email
                </Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  value={this.state.email}
                  onChange={this.handleChange}
                  style={blogStyle.input}
                  onKeyPress={this.keyPressed}
                  ref="phone"
                />
              </FormGroup>
              {/* {typeof error === "string" && (
                <div style={{ color: "red", marginBottom: 20 }}>{error}</div>
              )} */}
              {typeof this.state.error !== "undefined" && (
                <p style={{ color: "red", lineHeight: "1.2rem" }}>
                  {this.state.error}
                </p>
              )}
              <Button
                onClick={() => {
                  if (this.validate()) {
                    console.log("Login success");
                    this.login();
                  }
                }}
                style={blogStyle.button}
                size="lg"
                block
              >
                Login
              </Button>
              {/* <div style={height < 741 ? { marginTop: "0.5rem" } : {}}>
                <Link to={"forgetPassword"}>
                  <span
                    style={{
                      color: "black",
                      float: "right",
                      fontFamily: "baloo",
                    }}
                  >
                    Forgot Password ?
                  </span>
                </Link>
              </div> */}
            </Form>
          </CardBody>
        </Card>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            alignItems: "center",
          }}
        >
          <h6
            style={{ fontFamily: "baloo", fontSize: 14 }}
          >{`${manifest.applicationName} v${manifest.version}`}</h6>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //isLogin: state.login.isLogin
  defaultEmail: state.userState.email,
  defaultPassword: state.userState.password,
  defaultPhone: state.userState.phone,
});
const mapDispatchToProps = (dispatch) => ({
  login: (phone,email,verify, onSuccess, onFailure) =>
    dispatch(signIn(phone, email, verify, onSuccess, onFailure)),
  showModal: (type,message) =>
    dispatch(showModal(type,message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
//export default Login;
