import { CHANGE_TRANSPORT, CANCEL_TRANSPORT_REQUEST } from "./action";
import { getRequestType, fetchPastRequest } from "../../Support/redux/action";

import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";

import moment from "moment";

import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const changeTransport = createLogic({
  type: CHANGE_TRANSPORT,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();

      // const parentName = getState().constants.parentName.toLowerCase();

      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/request`;

      const body = {
        date: moment(action.payload.selectedDate).format(SERVER_DATE_FORMAT),
        end_date: moment(action.payload.selectedDate).format(
          SERVER_DATE_FORMAT
        ),
        attrition_reason: "",

        // password: getState().userState.password,
        parent_name: parentName,

        // const Param = '?child_id='+childId
        // +'&parent_name='+parentName
        // +'&date='+date
        // +'&end_date='+end_date
        // +'&password='+ password
        // +'&attrition_reason='+pause_reason
        // +values
      };
      console.log("[Transport] Calling API: /parentapp/request, Payload: ",body);
      // console.log(getState().support.requestType.serviceStatus.Transport1);
      if (action.payload.oneWay) {
        body.one_way_transport =
          getState().support.requestType.serviceStatus.Transport1 !==
          "Stop Transport 1 Way"
            ?
          getState().support.requestType?.serviceStatus?.Transport1 !==
          "Stop Transport 1 Way"
            ? "yes"
            : "no"
            : "no";
        body.center_shift = "no";
        body.values = ["one_way_transport", "center_shift"];
      } else if (!action.payload.oneWay) {
        body.two_way_transport =
          getState().support.requestType.serviceStatus.Transport2 !==
          "Stop Transport 2 Way"
            ?
          getState().support.requestType?.serviceStatus?.Transport2 !==
          "Stop Transport 2 Way"
            ? "yes"
            : "no"
            : "no";;
        body.center_shift = "no";
        body.values = ["two_way_transport", "center_shift"];
      }
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        successReportType: "Alert",
        errorReportType: "Alert",
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Transport] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        error => {
          console.log("[Transport] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Change Transport Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Transport] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Change Transport Error", error);
      done();
    }
  }
});

export const cancelTransportRequest = createLogic({
  type: CANCEL_TRANSPORT_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/financials/cancelCustomerRequest`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_type: action.payload.type,
        request_id: action.payload.request_id,
        updated_by_name: getState().userState.parentProfile.parentName,
        updated_by: 0,
      };
      console.log("[Cancel Transport] Calling API: /financials/cancelCustomerRequest, Payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        successReportType: "Alert",
        errorReportType: "Alert",
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Cancel Transport] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //  NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Cancel Transport] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Cancel Change Transport Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Cancel Transport] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Change Transport Error", error);
      done();
    }
  }
});

export default [changeTransport, cancelTransportRequest];
