export const WITHDRAW_CHILD = "WITHDRAW_CHILD";
export const CANCEL_WITHRAW_REQUEST = "CANCEL_WITHRAW_REQUEST";
export const withrawChild = (
  { withdrawDate, reason, otherRemark, rating, tags, comment },
  onSuccess
) => ({
  type: WITHDRAW_CHILD,
  payload: {
    withdrawDate,
    otherRemark,
    rating,
    reason,
    tags,
    comment,
    onSuccess
  }
});

export const cancelWithrawlRequest = request => ({
  type: CANCEL_WITHRAW_REQUEST,
  payload: request
});
