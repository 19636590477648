export const CHANGE_TRANSPORT = "CHANGE_TRANSPORT";
export const CANCEL_TRANSPORT_REQUEST = "CANCEL_TRANSPORT_REQUEST";

export const changeTransport = (oneWay, selectedDate, onSuccess) => ({
  type: CHANGE_TRANSPORT,
  payload: { oneWay, selectedDate, onSuccess },
});

export const cancelTransportRequest = request => ({
  type: CANCEL_TRANSPORT_REQUEST,
  payload: request
});
