import React from "react";
import { Toast, ToastBody } from "reactstrap";
import { connect } from "react-redux";
import { setInfo } from "../redux/action";
class Footprintinfo extends React.Component {
  componentDidMount() { }

  componentDidUpdate(prevProps) {
    if (this.props.info && this.props.info.subtitle) {
      setTimeout(() => {
        this.props.setInfo();
      }, 2000);
    }
  }
  removeHtmlTags = (message) => {
    if (typeof message === "string") {
      const regex = /(<([^>]+)>)/gi;
      message = message.replace(regex, "");
      return message;
    }
    return message;
  };

  render() {
    const { info } = this.props;
    if (typeof info !== "undefined" && typeof info.subtitle === "string") {
      const { subtitle } = info;
      return (
        <Toast
          style={{
            position: "sticky",
            bottom: 50,
            margin: 'auto'
          }}
        // center={true}
        >
          <ToastBody style={{ textAlign: 'center' }}>
            {this.removeHtmlTags(subtitle)}
          </ToastBody>
        </Toast>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  info: state.global.info || {},
});

const mapDispatchToProps = (dispatch) => ({
  setInfo: (title, subtitle) => dispatch(setInfo(title, subtitle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footprintinfo);
