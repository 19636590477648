import React from "react";
// import Blogs from "../Blogs";
// import Login from "../Login";
// import Header from "../Blogs/Header";
// import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
// import NotificationService from "../../global/NotificationService";
// import FootprintNotification from "../../global/utils/FootprintNotification";
import { logout } from "../Login/redux/action";
import Constants from "../../constants";
import { resetReduxOnAppUpdate } from "../../global/redux/action";
// import { verifyOTP } from "../Login/redux/action";

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    console.log("[Splash Screen] Checking Login Condition...");
    try {
      // console.log("Token Timed out : ", this.props.tokenTimeout);
      // console.log("Current App Version : ", this.props.appVersion);
      // console.log("New App Version : ", Constants.env.version);

      if (this.props.appVersion !== Constants.env.version) {
        // console.log("Preparing To Logout");
        await this.props.resetReduxOnAppUpdate();
        localStorage.setItem("auth_token","");
        this.props.history.replace("/login");
      }
      let loggedInChild = localStorage.getItem("auth_token");
      if (this.props.user && this.props.user.activeChildDetail && loggedInChild) {
        if (moment(this.props.lastLoggedIn).isValid()) {
          var currentDate = moment();
          var lastLoggedInDate = moment(this.props.lastLoggedIn);
          const difference = currentDate.diff(lastLoggedInDate, 'days');
          if (difference < this.props.tokenTimeout) {
            if (window.location.href.indexOf("notification") > -1) {
              this.props.history.replace("/notification");
            } else {
              this.props.history.replace("/blogs");
            }
          } else {
            this.props.history.replace("/login");
          }
        } else {
          this.props.history.replace('/blogs');
        }
      } else {
        this.props.history.replace('/login');
      }
    } catch (error) {
    console.log("[Splash Screen] Error: ",error);
      // Error retrieving data
    }
  }


  render() {
    return (
      <div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state?.userState?.user,
  lastLoggedIn: state?.userState?.lastLoggedIn,
  tokenTimeout: state?.constants?.tokenTimeout || 30,
  appVersion: state?.userState?.user?.app_version || '4.2.0'
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  resetReduxOnAppUpdate: () => dispatch(resetReduxOnAppUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
