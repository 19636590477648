import React from "react";
// import { connect } from 'react-redux';

import { Card, CardHeader, CardBody, CardText } from "reactstrap";

const TABS = [
  {
    background: "#F44A4A",
    iconClass: "icon-cctv-1"
  },
  {
    background: "#00C351",
    iconClass: "icon-cctv-2"
  },
  {
    background: "#A500F8",
    iconClass: "icon-cctv-3"
  },
  {
    background: "#C41162",
    iconClass: "icon-cctv-4"
  },
  {
    background: "#304FFE",
    iconClass: "icon-cctv-5"
  },
  {
    background: "#DA0307",
    iconClass: "icon-cctv-6"
  },
  {
    background: "#6200EA",
    iconClass: "icon-cctv-7"
  },
  {
    background: "#AA00FF",
    iconClass: "icon-cctv-8"
  },
  {
    background: "#00BFA5",
    iconClass: "icon-cctv-9"
  },
  ,
  {
    background: "#00BFA5",
    iconClass: "icon-cctv-9"
  },
  ,
  {
    background: "#00BFA5",
    iconClass: "icon-cctv-9"
  },
  ,
  {
    //  image: require("../../assets/support/school.svg"),
    background: "#00BFA5",
    iconClass: "icon-cctv-9"
  }
];
class ActivityCalenderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, index } = this.props;
    // console.log("Text: ", item.plan_parent_text);
    const background = TABS[index % 9].background;
    let msg = item.plan_parent_text
      .replace(/amp;/g, "")
      .replace(/&lt;br&gt;/g, "\n")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&#039;/g, "'")
      .replace(/&ldquo;/g, "\u201C")
      .replace(/&rdquo;/g, "\u201D");
    // const background = 'red';
    return (
      <div className="timeline-block">
        <span className="timeline-step" style={{ background, color: "#fff" }}>
          <i className="icon-programs" style={{ fontSize: "1.5em" }}></i>
        </span>
        <div className="timeline-content">
          <Card id={index} className="border-0 shadow h-100">
            <CardHeader className="h5 border-0">
              <span
                className="badge badge-pill"
                style={{
                  fontSize: ".7em",
                  fontFamily: "Baloo",
                  textTransform: "capitalize",
                  background,
                  color: "#fff",
                  fontWeight: '100',
                  whiteSpace: "pre-line"
                }}
              >
                {item.plan_activity.replace(/<br>/g, "\n")}
              </span>
            </CardHeader>
            <CardBody className="pt-2">
              <CardText style={{whiteSpace: "pre-line"}}>
                {typeof item.plan_parent_text === "string"
                  ? msg.trim()
                  : ""}
              </CardText>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default ActivityCalenderItem;
