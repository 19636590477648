import moment from "moment";

import Break from "../../Break";
import ShiftCenter from "../../ShiftCenter";
import ProgramChange from "../../ProgramChange";
import Finance from "../../Finance";
import Transport from "../../Transport";
import WithdrawChild from "../../WithdrawChild";
import ExtendedDaycare from "../../ExtendedDaycare";

export const tabs = [
  {
    backgroundColor: "#00C853",
    borderColor: "#00C853",
    path: "Pause",
    key: "ChildPause",
    heading: "Take A Break",
    pendingHeading: "Child On Pause",
    value: "Pause Services",
    pendingValue: "Pause Cancellation and Extensions",
    pendingRequests: ["Leaves", "Child Pause"],
    // image: require("../../../assets/support/child.svg")
    icon: "icon-rest-child",
    component: Break
  },

  {
    pendingRequests: ["Center Shift"],
    backgroundColor: "#FFAB00",
    path: "ShiftCentre",
    pendingValue: "Cancel Transfer",
    key: "Center Shift",
    heading: "Shift Center",
    value: "Request A Transfer",
    //  image: require("../../../assets/support/school.svg")
    icon: "icon-admission",
    component: ShiftCenter
  },

  {
    pendingRequests: ["Program Change"],
    backgroundColor: "#0091EA",
    path: "ChangeProgram",
    pendingValue: "Cancel Request",
    key: "Program Change",
    heading: "Change Program",
    value: "Request Change Program",
    //  image: require("../../../assets/support/request.svg")
    icon: "icon-change-program",
    component: ProgramChange
  },

  {
    // onPress:{() :> { this.openLink(); }}
    pendingRequests: [],
    path: "Finance",
    key: "Finance",
    alwaysShow: true,
    backgroundColor: "#C51162",
    heading: "Finance",
    value: "Pay Invoices, Fee Certificate & Account Statement",
    //  image: require("../../../assets/support/bill.svg")
    icon: "icon-invoice",
    component: Finance
  },

  {
    pendingRequests: [
      "Add One Way Transport",
      "Add Two Way Transport",
      "Delete One Way Transport",
      "Delete Two Way Transport",
    ],
    path: "Transport",
    key: "Transport",
    pendingValue: "Cancel Request",
    backgroundColor: "#304FFE",
    heading: "Transport",
    value: "Start Or Stop Transport Service",
    //  image: require("../../../assets/support/school-bus.svg")
    icon: "icon-transport",
    component: Transport
  },
  {
    pendingRequests: ["Child Attrition"],
    path: "WithrawChild",
    pendingValue: "Cancel Withdrawal",
    backgroundColor: "#D50000",
    key: "ChildWithdraw",
    heading: "Withdraw Child",
    value: "Share Date Reason And Feedback",
    //  image: require("../../../assets/support/withdrawal.svg")
    icon: "icon-withdraw",
    component: WithdrawChild
  },
  {
    pendingRequests: ["Extended Daycare"],
    path: "ExtendedDaycare",
    pendingValue: "Cancel Extended Daycare",
    backgroundColor: "#D50000",
    key: "Extended Daycare",
    heading: "Extended Daycare",
    value: "Extend Daycare",
    //  image: require("../../../assets/support/withdrawal.svg")
    icon: "icon-withdraw",
    component: ExtendedDaycare,
  },
];

const getPendingRequest = (transformTabs, pendingRequests) => {
  return transformTabs.map(item => {
    let pendingRequest = false;
    pendingRequests.forEach(request => {
      if (item.pendingRequests.indexOf(request.type) > -1) {
        if (request.type === "Center Shift" && request.status !== "Cancelled") {
          pendingRequest = true;
        } else if (
          request.type === "Child Resume" &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        } else if (
          (request.type === "Leaves" || request.type === "Child Pause") &&
          request.status === "Pending"
        ) {
          pendingRequest = true;
        } else if (
          (request.type === "Leaves" || request.type === "Child Pause") &&
          request.status !== "Processing"
        ) {
          pendingRequest = true;
        } else if (
          request.type === "Child Attrition" &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        } else if (
          request.type === "Program Change" &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        } else if (
          (request.type === "Add One Way Transport" ||
            request.type === "Add Two Way Transport") &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        } else if (
          (request.type === "Delete One Way Transport" ||
            request.type === "Delete Two Way Transport") &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        } else if (
          request.type === "Extended Daycare" &&
          request.status !== "Cancelled"
        ) {
          pendingRequest = true;
        }
      }
    });

    return {
      ...item,
      pendingRequest: pendingRequest
    };
  });
};

export const transformRequests = pastRequests => {
  try {
    const transformedRequests = pastRequests.map(request => {
      //-------------------------------- show cancel button on past request logic---------------------------
      let showCancel = false;
      let showEarlyPauseAndResume = false;
      if (
        (request.type === "Leaves" || request.type === "Child Pause") &&
        (request.status === "Approved" ||
          request.status === "Pending" ||
          request.status === "Processing") &&
        moment(request.date).isAfter("2017-04-01") &&
        moment().isBefore(moment(request.end_date, "YYYY-MM-DD").add(1, "days"))
      ) {
        showEarlyPauseAndResume = true;
      }
      if (
        (request.type === "Leaves" || request.type === "Child Pause") &&
        // (request.status === "Pending" || request.status === "Processing") &&
        request.status === "Pending" &&
        moment().isBefore(request.date)
      ) {
        showCancel = true;
        showEarlyPauseAndResume = false;
      } else if (
        (request.type === "Add One Way Transport" ||
          request.type === "Add Two Way Transport") &&
        (request.status === "Processing" || request.status === "Pending")
      ) {
        showCancel = true;
      } else if (
        (request.type === "Delete One Way Transport" ||
          request.type === "Delete Two Way Transport") &&
        (request.status === "Processing" || request.status === "Pending")
      ) {
        showCancel = true;
      } else if (
        (request.type === "Center Shift" || request.type === "Program Change") &&
        // (request.status === "Pending" || request.status === "Processing")
        (request.status === "Pending")
      ) {
        showCancel = true;
      } else if (
        request.type === "Child Attrition" &&
        (request.status === "Pending" || request.status === "Processing")
      ) {
        showCancel = true;
      } else if (
        request.type === "Extended Daycare" &&
        (request.status === "Pending" || request.status === "Processing")
      ) {
        showCancel = true;
      } else {
        showCancel = false;
      }

      const transformedRequest = {
        ...request,
        showCancel,
        showEarlyPauseAndResume
      };
      return transformedRequest;
    });
    return transformedRequests;
  } catch (error) {}
};

export const transformTabs = (allowedServices, pendingRequests) => {
  const tabsBasedOnAllowedServices = tabs.filter(
    item => item.alwaysShow || allowedServices.indexOf(item.key) > -1
  );
  const tabsWithPendingRequest = getPendingRequest(
    tabsBasedOnAllowedServices,
    pendingRequests
  );
  // key basically checks if the tab's key is there in allowed services or not.
  // only show this to custoemr if its in allowed service
  return tabsWithPendingRequest;
};
