export const FETCH_ATTENDANCE = "FETCH_ATTENDANCE";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";

export const fetchAttendance = () => ({
  type: FETCH_ATTENDANCE
});

export const fetchAttendanceSuccess = attendanceList => ({
  type: FETCH_ATTENDANCE_SUCCESS,
  payload: {
    attendanceList
  }
});