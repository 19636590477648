import React from "react";
import { Label, Input } from "reactstrap";
const EditComponent = ({ onImagePicked, id }) => (
  <div
    className="text-center"
    style={{
      position: "absolute",
      width: "100%",
      marginTop: "-41px"
    }}
  >
    <Label
      for={id}
      style={{
        backgroundColor: "#f7fafc",
        borderColor: "#f7fafc",
        borderRadius: "3px",
        boxShadow:
          "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
      }}
    >
      
    </Label>
    <Input
      style={{ display: "none" }}
      id={id}
      type="file"
      onChange={event => {
        onImagePicked(event);
      }}
    />
  </div>
);

export default EditComponent;
