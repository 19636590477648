import React from "react";
import { connect } from "react-redux";
import { submitSurvey } from "./redux/action";
import { Navbar, NavbarBrand } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../assets/Footprints-Logo.svg";
import SurveyComponent from "./component/Survey";
import FootprintButton from "../../global/utils/FootprintInputButton";
import Theme from "../../theme";
class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: undefined,
      tags: undefined,
      comment: undefined
    };
  }
  componentDidMount() {
    console.log("[Survey] Fetching Survey details...");
  }

  validate = () => {
    if (this.state.rating === undefined || this.state.rating === '') {
      this.setState({ error: "Please Select Rating" });
      return false;
    } else if (this.state.tags === undefined || this.state.tags?.length === 0) {
      this.setState({ error: "Please check the Tags" });
      return false;
    } else if (this.state.comment === undefined || this.state.comment === '') {
      this.setState({ error: "Please Enter Comment" });
      return false;
    } else {
      return true;
    }
  };

  submit = type => {
    if (this.validate()) {
      if (this.props && this.props.match && this.props.match.params.surveyId) {
        this.props.submitSurvey(
          {
            rating: this.state.rating,
            tags: this.state.tags,
            comment: this.state.comment,
            surveyId: this.props.match.params.surveyId
          },
          () => {
            localStorage.setItem("survey_status",'');
            this.props.history.replace("/timeline");
          }
        );
      } else {
        this.setState({ error: "Survey id missing" });
      }
    }
  };

  render() {
    // console.log(this.state);
    return (
      <div
        className="bg-preview"
        style={{
          backgroundImage:
            "url(" + require("../../assets/bg-pattern.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh"
        }}
      >
        <Navbar
          color="white"
          light
          expand="md"
          className="shadow mb-5 fixed-top"
        >
          <NavbarBrand href="/" className="m-auto py-2">
            <img src={logo} alt="Logo" />
          </NavbarBrand>
        </Navbar>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={true}
          backdrop={false}
        >
          <ModalHeader
            style={{
              background: Theme.colorSecondry,
              fontFamily: "baloo",
              fontSize: "1.3em",
              color: "#fff"
            }}
          >
            <span className="text-white">Survey</span>
          </ModalHeader>
          <ModalBody>
            <SurveyComponent
              onRatingPressed={rating =>
                this.setState({ rating, error: undefined })
              }
              getComment={comment =>
                this.setState({ comment, error: undefined })
              }
              getTags={answers => {
                this.setState(
                  {
                    tags: answers.filter(item => item.checked === true),
                    error: undefined
                  },
                  () => {}
                );
              }}
            />
            {typeof this.state.error !== "undefined" && (
              <p style={{ color: "red" }}>{this.state.error}</p>
            )}
            <FootprintButton
              onClick={() => {
                this.submit();
              }}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  submitSurvey: (payload, onSuccess) => {
    dispatch(submitSurvey(payload, onSuccess));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Survey);
