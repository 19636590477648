import {
  GET_FEE_CERTIFICATE,
  NON_SATISFACTION_REFUND,
  EMAIL_FEE_CERTIFICATE,
  CHECK_NON_SATISFACTION_REFUND,
} from "./action";

import { getRequestType, fetchPastRequest } from "../../Support/redux/action";

import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";

import moment from "moment";
import Constants from "../../../constants";

import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const getFeeCertificate = createLogic({
  type: GET_FEE_CERTIFICATE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/financials/getTuitionFeeCertificate`;
      const body = {
        start_date: moment(action.payload.startDate).format(SERVER_DATE_FORMAT),
        end_date: moment(action.payload.endDate).format(SERVER_DATE_FORMAT),
        nature_of_Service: action.payload.natureOfService,
      };
      console.log("[Get Fee Certificate] Calling Api: /financials/getTuitionFeeCertificate, Payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        // successReportType: "Alert",
        errorReportType: "Alert",
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Get Fee Certificate] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');
            // as in this case user goes to share screen

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response.response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        (error) => {
          console.log("[Get Fee Certificate] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Fee Certificate Error", error);
          done();
        }
      );
    } catch (error) {
    console.log("[Get Fee Certificate] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Fee Certificate Error", error);
      done();
    }
  },
});

export const emailFeeCertificate = createLogic({
  type: EMAIL_FEE_CERTIFICATE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();

      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/sendFeeCertificate`;
      const body = {
        email: getState().userState.email,
        name: parentName,
        url: action.payload.url,
      };
      console.log("[Email Fee Certificate] Calling Api: /parentapp/sendFeeCertificate, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Email Fee Certificate] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //NavigationService.navigate('CustomerSupport');
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response);
              done();
            } else {
              //console.log("off fetch blogs");
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        (error) => {
          console.log("[Email Fee Certificate] Error: ",error);
          dispatch(setLoading(false));
          // failure
          //console.log("Error: ", error.message);
          setContext("Send mail error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Email Fee Certificate] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Send mail error", error);
      done();
    }
  },
});

export const checkNonSatisfactionRefund = createLogic({
  type: CHECK_NON_SATISFACTION_REFUND,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/financials/saveNonSatisfactionRefund/`;
      const body = {
        refund_child_id: getState().userState.user.activeChildDetail.id,
        show_refund_amount: "yes",
      };
      console.log("[Check Non Satisfaction Refund] Calling Api: /financials/saveNonSatisfactionRefund, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        fatal: false,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Check Non Satisfaction Refund] Receiving Response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            if (action.payload && action.payload.onError) {
              //alert('onError');
              action.payload.onError(response.error);
              done();
            }
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        (error) => {
          console.log("[Check Non Satisfaction Refund] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("check non Satisfaction Refund Error", error);
          done();
        }
      );
    } catch (error) {
    console.log("[Check Non Satisfaction Refund] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("check non Satisfaction Refund Error", error);
      done();
    }
  },
});
export const nonSatisfactionRefund = createLogic({
  type: NON_SATISFACTION_REFUND,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/financials/saveNonSatisfactionRefund/`;
      const body = {
        refund_date_submit: moment(action.payload.selectedDate).format(
          SERVER_DATE_FORMAT
        ),
        refund_date: moment().format(SERVER_DATE_FORMAT),
        refund_child_id: getState().userState.user.activeChildDetail.id,
        user_id: 0,
        refund_comments: action.payload.comment,
        refund_amount: action.payload.amount,
        show_refund_amount: "no",
      };
      console.log("[Non Satisfaction Refund] Calling Api: /financials/saveNonSatisfactionRefund, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,
        authenticatedAPI: false,
        successReportType: "Alert",
        errorReportType: "Alert",
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Non Satisfaction Refund] Received Response: ",response);
          if (response.status === "ok") {
            //console.log("saveNonSatisfactionRefund", response);
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        (error) => {
          console.log("[Non Satisfaction Refund] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Non Satisfaction Refund Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Non Satisfaction Refund] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Non Satisfaction Refund Error", error);
      done();
    }
  },
});

export default [
  getFeeCertificate,
  checkNonSatisfactionRefund,
  nonSatisfactionRefund,
  emailFeeCertificate,
];
