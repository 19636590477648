import {
  TAKE_BREAK,
  EXTEND_PAUSE,
  CANCEL_PAUSE_REQUEST,
  RESUME_FROM_BREAK,
  CANCEL_LEAVE_REQUEST
} from "./action";
import { getRequestType, fetchPastRequest } from "../../Support/redux/action";
import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import moment from "moment";

import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const takeABreak = createLogic({
  type: TAKE_BREAK,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/request`;
      const body = {
        date: moment(action.payload.startDate).format(SERVER_DATE_FORMAT),
        end_date: moment(action.payload.endDate).format(SERVER_DATE_FORMAT),
        attrition_reason: action.payload.reason,
        child_resume: "yes",
        child_pause: "yes",
        values: ["child_resume", "child_pause"],
        // password: getState().userState.password,
        parent_name: parentName
      };
      console.log("[Take A Break] Calling API: /parentapp/request, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[Take A Break] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
      console.log("[Take A Break] Error: ",error);
          dispatch(setLoading(false));
          setContext("Break Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Take A Break] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Break Error", error);
      done();
    }
  }
});
export const cancelPauseRequest = createLogic({
  type: CANCEL_PAUSE_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/admission/cancelChildPauseRequest/`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_id: action.payload.request_id,
        end_date: action.payload.end_date
      };
      console.log("[Cancel Pause] Calling API: /admission/cancelChildPauseRequest, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[Cancel Pause]  Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //  NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          dispatch(setLoading(false));
          // failure
          console.log("[Cancel Pause] Error: ",error);
          done();
        }
      );
    } catch (error) {
      console.log("[Cancel Pause] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  }
});

export const extendPause = createLogic({
  type: EXTEND_PAUSE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      // const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/franchises/pauseChild/`;
      const body = {
        req_id: parseInt(action.payload.requestId) + 1,
        extend_date: moment(action.payload.selectedDate).format(
          SERVER_DATE_FORMAT
        )
      };
      console.log("[Extend Pause] Calling API: /franchises/pauseChild, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        body,
        fatal: true,

        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[Extend Pause]  Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        error => {
          dispatch(setLoading(false));
          // failure
          console.log("[Extend Pause] Error: ",error);
          done();
        }
      );
    } catch (error) {
      console.log("[Extend Pause] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  }
});

export const resumeFromBreak = createLogic({
  type: RESUME_FROM_BREAK,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      // const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/franchises/resumeChild`;
      const body = {
        req_id: action.payload.requestId,
        early_resume_date: moment(action.payload.selectedDate).format(
          SERVER_DATE_FORMAT,
          "YYYY-MM-DD"
        )

        // const Param = '?child_id='+childId
        // +'&req_id='+rid
        // +'&early_resume_date='+date
      };
      console.log("[Resume Break] Calling API: /franchises/resumeChild, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        fatal: true,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };
      fetchAPI(fetchObject).then(
        response => {
      console.log("[Resume Break]  Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        error => {
      console.log("[Resume Break] Error: ",error);
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[Resume Break] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  }
});
export const cancelLeaveRequest = createLogic({
  type: CANCEL_LEAVE_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/admission/cancelChildPauseRequest/`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_id: action.payload.request_id,
        end_date: action.payload.end_date
      };
      console.log("[Cancel Leave] Calling API: /admission/cancelChildPauseRequest/, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        successReportType: "Alert",
        errorReportType: "Alert",
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[Cancel Leave] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
      console.log("[Cancel Leave] Error: ",error);
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[Cancel Leave] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  }
});
export default [
  takeABreak,
  extendPause,
  resumeFromBreak,
  cancelLeaveRequest,
  cancelPauseRequest
];
