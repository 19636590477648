import { setError, setLoading, onErrLoading } from "../../../global/redux/action";
import fetchAPI from "../../../global/utils/fetch";
import { FETCH_NOTIFICATION, fetchNotificationSuccess } from "./action";
import { createLogic } from "redux-logic";

import { ITEM_PER_PAGE } from "../../../global/redux/combineLogic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchNotifications = createLogic({
  type: FETCH_NOTIFICATION,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('NOTIFICATION', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getChildTimeline`;
      const body = {
        include_types: getState().constants.INCLUDE_TYPES_NOTIFICATION,
        index_start:
          action.payload.startIndex === 0 ? 0 : action.payload.startIndex + 1,
        limit: ITEM_PER_PAGE
      };
      console.log("[Notification] Calling API: /parentapp/getChildTimeline, payload: ", body);
      const fetchObject = {
        url,
        errorReportType: "none",
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Notification] Received Response: ", response);
          if (response.status === "ok") {
            dispatch(fetchNotificationSuccess(response.response));
            dispatch(setLoading(false));
            dispatch(onErrLoading("NOTIFICATION", false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {

              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('NOTIFICATION', false))
            done();
          }
        },
        error => {
          console.log("[Notification] Error: ", error);
          dispatch(setLoading(false));
          dispatch(onErrLoading('NOTIFICATION', false))
          setContext("Notification Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Notification] Error: ", error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('NOTIFICATION', false))
      setContext("Notification Error", error);
      done();
    }
  }
});

export default [fetchNotifications];
