import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Col,
  Row,
  Card,
  Form,
  FormGroup,
  Label,
  CardHeader,
  CardBody,
  CustomInput
} from "reactstrap";

import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

import { getCenterList } from "../../global/redux/action";
import { showPasswordOverlay } from "../../global/redux/action";
import { shiftCenter } from "./redux/action";
import FootprintDropdown from "../../global/utils/FootprintDropdown";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import FootprintButton from "../../global/utils/FootprintInputButton";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
class CenterShift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attritionDate: undefined,
      joiningDate: undefined,
      selectedCenter: undefined,
      selectedProgram: undefined
    };
  }
  componentDidMount() {
    console.log("[Center Shift] Requesting Center Shift...");
    this.props.getCenterList();
  }

  validate = () => {
    if (this.state.holidaySelected === true) {
      this.setState({ error: "The selected Date Cannot Be a Holiday" });
      return false;
    } else if (this.state.attritionDate === undefined) {
      this.setState({ error: "Please Select Joining Date" });
      return false;
    } else if (this.state.joiningDate === undefined) {
      this.setState({ error: "Please Select Attrition Date" });
      return false;
    } else if (this.state.selectedCenter === undefined || this.state.selectedCenter === '') {
      this.setState({ error: "Please Select Center" });
      return false;
    } else if (this.state.selectedProgram === undefined || this.state.selectedProgram === '') {
      this.setState({ error: "Please Select Program" });
      return false;
    } else {
      return true;
    }
  };

  submit = type => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, isMatched => {
      //   if (isMatched) {
          this.props.shiftCenter(
            {
              attritionDate: this.state.attritionDate,
              joiningDate: this.state.joiningDate,
              selectedCenter: this.state.selectedCenter,
              selectedProgram: this.state.selectedProgram,
            },
            () => {
              this.props.history.replace("pastRequestTab");
            }
          );
        } else {
        }
    //   });
    // }
  };
  render() {
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em",
            }}
          >
            Shift Center
          </CardHeader>
          <CardBody>
            <Card body className="border-0 shadow">
              <Form>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="startDate">Old Center Attrition Date</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <FootprintDatePicker
                          holidaySelected={(selected) => {
                            this.setState({
                              error: "The selected Date Cannot Be a Holiday",
                              holidaySelected: selected,
                            });
                          }}
                          minimumDate={moment()}
                          inputProps={{
                            placeholder: "Select Date",
                          }}
                          defaultDate={this.state.attritionDate}
                          onChange={(date) => {
                            this.setState({
                              holidaySelected: false,
                              attritionDate:
                                moment(date).format(DEFAULT_FORMAT),
                              error: undefined,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="joiningDate">New Center Joining Date</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <FootprintDatePicker
                          holidaySelected={(selected) => {
                            this.setState({
                              error: "The selected Date Cannot Be a Holiday",
                              holidaySelected: selected,
                            });
                          }}
                          minimumDate={moment(
                            this.state.attritionDate
                          ).add("days", 2)}
                          maximumDate={moment(
                            this.state.attritionDate
                          ).add("days", 8)}
                          inputProps={{
                            placeholder: "Select Joining date",
                            disabled: !this.state.attritionDate,
                          }}
                          defaultDate={this.state.joiningDate}
                          onChange={(date) => {
                            this.setState({
                              holidaySelected: false,
                              joiningDate: moment(date).format(DEFAULT_FORMAT),
                              error: undefined,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="newCenterSelect">Shift To</Label>
                      <FootprintDropdown
                        data={this.props.centerList}
                        label={"Select Center"}
                        value={this.state.selectedCenter}
                        onChange={(value) => {
                          this.setState(
                            {
                              selectedCenter: value,
                              error: undefined,
                            },
                            () => {}
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="newProgramSelect">Program To</Label>
                      <CustomInput
                        id="newProgram"
                        type="select"
                        onChange={(event) => {
                          let selectedValue = event.target.value;
                          this.setState(
                            {
                              selectedProgram: selectedValue,
                              error: undefined,
                            },
                            () => {}
                          );
                        }}
                      >
                        <option value="">Select Program</option>
                        {this.props.programList.map((item, i) => {
                          return (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </CustomInput>
                      {/* <FootprintDropdown
                        data={this.props.programList}
                        label={'Select Program'}
                        value={this.state.selectedProgram}
                        onChange={value => {
                          this.setState(
                            {
                              selectedProgram: value,
                              error: undefined
                            },
                            () => { }
                          );
                        }}
                      /> */}
                    </FormGroup>
                  </Col>
                </Row>

                {typeof this.state.selectedCenter !== "undefined" &&
                typeof this.state.selectedCenter.Center_Feecard !==
                  "undefined" ? (
                  <center>
                    <a
                      style={{ marginBottom: "4px" }}
                      href={this.state.selectedCenter.Center_Feecard}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Fee Details
                    </a>
                  </center>
                ) : (
                  ""
                )}
                {/* {typeof this.state.selectedCenter !== "undefined" &&
                  typeof this.state.selectedCenter.Center_Feecard !==
                    "undefined" && (
                    <button
                      style={{
                        color: " blue",
                        background: "transparent",
                        border: "none"
                      }}
                      onClick={() => {
                        if (this.state.selectedCenter) {
                          window.open(
                            this.state.selectedCenter.Center_Feecard,
                            "_blank",
                            "location=no,closebuttoncaption=Back"
                          );

                          
                        }
                      }}
                    >
                      <p>View Fee Details</p>
                    </button>
                  )} */}
                {typeof this.state.error !== "undefined" && (
                  <p style={{ color: "red" }}>{this.state.error}</p>
                )}

                <FormGroup className="text-center mb-0">
                  <FootprintButton
                    style={{ color: "white" }}
                    className="btn btn-primary"
                    onClick={() => this.submit()}
                  >
                    Submit
                  </FootprintButton>
                </FormGroup>
              </Form>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => {
const programsAvailable = state?.support?.requestType?.programs_available;
let programList = [];

if (programsAvailable) {
  let validateList = programsAvailable.split(",");
  programList = state.global.programList.filter(
    (program) => validateList.indexOf(program.value) >= 0
  );
}
  
if(!programList || programList.length === 0)  {
  programList = state.global.programList;
}


  return ({
    programList,
    centerList: state.global.centerList.map(item => ({
      label: item.Center_Name,
      value: JSON.stringify(item)
    }))
  })
};

const mapDispatchToProps = dispatch => ({
  getCenterList: () => dispatch(getCenterList()),
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  shiftCenter: (date, center, onSuccess) =>
    dispatch(shiftCenter(date, center, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(CenterShift);
