export const CHECK_NON_SATISFACTION_REFUND = "CHECK_NON_SATISFACTION_REFUND";
export const NON_SATISFACTION_REFUND = "NON_SATISFACTION_REFUND";
export const EMAIL_FEE_CERTIFICATE = "EMAIL_FEE_CERTIFICATE";
export const GET_FEE_CERTIFICATE = "GET_FEE_CERTIFICATE";

export const checkNonSatisfactionRefund = (onSuccess, onError) => ({
  type: CHECK_NON_SATISFACTION_REFUND,
  payload: { onSuccess, onError },
});
export const nonSatisfactionRefund = (
  selectedDate,
  comment,
  amount,
  onSuccess
) => ({
  type: NON_SATISFACTION_REFUND,
  payload: { selectedDate, comment, amount, onSuccess },
});

export const emailFeeCertificate = (url) => ({
  type: EMAIL_FEE_CERTIFICATE,
  payload: {
    url,
  },
});

export const getFeeCertificate = (
  startDate,
  endDate,
  natureOfService,
  onSuccess
) => ({
  type: GET_FEE_CERTIFICATE,
  payload: {
    startDate,
    endDate,
    natureOfService,
    onSuccess,
  },
});
