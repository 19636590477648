export const FETCH_FOOD = "FETCH_FOOD";
export const FETCH_FOOD_SUCCESS = "FETCH_FOOD_SUCCESS";

export const fetchFood = date => ({
  type: FETCH_FOOD,
  payload: date
});

export const fetchFoodSuccess = payload => ({
  type: FETCH_FOOD_SUCCESS,
  payload
});

export const FETCH_COMPONENT_Loading = payload => ({
  type: 'FETCH_COMPONENT_Loading',
  payload
});
