import React from "react";
import { connect } from "react-redux";
import { getOutDoorActivity } from "./redux/action";
import OutdoorActivityItem from "./item";

import { Row } from "reactstrap";
// import _ from "lodash";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { InnerHOC } from "../../global/HOComponet/innerPage";

class OutdoorActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("[Outdoor Activity] Fetching Outdoor data...");
    this.props.getOutDoorActivity();
  }

  render() {
    return (
      <InnerHOC heading="Outdoor Activity">
        {Array.isArray(this.props.outdoorActivity) &&
          this.props.outdoorActivity.length > 0 && (
            <Row>
              {this.props.outdoorActivity.map((item, index) => (
                <OutdoorActivityItem index={index} key={index} item={item} />
              ))}
            </Row>
          )}
        {!Array.isArray(this.props.outdoorActivity) ||
          (this.props.outdoorActivity.length === 0 && <ListEmptyComponent heading={this.props.loading ? "Loading..." : "No Data Found"} />)}
      </InnerHOC>
    );
  }
}

const mapStateToProps = state => ({
  outdoorActivity: state.outdoor.outdoorActivity,
  user: state.userState.user && state.userState.user.activeChildDetail,
  loading: state.outdoor.loading
});

const mapDispatchToProps = dispatch => ({
  getOutDoorActivity: () => dispatch(getOutDoorActivity())
});
export default connect(mapStateToProps, mapDispatchToProps)(OutdoorActivity);
