export const CHECK_CCTV = "CHECK_CCTV";
export const CHECK_CCTV_SUCCESS = "CHECK_CCTV_SUCCESS";
export const UPDATE_CCTV_CONNECTION = "UPDATE_CCTV_CONNECTION";
export const UPDATE_CCTV_LINKS = "UPDATE_CCTV_LINKS";

export const checkCCTV = payload => ({
  type: CHECK_CCTV,
  payload
});

export const checkCCTVSuccess = payload => ({
  type: CHECK_CCTV_SUCCESS,
  payload
});

export const updateCCTVConnection = payload => ({
  type: UPDATE_CCTV_CONNECTION,
  payload
});
export const updateCCTVLinks = payload => ({
  type: UPDATE_CCTV_LINKS,
  payload
});
