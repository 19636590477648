import {
  getGuardianSuccess,
  GET_GUARDIAN,
  getGuardian as fetchGuardianAction,
  ADD_GUARDIAN,
  UPDATE_GUARDIAN,
  REMOVE_GUARDIAN,
} from "./action";

import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
export const getGuardian = createLogic({
  type: GET_GUARDIAN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/list_guardians`;
      const body = {
        relation_to_child: ["Grand Parent", "House Help", "Other"],
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        body,
        dispatch,
        // successReportType: "Alert",
        // errorReportType: "Alert",
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          // console.log("Get Guardian list ", response);
          if (response.status === "ok") {
            if (typeof response.response !== "undefined") {
              dispatch(getGuardianSuccess(response.response));
            }
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              //console.log("off fetch blogs");
              done();
            }
          } else if (response.status === "error") {
            dispatch(getGuardianSuccess([]));
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(getGuardianSuccess([]));
          dispatch(setLoading(false));
          // failure
          //console.log("Error: ", error.message);
          done();
        }
      );
    } catch (error) {
      //console.log("the error is :", error, error.message);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});
export const addguardian = createLogic({
  type: ADD_GUARDIAN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentType = getState().userState.parentProfile.parentType;
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/add_guardian`;
      const body = {
        image: action.payload.image,
        // child_id: getState().userState.user.activeChildDetail.id,
        parent_name: action.payload.name, //guardian name
        mobile_no: action.payload.mobileno,
        relation_to_child: action.payload.relation,
        created_by: parentType,
      };

      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        // successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            //console.log("add_guardian", response.response);
            //dispatch(getGuardianSuccess(response.response));
            dispatch(fetchGuardianAction());

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            //console.log("add_guardian error", response.error);

            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));

          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});
export const updateGuardian = createLogic({
  type: UPDATE_GUARDIAN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentType = getState().userState.parentProfile.parentType;
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/update_guardian_by_guardian_id`;
      const body = {
        image: action.payload.image,
        modified_by: parentType,
        guardian_id: action.payload.guardianId,
        guardian_name: action.payload.guardianName, //guardian name
        mobile_no: action.payload.mobileNo,
        relation_to_child: action.payload.relation,
        update_type: "requested",
      };

      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        // successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            // console.log("update_guardian", response.response);
            //dispatch(updateByGuardianId(response.response));
            // dispatch(getGuardianSuccess(response.response));
            dispatch(fetchGuardianAction());

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            //console.log("update_guardian error", response.error);
            dispatch(getGuardianSuccess([]));

            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));

          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});

export const removeGuardian = createLogic({
  type: REMOVE_GUARDIAN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentType = getState().userState.parentProfile.parentType;
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/update_guardian_by_guardian_id`;
      const body = {
        // image: action.payload.image,
        modified_by: parentType,
        guardian_id: action.payload.guardianId,
        // guardian_name: action.payload.guardianName, //guardian name
        // mobile_no: action.payload.mobileNo,
        // relation_to_child: action.payload.relation,
        update_type: "remove",
      };

      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            //console.log("REMOVE_GUARDIAN", response.response);
            dispatch(fetchGuardianAction());

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            // console.log("REMOVE_GUARDIAN error", response.error);

            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));

          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});
export default [getGuardian, addguardian, updateGuardian, removeGuardian];
