import React from "react";
import { connect } from "react-redux";
import { fetchPastRequest } from "../Support/redux/action";
import PastRequestItem from "./item";
import { setNavigationParams } from "../../global/redux/action";
import { Row } from "reactstrap";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
class PastRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("[PastRequest] Fetching past request...");
    window.scrollTo(0, 0)
    this.props.fetchPastRequest();
  }
  render() {
    const { pastRequest } = this.props;
    if (Array.isArray(pastRequest) && pastRequest.length > 0) {
      return (
        <Row>
          {pastRequest.map((item, index) => (
            <PastRequestItem
              history={this.props.history}
              md={6}
              item={item}
              key={index}
              index={index}
            />
          ))}
        </Row>
      );
    } else {
      return <ListEmptyComponent heading={this.props.loading ? "Loading..." : "No Data Found"} />;
    }
  }
}
const mapStateToProps = state => ({
  pastRequest: state.support && state.support.pastRequest,
  loading: state.support.loading
});

const mapDispatchToProps = dispatch => ({
  fetchPastRequest: () => dispatch(fetchPastRequest()),
  setNavigationParams: params => dispatch(setNavigationParams(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(PastRequest);
