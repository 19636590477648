import fetchAPI from "../../../global/utils/fetch";
import { FETCH_HOLIDAYS, fetchHolidaySuccess } from "./action";
import { createLogic } from "redux-logic";

import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import Constants from "../../../constants";

import moment from "moment";
import { setContext } from "../../../global/utils/crashlyticsMethods";
const fetchHolidayLogic = createLogic({
  type: FETCH_HOLIDAYS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    dispatch(onErrLoading('Holidays', true))
    try {
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/wbsite/getHolidayList`;
      const body = {
        list: moment().year()
      };
      console.log("[Holiday Calendar] Calling Api: /wbsite/getHolidayList, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        body,
        getState,
        dispatch,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Holiday Calendar] Received Response: ",response);
          dispatch(fetchHolidaySuccess(response));
          dispatch(setLoading(false));
          dispatch(onErrLoading("Holidays", false));
          if (action.payload && action.payload.onSuccess) {
            action.payload.onSuccess();
            done();
          } else {
            done();
          }

          //done();
        },
        error => {
          console.log("[Holiday Calendar] Error: ",error);
          dispatch(setLoading(false));
          dispatch(onErrLoading('Holidays', false))
          setContext("Holiday Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Holiday Calendar] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('Holidays', false))
      setContext("Holiday Error", error);
      done();
    }
  }
});

export default [fetchHolidayLogic];
