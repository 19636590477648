
export const FETCH_PHOTOS = 'FETCH_PHOTOS';
export const FETCH_PHOTOS_SUCCESS = 'FETCH_PHOTOS_SUCCESS';
export const UPDATE_PHOTOS_PRESENCE = 'UPDATE_PHOTOS_PRESENCE';


export const fetchPhotos = (startIndex) => ({
    type: FETCH_PHOTOS,
    payload: {
      startIndex,
    },
  });
  
  export const fetchPhotosSuccess = (response, startIndex) => ({
    type: FETCH_PHOTOS_SUCCESS,
    payload: {
      response, startIndex,
    },
  });
  
  export const updatePhotoPresence = (presence) => ({
    type: UPDATE_PHOTOS_PRESENCE,
    payload: presence,
  });
  