export const GET_REQUESTS_TYPE = "GET_REQUESTS_TYPE";
export const GET_REQUESTS_TYPE_SUCCESS = "GET_REQUESTS_TYPE_SUCCESS";
export const FETCH_PAST_REQUEST = "FETCH_PAST_REQUEST";
export const FETCH_PAST_REQUEST_SUCCESS = "FETCH_PAST_REQUEST_SUCCESS";
export const getRequestType = payload => ({
  type: GET_REQUESTS_TYPE,
  payload
});

export const fetchRequestTypesSuccess = response => ({
  type: GET_REQUESTS_TYPE_SUCCESS,
  payload: {
    response
  }
});

export const fetchPastRequest = response => ({
  type: FETCH_PAST_REQUEST,
  payload: {
    response
  }
});

export const fetchPastRequestSuccess = response => ({
  type: FETCH_PAST_REQUEST_SUCCESS,
  payload: {
    response
  }
});
