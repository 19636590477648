import { FETCH_CHAT_SUCCESS, SEND_CHAT_MESSAGE_OFFLINE } from "./action";
import { transformChat } from "./transformChat";
import moment from "moment";
import { CHANGE_LOGGEDIN_CHILD,SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";

const initialState = {
  chat: [],
  sectionedChat: [],
  loading: true,
  moreData: false
};
const chatReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_CHAT_SUCCESS: {
      try {
        const { conversationList, startIndex, moreData } = action.payload;

        let totalChat = conversationList;
        if (startIndex > 0) {
          totalChat = [...state.chat, ...conversationList];
        }


        return {
          ...state,
          chat: totalChat,
          sectionedChat: transformChat(totalChat, false),
          loading: false,
          moreData: moreData
        };
      }

      catch (error) {
      console.log("[Conversation][Reducer] Error: ",error);
        return state;
      }
    }

    case SEND_CHAT_MESSAGE_OFFLINE: {
      try {
        const newItem = {
          message: action.payload.message,
          who: 'parent',
          time: moment().format('HH:mm:ss'),
        };
        const chat = [newItem, ...state.chat];
        if (Array.isArray(state.chat)) {
          return {
            ...state,
            chat,
            sectionedChat: transformChat(chat, false),
            loading: false,
          };
        }
        break;
      }
      catch (error) {
        console.log("[Conversation][Reducer] Error: ",error);
      }
    }
    case SET_COMPONENT_LOADING + "CONVERSATION": {
      return {
        ...state,
        loading: action.value
      }
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }



    default: {
      return state;
    }
  }
};
export default chatReducer;
