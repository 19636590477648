export const CANCEL_LEAVE_REQUEST = "CANCEL_LEAVE_REQUEST";
export const CANCEL_PAUSE_REQUEST = "CANCEL_PAUSE_REQUEST";
export const TAKE_BREAK = "TAKE_BREAK";
export const RESUME_FROM_BREAK = "RESUME_FROM_BREAK";
export const EXTEND_PAUSE = "EXTEND_PAUSE";

export const cancelLeaveRequest = (request) => ({
  type: CANCEL_LEAVE_REQUEST,
  payload: request,
});

export const cancelPauseRequest = (request) => ({
  type: CANCEL_PAUSE_REQUEST,
  payload: request,
});

export const takeABreak = (startDate, endDate, reason, onSuccess) => ({
  type: TAKE_BREAK,
  payload: {
    startDate,
    endDate,
    reason,
    onSuccess,
  },
});
export const extendPause = (requestId, selectedDate, onSuccess) => ({
  type: EXTEND_PAUSE,
  payload: {
    requestId,
    selectedDate,
    onSuccess,
  },
});

export const resumeFromBreak = (requestId, selectedDate, onSuccess) => ({
  type: RESUME_FROM_BREAK,
  payload: {
    requestId,
    selectedDate,
    onSuccess,
  },
});
