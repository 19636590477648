import React from "react";
import { connect } from "react-redux";

import { Col, Card, CardBody, CardTitle } from "reactstrap";

class MyCenterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, index } = this.props;

    return (
      <Col xs="12" sm="4" md="6" lg="3" className="mb-3" key={index}>
        <Card className="h-100 border-0 shadow rounded">
          <div
            className="teacher-img"
            style={{
              backgroundImage:
                "url" +
                "(" +
                this.props.constants.teachersPath +
                item.fullImgUrl +
                ")",

              backgroundSize: "contain",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: "center",
              height: "250px"
            }}
          ></div>
          {/* <CardImg top width="100%" src={this.props.constants.teachersPath + "" + item.fullImgUrl} alt="Card image cap" /> */}
          <CardBody className="p-3">
            <CardTitle className="m-0 text-center">
              <p className="m-0" style={{ fontFamily: "baloo" }}>
                {item.User_Name}
              </p>
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  constants: state.constants
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyCenterItem);
