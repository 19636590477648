import React from "react";
import { connect } from "react-redux";
import { Navbar, NavbarBrand } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../assets/Footprints-Logo.svg";
import FootprintButton from "../../global/utils/FootprintInputButton";
import FootprintInputText from "../../global/utils/FootprintInputText";

import { FormGroup, Label } from "reactstrap";
import { forgotPassword } from "./redux/action";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Theme from "../../theme";
class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.defaultEmail,
      error: ""
    };
    //
  }
  validate = () => {
    if (!this.state.email) {
      this.setState({ error: "Please Enter Email" });

      return false;
    }

    return true;
  };

  forgotPassword = () => {
    //
    this.props.forgotPassword(this.state.email, () => {
      this.props.history.push("OTPVerify", { email: this.state.email });
    });
  };

  render() {
    return (
      <div
        className="bg-preview"
        style={{
          backgroundImage:
            "url(" + require("../../assets/bg-pattern.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh"
        }}
      >
        {" "}
        <Navbar
          color="white"
          light
          expand="md"
          className="shadow mb-5 fixed-top"
        >
          <NavbarBrand href="/" className="m-auto py-2">
            <img src={logo} alt="Logo" />
          </NavbarBrand>
        </Navbar>
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={true}
          backdrop={false}
        >
          <ModalHeader
            style={{
              background: Theme.colorSecondry,
              fontFamily: "baloo",
              fontSize: "1.3em",
              color: "#fff"
            }}
          >
            <span className="text-white">Forget Password</span>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <FootprintInputText
                defaultValue={this.state.email}
                onChange={text => {
                  this.setState({ email: text, error: undefined });

                  // this.props.setError();
                }}
              />
            </FormGroup>

            {typeof this.state.error !== "undefined" && (
              <p style={{ color: "red" }}>{this.state.error}</p>
            )}
            <FootprintButton
              onClick={() => {
                if (this.validate()) {
                  this.forgotPassword();
                }
              }}
              text="Continue With Email"
            ></FootprintButton>
            <div>
              <Link to={"/"}>
                <span
                  style={{
                    color: "black",
                    float: "right",
                    fontFamily: "baloo"
                  }}
                >
                  Login To Account
                </span>
              </Link>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  defaultEmail: state.userState.email,
  defaultPassword: state.userState.password
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: (email, onSuccess) =>
    dispatch(forgotPassword(email, onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
//export default ForgetPassword;
