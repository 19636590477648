import React from "react";

class WebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //
  }

  render() {
    const { url } = this.props;
    return (
      <div>
        {/* <iframe id="desktop-cctv2" src={url} width="100%" height="700"></iframe> */}

        <iframe title="Parent Connect" id="desktop-cctv2" src={url} width="100%" height="700" name="live" align="center" frameBorder="0" scrolling="yes" allowtransparency="true" allowFullScreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
      </div>
    );
  }
}

export default WebView;
