import { FETCH_TICKETS_SUCCESS } from "./action";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = {
  //tickets: [],
  loading: true
};
const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TICKETS_SUCCESS: {
      try {
        return {
          ...state,
          tickets: action.payload,
          loading: false
        };
      } catch (error) {
        return { ...state, loading: false };
      }
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }
    case SET_COMPONENT_LOADING + 'TICKET': {
      return {
        ...state,
        loading: action.value
      }
    }

    case LOGOUT: {
      return initialState
    }

    default: {
      return state;
    }
  }
};
export default ticketReducer;
