export const CANCEL_REQUEST = "CANCEL_REQUEST";

export const CANCEL_WITHRAW_REQUEST = "CANCEL_WITHRAW_REQUEST";
export const cancelRequest = payload => ({
  type: CANCEL_REQUEST,
  payload
});

export const cancelWithrawlRequest = request => ({
  type: CANCEL_WITHRAW_REQUEST,
  payload: request
});
