import React from "react";
import { connect } from "react-redux";
import BlogItem from "./item";
import { fetchBlogs } from "./redux/action";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col,CardHeader,Card, CardBody} from 'reactstrap';
import Theme from "../../theme";


class Blogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    console.log("[Blogs] Fetching blogs information");
    this.props.fetchBlogs();
  }
  render() {
    return (
      <div className="commonFeature">
        <Card className="border-0 shadow border-purple">
          <CardHeader
            style={{
              background: Theme.colorPrimary,
            }}
          >
            {/* <CardColumns> */}
            <Col xs="12" md="6" className="">
              {/*{moment(this.state.selectedDate).format("DD MMMM, YYYY")} */}
              <div
                style={{
                  color: "white",
                  fontFamily: "baloo",
                  fontSize: "1.3em",
                }}
              >
                {" "}
                Blogs
              </div>
            </Col>
          </CardHeader>
          <CardBody>
            {this.props.blogs.map((item, index) => (
              <BlogItem key={index} item={item} />
            ))}
          </CardBody>
          {/* </CardColumns> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  blogs: state.blogs.blogs
});
const mapDispatchToProps = dispatch => ({
  fetchBlogs: () => dispatch(fetchBlogs())
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
