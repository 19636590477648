import { CHECK_CCTV_SUCCESS, UPDATE_CCTV_LINKS } from "./action";
import { CHANGE_LOGGEDIN_CHILD } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";

const initialState = {
  centerCctvDown: false,
  cctvUrl: ""
};
// const transformCCTVUrl = cctvlinks => {
//   if (Array.isArray(cctvlinks) && cctvlinks.length > 0) {
//     return cctvlinks.map(item => {});
//   }
//   return [];
// };

const cctvReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_CCTV_SUCCESS: {
      return {
        ...state,
        centerCctvDown: action.payload.centerCctvDown,
        centerDetail: action.payload.centerDetail
      };
    }
    case UPDATE_CCTV_LINKS: {
      return {
        ...state,
        cctvUrl: action.payload
      };
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
export default cctvReducer;
