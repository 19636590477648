import { CANCEL_REQUEST } from "./action";
import { fetchPastRequest as fetchPastRequestAction } from "../../Support/redux/action";
import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";

import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const cancelTicket = createLogic({
  type: CANCEL_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/financials/cancelCustomerRequest`;
      const body = {};
      console.log("[Past Request] Calling API: /financials/cancelCustomerRequest");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: 'Alert',
        errorReportType: 'Alert',
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Past Request] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchPastRequestAction(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {

              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchPastRequestAction([]));
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Past Request] Error: ",error);
          dispatch(setLoading(false));
          setContext("Past Request Error", error);
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[Past Request] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Past Request Error", error);
      done();
    }
  }
});

export default [cancelTicket];
