import React from "react";
import { connect } from "react-redux";

import { fetchNotification } from "./redux/action";
import Item from "./item";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
// import { InnerHOC } from "../../global/HOComponet/innerPage";
import { showBell } from "../../global/redux/action";
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Theme from '../../theme';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startIndex: 0,
      error: undefined,

    };
  }

  refetch = (state) => {
    const startIndex = this.state.startIndex + 50;
    this.setState({ startIndex });
    this.props.fetchNotification(startIndex);
  };
  fetch = () => {
    // reset to load more data

    const startIndex = 0;
    this.setState({ startIndex });
    this.props.fetchNotification(startIndex);
  };
  componentDidMount() {
    console.log("[Notification] Fetching notification...")
    this.props.showBell(false);
    this.props.fetchNotification();
    // _.debounce(() => {
    //   this.props.fetchNotification();
    // }, 3000);
  }

  render() {
    // console.log(this.props.loading)
    return (
      <Card className="border-0 shadow  card-timeline mt-4">
        <CardHeader
          className="text-white"
          style={{
            background: Theme.colorPrimary,
            fontFamily: "baloo",
            fontSize: "1.3em"
          }}
        >
          <div className="d-flex justify-content-center  align-items-center">
            <div>
              Notifications
            </div>


            <div className="ml-auto">
              <Link
                to='#'
                onClick={() => {
                  this.props.fetchNotification();
                }}
              >
                <i
                  className="icon-refresh fa-spin-custom"
                  style={{ color: "#fff", fontSize: "1.2em" }}
                ></i>
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {Array.isArray(this.props.notification) &&
            this.props.notification.length > 0 && (
              <div>
                <ul className="timeline">
                  {this.props.notification.map((items, index) => (
                    <Item
                      history={this.props.history}
                      items={items}
                      key={index}
                      index={index}
                    />
                  ))}
                </ul>

              </div>
            )}
          {!Array.isArray(this.props.notification) ||
            (this.props.notification.length === 0 && <ListEmptyComponent heading={this.props.loading ? "Loading..." : "No Data Found"} />)}
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  notification: state.notification.sectionedNotifications,
  loading: state.notification.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotification: (startIndex) => dispatch(fetchNotification(startIndex)),
  showBell: (show) => dispatch(showBell(show))
});
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
