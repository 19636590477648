import React from "react";
// import { connect } from 'react-redux';
import PastRequest from "../index";
import Ticket from "../../Tickets";
// reactstrap components

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
class PastRequestTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: PastRequest };
  }

  render() {
    return (
      <div className="shadow rounded">
        <Nav
          fill
          pills
          className="mt-4"
          style={{ fontFamily: "Baloo", background: "#f3f4fb" }}
        >
          <NavItem
            style={{
              flexGrow: "1",
              flexBasis: "calc(100% / 2)",
              paddingRight: "0px",
            }}
          >
            <NavLink
              style={{ fontSize: "1.3em" }}
              className={classnames({
                active: this.state.activeTab === PastRequest,
                "py-3 px-1": true,
              })}
              onClick={() => {
                this.setState({ activeTab: PastRequest });
              }}
            >
              Past Request
            </NavLink>
          </NavItem>
          <NavItem style={{ flexGrow: "1", flexBasis: "calc(100% / 2)" }}>
            <NavLink
              style={{ fontSize: "1.3em" }}
              className={classnames({
                active: this.state.activeTab === Ticket,
                "p-3": true,
              })}
              onClick={() => {
                this.setState({ activeTab: Ticket });
              }}
            >
              Tickets
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          className="p-3 "
          style={{
            background: "#fffefeb0 !important",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <TabPane>
            <this.state.activeTab history={this.props.history} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default PastRequestTab;
