import {
  LOADING,
  SET_ERROR,
  SET_INFO,
  CENTER_LIST_SUCCESS,
  SHOW_PASSWORD_OVERLAY,
  SET_NAVIGATION_PARAMS,
  SHOW_BELL,
  SHOW_MODAL,
  CHANGE_LOGGEDIN_CHILD,
  SHOW_CROP_IMAGE,
  SAVE_TOKEN,
  SET_NOTIFICATION,
  SAVE_TOKEN_SUCCESS
} from "./action";
import { LOGOUT } from "../../features/Login/redux/action";

const initialState = {
  showLoading: false,
  setInfo: {
    title: "",
    subtitle: "",
  },
  centerList: [],
  programList: [],
  myCenter: {},
  passwordOverlay: {},
  navigation: {
    params: {},
  },
  showCropImageModal: {},
  token: ''
};

const getMyCenter = (payload) => {
  try {
    const { userCenterID: centerId, allCenters } = payload;
    if (Array.isArray(allCenters)) {
      return allCenters.find((item) => {
        return item.Center_Id == centerId;
        // center id at userState is integer and in this api its number so == instead of ===
      });
    }
  } catch (error) {
    return {};
  }
};

const getProgramList = (myCenter, state, programList) => {
  try {
    if (myCenter) {
      const programsAvailable = myCenter.Programs_Available;
      const filteredPrograms = programList.filter(
        (item) => programsAvailable.indexOf(item.value) > -1
      );
      return filteredPrograms;
    }
  } catch (error) {
    return [];
  }
};
const transformedList = (payload) => {
  if (Array.isArray(payload)) {
    const centerList = payload.filter((center) => {
      return center.Admission_Enabled === "Yes";
    });
    return centerList;
    /*return centerList.map(item => ({
      label: item.Center_Name,
      value: item
    }));*/
  }
  return [];
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        showLoading: action.payload.showLoading,
      };
    }

    case SET_ERROR:
      return {
        ...state,
        error: { ...action.payload },
      };

    case SHOW_MODAL:
      return {
        ...state,
        modalInfo: { ...action.payload },
      };

    case SET_INFO:
      return {
        ...state,
        info: { ...action.payload },
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        notification: { ...action.payload },
      };


    case SHOW_BELL: {
      return {
        ...state,
        showBell: action.payload
      }
    }

    case SAVE_TOKEN: {
      return {
        ...state,
        token: action.payload
      }
    }
    case SAVE_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload
      }
    }

    case CENTER_LIST_SUCCESS: {
      const myCenter = getMyCenter(action.payload);

      return {
        ...state,
        centerList: transformedList(action.payload.allCenters) || [],
        myCenter,
        programList:
          getProgramList(myCenter, state, action.payload.programList) || [],
      };
    }
    case SHOW_PASSWORD_OVERLAY: {
      return {
        ...state,

        passwordOverlay: {
          show: action.payload.show,
          onSuccess: action.payload.onSuccess,
        },
      };
    }

    case SET_NAVIGATION_PARAMS: {
      return {
        ...state,
        navigation: {
          params: action.payload,
        },
      };
    }


    case SHOW_CROP_IMAGE: {
      return {
        ...state,
        showCropImageModal: action.payload || {},
      };
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return {
        ...initialState,
        token: state.token,

      };
    }

    default: {
      return state;
    }
  }
};

export default globalReducer;

//Object.assign()
