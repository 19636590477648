import React, { Component } from "react";
import { CustomInput } from "reactstrap";

export default class FootprintInputCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({
      items: this.props.data
    });
  }

  render() {
    const { onClick = () => {}, data } = this.props;

    if (data && Array.isArray(data)) {
      return (
        <div>
          {data.map((item, i) => (
            <CustomInput
              key={i}
              type="checkbox"
              id={"exampleCustomInline" + i}
              label={item.label}
              onChange={e => {
                const alteredItems = this.state.items.map(myItem => ({
                  ...myItem,
                  checked:
                    myItem.value.key === item.value.key
                      ? !myItem.checked
                      : myItem.checked
                }));

                this.setState({ items: alteredItems });
                if (onClick) {
                  onClick(alteredItems);
                }
              }}
              // onClick={() => {
              //
              //
              //   // const alteredItems = data.map(myItem => ({
              //   //   ...myItem,
              //   //   checked:
              //   //     myItem.value.key === item.value.key
              //   //       ? !myItem.checked
              //   //       : myItem.checked
              //   // }));
              //   //
              //   // this.setState({ items: alteredItems });
              //   // if (onClick) {
              //   //   onClick(alteredItems);
              //   // }
              // }}
              inline
            />
          ))}
        </div>
      );
    } else {
      return <div />;
    }
  }
}
