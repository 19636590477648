import React, { Component } from 'react';
import { connect } from "react-redux";
import LoadingBar from 'react-top-loading-bar';
import Theme from '../../theme';

class Loading extends Component {
  state = {
    loadingBarProgress: 50
  };

  componentDidMount() {
    this.startFetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading === false && this.props.loading === true) {
      this.startFetch();
    }
    else if (this.props.loading === false) {
      this.onFinishFetch();
    }
  }


  startFetch = () => {
    this.LoadingBar.continuousStart()
  }

  onFinishFetch = () => {
    this.LoadingBar.complete()
  }

  render() {
    const { innerHeight: height } = window;
    let color = (height > 915 || this.props.flag) ? Theme.colorPrimary : Theme.primaryDark
    return (
      <div style={{}}>
        <LoadingBar
          color={color}
          onRef={ref => (this.LoadingBar = ref)} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loading: state.global.showLoading
});

const mapDispatchToProps = dispatch => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(Loading);