import {
  //LOADING,
  FETCH_NEWS_SUCCESS
} from "./action";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
import moment from "moment";

const initialState = {
  news: [],
  loading: true
};

const transformNews = news => {
  if (Array.isArray(news) && news.length > 0) {
    return news.map(item => {
      return {
        ...item,
        newsletter_due_date: moment(item.newsletter_due_date).format(
          'DD MMMM, YYYY',
        ),
        loading: false
      };
    });
  }
  return [];
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEWS_SUCCESS: {
      return {
        ...state,
        //news: action.payload.news.map(item => item.notification_subject),

        news: transformNews(action.payload.news),
        loading: false
      };
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }
    case SET_COMPONENT_LOADING + 'NEWS': {
      return {
        ...state,
        loading: action.value
      }
    }

    case LOGOUT: {
      return initialState
    }

    default: {
      return state;
    }
  }
};

export default newsReducer;

//Object.assign()
