import React, { Component } from "react";

import classnames from "classnames";
import { Input } from "reactstrap";

import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
const isAlpha = (value) => {
  return value === "" || value.match(/^[0-9]+$/);
};
export default class FootprintInputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.defaultValue || "",
      show: false,
    };
  }

  showComponent = () => {
    // const { show } = this.state;
    this.setState({
      show: false,
    });
  };

  render() {
    const {
      placeholder = "Write here.",
      defaultValue,
      type,
      icon,
      maxLength = 2048,
      // onChange = () => {},
      // disable = "false"
    } = this.props;
    // console.log("Show : ", this.state.show);
    return (
      <InputGroup
        className={classnames("input-group-merge", {
          focused: this.state.merged && this.state.merged.username,
        })}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            style={{ padding: 0, paddingLeft: "6px", paddingRight: "6px" }}
          >
            <i className={icon} style={{ fontSize: "28px" }}></i>
          </InputGroupText>
        </InputGroupAddon>
        {!this.state.show && (
          <Input
            style={{ paddingLeft: 5, paddingRight: 5, fontSize: "18px" }}
            disabled
            type="text"
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
        )}
        {this.state.show && (
          <Input
            style={{ paddingLeft: 5, paddingRight: 5, fontSize: "18px" }}
            type={type}
            value={this.state.inputValue}
            placeholder={placeholder}
            onChange={(event) => {
              //console.log(maxLength);
              const value = event.target.value;
              if (maxLength === "10") {
                if (value.length <= maxLength && isAlpha(value)) {
                  this.setState({
                    inputValue: value,
                  });
                }
              } else {
                this.setState({
                  inputValue: value,
                });
              }
            }}
          />
        )}

        {/* <Input
    placeholder="Email ID"
    value={this.props.parentEmail}
    type="email"
    onFocus={e =>
      this.setState({
        merged: { ...this.state.merged, username: true }
      })
    }
    onBlur={e =>
      this.setState({
        merged: { ...this.state.merged, username: false }
      })
    }
  /> */}
        {/* <InputGroupAddon addonType="append">
          <InputGroupText className="pl-2 pr-2 p-0 m-0">
            {this.state.show && (
              <Button
                className="p-0"
                type="button"
                onClick={() => {
                  this.showComponent();

                  this.setState({ show: true }, () => {
                    if (this.input && this.input.focus) {
                      this.input.focus();
                    }
                  });
                }}
              >
                <i className="icon-edit" style={{ fontSize: "20px" }}></i>
              </Button>
            )}
            {this.state.show && (
              <Button
                className="p-1"
                type="button"
                onClick={() => {
                  if (this.state.inputValue !== this.props.value) {
                    if (onChange) {
                      onChange(this.state.inputValue, () => {
                        this.setState({
                          show: false,
                        });
                      });
                    }
                  } else {
                    onChange(this.state.inputValue, () => {
                      this.setState({
                        show: false,
                      });
                    });
                  }
                }}
                  onClick={() => {
                this.showComponent();
              }} 
              >
                save
              </Button>
            )}
          </InputGroupText>
        </InputGroupAddon> */}
      </InputGroup>
    );
  }
}
