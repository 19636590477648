import React from "react";
import { connect } from "react-redux";
import { showPasswordOverlay } from "../../global/redux/action";
import { extendPause } from "./redux/action";
import moment from "moment";
import {
  Col,
  Row,
  Card,
  Button,
  Form,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";

import {
  DEFAULT_FORMAT,
  SERVER_DATE_FORMAT,
} from "../../global/redux/combineLogic";
class ExtendPause extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  validate = () => {
    if (this.state.endDate === undefined) {
      this.setState({ error: "Please Select End Date" });
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    console.log("[Extend Break] Request for Break extension...");
    if (this.props.navParams) {
    }
  }

  submit = () => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, (isMatched) => {
      //   if (isMatched) {
          this.props.extendPause(
            this.props.navParams.request_id,

            this.state.endDate,
            () => {
              this.props.history.replace("support");
            }
          );
        } else {
        }
    //   });
    // }
  };
  render() {
    const item = this.props.navParams;
    if (item) {
      return (
        <div>
          <Card className="border-0 shadow  card-timeline mt-4">
            <CardHeader
              className="text-white"
              style={{
                background: "#A500F8",
                fontFamily: "baloo",
                fontSize: "1.3em",
              }}
            >
              Extend Pause{" "}
            </CardHeader>
            <CardBody>
              <Form>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="startDate">Current End Time</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <FootprintDatePicker
                          defaultDate={moment(item.end_date).format(
                            DEFAULT_FORMAT
                          )}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        {/* <ReactDatetime
                        inputProps={{
                          placeholder: "Start Date",
                          disabled: true
                        }}
                        defaultValue={moment(item.end_date, "YYYY-MM-DD")}
                        timeFormat={false}
                        readOnly={false}
                      />*/}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="endDate">New End Date</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FootprintDatePicker
                          onChange={(date) => {
                            this.setState({
                              endDate: date,
                              error: undefined,
                            });
                          }}
                          inputProps={{
                            placeholder: "Select date",
                          }}
                          timeFormat={false}
                          minimumDate={moment(
                            item.end_date,
                            SERVER_DATE_FORMAT
                          ).add("days", 1)}
                          maximumDate={moment(
                            item.date,
                            SERVER_DATE_FORMAT
                          ).add("days", 89)}
                        />
                        {/* <ReactDatetime
                        onChange={date => {
                          this.setState({
                            endDate: date,
                            error: undefined
                          });
                        }}
                        inputProps={{
                          placeholder: "End date"
                        }}
                        timeFormat={false}
                      />*/}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                {typeof this.state.error !== "undefined" && (
                  <p style={{ color: "red" }}>{this.state.error}</p>
                )}

                <Button
                style={{ color: "white" }}
                  className="btn-block btn-lg bg-purple border-0"
                  onClick={() => {
                    this.submit();
                  }}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <p>Extended Pause</p>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  navParams: state.global.navigation.params,
});

const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  extendPause: (requestId, selectedDate, onSuccess) =>
    dispatch(extendPause(requestId, selectedDate, onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExtendPause);
