import React, { useCallback, useMemo } from 'react';
import downloadIcon from '../../assets/download-icon.svg';
import { Filesystem} from '@capacitor/filesystem';
import { Toast } from '@capacitor/toast';
import Constants from '../../constants';
import {
  FaTimes,
} from "react-icons/fa";
import { Capacitor } from '@capacitor/core';
import { Media } from "@capacitor-community/media";

const ensureDemoAlbum = async () => {
  let { albums } = await Media.getAlbums();
  const albumName = "Footprints Album"
  let demoAlbum = undefined;
  if (Capacitor.getPlatform() === "android") {
    const albumsPath = (await Media.getAlbumsPath()).path
    demoAlbum = albums.find(a => a.name === albumName && a.identifier.startsWith(albumsPath));
    // console.log(demoAlbum, "demoalbum");
  } else {
    demoAlbum = albums.find(a => a.name === albumName);
  }

  if (!demoAlbum) {
    // console.log("Inside")
    // Doesn't exist, create new album
    await Media.createAlbum({ name: albumName });
    albums = await Media.getAlbums();
    demoAlbum = albums.albums.find(
      (a) => a.name === albumName
    );
  }
  // console.log("albums: ", albums)
  const message = demoAlbum ? "Image downloaded successfully" : "Image download failed";
  Toast.show({ text: message, duration: "short" });

  return demoAlbum ? demoAlbum.identifier : "";
};

const DownloadImageMobile = async (fileName, base64Data) => {


  // console.log("I am here")
  // let result = await Filesystem.writeFile({
  //   path: fileName,
  //   data: base64Data,
  //   directory: Directory.Documents,
  //   recursive: true,
  // });

  const image = await Media.savePhoto({ path: base64Data, albumIdentifier: await ensureDemoAlbum(), fileName });
  // console.log(image);

  // const message = result
  //   ? "Image downloaded successfully"
  //   : "Image download failed";
  // Toast.show({ text: message, duration: "short" });

  return image;
};

const ImageDownloader = ({
  disableCache = true,
  buttonText = 'Download',
  imageUrl = '',
  component,
  closeLightbox
}) => {
  const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  const downloadImage = useCallback(

    async (e) => {
      // console.log("Download", imageUrl);
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (!imageUrl.length) {
        // console.log('Please add an image url');
        return;
      }
      try {
        const fetchUrl = `${e.target.href}${disableCache ? `?dummy=${Math.floor(Date.now())}` : ''
          }`;

        const response = await fetch(fetchUrl);
        const blob = await response.blob();
        const base64Data = await convertBlobToBase64(blob);

        // console.log(JSON.stringify(blob), base64Data);

        const timestamp = new Date().getTime();
        const fileName = `image_${timestamp}.png`;
        const permissionStatus = await Filesystem.checkPermissions();
        // console.log("permissionStatus", permissionStatus);

        if (permissionStatus.publicStorage === "granted") {
          DownloadImageMobile(fileName, base64Data);
        } else {
          await Filesystem.requestPermissions().then(async (requestStatus) => {
            // console.log("requestStatus", requestStatus);
            if (requestStatus.publicStorage === "granted") {
              DownloadImageMobile(fileName, base64Data);
            } else {
              Toast.show({
                text: "Permission denied, please enable it manually",
                duration: "long",
              });
            }
          });
        }
      } catch (error) {
        console.error("Error downloading image:", error);
        Toast.show({
          text: "Error downloading image",
          duration: "short",
        });
      }
    },
    [disableCache]
  );

  const onButtonClick = useCallback(
    (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (!imageUrl.length) {
        // console.log('Please add an image url');
        return;
      }

      const fetchUrl = `${e.target.href}${disableCache ? `?dummy=${Math.floor(Date.now())}` : ''
        }`;

      fetch(fetchUrl, {
        method: 'GET',
        headers: {}
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute(
              'download',
              imageUrl.substr(imageUrl.lastIndexOf('/') + 1)
            );
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
    [disableCache]
  );

  return useMemo(
    () => (
      <React.StrictMode>
        <div className="container show-image" style={{ maxHeight: "700px" }}>
          <div
            className="position-absolute photo-css"
            onClick={closeLightbox}
          >
            <FaTimes style={{ color: "#4267B2", fontSize: 25 }} />
          </div>
          <img
            src={imageUrl}
            alt="area"
            style={{ width: "100%", border: "0", margin: "0", maxHeight: "550px", objectFit: "contain", background: "white" }}
          />

          <div className="d-flex flex-row justify-content-center mt-3">
            <a
              href={imageUrl}
              download
              onClick={(e) =>
                Constants.env.web === "yes"
                  ? onButtonClick(e)
                  : downloadImage(e)
              }
              style={{ width: "30px" }}
            >
              <img src={downloadIcon} height={32} />
            </a>
            {component}
          </div>
        </div>
      </React.StrictMode>
    ),
    [imageUrl, buttonText]
  );
};

export default ImageDownloader;