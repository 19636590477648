import React from "react";
import { connect } from "react-redux";
import { fetchFood } from "./redux/action";
import moment from "moment";
//import { MaterialUIPickers } from "../../global/utils/DatePicker";

import FootprintDatePicker from "../../global/utils/FootprintDatePicker";

import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import { SERVER_DATE_FORMAT } from "../../global/redux/combineLogic";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import Theme from "../../theme";
import { setContext } from "../../global/utils/crashlyticsMethods";
//const DATE_FORMAT = "YYYY-MM-DD";
class FoodMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().format(SERVER_DATE_FORMAT),
      loading: true
    };
  }
  async componentDidMount() {
    console.log("[Food Menu] Fetching data...");
    //_.debounce(() => {
    await this.props.fetchFood(moment());
    //}, 3000);
    setContext("Food Menu", this.props.food);
  }

  render() {
    const { food, loading = true } = this.props;
    const { lunch, snacks, fruits, breakfast } = food;
    return (
      <div>
        <Card className="border-0 shadow border-purple">
          <CardHeader
            style={{
              background: Theme.colorPrimary,
            }}
          >
            <Row>
              <Col xs="12" md="6" className="my-auto">
                {/*{moment(this.state.selectedDate).format("DD MMMM, YYYY")} */}
                <div
                  style={{
                    color: "white",
                    fontFamily: "baloo",
                    fontSize: "1.3em",
                  }}
                >
                  {" "}
                  Food Menu
                </div>
              </Col>
              <Col xs="12" md="6">
                <FormGroup className="m-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="p-0 m-0 pl-2">
                        <i
                          className="icon-calender"
                          style={{ fontSize: "28px" }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <FootprintDatePicker
                      defaultDate={this.state.selectedDate}
                      onChange={(date) => {
                        this.setState({ selectedDate: date, loading: true });
                        this.props.fetchFood(date);
                      }}
                      timeFormat={false}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {typeof lunch !== "undefined" &&
              typeof breakfast !== "undefined" && (
                <Row>
                  <Col xs="12" sm="6" md="6" lg="4" className="mb-3">
                    <Card className="border-0 shadow h-100">
                      <CardHeader
                        className="border-0 bg-success"
                        style={{ fontFamily: "Baloo", color: "#fff" }}
                      >
                        Breakfast :
                      </CardHeader>
                      <CardBody className="p-3">
                        <CardTitle>{breakfast}</CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="4" className="mb-3">
                    <Card className="border-0 shadow h-100">
                      <CardHeader
                        className="border-0 bg-red"
                        style={{ fontFamily: "Baloo", color: "#fff" }}
                      >
                        Lunch :
                      </CardHeader>
                      <CardBody className="p-3">
                        <CardTitle>{lunch}</CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="4" className="mb-3">
                    <Card className="border-0 shadow h-100">
                      <CardHeader
                        className="border-0 bg-teal"
                        style={{ fontFamily: "Baloo", color: "#fff" }}
                      >
                        Snacks :
                      </CardHeader>
                      <CardBody className="p-3">
                        <CardTitle>{snacks}</CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="4" className="mb-3">
                    <Card className="border-0 shadow h-100">
                      <CardHeader
                        className="border-0 bg-warning"
                        style={{ fontFamily: "Baloo", color: "#fff" }}
                      >
                        Fruits :
                      </CardHeader>
                      <CardBody className="p-3">
                        <CardTitle>{fruits}</CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

            {typeof lunch === "undefined" &&
              typeof breakfast === "undefined" && (
                <ListEmptyComponent
                  heading={loading ? "Loading..." : "No Data Found"}
                />
              )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  food: state.foodmenu.food,
  loading: state.foodmenu.loading,
  constants: state.constants
});

const mapDispatchToProps = dispatch => ({
  fetchFood: date => dispatch(fetchFood(date))
});
export default connect(mapStateToProps, mapDispatchToProps)(FoodMenu);
