import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

import {
  Col,
  Row,
  Card,
  Form,
  FormGroup,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import { showPasswordOverlay } from "../../global/redux/action";
import moment from "moment";
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import FootprintInputText from "../../global/utils/FootprintInputText";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";

import {
  checkNonSatisfactionRefund,
  nonSatisfactionRefund,
} from "./redux/action";
class NonSatisfactionRefund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMsg: "false",
      successMsg: "",
      errorMsg: "",
      fromDate: "",
      comment: "",
      amount: "",
      selectedDate: "",
      //moment().format(DEFAULT_FORMAT)
    };
  }
  componentDidMount() {
    console.log("[Finance][NonSatisfactionRefund] Checking for non satisfaction refund");
    this.props.checkNonSatisfactionRefund(
      (item) => {
        const msg = item.message;
        const refundAmount = item.amount;
        this.setState({
          successMsg: msg,
          amount: refundAmount,
        });
      },
      (errorMsg) => {
        this.setState({
          errorMsg: errorMsg,
        });
      }
    );
  }

  validate = () => {
    if (this.state.holidaySelected === true) {
      this.setState({ error: "The Selected Date Cannot Be a Holiday" });
      return false;
    }else if (this.state.selectedDate === "") {
      this.setState({ error: "Please Select Date" });
      return false;
    } else if (this.state.comment === "") {
      this.setState({ error: "Please Enter Comment" });
      return false;
    } else {
      return true;
    }
  };

  submit = () => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, (isMatched) => {
      //   if (isMatched) {
          // alert(this.state.amount);
          this.props.nonSatisfactionRefund(
            this.state.selectedDate,
            this.state.comment,
            this.state.amount,
            () => {
              this.props.history.replace("support");
            }
          );
        } else {
        }
      // });
    // } 
    // else {
    // }
  };
  render() {
    // console.log("\nState=>",this.state);
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em",
            }}
          >
            Non Satisfaction Refund
          </CardHeader>
          <CardBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    {this.state.successMsg !== "" && (
                      <Alert color="warning">
                        <strong>{this.state.successMsg}</strong>
                      </Alert>
                    )}
                    {this.state.errorMsg !== "" && (
                      <Alert color="warning">
                        <strong> {this.state.errorMsg} </strong>
                      </Alert>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {this.state.errorMsg === "" && (
                // <Form>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="startDate">Date</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-calender" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <FootprintDatePicker
                            holidaySelected={(selected) => {
                              this.setState({
                                error: "The selected Date Cannot Be a Holiday",
                                holidaySelected: selected,
                              });
                            }}
                            maximumDate={moment()}
                            minimumDate={moment().subtract(2,"days")}
                            defaultDate={this.state.selectedDate}
                            onChange={(date) => {
                              this.setState({
                                holidaySelected: false,
                                selectedDate:
                                  moment(date).format(DEFAULT_FORMAT),

                                error: "",
                              });
                            }}
                            inputProps={{
                              placeholder: "Select Date",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="endDate">Reason</Label>
                        <FootprintInputText
                          type="text"
                          placeholder="Reason"
                          onChange={(value) =>
                            this.setState({ comment: value, error: "" })
                          }
                          name="text"
                        />
                      </FormGroup>
                    </Col>

                    {typeof this.state.error !== "undefined" && (
                      <p style={{ color: "red" }}>{this.state.error}</p>
                    )}
                    <FootprintInputButton
                      onClick={() => {
                        this.submit();
                      }}
                    />
                  </Row>
                // </Form>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  constants: state.constants,
});
const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  checkNonSatisfactionRefund: (onSuccess, onError) =>
    dispatch(checkNonSatisfactionRefund(onSuccess, onError)),
  nonSatisfactionRefund: (selectedDate, comment, amount, onSuccess) =>
    dispatch(nonSatisfactionRefund(selectedDate, comment, amount, onSuccess)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonSatisfactionRefund);
//export default NonSatisfactionRefund;
