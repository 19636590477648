import React, { Component } from "react";
import { Container } from "reactstrap";

import HeaderHOC from "../../global/HOComponet/Header";
// import SideDrawer from "../../global/HOComponet/SideDrawer";
import { connect } from "react-redux";
import BottomPanel from "../../global/HOComponet/BottomPanel";
import SideMenu from "../../global/HOComponet/sideMenu";
import NotificationService from "../../global/NotificationService";
import Constants from "../../constants";
import { logout } from "../Login/redux/action";
import { setNotification } from "../../global/redux/action";
// import { connect } from 'react-redux';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    //
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { user } = this.props;
    // console.log("USER=> ",user);
    // console.log(Constants.env.version);
    let current_version = Constants.env.version;
    let old_version = user.app_version;
    // console.log("Old version: ",old_version,"Current version: ",current_version);
    if (user && user.activeChildDetail && user.activeChildDetail.id) {
      // console.log("User Checked");
      if (old_version !== current_version) {
        // console.log("Version Checked");
        // this.props.logout();
        // window.location = "/";
        // this.props.setNotification("Parent Connect App Update","It seems you are using previous versions of app update the app to continue",()=>{})
      }
    } else {
      window.location = "/"
    }
  }

  render() {
    const { user } = this.props;
    if (user && user.activeChildDetail && user.activeChildDetail.id) {
      return (
        <div>
          <NotificationService />
          <HeaderHOC history={this.props.history} />
          {/* <SideDrawer /> */}
          <SideMenu />

          <div className="main-content" ref="mainContent">
            <Container fluid={true}>{this.props.children}</Container>
          </div >

          <BottomPanel
            picPresent={this.props.profilePicPresent}
            photo={this.props.childPhoto}
            constants={this.props.constants}
            location={this.props.location.pathname}
          />

        </div >
      );
    }
    return <div></div>
  }
}
const mapStateToProps = state => {
  const activeChildDetail =
    state.userState.user && state.userState.user.activeChildDetail;
  const photo =
    state.userState.user &&
    state.userState.user.activeChildDetail &&
    activeChildDetail.child_photo;
  return ({
    user: state.userState.user,
    lastLoggedIn: state.userState.lastLoggedIn,
    profilePicPresent: typeof photo === "string" && photo !== "",
    childPhoto:
      state.userState.user.activeChildDetail &&
      state.userState.user.activeChildDetail.child_photo,
    constants: state.constants,
  })
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNotification: (title, subtitle, onClick) =>
    dispatch(setNotification(title, subtitle, onClick)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);