import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import { Card, CardBody, Col, CardHeader } from "reactstrap";

import { cancelWithrawlRequest } from "./redux/action"; // withdrawarl case
import { cancelTransportRequest } from "../Transport/redux/action";
import { cancelShiftingRequest } from "../ShiftCenter/redux/action";
import { cancelChangeProgram } from "../ProgramChange/redux/action";
import { cancelExtendDaycare } from "../ExtendedDaycare/redux/action";
import { cancelLeaveRequest, cancelPauseRequest } from "../Break/redux/action";
import { setNavigationParams, showModal } from "../../global/redux/action";

class PastRequestItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onCancelPress = (item) => {
    this.props.showModal(
      "Success",
      "Are You Sure You Want To Cancel This Request ?",
      true,
      () => {
        //if (window.confirm("Are You Sure You Want To Cancel This Request")) {
        switch (item.type) {
          case "Add One Way Transport": {
            this.props.cancelTransportRequest(item);
            break;
          }

          case "Add Two Way Transport": {
            this.props.cancelTransportRequest(item);
            break;
          }

          case "Center Shift": {
            this.props.cancelShiftingRequest(item);
            break;
          }

          case "Program Change": {
            this.props.cancelChangeProgram(item);
            break;
          }

          case "Leaves": {
            this.props.cancelLeaveRequest(item);
            break;
          }
          case "Child Pause": {
            this.props.cancelPauseRequest(item);
            break;
          }
          case "Child Attrition": {
            this.props.cancelWithrawlRequest(item);
            break;
          }
          case "Delete One Way Transport": {
            this.props.cancelTransportRequest(item);
            break;
          }
          case "Delete Two Way Transport": {
            this.props.cancelTransportRequest(item);
            break;
          }
          case "Extended Daycare": {
            this.props.cancelExtendDaycare(item);
            break;
          }
          default: {}
        }
      },
      () => { }
    );
  };
  render() {
    const { item, index, md } = this.props;
    // console.log("Request Item => ",item)
    return (
      <Col md={md} className="mb-4">
        <Card className="h-100 shadow border-0">
          <CardHeader
            key={index}
            style={{ background: "#efefef", fontFamily: "baloo" }}
          >
            <div className="d-flex">
              <div className="mr-auto">
                {index + 1}. {item.type}
              </div>
              <div>Status - {item.status}</div>
            </div>
          </CardHeader>
          <CardBody className="py-3 px-1">
            <div className="d-flex py-2 px-2">
              <div className="mr-auto">
                <p>
                  <span style={{ paddingRight: "10px" }}>
                    {"Requested On "}
                  </span>
                  <br/>
                  {moment(item.request_date, "YYYY-MM-DD HH:mm:ss").format(
                    DEFAULT_FORMAT
                  )}
                </p>
              </div>
              <div>
                <span style={{ paddingRight: "10px" }}>
                  {" Affected Date "}
                </span>
                  <br/>
                {item.type === "Center Shift"
                  ? moment(item.end_date, "YYYY-MM-DD").isValid()
                    ? moment(item.end_date, "YYYY-MM-DD").format(DEFAULT_FORMAT)
                    : ""
                  : moment(item.date, "YYYY-MM-DD").isValid()
                    ? moment(item.date, "YYYY-MM-DD").format(DEFAULT_FORMAT)
                    : ""}
              </div>
            </div>

            {/* <div className="d-flex"> */}
            <div style={{ display: "inline" }}>
              {item.showEarlyPauseAndResume && (
                <button
                  className="btn btn-primary p-2"
                  type="button"
                  style={{ marginTop: "4px" }}
                  onClick={() => {
                    this.props.setNavigationParams(item);

                    this.props.history.push({
                      pathname: "earlyResume",
                      item: item,
                    });
                  }}
                >
                  Early Resume
                </button>
              )}
              {item.showEarlyPauseAndResume && (
                <button
                  className="btn btn-warning p-2"
                  type="button"
                  style={{ marginTop: "4px" }}
                  onClick={() => {
                    this.props.setNavigationParams(item);
                    this.props.history.push({
                      pathname: "extendPause",
                      //item: [item]
                      item: item,
                    });
                  }}
                >
                  Extend Pause
                </button>
              )}

              {item.showCancel && (
                <button
                  className="btn btn-danger"
                  type="button"
                  style={{ marginTop: "4px", marginLeft: '.75rem' }}
                  onClick={() => this.onCancelPress(item)}
                >
                  Cancel
                </button>
              )}
            </div>
            <div>{/* previous cancel button here */}</div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  cancelTransportRequest: (item) => dispatch(cancelTransportRequest(item)),
  cancelShiftingRequest: (item) => dispatch(cancelShiftingRequest(item)),
  cancelChangeProgram: (item) => dispatch(cancelChangeProgram(item)),
  cancelLeaveRequest: (item) => dispatch(cancelLeaveRequest(item)),
  cancelPauseRequest: (item) => dispatch(cancelPauseRequest(item)),
  cancelWithrawlRequest: (item) => dispatch(cancelWithrawlRequest(item)),
  cancelExtendDaycare: (item) => dispatch(cancelExtendDaycare(item)),
  setNavigationParams: (params) => dispatch(setNavigationParams(params)),
  showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
    dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PastRequestItem);
