import React from "react";
import ChatItem from "./item";
// import { connect } from 'react-redux';

class DateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dateGroup } = this.props;
    if (dateGroup && Array.isArray(dateGroup.data)) {
      return <div className="conversation-list">
        <p className="msg_date text-center mb-1"><span>{dateGroup.dateGroup}</span></p>
        {
          dateGroup.data.map((item, index) => (
            <ChatItem key={index} item={item} />
          ))
        }
      </div>
    }
    return <div />
  }
}

export default DateGroup;
