import { RESET_DOOR_PIN_SUCCESS } from "./action";

const initialState = {};
const myProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DOOR_PIN_SUCCESS: {
      return {
        ...state,
        accessPin: action.payload.pin,
      };
    }
    default:
      return state;
  }
};
export default myProfileReducer;
