import React from "react";
import nico from "../../assets/splash-nico-hi.png";
import { connect } from "react-redux";
import { signIn } from "./redux/action";
import { Link } from "react-router-dom";

import { Form, FormGroup,Button } from "reactstrap";
import { Card, CardBody, CardTitle } from "reactstrap";
import blogStyle from "../Blogs/style";
import manifest from "../../manifest.json";
import { forgotPassword, verifyOTP } from "./redux/action";
import ChangeLoginChildOverlay from "../../global/ChangeLoginChildOverlay";
import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
// import { generateAuthToken, getAuthToken } from "../../global/utils/fetch";
import Constants from "../../constants";
// import { BeatLoader } from "react-spinners";
import WaitingLoading from "../../global/utils/waitingForOTP";
import VerifyInput from "./VerifyInputScreen";
import { showModal } from "../../global/redux/action";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      OTP: "",
      showOverlay: false,
      loading: false,
    };
      this.interval = null;
  }

  startSMSRetriever = async () => {
      if (
        window.cordova &&
        window.cordova.plugins &&
        window.cordova.plugins.SMSRetriever
      ) {
        try {
          // Start SMS Retriever
          // console.log("Cordova: ", window.cordova);
          await window.cordova.plugins.SMSRetriever.startWatch(
          async (res) => {
              // console.log("SMS Retriever started: ", res);
              await document.addEventListener("onSMSArrive", (event) => {
                // console.log("Event: ", event);
                const message = event.message;
                // console.log("SMS received", message);

                // Extract OTP from the message
                const otpMatch = message.match(/\d{6}/);
                // console.log("OTP: ", otpMatch[0]);
                if (otpMatch) {
                  const otp = otpMatch[0];
                  this.setState({ OTP: otp, loading: false });
                  this.submit();
                  return otp;
                } else {
                  return 0;
                }
              });
            },
            (error) => {
              console.error("Error starting SMS Retriever", error);
            }
          );
        
        } catch (error) {
          console.error("Error requesting SMS permissions", error);
          return error;
        }
      }
    };

  async componentDidMount() {
    console.log("[Verify OTP] Enter login otp...");
    let platform = Constants.env.platform;
    if (platform === "android") {
      this.writeToClipboard();
        this.interval = setInterval(() => {
          // console.log("Interval Started");
          this.checkClipboard();
        }, 1 * 1000);
      // this.setState({ loading: true });
      // await this.startSMSRetriever();
    } 
    // else if (platform === "web") {
    //   this.writeToClipboard();
    //   this.interval = setInterval(() => {
    //     console.log("Interval");
    //     this.checkClipboard();
    //   }, 1 * 1000);
    // }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    document.removeEventListener("onSMSArrive", () => {});
  }

  checkClipboard = async () => {
    if (document.hasFocus()) {
      // console.log("Window is focused!");
      if (Capacitor.isNativePlatform()) {
        const { type, value } = await Clipboard.read();
        // console.log(`Got ${type} from clipboard: ${value}`);
        if (value && this.state.OTP !== value) {
          await this.setState({ ...this.state, OTP: value });
          await clearInterval(this.interval);
          this.submit();
        }
      } else {
        let value = await navigator.clipboard.readText();
        // console.log("Value => ",value);
        if (value) {
          this.setState({ ...this.state, OTP: value });
          clearInterval(this.interval);
        }
      }
    } else {
      // console.log("Window is not focused.");
    }
  };

  writeToClipboard = async () => {
    if (document.hasFocus()) {
      // console.log("Window is focused!");
      if (Capacitor.isNativePlatform()) {
        await Clipboard.write({
          string: "",
        });
      } else {
        await navigator.clipboard.writeText("");
      }
    } else {
      // console.log("Window is not focused.");
    }
    
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      // console.log("set focus");
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
    }
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
    this.setState({ error: "" });
  };

  validate = () => {
    if (!this.state.OTP) {
      this.setState({ error: "Please Enter OTP" });
      // this.props.showModal("Error", "Please Enter OTP");
      return false;
    } else if (this.state?.OTP?.length !== 6) {
      this.setState({ error: "Please Enter a valid 6 digit OTP" });
      // this.props.showModal("Error", "Please Enter a valid 6 digit OTP");
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
  };

  showChildChangeOption = () => {
    this.setState({ showOverlay: true }, () => {});
  };

  isMultipleChild = () => {
    // console.log(
    //   "Multiple child : ",
    //   Array.isArray(this.props?.user.childList) &&
    //     this.props?.user.childList.length > 1
    // );
    return (
      Array.isArray(this.props.user.childList) &&
      this.props?.user.childList.length > 1
    );
  };

  submit = () => {
    if (this.validate()) {
      // localStorage.setItem("auth_token", generateAuthToken());
      this.props.verifyOTP(this.state.OTP, (response) => {
        localStorage.setItem("auth_token", response.auth_token);
        localStorage.setItem("last_reload", response.time);
        if (
          response &&
          response.survey &&
          response.survey.survey_id > 0
          ) {
          localStorage.setItem("survey_status", response.survey.survey_id);
          // console.log("Redirecting to survey ");
          this.props.history.replace({
            pathname: `survey/${response.survey.survey_id}`,
          });
        } else {
          if (this.isMultipleChild()) {
            this.showChildChangeOption();
          } else this.props.history.replace("/");

          if (response && response.eventStatus > 0) {
            this.props.showModal('Ok', 'A new event has been scheduled, Click ok to view event details.', true, () => { this.props.history.replace({ pathname: '/outdoorActivity' }) }, ()=>{})
          }
        }
      });
    }
  }

  enterManually = async () => { 
    if (
      window.cordova &&
      window.cordova.plugins &&
      window.cordova.plugins.SMSRetriever
    ) {
      await window.cordova.plugins.SMSRetriever.stopWatch(
        async (res) => {
          // console.log("SMS Retriever stop: ", res);
        },
        (error) => {
          console.error("Error starting SMS Retriever", error);
        }
      );
      }
      document.removeEventListener("onSMSArrive", () => { });
      this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { innerHeight: height } = window;
    return (
      <>
        <WaitingLoading loading={loading} setLoading={this.enterManually} />
        <ChangeLoginChildOverlay
          isOpen={this.state.showOverlay}
          toggle={(isOpen) => {
            this.setState({
              showOverLay: isOpen,
            });
          }}
          history={this.props.history}
        />
        <Card className="pt-2" style={blogStyle.cardView}>
          <img
            className={height > 901 ? "" : "mr-auto ml-auto"}
            top="true"
            width={height < 741 ? "40%" : height < 900 ? "88%" : "100%"}
            src={nico}
            alt="Card image cap"
          />
          <CardBody>
            <CardTitle className="mb-0">
              <h5
                style={
                  height > 741
                    ? { fontFamily: "baloo" }
                    : { fontFamily: "baloo", fontSize: "1rem" }
                }
              >
                Enter OTP to verify your account
              </h5>
            </CardTitle>
            <Form style={height < 741 ? { lineHeight: "0.5rem" } : {}}>
              <FormGroup>
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontFamily: "baloo",
                    fontWeight : "100"
                  }}
                >
                  {" OTP valid for 30 minutes only"}
                </p>
                <VerifyInput OTP={this.state.OTP} setOTP={(val) => this.setState({ OTP: val })} />
                {typeof this.state.error !== "undefined" && (
                <p className="mt-1" style={{ color: "red", lineHeight: "1.2rem", fontSize: "14px" }}>
                  {this.state.error}
                </p>
              )}
              </FormGroup>
              
              <div className="d-flex justify-content-center mb-2 mt-5">
                <span
                  style={{
                    color: "black",
                    // float: "center",
                    fontFamily: "baloo",
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                    fontSize: "16px"
                  }}
                  
                >
                  Didn't get the code, <span onClick={() =>
                    this.props.login(this.props.phone, this.props.email, "")
                  }
                  style={{color: "#19ade4"}}
                  >Send again</span>
                </span>
              </div>

              <Button
                onClick={this.submit}
                style={{...blogStyle.button, color: "white"}}
                size="lg"
                block
              >
                Verify
              </Button>

              <Button
                onClick={()=> this.props.history.replace("/")}
                style={{...blogStyle.button,color: "#19ade4", background: "white", marginTop: "0.75rem" }}
                size="lg"
                block
              >
                Cancel
              </Button>
              {/* <div className="d-flex justify-content-center mt-3">
                <Link to={"/"}>
                  <span
                    style={{
                      color: "black",
                      // float: "right",
                      fontFamily: "baloo",
                      fontSize: "14px"
                    }}
                  >
                    Cancel
                  </span>
                </Link>
              </div> */}
            </Form>
          </CardBody>
        </Card>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            alignItems: "center",
          }}
        >
          <h6
            style={{ fontFamily: "baloo", fontSize: 14 }}
          >{`${manifest.applicationName} v${manifest.version}`}</h6>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.userState.phone,
  email: state.userState.email,
  user: state.userState.user,
});
const mapDispatchToProps = (dispatch) => ({
  verifyOTP: (otp, onSuccess) =>
    dispatch(verifyOTP(otp, false,false, onSuccess)),
  forgotPassword: (OTP) => dispatch(forgotPassword(OTP)),
  login: (phone, email, verify) => dispatch(signIn(phone, email, verify)),
  showModal: (type,message,isConfirm,onSuccess,onCancel) =>
    dispatch(showModal(type,message,isConfirm,onSuccess,onCancel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
//export default Login;