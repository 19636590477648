import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import FootprintDatePicker from '../../global/utils/FootprintDatePicker';
import FootprintInputButton from '../../global/utils/FootprintInputButton';
import moment from 'moment';
import { DEFAULT_FORMAT } from '../../global/redux/combineLogic';
import { extendDaycare } from './redux/action';


const ExtendedDaycare = (props) => {
  const [error, setError] = useState(undefined);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [holidaySelected, setHolidaySelected] = useState(false);
  const dispatch = useDispatch();
  const durationValues = [{ key: 'Monthly', value: '1 Month' }, { key: 'Weekly', value: '1 Week' }, { key: 'Daily', value: '1 Day' }];
  console.log("[ExtendedDaycare] Request for Daycare Services");
  const submit = () => {
    if (
      selectedDuration === undefined ||
      selectedDuration === ""
    ) {
      setError("Please select a type.");
    } else if (selectedDate === undefined) {
      setError("Please select start date.");
    } else if (holidaySelected === true) {
      setError("The selected Date Cannot Be a Holiday.");
    } else {
      dispatch(extendDaycare(selectedDate, selectedDuration, ()=>{ props.history.replace("PastRequestTab");}))
    }
  }

  return (
    <Card className="border-0 shadow  card-timeline mt-4">
      <CardHeader
        className="text-white"
        style={{
          background: "#A500F8",
          fontFamily: "baloo",
          fontSize: "1.3em",
        }}
      >
        Extended Daycare
      </CardHeader>
      <CardBody>
        <Form>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>Select Type</Label>

                <CustomInput
                  id="newProgram"
                  type="select"
                  onChange={(event) => {
                    let selectedValue = event.target.value;
                    setSelectedDuration(selectedValue);
                    setError(undefined);
                  }}
                >
                  <option value="">-- Select --</option>
                  {durationValues.map((ele, i) => (
                    <option key={i} value={ele.key}>
                      {ele.value}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="startDate">Start Date</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-calender" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FootprintDatePicker
                    holidaySelected={(selected) => {
                      setError("The selected Date Cannot Be a Holiday");
                      setHolidaySelected(true);
                      setSelectedDate(selected);
                    }}
                    inputProps={{ placeholder: "Select Date" }}
                    minimumDate={moment()}
                    //defaultDate={this.state.selectedDate}
                    onChange={(date) => {
                      setHolidaySelected(false);
                      setSelectedDate(moment(date).format(DEFAULT_FORMAT));
                      setError(undefined);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {typeof error !== "undefined" && (
            
              <Label style={{ color: "red" }}>{error}</Label>
         
          )}
          <FormGroup className="mb-0 text-center">
            <FootprintInputButton
              style={{ color: "white" }}
              className="btn btn-primary"
              onClick={() => {
                submit();
              }}
            >
              Submit
            </FootprintInputButton>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
}

export default ExtendedDaycare;