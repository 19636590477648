import React, { Component } from "react";
import { Input } from "reactstrap";
const isAlpha = (value) => {
  return value === "" || value.match(/^[0-9]+$/);
};

export default class FootprintInputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.defaultValue || "",
    };
  }

  setValue = (value) => {
    if (this.props.updateValue) {
      this.setState({ inputValue: value });
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    } else if (this.props.updateValue !== undefined) this.props.onChange('')
    else {
      this.setState({ inputValue: value });
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
  };
  render() {
    const {
      placeholder = "Write here",
      maxLength = 32768,
      // type,
      //onKeyPress = () => {},
      //onChange = () => {},
      // disable = "false"
    } = this.props;
    return (
      <Input
        type="text"
        value={this.state.inputValue}
        placeholder={placeholder}
        //onKeyPress={onKeyPress}
        onChange={(event) => {
          const value = event.target.value;
          if (maxLength === 10) {
            if (value.length <= maxLength && isAlpha(value)) {
              this.setValue(value);
            }
          } else {
            this.setValue(value);
          }
          // if (value.length <= maxLength) {
          //   if (this.props.type == "number" && isAlpha(value)) {
          //     this.setValue(value);
          //   }
          //   this.setValue(value);
          // }
        }}
      />
    );
  }
}
