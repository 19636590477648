import React, { createRef } from "react";
import { connect } from "react-redux";
import { fetchChat, sendChatMessage } from "./redux/action";
import DateGroup from "./DateGroup";
import { Card, Button, CardHeader, CardFooter, CardBody } from "reactstrap";
import { Input, InputGroupAddon, InputGroup } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Theme from "../../theme";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { setContext } from "../../global/utils/crashlyticsMethods";

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      startIndex: 0,
    };
    this.scrollDiv = createRef();
  }

  async componentDidMount() {
    console.log("[Conversation] Fetching data...");
    await this.props.fetchChat(0);
    this.scrollToBottom();
    setContext("Conversation", this.props.chat);
  }

  scrollToBottom() {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

  fetchMoreData = () => {
    // console.log("Fetch More Data");
    const newStartIndex = this.state.startIndex + 50 + 1;
    this.props.fetchChat(newStartIndex);
    this.setState({
      startIndex: newStartIndex,
    });
  };

  fetch = () => {
    this.setState({
      startIndex: 0,
    });
    this.props.fetchChat(0);
  }

  render() {
    const { chat, dataLength, moreDataPresent } = this.props;
    // console.log("Data Present => ", moreDataPresent);
    return (
      <Card className="msg-card mt-4 shadow border-0">
        <CardHeader
          style={{
            background: Theme.colorPrimary,
            fontFamily: "baloo",
            fontSize: "1.3em",
          }}
          className="msg_head"
        >
          <div
            style={{ color: "white", alignItems: "center" }}
            className="d-flex bd-highlight"
          >
            Updates
            <div className="ml-auto my-auto">
              <Link
                to="#"
                onClick={() => {
                  this.fetch();
                  this.scrollToBottom();
                }}
              >
                <i
                  className="icon-refresh fa-spin-custom"
                  style={{ color: "#fff", fontSize: "1.2em" }}
                ></i>
              </Link>
            </div>
          </div>
        </CardHeader>

        <CardBody
          className="msg_card_body"
          id="chat-container"
          style={{
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            scrollBehavior: "smooth",
          }}
        >
          <InfiniteScroll
            dataLength={dataLength}
            next={this.fetchMoreData}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true}
            hasMore={moreDataPresent}
            // loader={<p>Loading...</p>}
            scrollableTarget="chat-container"
          >
            {[...chat].map((dateGroup, index) => (
              <DateGroup key={index} dateGroup={dateGroup} />
            ))}
          </InfiniteScroll>

          {!Array.isArray(chat) ||
            (chat.length === 0 && (
              <ListEmptyComponent
                heading={
                  this.props.loading
                    ? "Loading..."
                    : "Conversation list is Empty"
                }
              />
            ))}
        </CardBody>

        <CardFooter>
          <InputGroup>
            <Input
              style={{ zIndex: 0 }}
              type="textarea"
              placeholder="Type a message"
              onFocus={(e) =>
                this.setState({
                  merged: { ...this.state.merged, textarea: true },
                })
              }
              onBlur={(e) =>
                this.setState({
                  merged: { ...this.state.merged, textarea: false },
                })
              }
              value={this.state.message}
              onChange={(event) => {
                this.setState({ message: event.target.value });
              }}
            />
            <InputGroupAddon
              addonType="prepend"
              style={{ alignItems: "center", zIndex: 0 }}
            >
              <Button
                className="btn rounded-circle"
                style={{
                  width: "50px",
                  height: "50px",
                  padding: "0",
                  background: "#F44A4A",
                  borderColor: "#F44A4A",
                }}
                onClick={() => {
                  if (
                    typeof this.state.message !== "undefined" &&
                    this.state.message.length > 0
                  ) {
                    this.setState({ isSendingMessage: true });
                    this.props.postMessage(this.state.message.trim(),this.scrollToBottom);
                  }
                  this.setState({ message: "" });
                }}
              >
                <i
                  className="icon-send"
                  style={{ fontSize: "2em", color: "#fff" }}
                ></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let chat = state.chat.sectionedChat;
  // console.log("Chat Data => ", chat,"\nLength => ", state.chat.chat.length);
  const parentProfile = state.userState && state.userState.parentProfile;
  return {
    chat: chat,
    loading: state.chat.loading,
    parentName: parentProfile && parentProfile.parentName,
    route: state.global.route,
    dataLength: state.chat.chat.length || 50,
    moreDataPresent: state.chat.moreData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChat: (startIndex) => {
    dispatch(fetchChat(startIndex));
  },
  postMessage: (message, onSuccess) =>
    dispatch(sendChatMessage(message, onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Conversation);