import { FETCH_NOTIFICATION_SUCCESS } from "./action";
import { transformTimeLine } from "../../ChildTimeline/redux/transformTimeline";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = { sectionedNotifications: [], loading: true };
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_SUCCESS: {

      return {
        ...state,
        notifications: action.payload.conversationList,
        sectionedNotifications: transformTimeLine(
          action.payload.conversationList,
        ),
        loading: false

      };
    }
    case SET_COMPONENT_LOADING + 'NOTIFICATION': {
      return {
        ...state,
        loading: action.value
      }
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }

    default: {
      return state;
    }
  }
};
export default notificationReducer;
