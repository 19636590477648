export const FETCH_CHILD_MEASUREMENTS = "FETCH_CHILD_MEASUREMENTS";
export const FETCH_CHILD_MEASUREMENTS_SUCCESS =
  "FETCH_CHILD_MEASUREMENTS_SUCCESS";
export const FETCH_CHILD_CARE = "FETCH_CHILD_CARE";
export const FETCH_CHILD_CARE_SUCCESS = "FETCH_CHILD_CARE_SUCCESS";
export const fetchMeasurements = payload => ({
  type: FETCH_CHILD_MEASUREMENTS,
  payload
});

export const fetchMeasurementsSuccess = payload => ({
  type: FETCH_CHILD_MEASUREMENTS_SUCCESS,
  payload
});

export const fetchChildCare = payload => ({
  type: FETCH_CHILD_CARE,
  payload
});

export const fetchChildCareSuccess = payload => ({
  type: FETCH_CHILD_CARE_SUCCESS,
  payload
});
