export const FETCH_CHILD_TIMELINE = "FETCH_CHILD_TIMELINE";
export const FETCH_CHILD_TIMELINE_SUCCESS = "FETCH_CHILD_TIMELINE_SUCCESS";
export const UPDATE_DATA_PRESENCE = 'UPDATE_DATA_PRESENCE';

export const fetchChildTimeLine = startIndex => ({
  type: FETCH_CHILD_TIMELINE,
  payload: { startIndex }
});

export const fetchChildTimeLineSuccess = (response = {}, startIndex) => ({
  type: FETCH_CHILD_TIMELINE_SUCCESS,
  payload: {
    conversationList: response.conversationList,
    startIndex
  }
});

export const updateDataPresence = (dataAvailable) => ({
  type: UPDATE_DATA_PRESENCE,
  payload: dataAvailable
})
