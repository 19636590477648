import _ from "lodash";
import Theme from "../../../theme";

const moment = require("moment");

const FOOD_TYPE = ["Breakfast", "Fruit", "Lunch", "Snack", "Snacks", "Fruits"];
export const DISPLAY_TIME_FORMAT = "hh:mm a";

const getTypes = item => {
  const isfood = FOOD_TYPE.indexOf(item.type);

  //const isToday = item.date === moment().format("YYYY-MM-DD") ? true : false;

  let type = isfood >= 0 ? "food" : item.type ? item.type.toLowerCase() : "";
  let navigationParams = "";
  let dotColor = "";
  let linkflag = true;
  let navigateTo = "";
  let label = "";
  let linkImg = "";
  //let linkImg = require("../../../assets/foodMenu/Lunch.png");
  switch (type) {
    case "food":
      dotColor = Theme.colorPrimary;
      navigateTo = "FoodMenu";
      label = "Food Menu";
      linkImg = require("../../../assets/foodMenu/Lunch.png");
      break;
    case "milk":
      dotColor = Theme.colorPrimary;
      linkflag = false;
      label = "Milk";
      linkImg = require("../../../assets/timeline/Milk-icon.png");
      break;
    case "image":
    case "photo":
      dotColor = Theme.colorPrimary;
      navigateTo = "PhotoGallery";
      label = "View Pics";
      linkImg = require("../../../assets/timeline/image-icon.png");
      break;
    case "message":
      dotColor = Theme.colorPrimary;
      navigateTo = "chat";
      label = "Message";

      linkImg = require("../../../assets/timeline/message-icon.png");
      break;
    case "measurement":
      dotColor = Theme.colorPrimary;
      navigateTo = "Growth";
      label = "Measurement";
      linkImg = require("../../../assets/timeline/measurement-icon.png");
      break;
    case "cor":
      dotColor = Theme.colorPrimary;
      navigateTo = "Growth";
      navigationParams = { activeTab: "COR" };
      label = "COR";
      linkImg = require("../../../assets/timeline/report-icon.png");
      break;
    case "attendance":
      dotColor = Theme.colorPrimary;
      navigateTo = "Attendance";
      linkflag = false;
      label = "Attendance";
      linkImg = require("../../../assets/timeline/attendance-icon.png");
      break;
    case "activity":
      dotColor = Theme.colorPrimary;

      linkflag = false;
      //navigateTo = 'ActivityCalendar';
      label = "Activity";
      linkImg = require("../../../assets/timeline/activity-icon.png");
      break;
    case "outdoorevents":
      dotColor = Theme.colorPrimary;
      navigateTo = "OutdoorActivity";
      label = "OutdoorEvents";
      linkImg = require("../../../assets/timeline/outdoor.png");
      break;
    default:
      // dotColor = 'yellow';
      dotColor = Theme.colorPrimary;

      linkflag = false;
  }

  return {
    dotColor,
    navigateTo,
    label,
    linkImg,
    linkflag,
    navigationParams
  };
};

export const transform = timeLineItem => {
  if (Array.isArray(timeLineItem) && timeLineItem.length > 0) {
    return timeLineItem.map(item => {
      const momentTime = moment(item.time, "hh:mm:ss");
      return {
        ...item,
        time: momentTime.format(DISPLAY_TIME_FORMAT),
        momentTime,
        ...getTypes(item)
      };
    });
  }
  return [];
};

export const transformTimeLine = (timeline, useDateFormat = true) => {
  try {
    if (Array.isArray(timeline)) {
      let dateGroup = _.groupBy(timeline, d => {
        if (useDateFormat) {
          return moment(d.date, "YYYY-MM-DD").format("DD-MM-YY");
        } else {
          return moment(d.date).format("DD-MM-YY");
        }
      });
      dateGroup = _.reduce(
        dateGroup,
        (acc, next, index) => {
          acc.push({
            dateGroup: index,
            data: transform(next)
          });
          return acc;
        },
        []
      );
      return dateGroup;
    }
    return [];
  } catch (error) {
    console.log("[ChildTimeline][Transform func.] Error: ",error);
    return [];
  }
};
