import {
  SIGN_IN,
  fetchUserSuccess,
  FORGOT_PASSWORD,
  saveOTP,
  VERIFY_OTP,
  FREE_BEE_LOGIN,
  FETCH_CHILD_DETAIL,
  SIGN_IN_VERIFY,
  fetchOtpSuccess,
  setChildDetail
} from "./action";

import { createLogic } from "redux-logic";
import { setLoading, setError } from "../../../global/redux/action";
import fetchAPI from "../../../global/utils/fetch";
import manifest from "../../../manifest";
import Constants from "../../../constants";
import { verify } from "crypto";
import { Device } from '@capacitor/device';
import { setContext } from "../../../global/utils/crashlyticsMethods";

const logDeviceInfo = async () => {
  const info = await Device.getInfo();

  // console.log(info);
  return info;
};

export const freeBeeLogin = createLogic({
  type: FREE_BEE_LOGIN,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/chargebees/login/`;
      const body = {
        id: getState().userState.user.activeChildDetail.id,
        redirect_url: getState().constants.AppUrl || Constants.env.AppUrl,
      };
      console.log("[Finance][Login] Calling API: /chargebees/login, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,

        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Finance][Login] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));
          console.log("[Finance][Login] Error: ",error);
          setContext("Fee Bee Login Error", error);
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[Finance][Login] Error: ",error);
      dispatch(setLoading(false));
      setContext("Fee Bee Login Error", error);
      done();
    }
  },
});

export const fetchChildDetail = createLogic({
  type: FETCH_CHILD_DETAIL,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/childData`;
      const body = {
        child_id: getState()?.userState?.user?.activeChildDetail?.id,
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            dispatch(setChildDetail(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response.response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));
          setContext("Child Login Error", error);
          // failure

          done();
        }
      );
    } catch (error) {
      dispatch(setLoading(false));
      setContext("Child Login Error", error);
      done();
    }
  },
});

export const loginLogic = createLogic({
  type: SIGN_IN,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      let childName =
        getState().userState?.user?.activeChildDetail?.name +
        " " +
        getState().userState?.user?.activeChildDetail?.last_name;
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/childLoginOTP`;
      // console.log("Request Payload=> ",action.payload);
      // const info= await logDeviceInfo();
      const body = {
        email: action.payload.email,
        phone: action.payload.phone,
        // verify: action.payload.verify,
        child: childName ? childName : "",
        parent: getState().userState?.user?.activeChildDetail?.parent_name,
        platform: Constants.env.platform,
        web: Constants.env.web,
        hashString: action.payload.hash,
        // token: getState().global.token,
        // aws: 1,
        app_version: Constants.env.version,
        // device_Modal: info.model,
        // device_os: info.operatingSystem,
      };
      console.log("[Login] Calling API: /parentapp/childLoginOTP, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Login] Received Response: ",response);
          if (response.status === "ok") {
            // console.log("logic", action.payload.webToken);
            // console.log("Response=> ",response.response)
            dispatch(fetchOtpSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response.response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            if (action.payload.onFailure) {
              action.payload.onFailure(response.error);
            }
            done();
          }
        },
        (error) => {
          console.log("[Login] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Login OTP Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Login] Error: ",error);
      dispatch(setLoading(false));
      setContext("Login OTP Error", error);
      done();
    }
  },
});

export const verifyOTP = createLogic({
  type: VERIFY_OTP,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = action.payload.reload
        ? `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/childLoginReloadDetails`
        : `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/childLoginViaOTPDetails`;
        // }/parentapp/validateResetOTP`;
      const info = await logDeviceInfo();
      const body = {
        otp: action.payload.otp,
        temp_otp: getState().userState.otpDetails.OTP,
        // temp_email: getState().userState.otpDetails.email,
        temp_time: getState().userState.otpDetails.time,
        phone: getState().userState.phone,
        email: getState().userState.email,
        platform: Constants.env.platform,
        web: Constants.env.web,
        token: getState().global.token,
        verify: action.payload.verify,
        aws: 1,
        app_version: Constants.env.version,
        device_Modal: `${info?.manufacturer} ${info?.model}`,
        device_os: `${info?.operatingSystem} ${info?.osVersion}`,
      };
      console.log("[Verify OTP] Calling API: ",url,", payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Verify OTP] Received Response: ",response);
          if (response.status === "ok") {
            // dispatch(saveOTP(response.response));
            // console.log(!action.payload.verify);
            if(!action.payload.verify){
             dispatch(fetchUserSuccess(response.response));
            }
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response.response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            // console.log("Payload => ",action.payload)
            if (action.payload && action.payload.onError) {
              const message = response?.error || response?.message;
              if (message === "logout" || message === "Invalid Username.") {
                action.payload.onError();
              } else {
                dispatch(setError(response.error, response.error));
              }
            } else {
              dispatch(setError(response.error, response.error));
            }
            done();
          }
        },
        (error) => {
          console.log("[Verify OTP] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Login Verify OTP Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Verify OTP] Error: ",error);
      dispatch(setLoading(false));
      setContext("Login Verify OTP Error", error);
      done();
    }
  },
});

export const forgotPassword = createLogic({
  type: FORGOT_PASSWORD,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/validateParentEmail`;
      const body = {
        email: action.payload.email,
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        errorReportType: "Alert",
        successReportType: "Info",
        successMessage: "OTP Sent Sucessfully",
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            // alert("OTP Sent  Successfully");
            dispatch(saveOTP(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            if (action.payload.onFailure) {
              action.payload.onFailure(response.error);
            }
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      dispatch(setLoading(false));
      done();
    }
  },
});

export default [
  loginLogic,
  fetchChildDetail,
  forgotPassword,
  verifyOTP,
  freeBeeLogin,
];
