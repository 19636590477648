export const EXTEND_DAYCARE = "EXTEND_DAYCARE";
export const CANCEL_EXTENDED_DAYCARE = "CANCEL_EXTEND_DAYCARE";
export const extendDaycare = (selectedDate, selectedType, onSuccess) => {
    // console.log("Params=>", selectedType);
    return ({
    type: EXTEND_DAYCARE,
    payload: { selectedDate, selectedType, onSuccess },
})};
export const cancelExtendDaycare = request => ({
    type: CANCEL_EXTENDED_DAYCARE,
    payload: request,
});
