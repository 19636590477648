const Theme = {
    colorProfile: '#5b9bd1',
    opaceColorPrimary: 'rgba(91, 155, 209, 0.9)',
    colorPrimary: '#19ade4',
    colorPrimaryDark: '#127ea5',
    colorSecondry: '#5D00DF',
    white: '#ffffff',
    light: '#f5f5f5',
    mediumLight: '#9E9E9E',
    whiteLight: '#F5F5F5',
    dark: '#000000',
    danger: '#F44336',
    success: '#4CAF50',
    disableColor: '#e0e0e0',
    primaryDark: '#01579B',
    orange: '#FF5722',
    brown: '#de7243',
    green: '#4CAF50',
    cyan: '#00BCD4',
    purple: '#B39DDB',
    teal: '#009688',
    pink: '#E91E63',
    indigo: '#3f51b5',
    blueGray: '#607D8B',
    warning: '#fcf8e3',
    heading: '#757575',
    defaultTextColor: '#424242',
    
  };
  export default Theme;
  