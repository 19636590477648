import React from "react";
import { connect } from 'react-redux';

import { initializeFirebase } from "../pushNotification";
import { saveToken, showModal, setNotification, showBell } from "./redux/action";
import { fetchChat } from "../features/Conversation/redux/action";

import { isMobile } from 'react-device-detect';

// import DefaultConstants from "../defaultConstants";

import { PushNotifications } from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import DefaultConstants from "../defaultConstants";

const showToast = async (msg) => {
    await Toast.show({
        text: msg
    })
}

//Broswer agents
// let userAgentString = navigator.userAgent;
// const isChrome =
//     !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
// const isFirefox = typeof InstallTrigger !== "undefined";
// Detect Safari
// let safariAgent = userAgentString.indexOf("Safari") > -1;

class NotificationService extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    saveToken = (token) => {
        this.timeout = setTimeout( async () => {
            await this.props.saveToken(token);
        }, 2000);
    };

    getDeliveredNotifications = async () => {
        // const notificationList =
        //     await PushNotifications.getDeliveredNotifications();
        // console.log("delivered notifications", notificationList);
    };

    // registerMobileNotifications() {
    //     PushNotifications.checkPermissions().then((res) => {
    //         console.log("Check permissions: ",res);
    //         if (res.receive !== 'granted') {
    //             // PushNotifications.requestPermissions().then((res) => {
    //             //     if (res.receive === 'denied') {
    //                     showToast('Push Notification permission denied');
    //             //     }
    //             //     else {
    //             //         showToast('Push Notification permission granted');
    //                     this.register();
    //             //     }
    //             // });
    //         }
    //         else {
    //             this.register();
    //         }
    //     })
    // }
    registerMobileNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();
        // console.log("Check permissions: ", permStatus);

        // if (permStatus.receive === "prompt") {
        //     permStatus = await PushNotifications.requestPermissions();
        //     if (permStatus.receive === 'denied') {
        //         showToast('Push Notification permission denied');
        //     } else {
        //         showToast('Push Notification permission granted');
        //         this.register();
        //     }
        // }

        if (permStatus.receive !== "granted") {
             permStatus = await PushNotifications.requestPermissions();
             if (permStatus.receive === "denied") {
               showToast("Push Notification permission denied");
             } else {
               showToast("Push Notification permission granted");
               this.register();
             }

        }else {
        this.register();
        }
    };

    register = async () => {
        // console.log("Initializing HomePage");

        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener("registration", (token) => {
            // console.log("token", token.value);
            this.saveToken(token.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error) => {
            alert("Error on registration: " + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
                // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
                // console.log("pushNotificationReceived", notification);
                this.props.showBell(true);
                this.props.setNotification(notification.title, notification.body);
                this.props.fetchChat(0);
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification) => {
                // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
                window.location = "/notification";
            }
        );
    };

    componentDidMount() {
        if (isMobile) {
            this.registerMobileNotifications();
        } else if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission !== "granted") {
            this.props.showModal(
                "Success",
                this.props.constants.pushPermissionMessage || DefaultConstants.pushPermissionMessage,
                false,
                () => {
                    initializeFirebase(
                        (token) => {
                            this.saveToken(token);
                        },
                        () => { },
                        (message) => this.props.showModal("Error", message, false, () => { })
                    );
                }
            );
        } else if (Notification.permission === "granted") {
            initializeFirebase(
                (token) => {
                    this.saveToken(token);
                },
                (notification) => {
                    this.props.showBell(true);
                    this.props.setNotification(notification.title, notification.body);
                    this.props.fetchChat(0);
                },
                (message) => this.props.showModal("Error", message, false, () => { })
            );
        }
        // if (isFirefox) {
        //console.log("yes its firefox");
        //console.log("notification permission", Notification.permission)
        // if (Notification.permission !== 'granted') {
        //     this.props.showModal('Success', DefaultConstants.pushPermissionMessage, false, () => {
        //         initializeFirebase((token) => {
        //             this.saveToken(token);
        //         });
        //     })
        // }
        // if (Notification.permission === 'granted') {
        //     initializeFirebase((token) => {
        //         this.saveToken(token);
        //     },
        //         (notification) => {
        //             this.props.showBell(true);
        //             this.props.setNotification(notification.title, notification.body);
        //             this.props.fetchChat(0);
        //         }
        //     );
        // }
        // }
        // else if (isChrome) {
        //     initializeFirebase((token) => {
        //         this.saveToken(token);
        //     },
        //         (notification) => {
        //             this.props.showBell(true);
        //             this.props.setNotification(notification.title, notification.body);
        //             this.props.fetchChat(0);
        //         }
        //     );
        // }
        // else if (safariAgent) {
        //     var notify = function () {
        //         // Check for notification compatibility.
        //         if (!"Notification" in window) {
        //             // If the browser version is unsupported, remain silent.
        //             return;
        //         }
        //         // Log current permission level
        //         console.log(Notification.permission);

        //         if (Notification.permission === "default") {
        //             Notification.requestPermission(function () {
        //                 notify();
        //             });
        //         }
        //         // If the user has granted permission for this domain to send notifications...
        //         else if (Notification.permission === "granted") {
        //             var n = new Notification("New message from ParentConnect web", {
        //                 body: "Hi your child reached",
        //                 tag: "Local notification ",
        //             });
        //             n.onclick = function () { };
        //             n.onclose = function () { };
        //         } else if (Notification.permission === "denied") {
        //             return;
        //         }
        //     };
        //     notify();
        // }
        // else {
        // }
    }
    componentWillUnmount() { 
        clearTimeout(this.timeout);
    }

    render() {
        return <div></div>;
    }
}


const mapStateToProps = (state) => ({
    constants: state.constants
});
const mapDispatchToProps = (dispatch) => ({
    saveToken: (token) => dispatch(saveToken(token)),
    showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
        dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
    setNotification: (title, subtitle, onClick) => dispatch(setNotification(title, subtitle, onClick)),
    showBell: (show) => dispatch(showBell(show)),
    fetchChat: startIndex => {
        dispatch(fetchChat(startIndex));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationService);
