import React from "react";
// import { connect } from 'react-redux';
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';

import logo from '../../assets/Footprints-Logo.svg';
import Loading from "../../global/utils/Loading";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen : false
    };
  }

  render() {
    return (
      <div>
      <Navbar color="white" light expand="md" className="shadow mb-5 fixed-top">
        <NavbarBrand href="/" className="m-auto py-2">
           <img src={logo} alt="Logo" />
        </NavbarBrand>
        {/* <Loading /> */}
      </Navbar>
    </div>
    );
  }
}

export default Header;
