import React from "react";
import { connect } from "react-redux";

import moment from "moment";
//import defaultImg from "../../assets/left-nico.png";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { Card, CardTitle, CardBody } from "reactstrap";
import {
  updateUserPhone,
  updateUserEmail,
  addAdmissionDetail,
  doorAccessPin,
} from "./redux/action";
import classnames from "classnames";
// reactstrap components
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

// reactstrap components
import { CardHeader, Row, Col } from "reactstrap";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import FootprintInputText from "../../global/utils/FootprintProfileText";
import { InnerHOC } from "../../global/HOComponet/innerPage";
//import FootprintCropper from "../../global/utils/FootprintCropper";
import EditComponent from "./component/EditComponent";
import { setLoading, showCropModal, showModal } from "../../global/redux/action";
import { showPasswordOverlay } from "../../global/redux/action";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { generateAuthToken } from "../../global/utils/fetch";
import { logout, verifyOTP } from "../Login/redux/action";
import { App } from "@capacitor/app";
import password_minimalistic from "../../assets/password_minimalistic.svg";
export const getFullPath = (constants, path) => {
  // console.log("inside = ", path);
  if (typeof path === "string" && path.startsWith("https")) {
    // console.log("path https = ", path);
    return path;
  } else if (typeof path === "string" && path.length === 0) {
    // console.log("inside path.length  ", path);
    return path;
  } else {
    //console.log("constants.ImagePath + path ", constants.ImagePath + path);
    return constants.ImagePath + path;
  }
};
class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      editiable: false,
      disabled: false,
      show: false,
      error: "",
      loader: false,
      errorPhone: "",
    };
  }
  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  editText = () => {
    this.setState({ editiable: !this.state.editiable }, () => {});
  };
  loader = () => {
    this.setState({ loader: true });
  };
  // ^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2})+$

  validate = (value) => {
    if (this.props.parentEmail === value) {
      this.setState({ error: "Same Email Address" });
      return false;
    }
    if (!value.match(/^[\w-]+@[-]+\w+$/)) {
      // alert("Invalid format");
      this.setState({ error: "Invalid Email Format" });
      return false;
    }
    return true;
  };

  fetchData() {
    localStorage.setItem("auth_token", generateAuthToken());
      this.props.verifyOTP(
        "999999",
        false,
        true,
        (response) => {
          localStorage.setItem("auth_token", response.auth_token);
          localStorage.setItem("last_reload", response.time);
          // console.log("Response => ", response);
          if (response && response.survey && response.survey.survey_id > 0) {
            localStorage.setItem("survey_status", response.survey.survey_id);
            // console.log("Redirecting to survey ");
            window.location.href = `survey/${response.survey.survey_id}`;
          }
          if (response && response.eventStatus > 0) {
            this.props.showModal('Ok', 'A new event has been scheduled,  Click ok to view event details.', true, () => { this.props.history.replace('/outdoorActivity') }, ()=>{})
          }
        },
        async () => {
          this.props.loading(true);
          await this.props.logout();
          await App.removeAllListeners();
          localStorage.setItem("auth_token", "");
          this.props.history.replace("/");
        }
      );
  }

  render() {
    console.log("[My Profile] Displaying My Profile...");
    //const { show } = this.state;
    const image = {
      uri: getFullPath(this.props.constants, this.props.parentPhoto),
      status: this.props.parentApprovedStatus,
    };
    //console.log("image", image);
    return (
      <InnerHOC
        heading={
          <div className="d-flex justify-content-center align-items-center">
            <div>My Profile</div>

            <div className="ml-auto">
              <Link
                to="#"
                onClick={() => {
                  this.fetchData();
                }}
              >
                <i
                  className="icon-refresh fa-spin-custom"
                  style={{ color: "#fff", fontSize: "1.2em" }}
                ></i>
              </Link>
            </div>
          </div>
        }
      >
        <Row>
          <Col
            xs="12"
            md="12"
            sm="12"
            lg="12"
            xl="5"
            className="order-lg-2 my-3"
          >
            <Card className="border-0 shadow">
              <CardHeader
                style={{
                  background: "#21C1E0",
                  fontFamily: "Baloo",
                  color: "#fff",
                }}
              >
                Child Information
              </CardHeader>
              <CardBody>
                {this.props.profilePicPresent === true &&
                  this.props.childPhotoApprovedStatus !== "Inactive" && (
                    <div style={{ position: "relative" }}>
                      <div
                        className="profile-img mx-auto d-block rounded-circle mb-3"
                        style={{
                          backgroundImage:
                            "url" +
                            "(" +
                            getFullPath(
                              this.props.constants,
                              this.props.childPhoto
                            ) +
                            // this.props.constants.ImagePath +
                            // this.props.childPhoto +
                            ")",
                          backgroundColor: "#e8e5e5",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <EditComponent
                        id="child-image"
                        onImagePicked={(event) => {
                          // fire action to open cropper and send the event
                          const relation = "Self";
                          this.props.showCropModal(
                            event.target.files[0],
                            (response) => {
                              this.props.showPasswordOverlay(
                                true,
                                (isMatched) => {
                                  if (isMatched) {
                                    this.props.addAdmissionDetail(
                                      response,
                                      relation
                                    );
                                  }
                                }
                              );
                            }
                          );
                        }}
                      />
                      {
                        <p
                          className=" text-center"
                          style={{
                            color: "red",
                            fontSize: 12,
                          }}
                        >
                          {this.props.childPhotoApprovedStatus === "Active"
                            ? ""
                            : this.props.childPhotoApprovedStatus}
                        </p>
                      }
                    </div>
                  )}
                {(!this.props.profilePicPresent ||
                  this.props.childPhotoApprovedStatus === "Inactive") && (
                  <div style={{ position: "relative" }}>
                    <div
                      className="profile-img mx-auto d-block rounded-circle mb-3"
                      style={{
                        backgroundImage:
                          "url(" + require("../../assets/left-nico.png") + ")",
                        backgroundSize: "cover",
                        backgroundColor: "#e8e5e5",

                        backgroundPosition: "center",
                      }}
                    ></div>
                    <EditComponent
                      id="nico-image"
                      onImagePicked={(event) => {
                        // fire action to open cropper and send the event
                        const relation = "Self";
                        this.props.showCropModal(
                          event.target.files[0],
                          (response) => {
                            this.props.showPasswordOverlay(
                              true,
                              (isMatched) => {
                                if (isMatched) {
                                  this.props.addAdmissionDetail(
                                    response,
                                    relation
                                  );
                                }
                              }
                            );
                          }
                        );
                      }}
                    />
                  </div>
                )}

                <CardTitle
                  className="mb-1 text-center"
                  style={{ fontFamily: "Baloo", fontSize: "25px" }}
                >
                  {this.props.childName}
                </CardTitle>
                <p className="mb-4 text-center">{this.props.centerName}</p>
                <Row className="pt-4 child-info-view">
                  <Col xs="6" className="border-right border-bottom">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-left my-3 child-card mb-3">
                      <div>
                        <i
                          className="icon-class"
                          style={{ fontSize: "2.5em", color: "#21C1E0" }}
                        ></i>
                      </div>
                      <div>
                        <p
                          className="mb-1 font-weight-bold"
                          style={{ fontFamily: "Baloo", color: "#525e7f" }}
                        >
                          Class
                        </p>
                        <p>{this.props.class}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" className="border-bottom">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-left my-3 child-card mb-3">
                      <div>
                        <i
                          className="icon-admission"
                          style={{ fontSize: "2.5em", color: "#21C1E0" }}
                        ></i>
                      </div>
                      <div>
                        <p
                          className="mb-1 font-weight-bold"
                          style={{ fontFamily: "Baloo", color: "#525e7f" }}
                        >
                          {"Admission Id"}
                        </p>
                        <p> {this.props.admissionID}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" className="border-right">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-left my-3 child-card my-3">
                      <div>
                        <i
                          className="icon-birthday"
                          style={{ fontSize: "2.5em", color: "#21C1E0" }}
                        ></i>
                      </div>
                      <div>
                        <p
                          className="mb-1 font-weight-bold"
                          style={{ fontFamily: "Baloo", color: "#525e7f" }}
                        >
                          {"Date Of Birth"}
                        </p>
                        <p>{moment(this.props.dob).format(DEFAULT_FORMAT)}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-left my-3 child-card my-3">
                      <div>
                        <i
                          className="icon-programs"
                          style={{ fontSize: "2.5em", color: "#21C1E0" }}
                        ></i>
                      </div>
                      <div>
                        <p
                          className="mb-1 font-weight-bold"
                          style={{ fontFamily: "Baloo", color: "#525e7f" }}
                        >
                          {" Program"}
                        </p>
                        <p> {this.props.program}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col
            xs="12"
            md="12"
            sm="12"
            lg="12"
            xl="7"
            className="order-lg-1 my-3"
          >
            <Card className="border-0 shadow">
              <CardHeader
                style={{
                  background: "#21C1E0",
                  fontFamily: "Baloo",
                  color: "#fff",
                }}
              >
                Parent Information
              </CardHeader>
              <CardBody>
                <p className="mb-3">{this.props.parentType} Information</p>
                <Row>
                  <Col sm="12" md="12" lg="12" xl="5">
                    {this.props.parentProfilePresent === true &&
                      this.props.parentApprovedStatus !== "Inactive" && (
                        <div style={{ position: "relative" }}>
                          <div
                            //className="p-2 shadow border-0 mb-3"
                            className="profile-img mx-auto d-block rounded-circle mb-3"
                            style={{
                              backgroundImage: "url" + "(" + image.uri + ")",
                              backgroundSize: "cover",
                              backgroundColor: "#e8e5e5",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          <EditComponent
                            id={this.props.relation}
                            onImagePicked={(event) => {
                              const relation = this.props.relation;
                              // fire action to open cropper and send the event
                              this.props.showCropModal(
                                event.target.files[0],
                                (response) => {
                                  this.props.showPasswordOverlay(
                                    true,
                                    (isMatched) => {
                                      if (isMatched) {
                                        this.props.addAdmissionDetail(
                                          response,
                                          relation
                                        );
                                      }
                                    }
                                  );
                                }
                              );
                            }}
                          />
                          {
                            <p
                              className=" text-center"
                              style={{
                                color: "red",
                                fontSize: 11,
                              }}
                            >
                              {image.status === "Active" ? "" : image.status}
                            </p>
                          }
                        </div>
                      )}

                    {(!this.props.parentProfilePresent ||
                      this.props.parentApprovedStatus === "Inactive") && (
                      <div style={{ position: "relative" }}>
                        <div
                          className="profile-img mx-auto d-block rounded-circle mb-3"
                          style={{
                            backgroundImage:
                              "url(" +
                              require("../../assets/demoImg.png") +
                              ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "180px",
                          }}
                        ></div>

                        <EditComponent
                          id={this.props.relation}
                          onImagePicked={(event) => {
                            const relation = this.props.relation;
                            // fire action to open cropper and send the event
                            this.props.showCropModal(
                              event.target.files[0],
                              (response) => {
                                this.props.showPasswordOverlay(
                                  true,
                                  (isMatched) => {
                                    if (isMatched) {
                                      this.props.addAdmissionDetail(
                                        response,
                                        relation
                                      );
                                    }
                                  }
                                );
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col sm="12" md="12" lg="12" xl="7">
                    <Form>
                      <FormGroup>
                        <InputGroup
                          className={classnames("input-group-merge", {
                            focused:
                              this.state.merged && this.state.merged.username,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              style={{
                                padding: 0,
                                paddingLeft: "6px",
                                paddingRight: "6px",
                              }}
                            >
                              <i
                                className="icon-user"
                                style={{ fontSize: "28px" }}
                              ></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            name="text"
                            value={this.props.parentName}
                            readOnly
                            style={{ paddingLeft: "5px", fontSize: "18px" }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <FootprintInputText
                          icon="icon-email"
                          type="text"
                          defaultValue={this.props.parentEmail}
                          onChange={(value, onSuccess) => {
                            if (this.validate(value)) {
                              this.setState({ error: "" });

                              this.props.showPasswordOverlay(
                                true,
                                (isMatched) => {
                                  if (isMatched) {
                                    this.props.updateUserEmail(value, () => {
                                      this.setState({ error: "" });
                                      onSuccess();
                                    });
                                  }
                                }
                              );
                            }
                          }}
                        />
                      </FormGroup>
                      {typeof this.state.error !== undefined && (
                        <p style={{ color: "red" }}>{this.state.error}</p>
                      )}
                      <FormGroup>
                        <FootprintInputText
                          icon="icon-phone"
                          type="number"
                          maxLength="10"
                          onChange={(value, onSuccess) => {
                            if (this.props?.parentPhone === value) {
                              this.setState({
                                errorPhone: "Same Phone Number",
                              });
                            } else if (value.length !== 10) {
                              this.setState({
                                errorPhone:
                                  "Phone Number should contain 10 numbers",
                              });
                            } else {
                              this.props.showPasswordOverlay(
                                true,
                                (isMatched) => {
                                  if (isMatched) {
                                    this.props.updateUserPhone(value, () => {
                                      onSuccess();
                                    });
                                  }
                                }
                              );
                            }
                          }}
                          defaultValue={this.props.parentPhone}
                        />
                      </FormGroup>
                      {typeof this.state.error !== undefined && (
                        <p style={{ color: "red" }}>{this.state.errorPhone}</p>
                      )}
                      {this.props.doorAccessPin ? (
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused:
                                this.state.merged && this.state.merged.username,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText
                                style={{
                                  padding: 0,
                                  paddingLeft: "11.5px",
                                  paddingRight: "11.5px",
                                }}
                              >
                                <img src={password_minimalistic} width="28px" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Input
                              style={{
                                paddingLeft: 5,
                                paddingRight: 5,
                                fontSize: "18px",
                              }}
                              disabled
                              type="text"
                              value={this.props.doorAccessPin}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText className="pl-2 pr-2 p-0 m-0">
                                <Button
                                  className="p-1"
                                  type="button"
                                  onClick={() => this.props.resetDoorPin()}
                                >
                                  Reset pin
                                </Button>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <CardTitle className="d-flex justify-content-center">
                            <Button
                              style={{
                                fontFamily: "baloo",
                                // fontSize: "1rem",
                                fontWeight: "400",
                                color: "#19ade4",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              onClick={() => this.props.resetDoorPin()}
                            >
                              Click here to generate door access pin
                            </Button>
                          </CardTitle>
                        </FormGroup>
                      )}
                    </Form>
                  </Col>
                </Row>
                {/* <p class="mb-3">Mother Information</p>
              <Row>
                <Col md="4">
                  <Card
                    body
                    className="p-2 shadow border-0 mb-3"
                    style={{
                      backgroundImage:
                        "url(" + require("../../assets/mother-img.jpg") + ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "180px"
                    }}
                  ></Card>
                </Col>
                <Col md="8">
                  <Form>
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused:
                            this.state.merged && this.state.merged.username
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="pl-2"
                          placeholder="Mother Name"
                          type="text"
                          value="Ritu Sharma"
                          onFocus={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: true }
                            })
                          }
                          onBlur={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: false }
                            })
                          }
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
 
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused:
                            this.state.merged && this.state.merged.username
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                       <Input
                          className="pl-2"
                          placeholder="Email ID"
                          value="ritu_sharma0987@gmail.com"
                          type="email"
                          onFocus={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: true }
                            })
                          }
                          onBlur={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: false }
                            })
                          }
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused:
                            this.state.merged && this.state.merged.username
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="pl-2"
                          placeholder="Phone Number"
                          type="text"
                          value="+91-987654321072"
                          onFocus={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: true }
                            })
                          }
                          onBlur={e =>
                            this.setState({
                              merged: { ...this.state.merged, username: false }
                            })
                          }
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </Col>
              </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </InnerHOC>
    );
  }
}
//const mapStateToProps = state => ({});

const mapStateToProps = (state) => {
  const activeChildDetail =
    state.userState.user && state.userState.user.activeChildDetail;
  const parentProfile = state.userState && state.userState.parentProfile;
  const photo =
    state.userState.user &&
    state.userState.user.activeChildDetail &&
    activeChildDetail.child_photo;
  const parentPhoto =
    state.userState &&
    state.userState.parentProfile &&
    parentProfile.parentPhoto;
  const parentEmail = state.userState.phone === activeChildDetail.father_phone ? activeChildDetail.father_email : activeChildDetail.mother_email;
  return {
    childName:
      activeChildDetail &&
      activeChildDetail.name + " " + activeChildDetail.last_name,
    centerName:
      activeChildDetail && activeChildDetail["admission_center_name.name"],
    dob: activeChildDetail && activeChildDetail.date_of_birth,
    class: activeChildDetail && activeChildDetail["room.name"],
    admissionID: activeChildDetail && activeChildDetail.id,
    program: activeChildDetail && activeChildDetail.length,
    profilePicPresent: typeof photo === "string" && photo !== "",
    parentProfilePresent: typeof parentPhoto === "string" && parentPhoto !== "",
    parentType: parentProfile && parentProfile.parentType,
    childPhoto: activeChildDetail && activeChildDetail.child_photo,
    parentPhoto: parentProfile && parentProfile.parentPhoto,
    parentName: parentProfile && parentProfile.parentName,
    parentPhone: parentProfile && parentProfile.parentPhone,
    parentEmail: parentEmail,
    fatherEmail: parentProfile && parentProfile.fatherEmail,
    motherEmail: parentProfile && parentProfile.motherEmail,
    doorAccessPin: state?.userState?.password || undefined,
    constants: state.constants,
    childPhotoApprovedStatus:
      activeChildDetail && activeChildDetail.child_approved_status,
    parentApprovedStatus: parentProfile && parentProfile.parentApprovedStatus,
    relation: state.userState.parentProfile.parentType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserPhone: (newPhone, onSuccess) =>
    dispatch(updateUserPhone(newPhone, onSuccess)),
  updateUserEmail: (newEmail, onSuccess) =>
    dispatch(updateUserEmail(newEmail, onSuccess)),
  showCropModal: (event, onSuccess) =>
    dispatch(showCropModal(event, onSuccess)),
  addAdmissionDetail: (base64, relation) =>
    dispatch(addAdmissionDetail(base64, relation)),
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  verifyOTP: (otp, verify, reload, onSuccess, onError) =>
    dispatch(verifyOTP(otp, verify, reload, onSuccess, onError)),
  logout: () => dispatch(logout()),
  loading: (load) => dispatch(setLoading(load)),
  resetDoorPin: () => dispatch(doorAccessPin()),
  showModal: (type, msg, isConfirm, onSuccess, onCancel) => dispatch(showModal(type, msg, isConfirm, onSuccess, onCancel)),

});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
