export const LOADING = "LOADING";

export const SET_ERROR = "SET_ERROR";
export const FETCH_CONFIG = "FETCH_CONFIG_OFFLINE";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const SET_INFO = "SET_INFO";
export const FETCH_CENTER_LIST = "FETCH_CENTER_LIST";
export const CENTER_LIST_SUCCESS = "CENTER_LIST_SUCCESS";
export const SHOW_PASSWORD_OVERLAY = "SHOW_PASSWORD_OVERLAY";
export const SET_NAVIGATION_PARAMS = "SET_NAVIGATION_PARAMS";
export const FREE_BEE_LOGIN = "FREE_BEE_LOGIN";
export const SHOW_BELL = "SHOW_BELL";
export const CHANGE_LOGGEDIN_CHILD = "CHANGE_LOGGEDIN_CHILD";
export const SUBMIT_TOKEN = "SUBMIT_TOKEN";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_CROP_IMAGE = "SHOW_CROP_IMAGE";
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_TOKEN_SUCCESS = 'SAVE_TOKEN_SUCCESS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_COMPONENT_LOADING = "FETCH_COMPONENT_ERROR_";
export const LOGOUT_RESET_REDUX = "LOGOUT_RESET_REDUX";


export const showBell = (show) => ({
  type: SHOW_BELL,
  payload: show
})

export const onErrLoading = (component, value) => ({
  type: SET_COMPONENT_LOADING + component,
  value
});

export const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: token
})
export const saveTokenSuccess = (token) => ({
  type: SAVE_TOKEN_SUCCESS,
  payload: token
})
export const setLoading = showLoading => ({
  type: LOADING,
  payload: {
    showLoading
  }
});

export const fetchConfig = onSuccess => ({
  type: FETCH_CONFIG,
  payload: {
    onSuccess
  },
  meta: {
    retry: true
  }
});

export const fetchConfigSuccess = config => ({
  type: FETCH_CONFIG_SUCCESS,
  payload: config
});

export const resetReduxOnAppUpdate = () => ({
  type: LOGOUT_RESET_REDUX,
});

export const setError = (title, subtitle) => ({
  type: SET_ERROR,
  payload: { title, subtitle }
});

export const setInfo = (title, subtitle) => ({
  type: SET_INFO,
  payload: {
    title,
    subtitle
  }
});

export const setNotification = (title, subtitle, onClick) => ({
  type: SET_NOTIFICATION,
  payload: {
    title,
    subtitle,
    onClick
  }
});

export const showModal = (
  type,
  message,
  isConfirm = false,
  onOkPressed,
  onCancelPressed
) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    message,
    isConfirm,
    onOkPressed,
    onCancelPressed
  }
});

export const getCenterList = payload => ({
  type: FETCH_CENTER_LIST,
  payload
});

export const fetchCenterSuccess = (allCenters, userCenterID, programList) => ({
  type: CENTER_LIST_SUCCESS,
  payload: { allCenters, userCenterID, programList }
});

export const showPasswordOverlay = (show, onSuccess) => ({
  type: SHOW_PASSWORD_OVERLAY,
  payload: { show, onSuccess }
});

export const showCropModal = (event, onSuccess) => ({
  type: SHOW_CROP_IMAGE,
  payload: {
    event,
    onSuccess
  }
});

export const setNavigationParams = (params = {}) => ({
  type: SET_NAVIGATION_PARAMS,
  payload: params
});
export const freeBeeLogin = onSuccess => ({
  type: FREE_BEE_LOGIN,
  payload: { onSuccess }
});

export const submitToken = token => ({
  type: SUBMIT_TOKEN,
  payload: token
});
