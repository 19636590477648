import React from "react";
import { connect } from "react-redux";
import { Navbar, NavbarBrand } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "../../assets/Footprints-Logo.svg";
import FootprintButton from "../../global/utils/FootprintInputButton";
import FootprintInputText from "../../global/utils/FootprintInputText";
import { signIn } from "./redux/action";

import { FormGroup, Label } from "reactstrap";
import { forgotPassword, verifyOTP } from "./redux/action";
import { showModal } from "../../global/redux/action";

import Theme from "../../theme";
import ChangeLoginChildOverlay from "../../global/ChangeLoginChildOverlay";
class OtpVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
      email: props?.location?.state?.email,
      error: undefined,
      showOverlay: false,
    };
  }
  validate = () => {
    if (!this.state.OTP) {
      this.setState({ error: "Please Enter OTP" });
      return false;
    } else {
      return true;
    }
  };

  isMultipleChild = () => {
    // console.log(
    //   "Multiple child : ",
    //   Array.isArray(this.props?.user.childList) &&
    //     this.props?.user.childList.length > 1
    // );
    return (
      Array.isArray(this.props.user.childList) &&
      this.props?.user.childList.length > 1
    );
  };

  showChildChangeOption = () => {
    this.setState({ showOverlay: true }, () => {});
  };

  render() {
    // console.log("\nEmail => ", this.state?.email);
    return (
      <div
        className="bg-preview"
        style={{
          backgroundImage:
            "url(" + require("../../assets/bg-pattern.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <Navbar
          color="white"
          light
          expand="md"
          className="shadow mb-5 fixed-top"
        >
          <NavbarBrand href="/" className="m-auto py-2">
            <img src={logo} alt="Logo" />
          </NavbarBrand>
        </Navbar>
        <ChangeLoginChildOverlay
          isOpen={this.state.showOverlay}
          toggle={(isOpen) => {
            this.setState({
              showOverLay: isOpen,
            });
          }}
          history={this.props.history}
        />
        <Modal
          className="modal-dialog-centered modal-lg"
          isOpen={true}
          backdrop={false}
        >
          <ModalHeader
            style={{
              background: Theme.colorSecondry,
              fontFamily: "baloo",
              fontSize: "1.3em",
              color: "#fff",
            }}
          >
            <span className="text-white">Verify OTP</span>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label
                style={{
                  fontFamily: "baloo",
                }}
              >
                One Time Password(OTP) has been sent to your registered mobile
              </Label>
              <FootprintInputText
                value={this.state.OTP}
                onChange={(text) => {
                  this.setState({ OTP: text, error: "" });
                  // this.props.setError();
                }}
                placeholder="Enter OTP"
              />
            </FormGroup>
            {typeof this.state.error !== "undefined" && (
              <p style={{ color: "red" }}>{this.state.error}</p>
            )}
            <FormGroup>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontFamily: "baloo",
                }}
              >
                {" OTP valid for 30 minutes only"}
              </p>
            </FormGroup>

            <FootprintButton
              onClick={() => {
                if (this.validate()) {
                  this.props.verifyOTP(this.state.OTP,false, (response) => {
                    if (this.isMultipleChild()) {
                      this.showChildChangeOption();
                    } else this.props.history.replace("/");
                    // this.props.showModal(
                    //   "Success",
                    //   response.message || "Login Success",
                    //   //  "New password has been sent to your e-mail addres",
                    //   true,
                    //   () => {
                        
                    //   },
                    //   () => {}
                    // );
                  });
                }
              }}
              text="Verify"
            ></FootprintButton>
            <FootprintButton
              onClick={() => {
                //this.props.forgotPassword(this.state.email);
                this.props.login(this.props?.phone);
              }}
              text="Resend OTP"
            ></FootprintButton>

            <FootprintButton
              onClick={() => {
                this.props.history.replace("/");
              }}
              text="Cancel"
            ></FootprintButton>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  phone: state.userState.phone,
  user: state.userState.user,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOTP: (otp,verify, onSuccess) => dispatch(verifyOTP(otp,verify, onSuccess)),
  forgotPassword: (OTP) => dispatch(forgotPassword(OTP)),
  login: (phone) =>
    dispatch(signIn(phone)),
  showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
    dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerify);
//export default ForgetPassword;
