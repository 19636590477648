import {
  FETCH_USER_SUCCESS,
  SIGN_IN,
  LOGOUT,
  SAVE_OTP,
  CHANGE_LOGGEDIN_CHILD,
  UPDATE_CHILD_PHOTO,
  UPDATE_PARENT_PROFILE,
  FETCH_OTP_SUCCESS,
  SET_CHILD_DETAIL
} from "./action";
import { SUBMIT_TOKEN } from "../../../global/redux/action";

import moment from "moment";
import {
  UPDATE_USER_PHONE_SUCCESS,
  UPDATE_USER_EMAIL_SUCCESS,
  RESET_DOOR_PIN_SUCCESS,
} from "../../MyProfile/redux/action";

export const initialState = {
  user: {},
  email: "",
  password: "",
  phone:''
};
const addTimeStamp = (url) => {
  if (typeof url === "string" && url.length > 0 && url.indexOf("?") === -1) {
    return url + "?" + moment().unix();
  }
  return url;
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        email: action.payload.email.toLowerCase(),
        password: state.password,
        phone: action.payload.phone,
      };
    }

    case UPDATE_USER_PHONE_SUCCESS: {
      try {
        const phoneKey =
          state.parentProfile.parentType === "Mother"
            ? "motherPhone"
            : "fatherPhone";

        return {
          ...state,
          parentProfile: {
            ...state.parentProfile,
            parentPhone: action.payload.phone,
            [phoneKey]: action.payload.phone,
          },
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    }

    case UPDATE_USER_EMAIL_SUCCESS: {
      try {
        return {
          ...state,
          email: action.payload.email,
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    }

    case FETCH_OTP_SUCCESS: {
      // console.log("Save OTP", action.payload);
      try {
        return {
          ...state,
          otpDetails: action.payload,
        };
      } catch (error) {
        return {
          ...state,
        };
      }
    }

    case FETCH_USER_SUCCESS: {
      // console.log("Save User", action.payload);
      let parentProfile = {};
      const activeChildDetail = action.payload.activeChildDetail;

      let childList = action.payload.childList;
      let email =
        activeChildDetail.parent === "Mother"
          ? activeChildDetail?.mother_email
          : activeChildDetail?.father_email;
      let password =
        state.phone === activeChildDetail?.mother_phone ||
        state.email === activeChildDetail.mother_email
          ? activeChildDetail?.mother_password
          : activeChildDetail?.father_password;
      let phone = state.phone
        ? state.phone
        : activeChildDetail.parent === "Mother"
        ? activeChildDetail.mother_phone
        : activeChildDetail.father_phone;

      try {
        // const activeChildDetail = action.payload.activeChildDetail;
        const parentName = activeChildDetail.parent_name;
        const fatherName = activeChildDetail.father_name;
        const motherName = activeChildDetail.mother_name;
        const fatherPhoto = activeChildDetail.father_photo;
        const motherPhoto = activeChildDetail.mother_photo;
        const parentType = activeChildDetail.parent;
        const motherEmail = activeChildDetail.mother_email.toLowerCase();
        const fatherEmail = activeChildDetail.father_email.toLowerCase();
        const motherPhone = activeChildDetail.mother_phone;
        const fatherPhone = activeChildDetail.father_phone;
        const motherPhotoApproved = activeChildDetail.mother_approved_status;
        const fatherPhotoApproved = activeChildDetail.father_approved_status;
        parentProfile = {
          parentName,
          parentType,
          motherEmail,
          fatherEmail,
          motherPhone,
          fatherName,
          motherName,
          fatherPhone,
          motherPhoto,
          fatherPhoto,
          // childPhoto,
          parentPhone:
            parentType.toLowerCase() === "mother" ? motherPhone : fatherPhone,
          parentPhoto: addTimeStamp(
            parentType.toLowerCase() === "mother" ? motherPhoto : fatherPhoto
          ),
          parentApprovedStatus:
            parentType.toLowerCase() === "mother"
              ? motherPhotoApproved
              : fatherPhotoApproved,
          motherPhotoApproved,
          fatherPhotoApproved,

          // parentEmail: state.email,
        };

        // updating the timestamp in child photo
        activeChildDetail.child_photo = addTimeStamp(
          activeChildDetail.child_photo
        );

        // updating the timestamp in other child photos

        childList = childList.map((child) => ({
          ...child,
          child_photo: addTimeStamp(child.child_photo),
        }));
      } catch (error) {}
      return {
        /* ...state,
        parentProfile,
        user: action.payload,*/
        ...state,
        parentProfile,
        email: email,
        password: password,
        phone: phone,
        user: {
          ...action.payload,
          childList,
          activeChildDetail,
        },
        lastLoggedIn: moment(),
      };
    }

    case SUBMIT_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
      };
    }

    case CHANGE_LOGGEDIN_CHILD: {
      let password = state.password;
      if (
        action.payload.mother_email.toLowerCase() === state.email.toLowerCase()
      ) {
        password = action.payload.mother_password;
      } else if (
        action.payload.father_email.toLowerCase() === state.email.toLowerCase()
      ) {
        password = action.payload.father_password;
      }
      return {
        ...state,
        password: password,
        user: {
          ...state.user,
          activeChildDetail: {
            ...state.user.activeChildDetail,
            ...action.payload,
          },
        },
      };
    }
    case UPDATE_PARENT_PROFILE: {
      // will update guardian photograph (mother/ father) from this action

      // dont need to update child list here as parent is same for both the child.
      const key =
        state.parentProfile.parentType === "Mother"
          ? "motherPhoto"
          : "fatherPhoto";
      const ApprovalKey =
        state.parentProfile.parentType === "Mother"
          ? "motherPhotoApproved"
          : "fatherPhotoApproved";

      return {
        ...state,
        parentProfile: {
          ...state.parentProfile,
          parentPhoto: action.payload.url,
          parentApprovedStatus: action.payload.status,
          [key]: action.payload.url,
          [ApprovalKey]: action.payload.status,
        },
      };
    }

    case UPDATE_CHILD_PHOTO: {
      try {
        const activeChildDetail = {
          ...state.user.activeChildDetail,
          child_photo: action.payload.url,
          child_approved_status: action.payload.status,
        };

        // updating the child list with new child_photo
        // as in case of "change_loogeedin_child" the active child is replaced from child_list
        const childList = state.user.childList;
        let alteredChildList = childList;
        if (Array.isArray(childList) && childList.length > 1) {
          alteredChildList = childList.map((child) => ({
            ...child,
            child_photo:
              child.id === activeChildDetail.id
                ? action.payload.url
                : child.child_photo,
          }));
        }
        return {
          ...state,
          user: {
            ...state.user,
            activeChildDetail,
            childList: alteredChildList,
          },
        };
      } catch (error) {
        // console.log("the error is ", error, error.message);
        return state;
      }
    }

    case SET_CHILD_DETAIL: {
      // console.log("Payload => ", action.payload.response);
      return {
        ...state,
        user: {
          ...state.user,
          activeChildDetail: action.payload.response,
        },
      };
    }
    case LOGOUT: {
      return {
        ...state,
        user: {
          ...state.user,
          activeChildDetail: undefined,
        },
      };
    }
    case SAVE_OTP: {
      return {
        ...state,
        otpDetails: action.payload.otpDetails,
      };
    }
    case RESET_DOOR_PIN_SUCCESS: {
      return {
        ...state,
        password: action.payload.pin,
      };
    }

    default: {
      return state;
    }
  }
};

export default loginReducer;
