import {
  GET_REQUESTS_TYPE_SUCCESS,
  FETCH_PAST_REQUEST_SUCCESS
} from "./action";
import { transformTabs, transformRequests } from "./transformRequest";
import { SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { CHANGE_LOGGEDIN_CHILD, LOGOUT } from "../../Login/redux/action";

const initialState = {
  pastRequest: {},
  requestType: {
    allowedServices: [],
    pendingRequests: [],
    serviceStatus: {}
  },
  tabs: [],
  loading: true,
};

const SupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAST_REQUEST_SUCCESS: {
      return {
        ...state,
        pastRequest: transformRequests(action.payload.response),
      };
    }
    case GET_REQUESTS_TYPE_SUCCESS: {
      //
      const allowedServices = action.payload.response.allowed_service;
      const pendingRequests = transformRequests(
        action.payload.response.pending_requests
      );
      const programs_available = action.payload.response.programs_available;

      return {
        ...state,
        requestType: {
          allowedServices,
          pendingRequests,
          programs_available,
          serviceStatus: action.payload.response.service_status
        },
        tabs: transformTabs(allowedServices, pendingRequests),
        loading: false
      };
    }
    case SET_COMPONENT_LOADING + "SUPPORT": {
      return {
        ...state,
        loading: action.value
      }
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default SupportReducer;
