import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  showPasswordOverlay,
  setNavigationParams
} from "../../global/redux/action";
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { getFeeCertificate } from "./redux/action";
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import moment from "moment";
class FeeCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  validate = () => {
  if (this.state.startDate === undefined) {
      this.setState({ error: "Please Select From Date" });
      return false;
    } else if (this.state.endDate === undefined) {
      this.setState({ error: "Please Select End Date" });
      return false;
    } else {
      return true;
    }
  };

  submit = type => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, isMatched => {
      //   if (isMatched) {
          this.props.getFeeCertificate(
            this.state.startDate,
            this.state.endDate,
            pdfUrl => {
              // this.props.history.push("shareFeeCerticate", {
              //   startDate: this.state.startDate,
              //   endDate: this.state.endDate,
              //   natureOfService: this.state.natureOfService,
              //   certificateUrl: pdfUrl
              // });
              this.props.setNavigationParams({ certificateUrl: pdfUrl });
              /*this.props.setNavigationParams({
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                natureOfService: this.state.natureOfService,
                certificateUrl: pdfUrl
              });*/
              this.props.history.push("shareFeeCerticate");
            }
          );
        } else {
        }
    //   });
    // }
  };

  render() {
    console.log("[Finance][Fee Certificate] Request for Fee Certificate");
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em"
            }}
          >
            Fee Certificate
          </CardHeader>
          <CardBody>
            <Form>
              <Row form>
                {/* <Col md={6}>
                  <FormGroup>
                    <Label for="newCenterSelect">Nature Of Service</Label>

                    <CustomInput
                      id="select"
                      type="select"
                      onChange={event => {
                        let selectedValue = event.target.value;
                        this.setState({ natureOfService: selectedValue });
                      }}
                    >
                      <option value="undefined">Select</option>
                      {this.props.constants.natureService.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </CustomInput>
                  </FormGroup>
                </Col> */}
                <Col md={6}>
                  <FormGroup>
                    <Label for="startDate">From</Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-calender" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FootprintDatePicker
                        onChange={date => {
                          this.setState(
                            {
                              holidaySelected: false,
                              startDate: moment(date),
                              error: undefined
                            },
                            () => { }
                          );
                        }}
                        inputProps={{
                          placeholder: "Start Date"
                        }}
                        timeFormat={false}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="endDate">To</Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-calender" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FootprintDatePicker
                        onChange={date => {
                          this.setState(
                            {
                              endDate: moment(date),
                              holidaySelected: false,
                              error: undefined
                            },
                            () => { }
                          );
                        }}
                        inputProps={{
                          placeholder: "End date"
                        }}
                        timeFormat={false}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              {typeof this.state.error !== "undefined" && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <Button
                className="btn-block btn-lg bg-purple border-0"
                style={{ color: "white" }}
                onClick={() => {
                  this.submit();
                }}
              >
                Generate
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  constants: state.constants
});

const mapDispatchToProps = dispatch => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  getFeeCertificate: (startDate, endDate, onSuccess) =>
    dispatch(getFeeCertificate(startDate, endDate, '', onSuccess)),
  setNavigationParams: params => dispatch(setNavigationParams(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(FeeCertificate);
