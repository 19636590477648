import {
  UPDATE_USER_PHONE,
  UPDATE_USER_EMAIL,
  updatePhoneSuccess,
  updateEmailSuccess,
  ADD_ADMISSION_DETAILS,
  RESET_DOOR_PIN,
  doorAccessPin,
  doorAccessPinSuccess,
} from "./action";

import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI, { generateAuthToken } from "../../../global/utils/fetch";
import {
  logout,
  updateChildPhoto,
  updateParentProfile,
  verifyOTP,
} from "../../Login/redux/action";
import moment from "moment";
import Constants from "../../../constants";
import { App } from "@capacitor/app";
export const updateUserPhone = createLogic({
  type: UPDATE_USER_PHONE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const activeChild = getState().userState.user.activeChildDetail;
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType.toLowerCase();
      //
      // const parentName = child.parent_name.toLowerCase();
      let father_phone = activeChild.father_phone;
      let mother_phone = activeChild.mother_phone;
      if (parentType === "father") {
        father_phone = action.payload.newPhone;
      } else if (parentType === "mother") {
        mother_phone = action.payload.newPhone;
      }

      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/admission/updateParentPhone`;
      const body = {
        type: parentType,
        update_phone: "yes",
        updated_by: activeChild.id,
        successReportType: "Alert",
        errorReportType: "Alert",
        father_phone,
        mother_phone,
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            body.phone = action.payload.newPhone;
            dispatch(updatePhoneSuccess(body));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          // dispatch(fetchTeachersSuccess([]));
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});

export const updateUserEmail = createLogic({
  type: UPDATE_USER_EMAIL,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType.toLowerCase();
      const activeChild = getState().userState.user.activeChildDetail;
      // const parentName = child.parent_name.toLowerCase();
      let father_email = parentProfile.fatherEmail;
      let mother_email = parentProfile.motherEmail;

      if (parentType === "father") {
        father_email = action.payload.newEmail;
      } else if (parentType === "mother") {
        mother_email = action.payload.newEmail;
      }

      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/franchises/updateParentEmail`;
      const body = {
        parent_type: parentType,
        update_email: "yes",
        updated_by: activeChild.id,
        father_email,
        mother_email,
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          if (response.status === "ok") {
            body.email = action.payload.newEmail;
            dispatch(updateEmailSuccess({ email: action.payload.newEmail }));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(updateEmailSuccess({ email: getState().userState.email }));
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          // dispatch(fetchTeachersSuccess([]));
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});

export const addGuardian = createLogic({
  type: ADD_ADMISSION_DETAILS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      // const url = `${
      //   getState().constants.AppUrl || Constants.env.AppUrl
      // }/parentapp/addAdmissionPhotograph`;
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/update_guardian_by_child_id`;
      const body = {
        image: action.payload.image,
        modified_by: getState().userState.parentProfile.parentType,
        parent_name: getState().userState.parentProfile.parentName,

        mobile_no: getState().userState.parentProfile.parentPhone,
        relation_to_child: action.payload.relation,
        update_type: "requested",
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          // console.log("the response is ", response);
          if (response.status === "ok") {
            if (action.payload.relation === "Self") {
              dispatch(
                updateChildPhoto(
                  response.guardian_picture + "?" + moment().unix(),
                  response.statusMessage
                )
              );
            } else {
              dispatch(
                updateParentProfile(
                  response.guardian_picture + "?" + moment().unix(),
                  response.statusMessage
                )
              );
            }
            // dispatch(getGuardianSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              // console.log("off fetch blogs");
              done();
            }
          } else if (response.status === "error") {
            // dispatch(getGuardianSuccess([]));
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          // dispatch(getGuardianSuccess([]));
          dispatch(setLoading(false));
          // failure
          // console.log("Error: ", error.message);
          done();
        }
      );
    } catch (error) {
      // console.log("the error is :", error, error.message);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});

export const resetDoorPin = createLogic({
  type: RESET_DOOR_PIN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/userauth/resetDoorPin`;
      const body = {
        child_id: getState().userState?.user?.activeChildDetail?.id
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
       async (response) => {
          // console.log("the response is ", response);
          if (response.status === "ok") {
            dispatch(setLoading(false));
            // console.log(response);
            dispatch(doorAccessPinSuccess(response.door_pin));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          dispatch(setLoading(false));
          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      done();
    }
  },
});

export default [updateUserEmail, updateUserPhone, addGuardian, resetDoorPin];
