import { FETCH_FOOD, fetchFoodSuccess } from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";

import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchFoodLogic = createLogic({
  type: FETCH_FOOD,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('FOOD', true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/foodMenu`;
      //const url = `http://localhost:4000/dev/parentapp/foodMenu`;
      const body = {
        // date: moment(action.payload)
        date: moment(action.payload).format(SERVER_DATE_FORMAT)
      };
      console.log("[Food Menu] Calling Api: /parentapp/foodMenu, Payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Food Menu] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchFoodSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(fetchFoodSuccess({}));
            dispatch(onErrLoading('FOOD', false))
            done();
          }
        },
        error => {
          console.log("[Food Menu] Error: ",error);
          dispatch(fetchFoodSuccess({}));
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('FOOD', false))
          setContext("Food Menu Error", error);
          done();
        }
      );
    } catch (error) {
    console.log("[Food Menu] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('FOOD', false))
      setContext("Food Menu Error", error);
      done();
    }
  }
});
export default [fetchFoodLogic];
