import { FETCH_SURVEY, fetchSurveySuccess, SUBMIT_SURVEY } from "./action";
import { setLoading, setError } from "../../../global/redux/action";

import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchSurveyLogic = createLogic({
  type: FETCH_SURVEY,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getSurveyParams`;
      const body = {
        // email: (action.payload.email),
        // password: action.payload.password,
        // web: 'no',
        // token: 'random',
      };
      console.log("[Survey Params] Calling API: /parentapp/getSurveyParams");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Survey Params] Received response: ",response);
          if (response.status === "ok") {
            dispatch(fetchSurveySuccess(response.response));
            dispatch(setLoading(false));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchSurveySuccess([]));
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Survey Params] Error: ",error);
          dispatch(fetchSurveySuccess([]));
          dispatch(setLoading(false));
          // failure
          setContext("Fetch Survey Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Survey Params] Error: ",error);
      dispatch(setLoading(false));
      setContext("Fetch Survey Error", error);
      done();
    }
  }
});

export const submitSurvey = createLogic({
  type: SUBMIT_SURVEY,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType;
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/updateParentFeedback`;
      const body = {
        // only in case of "other" this value is not empty
        nps_score: action.payload.survey.rating,
        nps_tags: action.payload.survey.tags.map(item => item.value.key),
        nps_parent: parentType,
        nps_comments: action.payload.survey.comment,
        survey_id: action.payload.survey.surveyId
      };
      console.log("[Survey Submit] Calling API: /parentapp/updateParentFeedback, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Survey Submit] Received response: ",response);
          if (response.status === "ok") {
            dispatch(setLoading(false));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Survey Submit] Error: ",error);
          dispatch(fetchSurveySuccess([]));
          dispatch(setLoading(false));
          // failure
          setContext("Submit Survey Error", error);
          if (action.payload && action.payload.onSuccess) {
            action.payload.onSuccess();
            done();
          } else {
            done();
          }
        }
      );
    } catch (error) {
      console.log("[Survey Submit] Error: ",error);
      dispatch(setLoading(false));
      setContext("Submit Survey Error", error);
      if (action.payload && action.payload.onSuccess) {
        action.payload.onSuccess();
        done();
      } else {
        done();
      }
    }
  }
});

export default [fetchSurveyLogic, submitSurvey];
