import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, Label } from "reactstrap";

import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import { changeLoggedInChild } from "../features/Login/redux/action";
import { fetchChildTimeLine } from "../features/ChildTimeline/redux/action";
import { Link } from "react-router-dom";

class ChangeLoginChildOverlay extends Component {
  constructor(props) {
    super(props);
  }
  toggle = () => {
    if (this.props.toggle) {
      this.props.toggle(!this.props.isOpen);
    }
  };

  dismiss = () => {
    if (this.props.toggle) {
      this.props.toggle(false);
    }
  };

  componentDidUpdate() { }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this.toggle()}
          className="border-red"
        >
          <ModalHeader
            toggle={() => this.toggle()}
            onClosed={() => {
              this.toggle(false);
            }}
            style={{ fontFamily: "Baloo", color: "#fff" }}
          >
            Change Logged In Child
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <CardTitle>
                  <Label style={{ fontFamily: "Baloo" }}>
                    You are currently logged in with :
                    {this.props?.loggedInChild?.name}{" "}
                    {this.props?.loggedInChild?.last_name}
                  </Label>
                </CardTitle>
                <CardText>
                  <ul>
                    {Array.isArray(this.props.children) &&
                      this.props.children.map((item) => (
                        <span key={item.id} style={{ cursor: "pointer" }}>
                          <div>
                            <li
                              onClick={() => {
                                // alert("clicked");
                                let parent_token = '';
                                if (this.props.parent_type == 'Mother') {
                                  parent_token = "mother_access_token";
                                } else {
                                  parent_token = "father_access_token";
                                }
                                localStorage.setItem("auth_token", item[parent_token]);
                                this.props.changeActiveChild(item);
                                this.dismiss();
                                this.props.history.replace("/myProfile");
                                // setTimeout(() => {
                                //   this.props.fetchChildTimeLine(0);
                                // });
                              }}
                            >
                              {item.name} {item.last_name}
                            </li>
                          </div>
                        </span>
                      ))}
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
/*
const mapStateToProps = state => ({
  children: state.userState.user.childList,
  loggedInChild: state.userState.user.activeChildDetail
});*/

const mapStateToProps = state => {
  return {
    children: state.userState.user.childList,
    loggedInChild: state.userState.user.activeChildDetail,
    parent_type: state?.userState?.parentProfile?.parentType || 'Mother',
  };
};

const mapDispatchToProps = dispatch => ({
  changeActiveChild: child => {
    dispatch(changeLoggedInChild(child));
  },

  fetchChildTimeLine: startIndex => dispatch(fetchChildTimeLine(startIndex))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeLoginChildOverlay);
