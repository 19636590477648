import { setError, setLoading, onErrLoading } from "../../../global/redux/action";
import fetchAPI from "../../../global/utils/fetch";
import {
  SEND_CHAT_MESSAGE_OFFLINE,
  fetchChatSuccess,
  FETCH_CHAT
} from "./action";
import { createLogic } from "redux-logic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchChat = createLogic({
  type: FETCH_CHAT,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('CONVERSATION', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getConversationList/`;
      const body = {
        index_start: action.payload.startIndex,
        limit: 50
      };
      console.log("[Conversation] Calling Api: /parentapp/getConversationList, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        errorReportType: "none",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Conversation] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(
              fetchChatSuccess(
                response.response.conversationList,
                action.payload.startIndex,
                response.response.moreData
              )
            );
            dispatch(setLoading(false));
            dispatch(onErrLoading("CONVERSATION", false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('CONVERSATION',false))
            done();
          }
        },
        error => {
          console.log("[Conversation] Error: ",error);
          dispatch(setLoading(false));
          // dispatch(fetchChildTimeLineSuccess([]));
          // failure
          dispatch(onErrLoading('CONVERSATION', false))
          setContext("Conversation Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Conversation] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('CONVERSATION', false))
      setContext("Conversation Error", error);
      done();
    }
  }
});

export const sendMessage = createLogic({
  type: SEND_CHAT_MESSAGE_OFFLINE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/saveParentMessage`;
      const body = {
        message: action.payload.message,
        parent_email: getState().userState.email
      };
      console.log("[Conversation][Send Message] Calling API: /parentapp/saveParentMessage, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Conversation][Send Message] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Conversation][Send Message] Error: ",error);
          dispatch(setLoading(false));
          // dispatch(fetchChildTimeLineSuccess([]));
          // failure
          setContext("Send Conversation Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Conversation][Send Message] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Send Conversation Error", error);
      done();
    }
  }
});

export default [sendMessage, fetchChat];
