import { setError, setLoading, onErrLoading } from "../../../global/redux/action";
import fetchAPI from "../../../global/utils/fetch";
import {
  FETCH_CHILD_TIMELINE,
  fetchChildTimeLineSuccess,
  updateDataPresence
} from "./action";
import { createLogic } from "redux-logic";
import { ITEM_PER_PAGE } from "../../../global/redux/combineLogic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchChildTimeline = createLogic({
  type: FETCH_CHILD_TIMELINE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading("CHILDTIMELINE", true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getChildTimeline`;
      const body = {
        include_types: getState().constants.INCLUDE_TYPES,
        index_start:
          action.payload.startIndex === 0 ? 0 : action.payload.startIndex + 1,
        limit: ITEM_PER_PAGE
      };
      console.log("[CHILD TIMELINE] Calling Api: /parentapp/getChildTimeline, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,

        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[CHILD TIMELINE] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(
              fetchChildTimeLineSuccess(
                response.response,
                action.payload.startIndex
              )
            );
            dispatch(setLoading(false));
            dispatch(onErrLoading("CHILDTIMELINE", false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchChildTimeLineSuccess([], action.payload.startIndex));
            dispatch(updateDataPresence(false));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading("CHILDTIMELINE", false))
            done();
          }
        },
        error => {
      console.log("[CHILD TIMELINE] Error: ",error);
          dispatch(setLoading(false));
          dispatch(fetchChildTimeLineSuccess([]));
          // failure
          dispatch(onErrLoading('CHILDTIMELINE', false))
          done();
        }
      );
    } catch (error) {
      console.log("[Child Timeline] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('CHILDTIMELINE', false))
      setContext("Timeline Error", error);
      done();
    }
  }
});

export default [fetchChildTimeline];
