export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";

export const fetchBlogs = () => ({
  type: FETCH_BLOGS
});

export const fetchBlogsSuccess = blogs => ({
  type: FETCH_BLOGS_SUCCESS,
  payload: {
    blogs
  }
});
