import React from "react";
import { connect } from "react-redux";
import { getRequestType, fetchPastRequest } from "./redux/action";
import { HalfWidthTile, FullWidthTile } from "./components/SupportTiles";

import { Row, Col } from "reactstrap";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import PastRequestItem from "../PastRequest/item";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { fetchChildDetail } from "../Login/redux/action";

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    console.log("[Support] fetching data...");
    this.props.getRequestType();
    this.props.fetchPastRequest();
    // this.props.fetchChildData();
  }

  render() {
    const { pastRequest, tabs, isEmergency, allowedServices, loading } = this.props;
    let PendingList = [];

    if (Array.isArray(pastRequest)) {
      PendingList = pastRequest.filter(item => {
        return item.status === "Pending" && item.type !== "Child Resume";
      });
    }

    return (

      < InnerHOC heading="Support" >
        {
          < Row >
            {
              Array.isArray(PendingList) &&
              PendingList.length > 0 &&
              PendingList.map((item, index) => (
                <PastRequestItem
                  history={this.props.history}
                  item={item}
                  md={12}
                  key={index}
                  index={index}
                />
              ))
            }
          </Row >
        }


        {(tabs.length === 0) ?
          <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} /> :
          <Row>
            {
              tabs.map((item, index) => (
                <HalfWidthTile {...item} />

              ))
            }
          </Row>}

        <Row>
          <Col xs="12" sm="6" md="12" lg="6" xl="6" className="mb-3">
            {this.props.isEmergency === true ||
              (Array.isArray(this.props.allowedServices) &&
                this.props.allowedServices.length > 0 && (
                  <FullWidthTile
                    backgroundColor="#6200EA"
                    heading="Past Request"
                    //path="pastRequest"
                    path="PastRequestTab"
                    value="Request Cancellation and Status Update"
                    // image={require("../../assets/support/request-pending.svg")}
                    icon="icon-past-request"
                  />
                ))}
          </Col>

          <Col xs="12" sm="6" md="12" lg="6" xl="6" className="mb-3">
            {isEmergency === true ||
              (Array.isArray(allowedServices) &&
                allowedServices.length > 0 && (
                  <FullWidthTile
                    backgroundColor="#00B8D4"
                    heading="Raise A Ticket"
                    path="raiseAnIssue"
                    value="Raise a Ticket"
                    // image={require("../../assets/support/request-pending.svg")}
                    icon="icon-it-support"
                  />
                ))}
          </Col>
        </Row>
      </InnerHOC >
    );
  }
}
const mapStateToProps = state => ({
  isEmergency: state.userState.user.activeChildDetail
    ? state.userState.user.activeChildDetail.admission_type ===
    state.constants.EmergencyDaycareType
    : false,

  tabs: state.support.tabs,
  pendingRequests: state.support.requestType.pendingRequests,
  allowedServices: state.support.requestType.allowedServices,
  pastRequest: state.support.pastRequest,
  loading: state.support.loading
});

const mapDispatchToProps = dispatch => ({
  getRequestType: () => dispatch(getRequestType()),
  fetchPastRequest: () => dispatch(fetchPastRequest()),
  fetchChildData:() => dispatch(fetchChildDetail())
});
export default connect(mapStateToProps, mapDispatchToProps)(Support);