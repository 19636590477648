const DefaultConstants = {
  // cctvConnectionCheck:
  //     'https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json',
  //baseUrl: "https://78w69rnnm8.execute-api.ap-south-1.amazonaws.com/dev",
  pushPermissionMessage: 'Please Allow Push Notification Permission, To receive your updates from footprints',
  //AppUrl: "https://lof8j6yh1f.execute-api.ap-south-1.amazonaws.com",
  // lambdaApiServer:
  //   "https://5ox1hxt4qe.execute-api.ap-southeast-1.amazonaws.com/Production",
  // AppUrl: "https://5ox1hxt4qe.execute-api.ap-southeast-1.amazonaws.com",

  //AmazonAPI: "https://parentapp.s3-ap-southeast-1.amazonaws.com",

  EmergencyDaycareType: "Emergency Daycare",
  // ImagePath:
  //     'https://staging-franchise.footprintseducation.in/uploads/admission_photos/',
  // TimeLineThumbUrl:
  //     'https://staging-admissions.footprintseducation.in/wp-content/uploads/daily_uploads/resize/',
  // teachersPath: 'https://franchise.footprintseducation.in/',

  // PaymentCheckUrl: 'https://staging.footprintseducation.in/onlinepayment/',
  ParentOrientationVideos: ["https://player.vimeo.com/video/227397718"],
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.footprint.daycareclient",
  appStoreUrl: "https://www.footprintseducation.in/parent-area",
  INCLUDE_TYPES:
    "Attendance::Sleep::Milk::Breakfast::Fruit::Lunch::Snack::Activity::Image::Snacks::Fruits::OutdoorEvents::Diaper",
  INCLUDE_TYPES_NOTIFICATION:
    "Attendance::Sleep::Milk::Breakfast::Fruit::Lunch::Snack::Activity::Image::Snacks::Fruits::Message::Measurement::COR::OutdoorEvents::Diaper",

  withdrawReason: [
    "Transfer",
    "Not satisfied with services",
    "Moving to formal schooling",
    "Others",
  ],

  issueList: [
    "Accounts",
    "Appreciation",
    "CCTV",
    "Food",
    "Mobile App",
    "Transport",
    "Others",
  ],
  updateMessage:
    "Sorry, We No longer support this version of the app, Please update the app",

  programList: [
    { label: "After School", value: "After School" },
    { label: "Half Day(09:00 - 12:30)", value: "Half Day" },
    { label: "Half Day (09:00 - 12:00)", value: "Half Day 2" },
    { label: "Half Day (12:30 - 15:30)", value: "Half Day 3" },
    { label: "Evening Program (4:00 To 6:30)", value: "Evening Program" },
    { label: "Extended Preschool (9:00 To 3:30)", value: "Extended Preschool" },
    { label: "Full Day(9:00 To 6:30)", value: "Full Day" },
  ],

  natureService: [
    { label: "Tuition Fee", value: "Tuition Fee" },
    { label: "Daycare Fee", value: "Daycare Fee" },
  ],

  doctorName: "Dr Ashutosh Sinha",
  doctorLink: "https://www.footprintseducation.in/footprints-paediatric",

  ImagePath:
    "https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/uploads/admission_photos/",
  teachersPath: "https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/",
  TimeLineThumbUrl:
    "https://fp-portal-uploads.s3.ap-southeast-1.amazonaws.com/wp-content/uploads/daily_uploads/resize/",
  PaymentCheckUrl: "https://www.footprintseducation.in/onlinepayment/",
  cctvConnectionCheck:
    "https://admissions.footprintseducation.in/parents/site-json/Uptimerobot_listCentresStatus.json",

  transportTitle: "Approval is not instant and subject to availability",
  changeProgramTitle: "Maximum 2 changes are allowed in a period of 3 months",
  creditAdjustmentSubtitle:
    "Credits are adjusted in the next month of issue. So if you applied for Pause from July 5th to August 10th, system would only process credit once you have completed your pause period which is on August 11th, since next Invoice is only sent on September 1, these credits would only reflect in that Invoice.",
  cameraPauseTitle: "The Child Is On Pause.",
  cameraPauseSubtitle: "CCTV feed is not available for paused children",
  cameraAvaiableTimings:
    "The live streaming videos will be available between 8:45 AM to 6:45 PM.",
  linkOpenError: "Could not open link valid link not avaiable",
  CreditsAdjustedInvoice:
    "Credits are adjusted in the next month of issue. So if you applied for Pause from July 5th to August 10th, system would only process credit once you have completed your pause period which is on August 11th, since next Invoice is only sent on September 1, these credits would only reflect in that Invoice.",
  StopRemindersText:
    "We are extremely sorry if you have already paid and still facing reminders, please provide details so that we can update our system and stop sending updates. ",
  relationToChild: [
    {
      label: "Grand Parent",
      value: "Grand Parent",
    },
    {
      label: "House Help",
      value: "House Help",
    },
    {
      label: "Other",
      value: "Other",
    },
  ],
  ArchiveMessage: "Pictures can be archived any time after 3 months.",
  AttendanceNoMonthDataText: 'No attendance data found for the selected month.',
  AttendanceNoDataFoundText: 'No attendance data found for the child.'
};

export default DefaultConstants;

// the value of these constants would change according to the response of fetch constants API
