import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { showPasswordOverlay } from "./redux/action";
import { signIn, verifyOTP } from "../features/Login/redux/action";
class PasswordOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  toggle = () => {
    this.setState({ show: !this.state.isOpen });
  };

  componentDidMount() {
  }
  componentDidUpdate(prevProps) {
    if (this.props.overlay !== prevProps.overlay) {
      this.setState({ OTP: "", error: undefined, verify: '',showVerify: false });
      // if(this.props.overlay.show){
      //   this.props.login(this.props.phone, this.props.email);
      // }
    }
  }
  validate = () => {
    if (!this.state.OTP) {
      this.setState({ error: "Please Enter OTP" });
      return false;
    } else {
      return true;
    }
  };
  render() {
    const { overlay } = this.props;
    // console.log("Verify => ",this.state.verify);
    return (
      <div>
        {this.state.showVerify ? (
          <Modal
            //isOpen={this.state.show}
            isOpen={overlay.show === true}
            //toggle={() => this.toggle()}
            className="border-red"
          >
            <ModalHeader
              // toggle={() => this.toggle()}
              onClose={() => {
                //this.setState({ isOpen: false });
                this.props.showPasswordOverlay(false);
              }}
              style={{ fontFamily: "Baloo", color: "#fff" }}
            >
              Please Enter OTP To Confirm ?
              <p style={{ fontSize: "13px", marginTop: "4px" }}>
                {" "}
                This action cannot be undone
              </p>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="confirmPassword" className="sr-only">
                  Confirm OTP
                </Label>
                <Input
                  type="number"
                  name="text"
                  id="confirmPassword"
                  placeholder="Confirm OTP"
                  onChange={(event) => {
                    this.setState({
                      OTP: event.target.value,
                      error: undefined,
                    });
                  }}
                />
              </FormGroup>
              {typeof this.state.error !== "undefined" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "13px",
                    fontFamily: "Baloo",
                  }}
                >
                  {this.state.error}
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={() => {
                  if (this.validate()) {
                    this.props.verifyOTP(this.state.OTP, true, (response) => {
                      // console.log("Response : ", response);
                      if (response.status === "ok") {
                        this.props.showPasswordOverlay(false);
                        // console.log(
                        //   "Password Overlay onSuccess : ",
                        //   overlay.onSuccess
                        // );
                        if (overlay.onSuccess) {
                          // console.log("Success request processing...");
                          overlay.onSuccess(true);
                        }
                      } else {
                        this.setState({
                          error: response.error,
                        });
                        if (overlay.onSuccess) {
                          overlay.onSuccess(false);
                        }
                      }
                    });
                  }

                  // if (overlay.onSuccess) {
                  //   const isMatched =
                  //     this.props.currentPassword === this.state.enteredPassword;

                  //   if (isMatched === true) {
                  //     this.props.showPasswordOverlay(false);
                  //   } else {
                  //     this.setState({
                  //       error: "Password Didn't Match, Please Try Again",
                  //     });
                  //   }
                  //   if (overlay.onSuccess) {
                  //     overlay.onSuccess(isMatched);
                  //   }
                  // }
                }}
              >
                OK
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  this.props.login(
                    this.props.phone,
                    this.props.email,
                    this.state.verify
                  )
                }
              >
                Resend OTP
              </Button>
              <Button
                color="secondary"
                onClick={() => this.props.showPasswordOverlay(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          <Modal
            //isOpen={this.state.show}
            isOpen={overlay.show === true}
            //toggle={() => this.toggle()}
            className="border-red"
          >
            <ModalHeader
              // toggle={() => this.toggle()}
              onClose={() => {
                //this.setState({ isOpen: false });
                this.props.showPasswordOverlay(false);
              }}
              style={{ fontFamily: "Baloo", color: "#fff", paddingBottom: 0 }}
            >
              Please Choose One To Verify ?
              <p style={{ fontSize: "13px", marginTop: "4px" }}></p>
            </ModalHeader>
            <ModalBody style={{padding: '0 1.5rem'}}>
              <FormGroup check>
                <Input
                  className="verifyInput"
                  name="radio1"
                  type="radio"
                  onChange={() => this.setState({ verify: "phone" })}
                  checked={this.state.verify === "phone"}
                />{" "}
                <Label
                  onClick={() => this.setState({ verify: "phone" })}
                  check
                  style={{
                    fontSize: "20px",
                    marginTop: "7px",
                    marginLeft: "5px",
                  }}
                >
                  +91 {" " + this.props?.phone}
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  className="verifyInput"
                  name="radio1"
                  type="radio"
                  onChange={() => this.setState({ verify: "email" })}
                  checked={this.state.verify === "email"}
                />{" "}
                <Label
                  check
                  style={{
                    fontSize: "20px",
                    marginTop: "7px",
                    marginLeft: "5px",
                  }}
                  onClick={() => this.setState({ verify: "email" })}
                >
                  <span>{this.props?.email}</span>
                </Label>
              </FormGroup>
              {typeof this.state.error !== "undefined" && (
                <p
                  style={{
                    color: "red",
                    fontSize: "13px",
                    fontFamily: "Baloo",
                  }}
                >
                  {this.state.error}
                </p>
              )}
            </ModalBody>
            <ModalFooter style={{ padding: "0.75rem 1.5rem"}}>
              <Button
                color="success"
                onClick={() => {
                  if (this.state.verify) {
                    if (this.state.verify === "email") {
                      // console.log("Verified", this.props.email);
                      this.props.login(
                        this.props.phone,
                        this.props.email,
                        this.state.verify
                      );
                    } else
                      this.props.login(
                        this.props.phone,
                        this.props.email,
                        this.state.verify
                      );
                    this.setState({ error: "", OTP: "", showVerify: true });
                  } else {
                    this.setState({
                      error: "Please Select One Option to Verify",
                    });
                  }
                }}
              >
                Send OTP
              </Button>
              <Button
                color="secondary"
                onClick={() => this.props.showPasswordOverlay(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overlay: state.global.passwordOverlay,
  phone: state.userState.phone,
  email: state.userState.email,
  currentPassword: state.userState && state.userState.password,
});

const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  login: (phone,email,verify) => dispatch(signIn(phone,email,verify)),
  verifyOTP: (otp,verify, onSuccess) => dispatch(verifyOTP(otp,verify, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordOverlay);
