import React, { useEffect, useMemo, useState } from 'react'
import { InnerHOC } from '../../global/HOComponet/innerPage'
import { fetchAttendance } from './redux/action';
import { connect } from 'react-redux';
import ListEmptyComponent from '../../global/utils/ListEmptyComponent';
import { Bar } from "react-chartjs-2";
import "chart.js/dist/Chart.css";
import { CardText, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import Theme from '../../theme';
import moment from "moment";
import { fullMonthsArray } from './redux/reducer';

const AttendanceList = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentData, setCurrentData] = useState({dateGroup:'',data:[]});
    const toggle = () => setDropdownOpen(!dropdownOpen);

    useMemo(() => {
        console.log(`AttendanceList: `,props.list);
        setCurrentData(props.list.find(item => item.dateGroup == moment().format('MMMM')));
    }, [props.list]);

    useEffect(() => {
        console.log("[Attendance] Fetching attendance data...");
        props.fetchAttendance();
    }, []);

    const calculatePercentage = (data, status) => {
        if (!Array.isArray(data) && data.length === 0) return 0;
        let totalCount = data.reduce((acc,curr)=> (acc += curr?.data.length),0);
        let statusCount = data.reduce((acc, curr) => (acc += curr?.data.filter(ele => ele.status === status).length ), 0);
        // console.log("statusCount: ", statusCount, "Total: ", totalCount);
        return Math.round((statusCount / totalCount) * 100);
    }

    const displayTimeFormat = (time) => {
        const date = new Date(`2024-08-30 ${time}`);
        // const [hours, minutes] = time.split(":");
        // return hours === '00' ? '' : `${hours}:${minutes}`;
        return time === "00:00:00" ? '' : moment(date).format("LT");
    }

    const monthArray = [
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "March",
      ];;
    let presentArray = props.list.map((ele) => (ele.data.filter((data) => data.status === 'Present').length))
    let absentArray = props.list.map((ele) => (ele.data.filter((data) => data.status === 'Absent').length))

    const data = {
        labels: monthArray,
        datasets: [
            {
                label: `Present ${calculatePercentage(props.list, 'Present') || 0}%`,
                data: presentArray,
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "#21c1e0",
                fill: false,
            },
            {
                label: `Absent ${calculatePercentage(props.list, 'Absent') || 0}%`,
                data: absentArray,
                borderColor: "rgba(54, 162, 235, 1)",
                backgroundColor: "#01579b",
                fill: false,
            },
        ],
    };
    return (
        <InnerHOC
            heading='Attendance Data'
            children={
                <>
                    {/* {(Array.isArray(props.list) && props.list.length > 0) ?
                        : null} */}

                    {(!Array.isArray(props.list) ||
                        props.list.length == 0) ?
                        <ListEmptyComponent heading={props.loading ? "Loading..." : props.noDataFoundText } />
                        :
                        <>
                        <div className='mb-4'>
                            <Bar
                                options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                        },
                                    },
                                    // maintainAspectRatio: false
                                }}
                                data={data}
                            />
                        </div>
                        <div
                            className="text-white rounded-top d-flex pl-2"
                            style={{ fontFamily: "Baloo", background: Theme.colorPrimary }}
                        >
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                style={{ cursor: "pointer", padding: "0.65rem" }}
                            >
                                <i className="icon-calender mr-2" style={{color: 'white', fontSize: '20px'}}></i>{" "}
                                <DropdownToggle caret size="lg" data-toggle="dropdown" tag="span">
                                    {currentData?.dateGroup}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {fullMonthsArray.map((ele, idx) => (
                                        <DropdownItem
                                            key={ele}
                                            onClick={() => setCurrentData(props.list.find(item => item.dateGroup == ele))
                                            }
                                        >
                                            {ele}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                            <div className='pb-3'>
                                {
                                    (currentData?.data && currentData?.data?.length > 0) ?
                                        <Table style={{ color: "#525f7f" }}>
                                            <thead className="border">
                                                <tr>
                                                    <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                                                        Date
                                                    </th>
                                                    <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                                                        In Time
                                                    </th>
                                                    <th style={{ fontSize: "14px", letterSpacing: "0.2px" }}>
                                                        Out Time
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="border">
                                                {currentData?.data.map((ele, index) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                                                            <CardText>{moment(ele.date).format("ddd, DD MMM YY")}</CardText>
                                                        </td>
                                                        <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                                                            {" "}
                                                            <CardText>
                                                                {ele.status !== "Absent" ?
                                                                    <>
                                                                        {ele.time_in ? displayTimeFormat(ele.time_in) : "--:--"}
                                                                    </>
                                                                    :
                                                                    <span className='text-danger'>Absent {
                                                                        // ele.status == "Present" ? <FaRegCheckCircle style={{ color: "green", fontSize: 18 }} /> : <FaRegTimesCircle style={{ color: "red", fontSize: 18 }} />
                                                                    }</span>
                                                                }
                                                            </CardText>
                                                        </td>
                                                        <td style={{ fontSize: "15px", letterSpacing: "0.2px" }}>
                                                            {" "}
                                                            <CardText>
                                                                {ele.status !== "Absent" ?
                                                                    <>
                                                                        {ele.time_out ? displayTimeFormat(ele.time_out) : "--:--"}
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </CardText>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className='d-flex px-4 pb-1 pt-3 border'>
                                            <p className='text-md'>{ props.noDataText }</p>
                                        </div>
                                }
                        </div>
                    </>}
                </>
            }
            className='p-3 md:p-4'
        />
    )
}
const mapStateToProps = ({ attendance, constants }) => ({
    noDataText: constants.AttendanceNoMonthDataText || 'No attendance data found for the selected month.',
    noDataFoundText: constants.AttendanceNoDataFoundText || 'No data found.',
    list: attendance.attendanceList,
    loading: attendance.loading
});

const mapDispatchToProps = dispatch => ({
    fetchAttendance: () => dispatch(fetchAttendance())
});
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceList);