export const GET_GUARDIAN = "GET_GUARDIAN";
export const GET_GUARDIAN_SUCCESS = "GET_GUARDIAN_SUCCESS";
export const ADD_GUARDIAN = "ADD_GUARDIAN";
export const UPDATE_GUARDIAN = "UPDATE_GUARDIAN";
export const REMOVE_GUARDIAN = "REMOVE_GUARDIAN";

export const getGuardian = () => ({
  type: GET_GUARDIAN,
});

export const getGuardianSuccess = (response) => ({
  type: GET_GUARDIAN_SUCCESS,
  payload: {
    response,
  },
});
export const addGuardian = (image, name, mobileno, relation, onSuccess) => ({
  type: ADD_GUARDIAN,
  payload: {
    image,
    name,
    mobileno,

    relation,
    onSuccess,
  },
});

export const updateGuardianByGuardianId = (
  image,
  guardianId,
  guardianName = "",
  mobileNo = "",
  relation = "",
  onSuccess
) => ({
  type: UPDATE_GUARDIAN,
  payload: {
    image,
    guardianId,
    guardianName,
    mobileNo,
    relation,
    onSuccess,
  },
});

export const removeGuardianByGuardianId = (guardianId) => ({
  type: REMOVE_GUARDIAN,
  payload: { guardianId },
});
