import React, { Component } from "react";
import { CustomInput } from "reactstrap";

export default class FootprintInputRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      className,
      key,
      id,
      name,
      label,
      onClick = () => {},
      value
    } = this.props;
    return (
      <CustomInput
        className={className}
        type="radio"
        name={name}
        label={label}
        value={value}
        key={key}
        id={id}
        onClick={event => {
          if (this.props.onClick) {
            onClick(event.target.value);
          }
        }}
        required
      />
    );
  }
}
