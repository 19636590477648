import React from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Form, FormGroup, Label } from "reactstrap";
import { showPasswordOverlay } from "../../global/redux/action";
import FootprintInputRadio from "../../global/utils/FootprintInputRadio";
import FootprintInputText from "../../global/utils/FootprintInputText";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
import { raiseAnIssue } from "../Tickets/redux/action";

class RaiseIssue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: undefined,
      issue: undefined,
      otherRemark: undefined
    };
  }
  validate = () => {
    if (this.state.issue === undefined) {
      this.setState({ error: "Please Select Issue" });
      return false;
    } else if (this.state.description === undefined) {
      this.setState({ error: "Please Enter Description" });
      return false;
    } else {
      return true;
    }
  };

  submit = type => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, isMatched => {
      //   if (isMatched) {
          this.props.raiseAnIssue(
            this.state.issue,
            this.state.description,
            () => {
              this.props.history.replace("support");
            }
          );
        } else {
        }
    //   });
    // }
  };

  render() {
    console.log("[Raise Issue] Raise a new issue...");
    return (
      <div className="commonFeature">
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em"
            }}
          >
            Submit Issue
          </CardHeader>
          <CardBody>
            <CardTitle className="h5 text-center" style={{ lineHeight: 'normal' }}>
              Our executive will get in touch with you within one business day.
              For anything urgent please get in touch with Teacher or Centre
              Head.
            </CardTitle>
            <Form>
              <FormGroup tag="fieldset">
                {Array.isArray(this.props.constants?.RaiseIssue)? this.props.constants.RaiseIssue.map((item, i) => (
                  <div key={i}>
                    <FootprintInputRadio
                      className="mb-2"
                      label={item}
                      value={item}
                      name="customRadio"
                      id={i}
                      onClick={value => {
                        this.setState(
                          { issue: value, error: undefined },
                          () => { }
                        );
                      }}
                    />
                  </div> 
                )): null}
                {/*   {this.props.constants.RaiseIssue.map((item, i) => (
                  <CustomInput
                    onClick={event => {
                      this.setState(
                        { issue: event.target.value, error: undefined },
                        () => {
                          
                        }
                      );
                    }}
                    className="mb-2"
                    key={i}
                    id={i}
                    name="customRadio"
                    label={item}
                    type="radio"
                    value={item}
                    required
                  />
                ))}*/}
              </FormGroup>
              {this.state.issue === "Others" && (
                <FormGroup>
                  <FootprintInputText
                    type="text"
                    name="text"
                    id="otherIssue"
                    onChange={value => {
                      this.setState(
                        { otherRemark: value, error: undefined },
                        () => { }
                      );
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Label for="writeIssue">Description</Label>
                <FootprintInputText
                  type="text"
                  name="text"
                  id="writeIssue"
                  placeholder="Write your issue here"
                  onChange={value => {
                    this.setState(
                      { description: value, error: undefined },
                      () => { }
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                {typeof this.state.error !== "undefined" && (
                  <p style={{ color: "red" }}>{this.state.error}</p>
                )}
              </FormGroup>
              <FootprintInputButton
                onClick={() => {
                  this.submit();
                }}
              />
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  constants: state.constants
});

const mapDispatchToProps = dispatch => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),

  raiseAnIssue: (date, center, onSuccess) =>
    dispatch(raiseAnIssue(date, center, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(RaiseIssue);
