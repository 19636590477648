import React from "react";
import { connect } from "react-redux";
import { getOutDoorActivity, outdooreventActionReq } from "./redux/action";
import moment from "moment";
import { Col, Card, CardHeader, CardBody } from "reactstrap";
import { showModal } from "../../global/redux/action";
class OutdoorActivityItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }
  show = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const { item, index } = this.props;
    return (
      <Col xs="12" md="12" sm="12" lg="6" className="mb-4">
        <Card key={index} className="border-0 shadow">
          <CardHeader style={{ background: "#DFE2E6", fontFamily: "baloo" }}>
            <div className="d-flex flex-md-row flex-sm-row flex-column bd-highlight">
              <div className="mr-auto">
                {item.sno} . {item.eventTitle}
              </div>
              {item.eventStatus === "Cancelled" && (
                <div>
                  {"Event Status : "}
                  {item.eventStatus}
                </div>
              )}
              {item.eventStatus !== "Cancelled" && (
                <div>
                  {"Status : "}
                  {item.eventConsentStatus
                    ? item.eventConsentStatus
                    : "Authorization Pending"}
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody className="p-3">
            <div className="d-flex flex-md-row flex-sm-row flex-column bd-highlight mb-3">
              <div className="mr-auto bd-highlight">
                <h6>Event date :- {item.eventDate}</h6>
              </div>
              <div className="bd-highlight">
                <h6>Request On : {item.eventCreatedOn}</h6>
              </div>
            </div>
            <p>{item.eventDetail}</p>

            <div>
              {(moment().isBefore(item.eventDate) ||
                moment().format("YYYY-MM-DD") === item.eventDate) &&
              item.eventStatus !== "Cancelled" ? (
                <div>
                  {item.eventConsentStatus !== "Authorized" ? (
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => {
                        this.props.showModal(
                          "Authorize",
                          "Are You Sure You Want To Authorize This Request?",
                          true,
                          async () => {
                            const eventId = item.eventId;
                            await this.props.eventActionReq(eventId, "Authorized");
                            // await this.props.getOutDoorActivity();
                          },
                          () => {
                            // console.log("Cancel");
                          }
                        );
                      }}
                    >
                      Authorise
                    </button>
                  ) : null}
                  {item.eventConsentStatus !== "Denied" ? (
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => {
                        this.props.showModal(
                          "Deny",
                          "Are You Sure You Want To Deny This Request? ",
                          true,
                          async () => {
                            const eventId = item.eventId;
                            await this.props.eventActionReq(eventId, "Denied");
                            // await this.props.getOutDoorActivity();
                          },
                          () => {
                            //console.log("Cancel");
                          }
                        );
                      }}
                    >
                      Deny
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.userState.user && state.userState.user.activeChildDetail,
});

const mapDispatchToProps = (dispatch) => ({
  eventActionReq: (eventId, type) =>
    dispatch(outdooreventActionReq(eventId, type)),
  showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
    dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
  getOutDoorActivity: () => dispatch(getOutDoorActivity())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutdoorActivityItem);
