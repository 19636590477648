import React from "react";
import { connect } from "react-redux";
import { fetchChildCare } from "../redux/action";
import CORItem from "./item";
import { Row } from "reactstrap";
import ListEmptyComponent from "../../../global/utils/ListEmptyComponent";

class COR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    console.log("[Growth][ChildCare] Fetching Data...");
    this.props.fetchChildCare();
  }

  render() {
    const { childCare, loading } = this.props;
    if (Array.isArray(childCare) && childCare.length > 0) {
      return (
        <Row>
          {childCare.map((item, index) => (
            <CORItem
              history={this.props.history}
              item={item}
              key={index}
              index={index}
            />
          ))}
        </Row>
      );
    } else {
      return <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} />;
    }
  }
}
const mapStateToProps = state => ({
  childCare: state.growth.childCare,
  loading: state.growth.childloading
});

const mapDispatchToProps = dispatch => ({
  fetchChildCare: () => dispatch(fetchChildCare())
});
export default connect(mapStateToProps, mapDispatchToProps)(COR);
