import { FETCH_TEACHERS, fetchTeachersSuccess } from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchTeachersLogic = createLogic({
  type: FETCH_TEACHERS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading("MYCENTER", true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/userauth/listUsersInRole`;
      const body = {
        roles: "Teacher"
      };
      console.log("[MyCenter] Calling API: /userauth/listUsersInRole, params: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[MyCenter] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchTeachersSuccess(response.response));
            // dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchTeachersSuccess([]));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading("MYCENTER", false));
            done();
          }
        },
        error => {
          console.log("[MyCenter] Error: ",error);
          dispatch(fetchTeachersSuccess([]));
          dispatch(setLoading(false));
          dispatch(onErrLoading("MYCENTER", false));
          setContext("Fetch Teacher Error", error);
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[MyCenter] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading("MYCENTER", false));
      setContext("Fetch Teacher Error", error);
      done();
    }
  }
});

export default [fetchTeachersLogic];
