import { FETCH_ATTENDANCE_SUCCESS } from "./action";
import _ from "lodash";
import moment from "moment";
import { SET_COMPONENT_LOADING } from "../../../global/redux/action";

const initialState = {
    attendanceList: [],
    loading: true,
};

export const fullMonthsArray = [
    "April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March", 
  ];

const isPreviousDate = (date) => {
    if (moment(date).format("DD-MM-YY") === moment().format("DD-MM-YY")) return 0;
    else if (moment(date).isAfter(moment())) {
        return +1;
    }
    return -1;
};

export const monthWiseAttendance = (attendanceData) => {
    try {
        if (Array.isArray(attendanceData)) {
            let dateGroup = _.groupBy(attendanceData, (d) => {
                return moment(d.date).format("MMMM");
            });
            dateGroup = _.reduce(
                dateGroup,
                (acc, next, index) => {
                    acc.push({
                        dateGroup: index,
                        data: transformAttendance(next),
                    });
                    return acc;
                },
                []
            );
            return dateGroup;
        }
        return [];
    } catch (error) {
        return [];
    }
};

const transformAttendance = (attendanceData) => {
    return attendanceData.map((item) => ({
        ...item,
        background: isPreviousDate(item.date),
    }));
};
const attendanceFullList = (attendanceData) => {
    if (attendanceData.length === 0) return [];
    return _.reduce(
        fullMonthsArray,
        (acc, next) => {
            let idx = attendanceData.findIndex(ele => next == ele.dateGroup);
            if (idx >= 0) return [...acc, attendanceData[idx]];
            else return [...acc, {dateGroup: next, data: []}];
        },
        []);
};

const attendanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ATTENDANCE_SUCCESS: {
            // console.log("Attendence: ", action.payload.attendanceList)
            return {
                ...state,
                attendanceList: attendanceFullList(monthWiseAttendance(
                    transformAttendance(action.payload.attendanceList)
                )),
                loading: false,
            };
        }
        case SET_COMPONENT_LOADING + "Attendance": {
            return {
                ...state,
                loading: action.value,
            };
        }

        default: {
            return state;
        }
    }
};
export default attendanceReducer;
