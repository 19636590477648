import React from "react";
import { connect } from "react-redux";

import defaultImg from "../../assets/demoImg.png";

import { Form, FormGroup, Label } from "reactstrap";
import { Card, CardBody } from "reactstrap";

// reactstrap components
import { Row, Col } from "reactstrap";
import FootprintInputText from "../../global/utils/FootprintInputText";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import EditComponent from "../MyProfile/component/EditComponent";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
import { showCropModal } from "../../global/redux/action";
import { addGuardian, updateGuardianByGuardianId } from "./redux/action";
import FootprintDrop from "../../global/utils/FootprintDrop";
import _ from "lodash";
import {
  setNavigationParams,
  showPasswordOverlay,
} from "../../global/redux/action";
//const Number_PATTERN = /^[0-9]+$/;

class AddGuardian extends React.Component {
  constructor(props) {
    super(props);
    const item = this.props.navParams;
    const isEmpty = _.isEmpty(item);
    this.state = {
      error: "",
      imageUrl: isEmpty ? undefined : item.guardian_picture,
      image: undefined,
      name: isEmpty ? "" : item.guardian_name,
      mobileno: isEmpty ? "" : item.guardian_mobile_no,
      relation: isEmpty ? "" : item.relation_to_child,
    };
  }
  isValid = () => {
    if (this.state.image === undefined && this.state.imageUrl === undefined) {
      this.setState({ error: "Please Upload Guardian Image" });
      return false;
    } else if (this.state.name === "") {
      this.setState({ error: "Please Enter Guardian Name" });
      return false;
    } else if (this.state.mobileno === "") {
      this.setState({ error: "Please Enter Valid Guardian Mobile Number" });
      return false;
    } else if (!this.state.relation) {
      this.setState({ error: "Please Select Relation" });
      return false;
    }

    return true;
  };

  validate = (value) => {
    if (!value.match(/^[0-9a-zA-Z]+$/)) {
      // alert("Spl charater not allowed");
      this.setState({ error: "Special character are not allowed" });

      return false;
    }
    return true;
  };

  submit = () => {
    if (this.isValid()) {
      this.props.showPasswordOverlay(true, (isMatched) => {
        if (isMatched) {
          this.props.addGuardian(
            this.state.image,
            this.state.name,
            this.state.mobileno,
            this.state.relation,
            (response) => {
              //console.log(response);
              this.props.setNavigationParams({});

              this.props.history.replace("/guardian");
            }
          );
        }
      });
    }
  };

  updateSubmit = () => {
    if (this.isValid()) {
      this.props.showPasswordOverlay(true, (isMatched) => {
        if (isMatched) {
          this.props.updateGuardianByGuardianId(
            this.state.image,
            this.props.navParams.guardian_id,
            this.state.name,
            this.state.mobileno,
            this.state.relation,
            (response) => {
              //console.log(response);

              this.props.setNavigationParams({});

              this.props.history.replace("/guardian");
            }
          );
        }
      });
    }
  };

  render() {
    const item = this.props.navParams;
    //console.log("item", item.guardian_id);

    return (
      // <InnerHOC heading="Add Guardian">
      <InnerHOC heading={_.isEmpty(item) ? "Add Guardian" : "Edit Guardian"}>
        <Row>
          <Col className="col-md-12">
            <Card className="border-0 shadow">
              <CardBody>
                <Row>
                  <Col sm="12" md="12" lg="12" xl="5">
                    <div style={{ position: "relative" }}>
                      <img
                        id="guardian_img"
                        src={
                          _.isEmpty(item) ? defaultImg : item.guardian_picture
                        }
                        alt="imageUpload"
                        className=" mx-auto d-block rounded-circle mb-3"
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "150px",
                          width: "150px",
                          border: "4px solid #22c1e0",
                          objectFit: "cover",
                        }}
                      />
                      <EditComponent
                        id="guardian"
                        onImagePicked={(event) => {
                          this.props.showCropModal(
                            event.target.files[0],
                            (response) => {
                              // console.log("response", response);
                              this.setState({
                                image: response,
                                error: undefined,
                              });
                              document.getElementById(
                                "guardian_img"
                              ).src = response;
                            }
                          );
                        }}
                      />{" "}
                    </div>
                  </Col>
                  <Col sm="12" md="12" lg="12" xl="7">
                    <Form>
                      <FormGroup>
                        <Label>Name</Label>
                        <span style={{ color: "red" }}>*</span>
                        <FootprintInputText
                          type="text"
                          id="fp_name"
                          placeholder="Please enter name "
                          defaultValue={this.state.name}
                          onChange={(value) => {
                            this.setState({ name: value, error: undefined });
                          }}
                          name="text"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Relation</Label>
                        <span style={{ color: "red" }}>*</span>

                        <FootprintDrop
                          id="fp_drop"
                          data={this.props.constants.relationToChild}
                          defaultValue={this.state.relation}
                          onChange={(value) => {
                            this.setState(
                              {
                                relation: value,
                                error: undefined,
                              },
                              () => { }
                            );
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Mobile number</Label>
                        <span style={{ color: "red" }}>*</span>

                        <FootprintInputText
                          maxLength={10}
                          id="fp_mobile"

                          type="number"
                          placeholder="Enter mobile no"
                          defaultValue={this.state.mobileno}
                          onChange={(value) => {
                            this.setState({
                              mobileno: value,
                              error: undefined,
                            });
                          }}
                          name="text"
                        />
                      </FormGroup>
                      {typeof this.state.error !== "undefined" && (
                        <p
                          style={{
                            fontSize: 14,

                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {this.state.error}
                        </p>
                      )}
                      <FormGroup className="text-center mb-0">
                        <FootprintInputButton
                        style={{ color: "white" }}
                          className="btn-lg border-0"
                          onClick={() => {
                            _.isEmpty(item)
                              ? this.submit()
                              : this.updateSubmit();
                          }}
                        >
                          Submit
                        </FootprintInputButton>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </InnerHOC>
    );
  }
}
const mapStateToProps = (state) => ({
  constants: state.constants,
  navParams: state.global.navigation.params,
});

const mapDispatchToProps = (dispatch) => ({
  showCropModal: (event, onSuccess) =>
    dispatch(showCropModal(event, onSuccess)),
  addGuardian: (image, name, mobileno, relation, onSuccess) =>
    dispatch(addGuardian(image, name, mobileno, relation, onSuccess)),
  updateGuardianByGuardianId: (
    image,
    guardianId,
    guardianName,
    mobileNo,
    relation,
    onSuccess
  ) =>
    dispatch(
      updateGuardianByGuardianId(
        image,
        guardianId,
        guardianName,
        mobileNo,
        relation,
        onSuccess
      )
    ),
  setNavigationParams: (params) => dispatch(setNavigationParams(params)),
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddGuardian);
