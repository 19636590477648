import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
import { setNavigationParams, freeBeeLogin } from "../../global/redux/action";
import { Card, Button, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Browser } from '@capacitor/browser';

class Finance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    console.log("[Finance] Request for Finance Services");
    window.scrollTo(0, 0)
  }

  goToInvoice = () => {
    const childId = this.props.childId;
    const invoiceUrl =
      this.props.constants.PaymentCheckUrl + "?child_id=" + childId;
    window.open(invoiceUrl, "_blank", "location=no,closebuttoncaption=Back");
  };
  openCapacitorSite = async (url) => {
    // console.log("In Browser")
    await Browser.open({ url: url });
  };
  gotToFinance = () => {
    this.props.freeBeeLogin((data) => {
      this.openCapacitorSite(data.response);
      // window.open(data.response, "_blank", "location=no,closebutton=Back");
    });
  };
  render() {
    const { innerHeight: height } = window;
    let headerStyle = height > 915 ? {
      background: "#00C853",
      color: "#fff",
      fontFamily: "Baloo",
    } : {
      background: "#00C853",
      color: "#fff",
      fontFamily: "Baloo",
      fontWeight: 'normal'
    }
    return (
      <div>
        <Card className="border-0 shadow">
          <CardHeader
            style={{
              background: "#A500F8",
              color: "#fff",
              fontFamily: "Baloo",
            }}
          >
            Finance
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" sm="12" lg="6" className="mb-3">
                <Card className="mb-4 h-100 border-0 shadow">
                  <CardHeader
                    className="h6"
                    style={headerStyle}
                  >
                    Why Credit adjustment did not happen?
                  </CardHeader>
                  <CardBody>
                    <CardTitle>
                      {this.props.constants.CreditsAdjustedInvoice}
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" sm="12" lg="6" className="mb-3">
                <Card className="mb-4 h-100 border-0 shadow">
                  <CardHeader
                    className="h6"
                    style={{ ...headerStyle, background: '#F44A4A', color: '#fff' }}
                  >
                    Stop Reminders
                  </CardHeader>
                  <CardBody>
                    <CardTitle>
                      {this.props.constants.StopRemindersText}
                    </CardTitle>
                    <Row>
                      <Col
                        xs="12"
                        sm="6"
                        md="12"
                        lg="12"
                        className="mb-3 mb-lg-0"
                      >
                        <Button
                          className="btn-outline-danger btn-block"
                          onClick={() => {
                            this.props.history.push("raiseAnIssue");
                          }}
                        >
                          Already Withdrawn.
                        </Button>
                      </Col>
                      <Col xs="12" sm="6" md="12" lg="12">
                        <Button
                          className="btn-outline-success btn-block"
                          onClick={() => {
                            this.goToInvoice();
                          }}
                        >
                          Already Paid
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="6" md="6" sm="6" lg="3" className="my-3">
                <Link to='#'>
                  <Card
                    outline
                    color="primary"
                    className="h-100 shadow"
                    onClick={() => {
                      this.gotToFinance();
                    }}
                  >
                    <CardBody className="text-center p-2">
                      <i
                        className="icon-invoice-view"
                        style={{ fontSize: "50px", color: "#696969" }}
                      ></i>
                      <CardTitle
                        style={{ color: "#21C1E0", fontFamily: "Baloo" }}
                      >
                        View Invoices
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              <Col xs="6" md="6" sm="6" lg="3" className="my-3">
                <Link to='#'>
                  <Card
                    outline
                    color="success"
                    className="h-100 shadow"
                    onClick={() => this.goToInvoice()}
                  >
                    <CardBody className="text-center p-2">
                      <i
                        className="icon-pay-invoice"
                        style={{ fontSize: "50px", color: "#696969" }}
                      ></i>
                      <CardTitle
                        style={{ color: "#21C1E0", fontFamily: "Baloo" }}
                      >
                        Pay Invoices
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col xs="6" md="6" sm="6" lg="3" className="my-3">
                <Link to='#'>
                  <Card
                    outline
                    color="info"
                    className="h-100 shadow "
                    onClick={() => {
                      //this.props.setNavigationParams();
                      this.props.history.push("feeCertificate");
                    }}
                  >
                    <CardBody className="text-center p-2">
                      <i
                        className="icon-fee-certificate"
                        style={{ fontSize: "50px", color: "#696969" }}
                      ></i>
                      <CardTitle
                        style={{ color: "#21C1E0", fontFamily: "Baloo" }}
                      >
                        Fee Certificate
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col xs="6" md="6" sm="6" lg="3" className="my-3">
                <Link to='#'>
                  <Card
                    outline
                    color="danger"
                    className="h-100 shadow"
                    onClick={() => {
                      this.props.history.push("nonSatisfaction");
                    }}
                  >
                    <CardBody className="text-center p-2">
                      <i
                        className="icon-refund"
                        style={{ fontSize: "50px", color: "#696969" }}
                      ></i>
                      <CardTitle
                        style={{ color: "#21C1E0", fontFamily: "Baloo" }}
                      >
                        Non Satisfaction Refund
                      </CardTitle>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  childId: state.userState.user.activeChildDetail.id,

  constants: state.constants,
});

const mapDispatchToProps = (dispatch) => ({
  setNavigationParams: (params) => dispatch(setNavigationParams(params)),
  freeBeeLogin: (onSuccess) => dispatch(freeBeeLogin(onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Finance);