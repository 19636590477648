import {
  fetchPhotosSuccess,
  updatePhotoPresence,
  FETCH_PHOTOS
} from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import fetchAPI from "../../../global/utils/fetch";
import { createLogic } from "redux-logic";
import { PHOTO_LIMIT } from "./reducer";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchPhotosLogic = createLogic({
  type: FETCH_PHOTOS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('PHOTOGALLERY', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getConversationList`;
      // need to change url for staging and production
      const body = {
        type: "photos",
        limit: PHOTO_LIMIT,
        index_start: action.payload.startIndex
      };
      console.log("[Photo Gallery] Calling API: /parentapp/getConversationList, payload: ", body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Photo Gallery] Received Response: ", response);
          if (response.status === "ok") {
            dispatch(
              fetchPhotosSuccess(
                response.response.photoList,
                action.payload.startIndex
              )
            );
            dispatch(setLoading(false));
            dispatch(onErrLoading("PHOTOGALLERY", false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {

              done();
            }
          } else if (response.status === "error") {
            dispatch(updatePhotoPresence(false));
            dispatch(fetchPhotosSuccess([], action.payload.startIndex));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('PHOTOGALLERY', false))
            done();
          }
        },
        error => {
          console.log("[Photo Gallery] Error: ", error);
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('PHOTOGALLERY', false))
          setContext("Photo Gallery Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Photo Gallery] Error: ", error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('PHOTOGALLERY', false))
      setContext("Photo Gallery Error", error);
      done();
    }
  }
});

export default [fetchPhotosLogic];
