export const OUTDOOR_ACTIVITY = "OUTDOOR_ACTIVITY";
export const FETCH_OUTDOOR_ACTIVITY_SUCCESS = "FETCH_OUTDOOR_ACTIVITY_SUCCESS";
export const POST_EVENT_ACTION_REQUIRED = "POST_EVENT_ACTION_REQUIRED";

export const getOutDoorActivity = payload => ({
  type: OUTDOOR_ACTIVITY,
  payload
});
export const getOutDoorActivitySuccess = payload => ({
  type: FETCH_OUTDOOR_ACTIVITY_SUCCESS,
  payload
});

export const outdooreventActionReq = (eventId, type, onSuccess) => ({
  type: POST_EVENT_ACTION_REQUIRED,
  payload: { eventId, type, onSuccess }
});
