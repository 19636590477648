import React from "react";
// import { connect } from 'react-redux';
import ReactDatetime from "react-datetime";
import moment from "moment";
export const DEFAULT_FORMAT = "MMM DD, YYYY";

class FootprintDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      placeholder = "Select Date",
      defaultDate,
      timeFormat = false,
      minimumDate,
      maximumDate,
      disabled,
      // readOnly,
      inputProps,
      onChange = () => {},
    } = this.props;

    return (
      <ReactDatetime
        onFocus={() => this.setState({ open: undefined })}
        open={this.state.open}
        value={
          this.state.selectedDate
            ? moment(this.state.selectedDate).format(DEFAULT_FORMAT)
            : defaultDate
            ? moment(defaultDate).format(DEFAULT_FORMAT)
            : ""
        }
        inputProps={{
          ...inputProps,
          readOnly: true,
          style: {
            fontSize: "18px",
            background: inputProps?.disabled ? "#dddddd" : "white",
          },
        }}
        disabled={disabled}
        viewDate={
          minimumDate ? moment(minimumDate).toDate() : moment().toDate()
        }
        placeholder={placeholder}
        isValidDate={(current) =>
          (minimumDate ? current >= minimumDate : true) &&
          (maximumDate ? current <= maximumDate : true)
        }
        onChange={(date) => {
          this.setState({ open: false });
          if (
            this.props.holidaySelected &&
            (moment(date).day() === 0 || moment(date).day() === 6)
          ) {
            this.props.holidaySelected(true);
          } else {
            this.setState({ selectedDate: date });
            onChange(date);
          }
          // this.setState({ fromDate: date });
        }}
        readOnly={true}
        timeFormat={timeFormat}
      />
    );
  }
}

export default FootprintDatePicker;
