import React from "react";
import { connect } from "react-redux";
//import { addAdmissionDetail } from "../../features/MyProfile/redux/action";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Button,
  CardBody,
} from "reactstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { showCropModal } from "../redux/action";
class FootprintCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      src: undefined,
      crop: {
        unit: "%",
        width: 30,
        aspect: 16 / 16,
      },
      open: false,
    };
  }

  pickImage = (event) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState({ src: reader.result })
    );
    reader.readAsDataURL(event);
  };

  componentDidUpdate(prevProps) {
    //console.log("this.props.modal", this.props.modal);
    //this.setState({ showModal: true });
    if (this.props.modal !== prevProps.modal) {
      if (this.state.showModal === false) {
        if (
          this.props.modal &&
          this.props.modal.event &&
          this.props.modal.onSuccess
        ) {
          this.pickImage(this.props.modal.event);
          this.setState({ showModal: true });
        }
      }
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
      //console.log("crop", this.state.croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    let uri = canvas.toDataURL("ímage/png");
    // let b64 = uri.split("base64,")[1];
    let b64 = uri.replace(/ˆdata:image.+;base64,/, "");
    //console.log("split", b64);
    return b64;
  }
  render() {
    // const { modal } = this.props;

    const { crop, src } = this.state;

    return (
      <Modal isOpen={this.state.showModal} className="border-red">
        <ModalHeader style={{ fontFamily: "Baloo", color: "#fff" }}>
          Selected Image
          {/* Selected Image : {typeof modal.event} */}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              {src && (
                <div>
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
              )}
              <div>
                <Button
                  onClick={() => {
                    if (this.props.modal && this.props.modal.onSuccess) {
                      this.props.modal.onSuccess(this.state.croppedImageUrl);

                      this.setState({ showModal: false });
                    }
                  }}
                >
                  Crop
                </Button>

                <Button
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.global.showCropImageModal,
});

const mapDispatchToProps = (dispatch) => ({
  // showCropModal: (event, onSuccess) => dispatch(showCropModal(event, onSuccess))
  showModal: () => dispatch(showCropModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FootprintCropper);
