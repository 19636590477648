import React from "react";
import { connect } from "react-redux";
import { fetchActivity } from "./redux/action";
import moment from "moment";
import ActivityCalenderItem from "./item";
import { Row, Col } from "reactstrap";
// react plugin used to create datetimepicker
import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import { Card, CardHeader, CardBody } from "reactstrap";
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import Theme from "../../theme";
import { setContext } from "../../global/utils/crashlyticsMethods";

class ActivityCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment()
    };
  }

  async componentDidMount() {
    console.log("[Activity Calendar] Fetching calendar information");
    await this.props.fetchActivity(moment());
    setContext("Activity Calendar", this.props.dailyFeed);
  }

  render() {
    const { dailyFeed, loading } = this.props;
    return (
      <div className="commonFeature">
        <Card className="border-0 shadow border-purple">
          <CardHeader
            style={{
              background: Theme.colorPrimary,
            }}
          >
            <Row>
              <Col xs="12" md="6" className="my-auto">
                {/*{moment(this.state.selectedDate).format("DD MMMM, YYYY")} */}
                <div
                  style={{
                    color: "white",
                    fontFamily: "baloo",
                    fontSize: "1.3em",
                  }}
                >
                  Activity Calender
                </div>
              </Col>
              <Col xs="12" md="6">
                <FormGroup className="mb-0">
                  <InputGroup
                    className="input-group-alternative"
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="m-0 p-0 pl-2">
                        <i
                          className="icon-calender"
                          style={{ fontSize: "28px" }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <FootprintDatePicker
                      defaultDate={this.state.selectedDate}
                      onChange={(date) => {
                        this.setState({ selectedDate: date });
                        this.props.fetchActivity(date);
                      }}
                      timeFormat={false}
                      inputProps={{style: {fontSize: "18px"} }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="activity-timeline">
            {Array.isArray(dailyFeed) && dailyFeed.length > 0 && (
              <div
                className="timeline timeline-one-side"
                data-timeline-axis-style="dashed"
                data-timeline-content="axis"
              >
                {dailyFeed.map((item, index) => (
                  <ActivityCalenderItem item={item} index={index} key={index} />
                ))}
              </div>
            )}
            {!Array.isArray(dailyFeed) ||
              (dailyFeed.length === 0 && (
                <ListEmptyComponent
                  heading={loading ? "Loading..." : "No Data Found"}
                />
              ))}
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dailyFeed: state.actvityCalender.dailyFeed,
  loading: state.actvityCalender.loading
});

const mapDispatchToProps = dispatch => ({
  fetchActivity: (date, onSuccess) => dispatch(fetchActivity(date, onSuccess))
});
export default connect(mapStateToProps, mapDispatchToProps)(ActivityCalendar);
