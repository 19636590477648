import { FETCH_CHILD_TIMELINE_SUCCESS, UPDATE_DATA_PRESENCE } from "./action";
import { transformTimeLine } from "./transformTimeline";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = {
  timeline: [],
  dataAvailable: true,
  sectionedTimeLine: [],
  loading: true
};
const childTimelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHILD_TIMELINE_SUCCESS: {
      try {
        const { conversationList, startIndex } = action.payload;

        let totalTimeline = conversationList;
        if (startIndex > 0) {
          totalTimeline = state.timeline.concat(conversationList);
        }

        // 
        return {
          ...state,
          timeline: totalTimeline,
          sectionedTimeLine: transformTimeLine(totalTimeline),
          dataAvailable: conversationList.length > 0,
          loading: false
        };
      } catch (error) {
        console.log("[ChildTimeline Reducer] Error: ",error);
        return state;
      }
    }


    case UPDATE_DATA_PRESENCE: {
      return {
        ...state,
        dataAvailable: action.payload,
      }
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }

    case SET_COMPONENT_LOADING + "CHILDTIMELINE": {
      return {
        ...state,
        loading: action.value
      }
    }
    case LOGOUT: {
      return initialState
    }


    default: {
      return state;
    }
  }
};
export default childTimelineReducer;
