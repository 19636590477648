import React from "react";
import { connect } from "react-redux";
import { logout, verifyOTP } from "../../features/Login/redux/action";
import { App } from "@capacitor/app";
import { setLoading, showModal } from "../redux/action";
import { generateAuthToken } from "./fetch";

// export default ReloadData;
class ReloadData extends React.Component {
  state = {
    timeoutId: null,
  };
  timeOut = null;

  componentDidMount() {
    App.addListener("appStateChange", async (state) => {
        if (state.isActive) {
            // console.log("if state is active")
            // clearTimeout(this.state.timeoutId);
          // this.fetchData();
            this.reloadFunctions();
          }
          });
        this.reloadFunctions();
    // console.log("Props => ",this.props)
  }

  reloadFunctions() { 
    if (!this.check_date(new Date(localStorage.getItem("last_reload")))) {
      localStorage.setItem("auth_token",generateAuthToken());
      this.fetchData();
    } else {
      let survey = localStorage.getItem("survey_status");
      if (survey) {
        // console.log("Redirecting to survey ");
        window.location.href = `survey/${survey}`;
      }
    }
  }

  check_date(date) {
  const givenDate = new Date(date);
  const today = new Date();
  givenDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  return givenDate.getTime() === today.getTime();
}

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  fetchData() {
    // console.log("User: ", this.props.user);
    let user = localStorage.getItem("auth_token");
    // console.log("User: ", user);
    if (user) {
      this.props.verifyOTP("999999", false, true, (response) => {
         localStorage.setItem("auth_token", response.auth_token);
         localStorage.setItem("last_reload", response.time);
        // console.log("Response => ", response);
        if (response && response.survey && response.survey.survey_id > 0) {
          localStorage.setItem("survey_status", response.survey.survey_id);
          // console.log("Redirecting to survey ");
          window.location.href = `survey/${response.survey.survey_id}`;
        }
        // console.log("Success => ", response.eventStatus);
        if (response && response.eventStatus > 0) {
          this.props.showModal('Ok', 'A new event has been scheduled, Click ok to view event details.', true, () => { this.props.history.replace('/outdoorActivity') }, ()=>{})
        }
      }, async () => {
        this.props.loading(true);
        await this.props.logout();
        await App.removeAllListeners();
        localStorage.setItem("auth_token", "");
        this.props.history.replace('/');
      });
    } else {
      // console.log("Else Logout")
        this.props.logout();
        App.removeAllListeners();
        this.props.history.replace("/");
    }
  }
  render() {
    return null;
  }
}

const mapStateToProps = ({userState}) => {
    const activeChildDetail =
        userState?.user && userState.user?.activeChildDetail;
    return {
        user: activeChildDetail,
    };
};

const mapDispatchToProps = (dispatch) => ({
    verifyOTP: (otp, verify, reload, onSuccess, onError) =>
    dispatch(verifyOTP(otp, verify, reload, onSuccess, onError)),
    logout: () => dispatch(logout()),
  loading: (load) => dispatch(setLoading(load)),
  showModal: (type, msg, isConfirm, onSuccess, onCancel) => dispatch(showModal(type, msg, isConfirm, onSuccess, onCancel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReloadData);
