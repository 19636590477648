const style = {
  cardView: {
    boxShadow: "0 17px 23px -13px #E6E6E6",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderColor: "#fff"
  },
  cardChild: {
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    objectFit: 'cover'
  },
  button: {
    backgroundColor: "#2cc1e0",
    borderColor: "#30afca",
    borderRadius: "30px"
  },
  input: {
    borderRadius: "30px"
  },
  label: {
    fontSize: "14px"
  }
};

export default style;
