import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import Loading from "../../global/utils/Loading";
import { FaAngleDown } from "react-icons/fa";

// import { AiOutlineUsergroupAdd } from "react-icons/ai";

// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import Blogs from "../../features/Blogs";
import MyProfile from "../../features/MyProfile";
import MyCenter from "../../features/MyCenter";
import Cctv from "../../features/Cctv";
import ActivityCalendar from "../../features/ActivityCalendar";
import Conversation from "../../features/Conversation";
import FoodMenu from "../../features/FoodMenu";
import Growth from "../../features/Growth";
import News from "../../features/News/index";
import PhotoGallery from "../../features/PhotoGallery";
import Holidays from "../../features/Holidays";
import Support from "../../features/Support";
import OutdoorActivity from "../../features/OutdoorActivity";
import { withRouter } from "react-router-dom";
import className from "classnames";
import ChildTimeline from "../../features/ChildTimeline";
import ChangeLoginChildOverlay from "../ChangeLoginChildOverlay";
// import Guardian from "../../features/Guardian/index";
import { logout } from "../../features/Login/redux/action";
import ParentVideos from "../../features/ParentOrientationVedio";
import { showModal } from "../redux/action";
import manifest from "../../manifest.json";
import { getFullPath } from "../../features/MyProfile/index";
import { GoPrimitiveDot } from "react-icons/go";
import Theme from "../../theme";
import AttendanceList from "../../features/Attendance";
export const draweItems = [
  {
    name: "Home",
    icon: "icon-home",
    path: "blogs",
    component: Blogs,
  },

  {
    name: "Child Timeline",
    icon: "icon-timeline",
    path: "timeline",
    component: ChildTimeline,
  },
  {
    name: "My Profile",
    icon: "icon-user",
    path: "myProfile",
    component: MyProfile,
  },
  /*{
    name: "Guardian Management",
    component: Guardian,
    icon: <AiOutlineUsergroupAdd style={{ fontSize: 20 }} />,
    path: "guardian",
  },*/
  {
    name: "Attendance",
    component: AttendanceList,
    icon: "icon-holiday-calender",
    path: "attendance",
  },
  {
    name: "My Center",
    icon: "icon-admission",
    component: MyCenter,
    path: "center",
  },
  {
    name: "Live CCTV",
    icon: "icon-video",
    component: Cctv,
    path: "cctv",
  },
  {
    name: "Conversation",
    icon: "icon-chat",
    component: Conversation,
    path: "chat",
  },
  {
    name: "Activity Calendar",
    icon: "icon-holiday-calender",
    component: ActivityCalendar,
    path: "activityCalendar",
  },
  {
    name: "Food Menu",
    icon: "icon-meal",
    component: FoodMenu,
    path: "foodMenu",
  },
  {
    name: "Growth",
    icon: "icon-growth",
    component: Growth,
    path: "growth",
  },
  {
    name: "News Room",
    icon: "icon-newspaper",
    component: News,
    path: "newsRoom",
  },
  {
    name: "Outdoor Activity",
    icon: "icon-outdoor-activity",
    component: OutdoorActivity,
    path: "outdoorActivity",
  },
  {
    name: "Photo Gallery",
    icon: "icon-photo-gallery-2",
    component: PhotoGallery,
    path: "photoGallery",
  },
  {
    name: "Parent Orientation Videos",
    icon: "icon-video-group-icon-2",
    component: ParentVideos,
    path: "parentVideo",
  },

  {
    name: "Holiday Calendar",
    icon: "icon-calender",
    component: Holidays,
    path: "holidayCalendar",
  },
  {
    name: "Support",
    component: Support,
    icon: "icon-support",
    path: "support",
  },
];

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  isMultipleChild = () => {
    return (
      Array.isArray(this.props.user.childList) &&
      this.props.user.childList.length > 1
    );
  };

  showChildChangeOption = () => {
    this.setState({ showOverLay: true }, () => { });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  render() {
    //const { loggedInChild } = this.props;

    // const child =
    //   loggedInChild.name +
    //   " " +
    //   (loggedInChild.last_name ? loggedInChild.last_name : "");

    const logo = {
      imgAlt: "Footprints-Logo",
      imgSrc: require("../../assets/Footprints-Logo.svg"),
    };
    let navbarBrandProps;
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light mobile-fixes"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("notification");
                  }}
                >
                  <i
                    className="icon-notification"
                    style={{ color: "#777", fontSize: "1.3em" }}
                  />
                  {this.props.showBell && (
                    <GoPrimitiveDot
                      style={{
                        color: Theme.danger,
                        position: "relative",
                        right: 19,
                        top: -13,
                      }}
                    />
                  )}
                </span>
              </DropdownToggle>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media
                  style={{
                    cursor: this.isMultipleChild() ? "pointer" : "default",
                  }}
                  className="align-items-center"
                >
                  {this.props.profilePicPresent ? (
                    this.props.childList.map((ele, index) => (
                      <span
                        key={index}
                        className="avatar avatar-sm rounded-circle"
                        onClick={() => {
                          if (this.isMultipleChild()) {
                            this.props.changeActiveChild(ele);
                            // this.showChildChangeOption();
                          } else {
                          }
                        }}
                        style={
                          index !== 0
                            ? {
                                backgroundImage:
                                  "url" +
                                  "(" +
                                  getFullPath(
                                    this.props.constants,
                                    ele.child_photo
                                  ) +
                                  ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                transform: "translate(-20px,0)",
                                zIndex: 9,
                              }
                            : {
                                backgroundImage:
                                  "url" +
                                  "(" +
                                  getFullPath(
                                    this.props.constants,
                                    ele.child_photo
                                  ) +
                                  ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                zIndex: 10,
                              }
                        }
                      ></span>
                    ))
                  ) : (
                    <span
                      className="avatar avatar-sm rounded-circle"
                      onClick={() => {
                        if (this.isMultipleChild()) {
                          this.showChildChangeOption();
                        } else {
                          //alert("single child");
                        }
                      }}
                      style={{
                        backgroundImage:
                          "url(" + require("../../assets/left-nico.png") + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></span>
                  )}

                  {this.isMultipleChild() && <FaAngleDown color="black" />}
                </Media>
              </DropdownToggle>
            </UncontrolledDropdown>
          </Nav>

          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {<img alt={logo.imgAlt} src={logo.imgSrc} />}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>
              {/* {this.createLinks(routes)} */}
              {draweItems.map((item, index) => (
                <li key={index} className="nav-item">
                  {
                    <Link
                      to={`/${item.path}`}
                      onClick={() => {
                        this.closeCollapse();
                      }}
                      className={className({
                        "nav-link": true,
                        active:
                          this.props.location &&
                          this.props.location.pathname.indexOf(item.path) > -1,
                      })}
                    >
                      {typeof item.icon === "string" && (
                        <i
                          className={`${item.icon} text-center`}
                          style={{ fontSize: "20px" }}
                        />
                      )}
                      {typeof item.icon === "object" && (
                        <span style={{ marginLeft: 5, marginRight: 5 }}>
                          {item.icon}
                        </span>
                      )}
                      <span style={{ fontFamily: "Baloo" }}>{item.name}</span>
                    </Link>
                  }
                </li>
              ))}
              <li key="Logout" className="nav-item">
                {
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.props.showModal(
                        "Success",
                        "Are You Sure You Want To Logout ?",
                        true,
                        () => {
                          this.props.logout();
                          this.props.history.replace("/");
                        },
                        () => {}
                      );
                    }}
                    className="nav-link"
                  >
                    <i
                      className={`icon-signout text-center`}
                      style={{ fontSize: "20px" }}
                    />
                    <span style={{ fontFamily: "Baloo" }}>Logout</span>
                  </span>
                }
              </li>
            </Nav>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6
                style={{ fontFamily: "baloo", fontSize: 14 }}
              >{`${manifest.applicationName} v${manifest.version}`}</h6>
            </div>
          </Collapse>
        </Container>

        {/* <Loading /> */}
        {
          <ChangeLoginChildOverlay
            isOpen={this.state.showOverLay}
            toggle={(isOpen) => {
              this.setState({
                showOverLay: isOpen,
              });
            }}
            history={this.props.history}
          />
        }
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  //
  const activeChildDetail =
    state.userState.user && state.userState.user.activeChildDetail;
  const photo =
    state.userState.user &&
    state.userState.user.activeChildDetail &&
    activeChildDetail.child_photo;
  const childList = state.userState?.user?.childList;
  // console.log("Child list1: " + childList);
  return {
    user: state.userState.user,

    children: state.userState.user.childList,
    loggedInChild: state.userState.user.activeChildDetail,
    profilePicPresent: typeof photo === "string" && photo !== "",
    childPhotoApprovedStatus:
      activeChildDetail && activeChildDetail.child_approved_status,
    childPhoto:
      state.userState.user.activeChildDetail &&
      state.userState.user.activeChildDetail.child_photo,
    constants: state.constants,
    showBell: state.global.showBell,
    childList:childList
  };
};
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
    dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
//export default withRouter(Sidebar);
