import { createLogic } from "redux-logic";
import { FETCH_NEWS, fetchNewsSuccess } from "./action";
//import axios from "axios";
import fetchAPI from "../../../global/utils/fetch";

import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

const fetchNewsLogic = createLogic({
  type: FETCH_NEWS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    dispatch(onErrLoading('NEWS', true))
    try {
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getNewLetters`;
      /* const body = {
        center_id: 4,
        room_id: 19
      };*/
      console.log("[News] Calling API: /parentapp/getNewLetters");
      const fetchObject = {
        url,
        //body,
        method: "POST",
        getState,
        dispatch,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[News] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchNewsSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchNewsSuccess([]));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('NEWS', false))
            done();
          }
        },
        error => {
          console.log("[News] Error: ",error);
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('NEWS', false))
          setContext("News Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[News] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('NEWS', false))
      setContext("News Error", error);
      done();
    }
  }
});

export default [fetchNewsLogic];
