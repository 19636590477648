import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import { setContext } from "../../../global/utils/crashlyticsMethods";
import { fetchPastRequest, getRequestType } from "../../Support/redux/action";
import { setError, setLoading } from "../../../global/redux/action";
import { CANCEL_EXTENDED_DAYCARE, EXTEND_DAYCARE } from "./action";
import Constants from "../../../constants";

export const extendDaycareRequest = createLogic({
    type: EXTEND_DAYCARE,
    latest: true,

    async process({ action, getState }, dispatch, done) {
        try {
            // console.log("Extending")
        const parentName =
            getState().userState?.parentProfile?.parentName.toLowerCase();
            dispatch(setLoading(true));
            // console.log("Name: " + parentName);
        const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/request`;
        const body = {
          date: moment(action.payload.selectedDate).format(SERVER_DATE_FORMAT),
          end_date: moment().format(SERVER_DATE_FORMAT),
          attrition_reason: action.payload.selectedType,
            extended_daycare: "yes",
          shift_center:"no",
          values: ["extended_daycare","shift_center"],
          // password: getState().userState.password,
          parent_name: parentName,
        };
        console.log("[Extend Daycare] Calling Api: /parentapp/request, payload: ", body);
            
        const fetchObject = {
            url,
            method: "POST",
            getState,
            successReportType: "Alert",
            errorReportType: "Alert",
            dispatch,
            body,
            authenticatedAPI: false,
            done,
            action,
        };
            // console.log("Extending Object",fetchObject);

        fetchAPI(fetchObject).then(
          (response) => {
            console.log("[Extend Daycare] Received Response: ",response);
            if (response.status === "ok") {
                dispatch(getRequestType());
                dispatch(fetchPastRequest());
                dispatch(setLoading());
                if (action.payload && action.payload.onSuccess) {
                    action.payload.onSuccess();
                    done();
                } else {
                    done();
                }
            } else if (response.status === "error") {
                dispatch(setError(response.error, response.error));
                done();
            }
            },
          (error) => {
                console.log("[Extend Daycare] Error: ",error);
                dispatch(setLoading(false));
                setContext("Extended Daycare Error", error);
                done();
            }
        );
        } catch (error) {
            console.log("[Extend Daycare] Error: ",error);
            dispatch(setError("Some Error Occured", error.message));
            dispatch(setLoading(false));
            setContext("Extended Daycare Error", error);
            done();
        }
    },
});

export const cancelExtendDaycareRequest = createLogic({
  type: CANCEL_EXTENDED_DAYCARE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/financials/cancelCustomerRequest`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_id: action.payload.request_id,
        end_date: action.payload.end_date,
        updated_by_name: getState().userState.parentProfile.parentName,
        updated_by: 0,
      };
      console.log("[Cancel Extend Daycare] Calling Api: /financials/cancelCustomerRequest, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        body,
        authenticatedAPI: false,
        done,
        action,
      };

      fetchAPI(fetchObject).then(
        (response) => {
          console.log("[Cancel Extend Daycare] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        (error) => {
          console.log("[Cancel Extend Daycare] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Cancel Extended Daycare Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Cancel Extend Daycare] Error: ",error);
      dispatch(setError("Some Error Occurred", error.message));
      dispatch(setLoading(false));
      setContext("Cancel Extended Daycare Error", error);
      done();
    }
  },
});

export default [extendDaycareRequest, cancelExtendDaycareRequest];
