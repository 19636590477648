import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  CardTitle,
  Row,
  Col,
  FormGroup,
  CardHeader,
  CardBody
} from "reactstrap";
import { changeTransport } from "./redux/action";
import { showModal, showPasswordOverlay } from "../../global/redux/action";
import DefaultConstants from "../../defaultConstants";
// import DefaultConstants from "../../defaultConstants";

class Transport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  submit = type => {
    // if (this.validate()) {
    // this.props.showPasswordOverlay(true, isMatched => {
    //   if (isMatched) {
    this.props.showModal('success', 'Select Date',true, (date) => {
      this.props.changeTransport(type === 1, date, () => {
        this.props.history.replace("pastRequestTab");
      })
    })
    
    // });
  };

  render() {
    console.log("[Transport] Showing transport data.");
    const { Transport1, Transport2 } = this.props.tabs;
    return (
      <div>
        <Card className="border-0 shadow  card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em",
            }}
          >
            Transport
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12">
                <div style={{ marginTop: 15, backgroundColor: "#d9edf7" }}>
                  <p style={{ fontSize: "15px", padding: 10 }}>
                    {this.props.constants.transportTitle || DefaultConstants.transportTitle}
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <Link to="#">
                  <Card
                    style={{ marginTop: "5px" }}
                    body
                    className="shadow border-0 text-center bg-success text-white"
                    onClick={() => {
                      this.submit(1);
                    }}
                  >
                    <i
                      className="icon-transport"
                      style={{ fontSize: "65px", color: "#fff" }}
                    ></i>
                    <CardTitle
                      style={{ fontFamily: "Baloo", fontSize: "25px" }}
                    >
                      {Transport1 ? Transport1 : "Start Transport 1 Way"}
                    </CardTitle>
                  </Card>
                </Link>
              </Col>
              <Col sm="6">
                <Link to="#">
                  <Card
                    body
                    style={{ marginTop: "5px" }}
                    className="shadow border-0 text-center bg-purple text-white"
                    onClick={() => {
                      this.submit(2);
                    }}
                  >
                    <i
                      className="icon-transport"
                      style={{ fontSize: "65px", color: "#fff" }}
                    ></i>
                    <CardTitle
                      style={{ fontFamily: "Baloo", fontSize: "25px" }}
                    >
                      {Transport2 ? Transport2 : "Start Transport 2 Way"}
                    </CardTitle>
                  </Card>
                </Link>
              </Col>
            </Row>
            <FormGroup></FormGroup>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  constants: state.constants,
  tabs: state.support.requestType.serviceStatus,
});

const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  changeTransport: (oneWay, date, onSuccess) =>
    dispatch(changeTransport(oneWay, date, onSuccess)),
  showModal: (type, message, isConfirm, onOkPressed) =>
    dispatch(showModal(type, message,isConfirm , onOkPressed)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Transport);
