import { FETCH_FOOD_SUCCESS } from "./action";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = {
  food: {},
  loading: true
};
const foodReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOOD_SUCCESS: {
      return {
        ...state,
        food: { ...action.payload },
        loading: false
      };
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }
    case SET_COMPONENT_LOADING + 'FOOD': {
      return {
        ...state,
        loading: action.value
      }
    }
    case LOGOUT: {
      return initialState
    }

    default: {
      return state;
    }
  }
};
export default foodReducer;
