export const FETCH_TICKETS_SUCCESS = "FETCH_TICKETS_SUCCESS";

export const FETCH_TICKETS = "FETCH_TICKETS";

export const fetchTickets = payload => ({
  type: FETCH_TICKETS,
  payload
});

export const fetchTicketsSuccess = payload => ({
  type: FETCH_TICKETS_SUCCESS,
  payload
});

export const RAISE_AN_ISSUE = "RAISE_AN_ISSUE";
export const raiseAnIssue = (subject, description, onSuccess) => ({
  type: RAISE_AN_ISSUE,
  payload: {
    subject,
    description,
    onSuccess
  }
});
