import React from "react";
import { connect } from "react-redux";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Dashboard from "./features/Dashboard";
import SplashScreen from "./features/EntryComponent/SplashScreen";
import Login from "./features/EntryComponent/Login";
import WebView from "./features/Cctv/FootprintWebView";

import {
  fetchConfigSuccess,
  fetchConfig,
  setError,
} from "./global/redux/action";

import { signIn } from "./features/Login/redux/action";
// import drawerNavigator from './homeNavigator';

import { draweItems } from "./global/HOComponet/SideDrawer";
import { tabs } from "./features/Support/redux/transformRequest";
import RaiseIssue from "./features/RaiseIssue";
import ScrollToTop from "./global/utils/ScrollToTop";
import PastRequestTab from "./features/PastRequest/Component/Tabs";
import EarlyResume from "./features/Break/EarlyResume";
import ExtendPause from "./features/Break/ExtendPause";

import FeeCertificate from "./features/Finance/FeeCertificate";
import NonSatisfaction from "./features/Finance/NonSatisfaction";

import Notification from "./features/Notification/index";
import Survey from "./features/Survey";
import ForgetPassword from "./features/Login/ForgetPassword";
import OtpVerify from "./features/Login/OtpVerify";
import Example from "./global/Example";
import ShareFeeCerticate from "./features/Finance/ShareFeeCertificate";
import manifest from "./manifest";
import AddGuardian from "./features/Guardian/addGuardian";
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from "@capacitor/core";
// import ReloadData from "./global/utils/ReloadData";
import Constants from "./constants";
import { sendUnsentReports, setContext, setEnabled, setUserId } from "./global/utils/crashlyticsMethods";


// import drawerNavigator from './homeNavigator';

class RootComponent extends React.Component {

  async componentDidMount() {
    //
    //alert(isChrome);
    if (Capacitor.isNativePlatform()) { 
      sendUnsentReports();
      setEnabled(true);
      // console.log("Firebase ",await this.isEnabled())
      // this.addLogMessage(this.props.state);
      const setInfo = this.props.state?.userState;
      const contextData = {
        parentDetails: setInfo.parentProfile,
        lastLoginUI: setInfo.lastLoggedIn,
        loggedInUserData: {
          email: setInfo.email,
          password: setInfo.password,
          phone: setInfo.phone,
        },
        apiVersion: setInfo.user?.app_version,
        uiVersion: Constants.env.version
      };
      setContext("UserData",contextData);
      setUserId(setInfo?.user?.activeChildDetail?.id);
    }
    //capacitor listener to check native back and back to previouys url
    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    this.props.setError();
    if (
      typeof this.props.constants === "undefined" ||
      typeof this.props.constants.lambdaApiServer === "undefined"
    ) {
      // update with default values
      this.props.fetchConfigSuccess({});
    }

    this.props.fetchConfig((response) => {
      if (response && typeof response.supportedVersions === "string") {
        const supportedVersions = response.supportedVersions;
        // console.log("the supported Version is ", supportedVersions);
        if (
          typeof supportedVersions !== "undefined" &&
          supportedVersions.indexOf(manifest.version) === -1
        ) {
          // console.log("Invalid version");
          //this.props.forceUpdate(true);
        } else {
          //this.props.forceUpdate(false);
        }
      } else {
        // console.log("supported version in not defined");
      }
    });
  }
  
  shouldComponentUpdate(nextProps) {
    return false;
  }

  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={SplashScreen} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/verifyUser" component={Login} />
          <Route path="/survey/:surveyId" component={Survey} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/otpVerify" component={OtpVerify} />

          <Dashboard>
            {/* <ReloadData /> */}
            <Route
              component={({ match }) => (
                <div>
                  {draweItems.map((item) => (
                    <Route
                      key={item.path}
                      path={`/${item.path}`}
                      component={item.component}
                    />
                  ))}

                  {tabs.map((item) => (
                    <Route
                      key={item.path}
                      path={`/${item.path}`}
                      component={item.component}
                    />
                  ))}

                  <Route path="/webview" component={WebView} />

                  <Route path="/PastRequestTab" component={PastRequestTab} />
                  <Route path="/raiseAnIssue" component={RaiseIssue} />
                  <Route path="/earlyResume" component={EarlyResume} />
                  <Route path="/extendPause" component={ExtendPause} />

                  <Route path="/notification" component={Notification} />

                  <Route path="/feeCertificate" component={FeeCertificate} />
                  <Route path="/nonSatisfaction" component={NonSatisfaction} />
                  <Route
                    path="/shareFeeCerticate"
                    component={ShareFeeCerticate}
                  />
                  <Route path="/addGuardian" component={AddGuardian} />

                  <Route path="/example" component={Example} />
                </div>
              )}
            />
          </Dashboard>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  constants: state.constants,
  state : state
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfigSuccess: (payload) => dispatch(fetchConfigSuccess(payload)),
  fetchConfig: (onSuccess) => dispatch(fetchConfig(onSuccess)),
  setError: () => dispatch(setError()),
  login: (email, password, onSuccess) =>
    dispatch(signIn(email, password, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);

// export default RootComponent;
