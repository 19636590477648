import { FETCH_PHOTOS_SUCCESS, UPDATE_PHOTOS_PRESENCE } from "./action";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
// import moment from "moment";
// import { SERVER_DATE_FORMAT,DEFAULT_FORMAT } from "../../../global/redux/combineLogic";

const initialState = { loading: true };

export const LIMIT = 50;
export const PHOTO_LIMIT = 50;

const getFullScreenPhotos = photos => {
  if (Array.isArray(photos)) {
    // return photos.reduce((acc, curr) => {
    //   let arrayIdx = acc.findIndex((ele) => moment(ele.date).format(DEFAULT_FORMAT) === moment(curr.date).format(DEFAULT_FORMAT));
    //   if (arrayIdx >= 0) {
    //     acc[arrayIdx].photos = [
    //       ...acc[arrayIdx].photos,
    //       {
    //         src: curr.message,
    //         width: 2,
    //         height: 2,
    //         thumbnail: curr.thumb,
    //       },
    //     ];
    //     return acc;
    //   } else return [...acc, { date: moment(curr.date).format(DEFAULT_FORMAT), photos: [{
    //     src: curr.message,
    //     width: 2,
    //     height: 2,
    //     thumbnail: curr.thumb,
    //   }] }];
    // }, []);
    // console.log("Photo Array: ", photos);
    return photos.map((item) =>
        ({
        src: item.message,
        width: 2,
        height: 2,
        thumbnail: item.thumb,
      })
    );
  }
};
const photoGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PHOTOS_PRESENCE: {
      return {
        ...state,
        photosDataPresent: action.payload
      };
    }

    case FETCH_PHOTOS_SUCCESS: {
      try {
        const { startIndex, response } = action.payload;
        // console.log("Response Photos: ",response);
        let photos = [];
        if (startIndex > 0) {
          photos = [...state.photos, ...response];
        } else if (startIndex === 0) {
          photos = response;
        }
        return {
          ...state,
          photos,
          photosDataPresent: response.length >= PHOTO_LIMIT,
          fullScreenPhotos: getFullScreenPhotos(photos),
          loading: false
        };
      } catch (error) {
        return state;
      }
    }

    case SET_COMPONENT_LOADING + "PHOTOGALLERY": {
      return {
        ...state,
        loading: action.value
      }
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState;
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
export default photoGalleryReducer;
