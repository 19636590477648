//import moment from "moment";


import ENVIRONMENT from './environment';


const compileOption = ENVIRONMENT.PRODUCTION;

// const compileOption = BUILD_CONFIGRATION.TEST;

// const compileOption = BUILD_CONFIGRATION.STAGING;

// const compileOption = BUILD_CONFIGRATION.PRODUCTION;

const Constants = {
  filePath: compileOption.filePath,
  DEBUG: compileOption.type,
  env: ENVIRONMENT.PRODUCTION
};

export default Constants;
