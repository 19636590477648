import React from "react";

import { Card, CardBody, CardTitle } from "reactstrap";
// import { connect } from 'react-redux';
import { Col } from "reactstrap";
class NewsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  render() {
    const { item, openModal } = this.props;
    return (
      <Col
        xs="12"
        md="6"
        sm="4"
        lg="4"
        className="mb-3"
        key={item.newsletter_id}
      >
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.setState({ isActive: !this.state.isActive });
            openModal(true, item);
          }}
        >
          <Card
            outline
            color="success"
            className="shadow text-center h-100"
            style={{ color: "#808080" }}
          >
            <CardBody className="p-3">
              <i className="icon-newspaper" style={{ fontSize: "50px" }}></i>
              {typeof item.notification_subject === "string" &&
                item.notification_subject.split("-").map((sub, idx) => (
                  <CardTitle className="mb-2" key={idx} style={{ fontFamily: "Baloo" }}>
                    {sub}
                  </CardTitle>
                ))}
              {typeof item.notification_subject === "string" &&
                item.notification_subject.indexOf("-") === -1 && (
                  <CardTitle className="mb-2" style={{ fontFamily: "Baloo" }}>
                    {item.notification_subject}
                  </CardTitle>
                )}
              {typeof item.notification_subject !== "string" &&
                typeof item.newsletter_due_date === "string" && (
                  <div>
                    <CardTitle className="mb-2" style={{ fontFamily: "Baloo" }}>
                      Weekley Newsletter
                    </CardTitle>
                    <CardTitle className="mb-2" style={{ fontFamily: "Baloo" }}>
                      {item.newsletter_due_date}
                    </CardTitle>
                  </div>
                )}
            </CardBody>
          </Card>
        </span>
      </Col>
    );
  }
}

export default NewsItem;
