import React from "react";
import {
  ClockLoader,
} from "react-spinners";
// import { connect } from "react-redux";
import { Modal } from "reactstrap";
// import Theme from "../../theme";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const WaitingLoading = (props) => {
    // console.log("Waiting: ", props);
  let { loading, setLoading} = props;
  return (
    <Modal
      isOpen={loading}
      className="modal-xl spinner-modal h-100"
      backdrop={true}
      style={{
        display: "grid",
        placeItems: "center",
        marginTop: 0,
        marginBottom: 0,
      }}
      fade={false}
      data-dismiss="modal"
    >
      <ClockLoader
        color={"#19ade4"}
        loading={loading}
        cssOverride={override}
        size={28}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <h6 className="text-center text-white pt-3 pe-auto">
        Waiting for OTP...
      </h6>
      <p
        className="text-center text-white mt-5 pt-5 pe-auto pointer-cursor position-fixed fixed-bottom"
        onClick={setLoading}
      >
        Enter OTP manually
      </p>
    </Modal>
  );
};

export default WaitingLoading;
