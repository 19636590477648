import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, CardHeader } from "reactstrap";

import CORDetailItem from "./GrowthDetail";
class CORItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: []
      //openedCollapses: ["collapseOne"]
    };
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = (e, collapse) => {
    e.preventDefault();
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      let list= openedCollapses.filter((ele)=> ele !== collapse);
      this.setState({
        openedCollapses: [...list]
      });
    } else {
      this.setState({
        openedCollapses: [...openedCollapses,collapse]
      });
    }
  };
  
  render() {
    const { item } = this.props;
    const description =
      Array.isArray(item.description) && item.description.length > 0
        ? item.description
        : undefined;
        return (
      <Col sm={12}>
        <div className="accordion my-3">
          <Card className="card-plain border-0 shadow rounded">
            <CardHeader
              className="d-flex"
              style={{ background: "#00BFA5" }}
              role="tab"
              onClick={(e) => this.collapsesToggle(e, "collapseOne")}
              aria-expanded={this.state.openedCollapses.includes("collapseOne")}
            >
              <div className="mr-auto">
                <p className="mb-0 text-white" style={{ fontFamily: "Baloo" }}>
                  <i className="icon-calender"></i>
                  {item.date}
                </p>
              </div>
            </CardHeader>

            <CardBody>
              {/* {typeof description !== "undefined" && (
                <div
                // onClick={(e) => this.collapsesToggle(e, "collapseOne")}
                >
                  <CORDetailItem
                    data={description[0]}
                    openedCollapses={this.state.openedCollapses}
                    setCollapse={this.collapsesToggle}
                    index={`${item.date}0`}
                    key={10}
                  />
                </div>
              )} */}
              {/* <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes("collapseOne")}
                // onClick={(e) => this.collapsesToggle(e, "collapseOne")}
              > */}
                {typeof description !== "undefined" &&
                  description.map((data, i) =>
                    (
                      <CORDetailItem
                        data={data}
                        openedCollapses={this.state.openedCollapses}
                        setCollapse={this.collapsesToggle}
                        index={`${item.date}${i}`}
                        key={i}
                        last={i===description.length-1}
                      />
                    )
                  )}
                {/*<p style={{ fontFamily: "Baloo" }}>{item.key}</p>
                <p>
                  <CustomInput
                    type="checkbox"
                    id="exampleCustomCheckbox"
                    label={item.value}
                    checked
                  />
                  
                </p> */}
              {/* </Collapse> */}
            </CardBody>
          </Card>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CORItem);
