import React from 'react';
import { Label } from "reactstrap";

const ListEmptyComponent = ({ heading = "No Data Found" }) => (
    <Label
        style={{
            fontFamily: "baloo",
            fontSize: "1em"
        }}
    >
        {heading}
    </Label>
)

export default ListEmptyComponent;