import React from "react";
import { connect } from "react-redux";
// javascipt plugin for creating charts
//import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import Measurement from "./Mesurement/index";
import COR from "./COR/index";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import { setNavigationParams } from "../../global/redux/action";

// Colors
class Growth extends React.Component {
  constructor(props) {
    super(props);
    //
    this.state = { activeTab: props.activeTab === "COR" ? COR : Measurement };
  }

  componentDidMount() {
    this.props.setNavigationParams();
  }

  render() {
    return (
      <div className="shadow rounded commonFeature">
        <Nav
          fill
          pills
          className="mt-4"
          style={{ fontFamily: "Baloo", background: "#f3f4fb" }}
        >
          <NavItem className="navigation-Item">
            <NavLink
              style={{ fontSize: "1.3em" }}
              className={classnames({
                active: this.state.activeTab === Measurement,
                "py-3 px-1": true,
              })}
              onClick={() => {
                this.setState({ activeTab: Measurement });
              }}
            >
              Measurement
            </NavLink>
          </NavItem>
          <NavItem className="navigation-Item">
            <NavLink
              style={{ fontSize: "1.3em" }}
              className={classnames({
                active: this.state.activeTab === COR,
                "p-3": true,
              })}
              onClick={() => {
                this.setState({ activeTab: COR });
              }}
            >
              Child Care
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          className="p-3"
        >
          <TabPane>
            <this.state.activeTab history={this.props.history} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
// export default Growth;
const mapStateToProps = (state) => ({
  activeTab:
    state.global.navigation.params && state.global.navigation.params.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  setNavigationParams: (params) => dispatch(setNavigationParams(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Growth);
