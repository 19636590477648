import { FETCH_HOLIDAYS_SUCCESS } from "./action";
import _ from 'lodash';
import moment from "moment";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";

const initialState = {
  holiday: [],
  loading: true
};

const isPreviousDate = (date) => {
  if (moment(date).format('DD-MM-YY') === moment().format('DD-MM-YY'))
    return 0;
  else if (moment(date).isAfter(moment())) {
    return +1;
  }
  return -1;
}



export const monthWiseHolidays = (holidays) => {
  try {
    if (Array.isArray(holidays)) {
      let dateGroup = _.groupBy(holidays, d => {
        return moment(d.holiday_date).format('MMMM');
      }
      );
      dateGroup = _.reduce(
        dateGroup,
        (acc, next, index) => {
          acc.push({
            dateGroup: index,
            data: transformHolidays(next),
          });
          return acc;
        },
        [],
      );
      return dateGroup;
    }
    return [];
  } catch (error) {

    return [];
  }
};


const transformHolidays = (holidays) => {
  return holidays.map(item => ({
    ...item,
    background: isPreviousDate(item.holiday_date)
  }))
}

const holidayReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        holiday: monthWiseHolidays(transformHolidays(action.payload.holidays)),
        loading: false
      };
    }
    case SET_COMPONENT_LOADING + 'Holidays': {
      return {
        ...state,
        loading: action.value
      }
    }

    default: {
      return state;
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }

  }
};
export default holidayReducer;
