import React from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, Form, FormGroup, Label } from "reactstrap";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
import { emailFeeCertificate } from "./redux/action";

class ShareFeeCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //console.log("Props received", this.props.Navparams);
  }
  componentDidMount() {
    console.log("[Finance][ShareFeeCertificate] Request for sharing fee certificate");
    // console.log(this.props.Navparams.certificateUrl);
  }
  render() {
    const { certificateUrl } = this.props.Navparams;

    return (
      <Card className="border-0 shadow  card-timeline mt-4">
        <CardHeader
          className="text-white"
          style={{
            background: "#A500F8",
            fontFamily: "baloo",
            fontSize: "1.3em",
          }}
        >
          Share Fee Certificate
        </CardHeader>
        <CardBody>
          <Form>
            <center>
              <Label>Your certificate has been generated successfully.</Label>
            </center>
            <FormGroup className="text-center mb-0">
              <FootprintInputButton
                className="btn-lg border-0"
                onClick={() => {
                  this.props.sendEmail(certificateUrl);
                }}
                text={"Send To Email"}
              ></FootprintInputButton>
              <a
                className="btn-block btn-lg bg-purple border-0"
                style={{ color: "black" }}
                href={certificateUrl}
                target="_blank"
              >
                View Certificate{" "}
              </a>
              <center>
                <Label>OR</Label>
              </center>
              <FootprintInputButton
                className="btn-lg border-0"
                onClick={() => {
                  this.props.history.replace("/feeCertificate");
                }}
                text={"Re-generate"}
              ></FootprintInputButton>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  Navparams: state.global.navigation.params,
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (url) => dispatch(emailFeeCertificate(url)),

  //setNavigationParams: params => dispatch(setNavigationParams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareFeeCertificate);
