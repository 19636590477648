import { WITHDRAW_CHILD, CANCEL_WITHRAW_REQUEST } from "./action";
import { getRequestType, fetchPastRequest } from "../../Support/redux/action";

import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";

import moment from "moment";

import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const withrawChild = createLogic({
  type: WITHDRAW_CHILD,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      const parentType = getState().userState.parentProfile.parentType.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/request`;
      const body = {
        date: moment(action.payload.withdrawDate).format(SERVER_DATE_FORMAT),
        end_date: moment(action.payload.selectedDate).format(
          SERVER_DATE_FORMAT
        ),
        attrition_reason:
          action.payload.reason === "Others"
            ? action.payload.otherRemark
            : action.payload.reason,
        // only in case of "other" this value is not empty
        // password: getState().userState.password,
        parent_name: parentName,
        center_shift: "no",
        child_attrition: "yes",
        values: ["child_attrition", "center_shift"],
        nps_score: action.payload.rating,
        nps_tags: action.payload.tags.map(item => item.value.key),
        nps_parent: parentType,
        nps_comments: action.payload.comment
      };
      console.log("[Child Withdraw] Calling API: /parentapp/request, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        successReportType: "Alert",
        errorReportType: "Alert",
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Child Withdraw] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        error => {
          console.log("[Child Withdraw] Error: ", error);
          dispatch(setLoading(false));
          // failure
          setContext("Withdraw Child Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Child Withdraw] Error: ", error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Withdraw Child Error", error);
      done();
    }
  }
});
export const cancelWithrawRequest = createLogic({
  type: CANCEL_WITHRAW_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/financials/cancelCustomerRequest`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_id: action.payload.request_id,
        updated_by_name: getState().userState.parentProfile.parentName,
        updated_by: 0
      };
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //NavigationService.navigate('CustomerSupport');

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          dispatch(setLoading(false));
          // failure
          setContext("Cancel Withdraw Child Error", error);
          done();
        }
      );
    } catch (error) {
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Cancel Withdraw Child Error", error);
      done();
    }
  }
});
export default [withrawChild, cancelWithrawRequest];
