export const FETCH_SURVEY = "FETCH_SURVEY";
export const FETCH_SURVEY_SUCCESS = "FETCH_SURVEY_SUCCESS";
export const CHECK_BOX_ANSWER = "CHECK_BOX_ANSWER";
export const SUBMIT_SURVEY = "SUBMIT_SURVEY";

export const fetchSurvey = payload => ({
  type: FETCH_SURVEY,
  payload: {}
});

export const fetchSurveySuccess = payload => ({
  type: FETCH_SURVEY_SUCCESS,
  payload
});

export const surveyCheckboxAnswer = checkboxAnswer => ({
  type: CHECK_BOX_ANSWER,
  payload: checkboxAnswer
});

export const submitSurvey = (survey, onSuccess) => ({
  type: SUBMIT_SURVEY,
  payload: { survey, onSuccess }
});
