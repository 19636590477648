import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  InputGroupText
} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { showModal } from "../redux/action";
import FootprintDatePicker from "./FootprintDatePicker";
import { DEFAULT_FORMAT } from "../redux/combineLogic";

class FootprintModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {date: moment()};
  }
  closeModal = () => {
    this.props.showModal();
  };

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps) {
    
  }


  removeHtmlTags = (message) => {
    if (typeof message === 'string') {
      const regex = /(<([^>]+)>)/ig;
      message = message.replace(regex, '');
      return message;
    }
    return message;
  }

  render() {
    const { modal } = this.props;
    // console.log("modalInfo-->", modal)
    if (typeof modal !== "undefined" && typeof modal.message === "string") {
      const {
        isConfirm = false,
        onOkPressed,
        onCancelPressed,
        message
      } = modal;
      return (
        <div>
          <Modal
            isOpen={typeof modal.message === "string"}
            toggle={() => this.closeModal()}
          >
            <ModalHeader>Parent Connect Says....</ModalHeader>
            <ModalBody>
              {message === "Select Date" ? (
                <>
                  <FormGroup>
                    <Label for="startDate">Select Transport Start/End Date</Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-calender" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <FootprintDatePicker
                        holidaySelected={(selected) => {
                          this.setState({
                            error: "The selected Date Cannot Be a Holiday",
                            holidaySelected: selected,
                          });
                        }}
                        minimumDate={moment()}
                        inputProps={{
                          placeholder: "Select Date",
                        }}
                        defaultDate={this.state.date}
                        onChange={(date) => {
                          this.setState({
                            holidaySelected: false,
                            date: moment(date).format(DEFAULT_FORMAT),
                            error: undefined,
                          });
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  {typeof this.state.error !== "undefined" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        fontFamily: "Baloo",
                      }}
                    >
                      {this.state.error}
                    </p>
                  )}
                </>
              ) : (
                this.removeHtmlTags(message)
              )}
            </ModalBody>
            <ModalFooter>
              {
                <Button
                  color="success"
                  onClick={() => {
                    if (onOkPressed) {
                      if (message === "Select Date")
                        onOkPressed(this.state.date);
                      else
                        onOkPressed();
                    }
                    this.closeModal();
                  }}
                >
                  Ok
                </Button>
              }
              {isConfirm === true && (
                <Button
                  color="secondary"
                  onClick={() => {
                    if (onCancelPressed) {
                      onCancelPressed();
                    }
                    this.closeModal();
                  }}
                >
                  Cancel
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  modal: state.global.modalInfo
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch(showModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(FootprintModal);
