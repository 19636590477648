import React from 'react'
import { Link } from 'react-router-dom'
import { getFullPath } from '../../features/MyProfile'
import Theme from '../../theme'

const BottomPanel = ({ picPresent, photo, constants, location }) => {

    return (
        <div className='bottom-panel'>
            <Link to='/blogs'
                style={{ color: location !== '/blogs' ? 'gray' : Theme.colorPrimary }}
                className='bottom-nav-link icon-home text-center'></Link>

            <Link to='/cctv'
                style={{ color: location !== '/cctv' ? 'gray' : Theme.colorPrimary }}
                className='bottom-nav-link icon-video text-center'></Link>

            <div>
                {
                    picPresent ? <Link to='/timeline' className=" middle-link"
                        style={{
                            backgroundImage:
                                "url" +
                                "(" +
                                getFullPath(
                                    constants,
                                    photo
                                ) +
                                ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            boxShadow: location !== '/timeline' ? '0px 0px 0px 5px rgba(0, 0, 0, 0.3)' : `0px 0px 0px 5px ${Theme.colorPrimary}`
                        }}>

                    </Link>
                        :
                        <Link to='/timeline'
                            className="middle-link"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    require("../../assets/left-nico.png") +
                                    ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                boxShadow: location !== '/timeline' ? '0px 0px 0px 5px rgba(0, 0, 0, 0.3)' : `0px 0px 0px 5px ${Theme.colorPrimary}`
                            }}
                        >
                        </Link>
                }
            </div>

            <Link to='/photoGallery' className='bottom-nav-link icon-photo-gallery-2 text-center'
                style={{ color: location !== '/photoGallery' ? 'gray' : Theme.colorPrimary }}>
            </Link>

            <Link to='/support' className='bottom-nav-link icon-support text-center'
                style={{ color: location !== '/support' ? 'gray' : Theme.colorPrimary }}>
            </Link>

        </div>
    )
}

export default BottomPanel