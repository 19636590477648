import { FETCH_OUTDOOR_ACTIVITY_SUCCESS } from "./action";

import moment from "moment";
import { CHANGE_LOGGEDIN_CHILD, SET_COMPONENT_LOADING } from "../../../global/redux/action";
import { LOGOUT } from "../../Login/redux/action";
const initialState = {
  outdoorActivity: [],
  loading: true
};
export const DEFAULT_FORMAT = "MMM DD, YYYY";

const transformOutDoorActivity = outdoorActivity => {
  if (Array.isArray(outdoorActivity) && outdoorActivity.length > 0) {
    return outdoorActivity.map((item, index) => {
      return {
        sno: index + 1,
        eventId: item.event_id,
        eventTitle: item.event_title,
        eventDate: moment(item.event_date).format(DEFAULT_FORMAT),
        eventCreatedOn: moment(item.event_created_on).format(DEFAULT_FORMAT),
        eventConsentStatus: item.consent_status,
        eventDetail: item.event_details,
        eventStatus: item.event_status
      };
    });
  }
  return [];
};
const outdoorActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OUTDOOR_ACTIVITY_SUCCESS: {
      return {
        ...state,
        outdoorActivity: transformOutDoorActivity(action.payload),
        loading: false
      };
    }

    case SET_COMPONENT_LOADING + 'OUTDOOR': {
      return {
        ...state,
        loading: action.value
      }
    }

    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }

    default: {
      return state;
    }
  }
};
export default outdoorActivityReducer;
