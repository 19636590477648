export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const FETCH_TEACHERS_SUCCESS = "FETCH_TEACHERS_SUCCESS";

export const fetchTeachers = (onSuccess) => ({
  type: FETCH_TEACHERS,
  payload: {
    onSuccess
  }
});

export const fetchTeachersSuccess = payload => ({
  type: FETCH_TEACHERS_SUCCESS,
  payload
});
