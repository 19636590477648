export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_DATE = "UPDATE_ACTIVITY_DATE";

export const fetchActivity = (date, onSuccess) => ({
  type: FETCH_ACTIVITY,
  payload: { date, onSuccess }
});

export const fetchActivitySuccess = payload => ({
  type: FETCH_ACTIVITY_SUCCESS,
  payload
});

export const updateActivityDate = date => ({
  type: UPDATE_ACTIVITY_DATE,
  payload: date
});
