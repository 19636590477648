import {
  FETCH_CHILD_MEASUREMENTS_SUCCESS,
  FETCH_CHILD_CARE_SUCCESS
} from "./action";
import { CHANGE_LOGGEDIN_CHILD, LOGOUT } from "../../Login/redux/action";
import { SET_COMPONENT_LOADING } from "../../../global/redux/action";
const initialState = { childCare: [], measurements: [], childloading: true, measureLoading: true };

const transformChildCare = childCare => {
  try {
    if (
      Array.isArray(childCare) &&
      childCare.length > 0 &&
      Array.isArray(childCare[0].description) &&
      childCare[0].description.length > 0
    ) {
      const cCare = childCare.map(item => ({
        ...item,
        ...item.description[0]
      }));

      return cCare;
    }
    return [];
  } catch (error) {

  }
};
const growthReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHILD_MEASUREMENTS_SUCCESS: {
      return {
        ...state,
        measurements: action.payload,
        measureLoading: false
      };
    }
    case FETCH_CHILD_CARE_SUCCESS: {
      return {
        ...state,
        childCare: transformChildCare(action.payload),
        childloading: false
        // childcare:action.payload
      };
    }
    case SET_COMPONENT_LOADING + 'CHILD_CARE': {
      return {
        ...state,
        childloading: action.value
      }
    }
    case SET_COMPONENT_LOADING + 'MEASUREMENT': {
      return {
        ...state,
        measureLoading: action.value
      }
    }
    case CHANGE_LOGGEDIN_CHILD: {
      return initialState
    }


    case LOGOUT: {
      return initialState
    }


    default: {
      return state;
    }
  }
};
export default growthReducer;
