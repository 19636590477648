import React from "react";
import FootprintErrorAlert from "./utils/FootprintModal";
// import { connect } from 'react-redux';
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };
  }

  handelShow = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
        <FootprintErrorAlert
          modal={this.state.modal}
          color="danger"
          msg="Error Message"
          buttonLabel="OK"
          test={() => this.handelShow()}
        />
      </div>
    );
  }
}

export default Example;
