import React from "react";
import "./App.css";
import { Provider } from "react-redux";
//import Header from "./features/Blogs/Header";
//import News from "./features/News";
//import Holidays from "./features/Holidays";
// Blogs from "./features/Blogs";
//import Login from "./features/Login";
import "./fontello-0c38a2d1/css/fontello.css";
import configureStore from "./global/redux/store";
import RootComponent from "./RootComponent";
import PasswordOverlay from "./global/PasswordOverlay";
import { PersistGate } from "redux-persist/lib/integration/react";

import FootprintOverlay from "./global/utils/FootprintModal";
import FootprintToast from "./global/utils/FootprintToast";
import FootprintNotification from "./global/utils/FootprintNotification";
import Constants from "./constants";
import FootprintCropper from "./global/utils/FootprintCropper";
import SpinnerLoading from "./global/utils/spinnerLoading";
const { store, persistor } = configureStore();

console.ignoredYellowBox = true;
document.addEventListener("contextmenu", (event) => {
  return Constants.DEBUG === false && event.preventDefault();
});
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RootComponent />
        {/* <NotificationService /> */}
        <PasswordOverlay />
        <FootprintOverlay />
        <FootprintToast />
        <FootprintCropper />
        <FootprintNotification />
        <SpinnerLoading />
        {/* <ChangeLoginChildOverlay />*/}
      </PersistGate>
    </Provider>
  );
}

export default App;
