import {
  GET_REQUESTS_TYPE,
  fetchRequestTypesSuccess,
  FETCH_PAST_REQUEST,
  fetchPastRequestSuccess
} from "./action";
import { createLogic } from "redux-logic";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";

import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

const fetchRequestTypesLogic = createLogic({
  type: GET_REQUESTS_TYPE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(onErrLoading("SUPPORT", true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getRequestTypes`;
      const body = {};
      console.log("[Support] Calling API: /parentapp/getRequestTypes");
      const fetchObject = {
        url,
        body,
        method: "POST",
        getState,
        dispatch,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Support] Received response: ", response);
          if (response.status === "ok") {
            dispatch(fetchRequestTypesSuccess(response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading("SUPPORT", false))
            done();
          }
        },
        error => {
          console.log("[Support] Error: ", error);
          dispatch(setLoading(false));
          dispatch(onErrLoading("SUPPORT", false))
          setContext("Fetch Request Type Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Support] Error: ", error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading("SUPPORT", false))
      setContext("Fetch Request Type Error", error);
      done();
    }
  }
});
export const fetchPastRequestLogic = createLogic({
  type: FETCH_PAST_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(onErrLoading("SUPPORT", true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/requestList`;
      const body = {};
      console.log("[Support][Past Request] Calling API: /parentapp/requestList");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Support][Past Request] Received response: ",response);
          if (response.status === "ok") {
            dispatch(fetchPastRequestSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading("SUPPORT", false))
            done();
          }
        },
        error => {
          console.log("[Support][Past Request] Error: ",error);
          dispatch(setLoading(false));
          dispatch(onErrLoading("SUPPORT", false))
          setContext("Fetch Past Request Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Support][Past Request] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading("SUPPORT", false))
      setContext("Fetch Past Request Error", error);
      done();
    }
  }
});
export default [fetchRequestTypesLogic, fetchPastRequestLogic];
