import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import Loading from "../../global/utils/Loading";
// import { FaAngleDown, FaUserFriends } from "react-icons/fa";
import { slide as Menu } from "react-burger-menu";

// import { AiOutlineUsergroupAdd } from "react-icons/ai";

// reactstrap components
import {
  // Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  // NavItem,
  // NavLink,
  Nav,
  Container,
  // Row,
  // Col,
  // Tooltip
} from "reactstrap";
// import Blogs from "../../features/Blogs";
// import MyProfile from "../../features/MyProfile";
// import MyCenter from "../../features/MyCenter";
// import Cctv from "../../features/Cctv";
// import ActivityCalendar from "../../features/ActivityCalendar";
// import Conversation from "../../features/Conversation";
// import FoodMenu from "../../features/FoodMenu";
// import Growth from "../../features/Growth";
// import News from "../../features/News/index";
// import PhotoGallery from "../../features/PhotoGallery";
// import Holidays from "../../features/Holidays";
// import Support from "../../features/Support";
// import OutdoorActivity from "../../features/OutdoorActivity";
import { withRouter } from "react-router-dom";
import className from "classnames";
// import ChildTimeline from "../../features/ChildTimeline";
import ChangeLoginChildOverlay from "../ChangeLoginChildOverlay";
// import Guardian from "../../features/Guardian/index";
import { changeLoggedInChild, logout } from "../../features/Login/redux/action";
// import ParentVideos from "../../features/ParentOrientationVedio";
import { showModal } from "../redux/action";
import manifest from "../../manifest.json";
import { getFullPath } from "../../features/MyProfile/index";
import { GoPrimitiveDot } from "react-icons/go";
import Theme from "../../theme";
import { draweItems } from "./SideDrawer";
import { App } from "@capacitor/app";

class SideMenu extends React.Component {
  state = {
    collapseOpen: false,
    tooltipOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    // console.log("toggleCollapse");
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  handleStateChange(state) {
    this.setState({ collapseOpen: state.isOpen });
  }
  toggleMenu() {
    this.setState((state) => ({ collapseOpen: !state.collapseOpen }));
  }
  closeMenu() {
    this.setState({ collapseOpen: false });
  }
  // closes the collapse
  closeCollapse = (path) => {
    this.setState({
      collapseOpen: false,
    });
  };
  isMultipleChild = () => {
    return (
      Array.isArray(this.props.user.childList) &&
      this.props.user.childList.length > 1
    );
  };

  toggleTooltip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  showChildChangeOption = () => {
    this.setState({ showOverLay: true }, () => {});
  };

  handleProfile = (ele = false) => {
    if (this.isMultipleChild()) {
      if (ele) {
        let parent_token = "";
        if (this.props.parent_type === "Mother") {
          parent_token = "mother_access_token";
        } else {
          parent_token = "father_access_token";
        }
        localStorage.setItem("auth_token", ele[parent_token]);
        this.props.changeActiveChild(ele);
      }
      if (this.props.history.location.pathname === "/myProfile") {
        this.setState({...this.state, render: Math.random()*100})
      } else this.props.history.replace("/myProfile");
    } else {
      this.props.history.replace("/myProfile");
    }
  };

  render() {
    // console.log(
    //   this.state.collapseOpen,
    //   "\n Tooltip: ",
    //   this.state.tooltipOpen
    // );
    const { innerWidth: width} = window;
    //const { loggedInChild } = this.props;

    // const child =
    //   loggedInChild.name +
    //   " " +
    //   (loggedInChild.last_name ? loggedInChild.last_name : "");

    const logo = {
      imgAlt: "Footprints-Logo",
      imgSrc: require("../../assets/Footprints-Logo.svg"),
    };
    const Menulogo = {
      imgAlt: "Footprints-Logo",
      imgSrc: require("../../assets/Footprints_Logo-White.svg"),
    };

    let navbarBrandProps;
    let disabled =
      width > 768
        ? "disableCloseOnEsc disableOverlayClick disableAutoFocus"
        : "disableCloseOnEsc";
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light mobile-fixes"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid className="flex-nowrap">
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
                onClick={() => this.props.history.replace("/blogs")}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}

          <Menu
            isOpen={this.state.collapseOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            {...disabled}
          >
            <div className="sideMenu">
              <div className="sideMenu-header d-md-none">
                {Menulogo ? (
                  <img
                    alt={Menulogo.imgAlt}
                    src={Menulogo.imgSrc}
                    style={{ fill: "white !important" }}
                  />
                ) : null}
              </div>
              <div className="sidemenu-content" hidden={width > 768 && false}>
                {draweItems.map((item, index) => (
                  <li key={index} className="nav-item">
                    {
                      <Link
                        to={`/${item.path}`}
                        onClick={() => this.closeMenu()}
                        className={className({
                          "nav-link": true,
                          active:
                            this.props.location &&
                            this.props.location.pathname.indexOf(item.path) >
                              -1,
                        })}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {typeof item.icon === "string" && (
                          <i
                            className={`${item.icon} text-center Icon`}
                            style={{
                              fontSize: "30px",
                              // lineHeight: "1.5rem",
                              minWidth: "2.25rem",
                              verticalAlign: "middle",
                            }}
                          />
                        )}
                        {typeof item.icon === "object" && (
                          <span
                            style={{
                              marginLeft: 5,
                              marginRight: 5,
                            }}
                          >
                            {item.icon}
                          </span>
                        )}
                        <span
                          style={{
                            fontFamily: "Baloo",
                            fontSize: "1rem",
                            paddingTop: "2px",
                            verticalAlign: "middle",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                    }
                  </li>
                ))}
                <li key="Logout" className="nav-item">
                  {
                    <span
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        this.props.showModal(
                          "Success",
                          "Are You Sure You Want To Logout ?",
                          true,
                          async () => {
                            await this.props.logout();
                            await App.removeAllListeners();
                            localStorage.setItem("auth_token", "");
                            this.props.history.replace("/");
                          },
                          () => {}
                        );
                      }}
                      className="nav-link"
                    >
                      <i
                        className={`icon-signout text-center Icon`}
                        style={{
                          fontSize: "30px",
                          // lineHeight: "1.5rem",
                          minWidth: "2.25rem",
                          verticalAlign: "middle",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Baloo",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                        }}
                      >
                        Logout
                      </span>
                    </span>
                  }
                </li>
              </div>
              <div className="menu-txt">
                <h6
                  className="menu-h6"
                  style={{ fontFamily: "baloo", fontSize: 14 }}
                >{`${manifest.applicationName} v${manifest.version}`}</h6>
              </div>
            </div>
          </Menu>
          <Nav className="align-items-center d-md-none flex-nowrap">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("notification");
                  }}
                >
                  <i
                    className="icon-notification"
                    style={{ color: "#777", fontSize: "1.3em" }}
                  />
                  {this.props.showBell && (
                    <GoPrimitiveDot
                      style={{
                        color: Theme.danger,
                        position: "relative",
                        right: 19,
                        top: -13,
                      }}
                    />
                  )}
                </span>
              </DropdownToggle>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media
                  style={{
                    cursor: this.isMultipleChild() ? "pointer" : "default",
                  }}
                  className="align-items-center"
                >

                  {this.props.childList.map((ele, index) =>
                    this.props.profilePicPresent ? (
                      <div className="position-relative" key={index}>
                      <span
                        key={index}
                        className="avatar avatar-sm rounded-circle"
                        onClick={() => {
                          this.handleProfile(ele);
                        }}
                        style={{
                          backgroundImage:
                            "url" +
                            "(" +
                            getFullPath(this.props.constants, ele.child_photo) +
                            ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          zIndex: 9,
                        }}
                        id={"Tooltip-" + index}
                      >
                        {/* <Tooltip
                          placement="top"
                          isOpen={this.state.tooltipOpen}
                          target={"Tooltip-" + index}
                          toggle={this.toggleTooltip}
                          trigger="click"
                        >
                          {ele.name + " " + ele.last_name}
                        </Tooltip> */}
                        </span>
                        { ele.id == this.props?.loggedInChild?.id ? <span className="position-absolute right-0 bg-success border rounded" style={{height: '10px', width: '10px', bottom: '6px'}}></span> : null}
                        </div>
                    ) : (
                      <span
                        className="avatar avatar-sm rounded-circle"
                        onClick={() => {
                          this.handleProfile()
                        }}
                        style={{
                          backgroundImage:
                            "url(" +
                            require("../../assets/left-nico.png") +
                            ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        id={"Tooltip-" + index}
                      >
                        {/* <Tooltip
                          placement="top"
                          isOpen={this.state.tooltipOpen}
                          target={"Tooltip-" + index}
                          toggle={this.toggleTooltip}
                          trigger="click"
                        >
                          {ele.name + " " + ele.last_name}
                        </Tooltip> */}
                      </span>
                    )
                  )}
                  {/* {this.isMultipleChild() && <FaAngleDown color="black" />} */}
                </Media>
              </DropdownToggle>
            </UncontrolledDropdown>
          </Nav>
        </Container>

        {/* <Loading /> */}
        {
          <ChangeLoginChildOverlay
            isOpen={this.state.showOverLay}
            toggle={(isOpen) => {
              this.setState({
                showOverLay: isOpen,
              });
            }}
            history={this.props.history}
          />
        }
      </Navbar>
    );
  }
}

SideMenu.defaultProps = {
  routes: [{}],
};

SideMenu.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  //
  const activeChildDetail =
    state.userState.user && state.userState.user.activeChildDetail;
  const photo =
    state.userState.user &&
    state.userState.user.activeChildDetail &&
    activeChildDetail.child_photo;
    const childList = state.userState?.user?.childList;
  return {
    user: state.userState.user,

    children: state.userState.user.childList,
    loggedInChild: state.userState.user.activeChildDetail,
    profilePicPresent: typeof photo === "string" && photo !== "",
    childPhotoApprovedStatus:
      activeChildDetail && activeChildDetail.child_approved_status,
    childPhoto:
      state.userState.user.activeChildDetail &&
      state.userState.user.activeChildDetail.child_photo,
    constants: state.constants,
    showBell: state.global.showBell,
    childList,
    parent_type: state.userState.parentProfile.parentType,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  showModal: (type, message, isConfirm, onOkPressed, onCancelPressed) =>
    dispatch(showModal(type, message, isConfirm, onOkPressed, onCancelPressed)),
  changeActiveChild: child=>{
    dispatch(changeLoggedInChild(child))
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideMenu));
//export default withRouter(SideMenu);
