import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics";
import { Capacitor } from "@capacitor/core";
import * as StackTrace from "stacktrace-js";

export const isEnabled = async () => {
    if (Capacitor.isNativePlatform()) {
        const result = await FirebaseCrashlytics.isEnabled();
        return result.enabled;
    }
};

export const sendUnsentReports = async () => {
    if (Capacitor.isNativePlatform()) {
        await FirebaseCrashlytics.sendUnsentReports();
    }
};

export const setEnabled = async (value) => {
    if (Capacitor.isNativePlatform()) {
        await FirebaseCrashlytics.setEnabled({
            enabled: value,
        });
    }
};

export const setUserId = async (details) => {
    if (Capacitor.isNativePlatform()) {
        await FirebaseCrashlytics.setUserId({
            userId: `${details}`,
        });
    }
};

export const setContext = async (key, value, type = 'string') => {
    if (Capacitor.isNativePlatform()) {
        await FirebaseCrashlytics.setContext({
            key: key,
            value: JSON.stringify(value),
            type: type,
        });
    }
};

export const addLogMessage = async (message) => {
  if (Capacitor.isNativePlatform()) {
    await FirebaseCrashlytics.addLogMessage({
      message: JSON.stringify(message),
    });
  }
};

export const handleCrash = async (error) => {
    // console.log("Crash")
    if (Capacitor.isNativePlatform()) {
        await setContext("RecodedError", error);
        await FirebaseCrashlytics.crash({ message: "Test" });
    }
};

export const recordExceptionWithStacktrace = async (error) => {
  if (Capacitor.isNativePlatform()) {
    const stacktrace = await StackTrace.fromError(error);
    await FirebaseCrashlytics.recordException({
      message: "This is a non-fatal message.",
      stacktrace,
    });
  }
};
