import React from "react";
import { connect } from "react-redux";
import { takeABreak } from "./redux/action";
import { showPasswordOverlay } from "../../global/redux/action";

import {
  Col,
  Row,
  Card,
  Form,
  FormGroup,
  Label,
  CardHeader,
  CardBody,
} from "reactstrap";
import { InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

import FootprintDatePicker from "../../global/utils/FootprintDatePicker";
import FootprintInputText from "../../global/utils/FootprintInputText";

import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import moment from "moment";
import FootprintInputButton from "../../global/utils/FootprintInputButton";
class Break extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: undefined,
      endDate: undefined,
      error: "",
    };
  }
  validate = () => {
    if (this.state.fromDate === undefined) {
      this.setState({ error: "Please Select Date" });
      return false;
    } else if (this.state.endDate === undefined) {
      this.setState({ error: "Please Select End Date" });
      return false;
    } else if (this.state.reason === undefined || this.state.reason === '') {
      this.setState({ error: "Please Enter Reason" });
      return false;
    } else {
      return true;
    }
  };

  submit = () => {
    if (this.validate()) {
      // this.props.showPasswordOverlay(true, (isMatched) => {
      //   if (isMatched) {
          this.props.takeABreak(
            this.state.fromDate,
            this.state.endDate,
            this.state.reason,
            () => {
              this.props.history.replace("PastRequestTab");
            }
          );
        } else {
        }
    //   });
    // }
  };

  handleChange = (value) => {
    if (this.state.fromDate)
      this.setState({ reason: value })
    else alert('Please Select Date First')
  }

  render() {
    console.log("[Take a Break] Request for Break...");
    return (
      <div className="commonFeature">
        <Card className="border-0 shadow card-timeline mt-4">
          <CardHeader
            className="text-white"
            style={{
              background: "#A500F8",
              fontFamily: "baloo",
              fontSize: "1.3em",
            }}
          >
            Take a break
          </CardHeader>
          <CardBody>
            <Card body className="border-0 shadow">
              <Form>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="startDate">From</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FootprintDatePicker
                          minimumDate={moment().add("days")}
                          inputProps={{
                            placeholder: "From Date",
                          }}
                          onChange={(date) => {
                            this.setState({
                              fromDate: moment(date),
                            });
                          }}
                          placeholder="Select From Date"
                          readOnly={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="endDate">To (Last Day of Break)</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-calender" />
                          </InputGroupText>
                        </InputGroupAddon>
                        {typeof this.state.fromDate === "undefined" && (
                          <FootprintDatePicker
                            minimumDate={moment(
                              this.state.fromDate,
                              DEFAULT_FORMAT
                            )}
                            onChange={(date) => {
                              this.setState({
                                endDate: moment(date),
                                error: undefined,
                              });
                            }}
                            inputProps={{
                              placeholder: "End Date",
                              disabled: true,
                            }}
                            readOnly={true}
                          />
                        )}
                        {typeof this.state.fromDate !== "undefined" && (
                          <FootprintDatePicker
                            minimumDate={moment(
                              this.state.fromDate,
                              DEFAULT_FORMAT
                            )}
                            maximumDate={
                              new Date(this.state.fromDate).getTime() +
                              1000 * 60 * 60 * 24 * 89
                            }
                            onChange={(date) => {
                              this.setState({
                                endDate: moment(date),
                                error: undefined,
                              });
                            }}
                            placeholder="End date"
                            inputProps={{
                              placeholder: "Select End Date",
                            }}
                            readOnly={true}
                          />
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="exampleText">Reason</Label>
                  <FootprintInputText
                    type="text"
                    updateValue={this.state.fromDate !== undefined}
                    onChange={(value) => this.handleChange(value)}
                    name="text"
                  />
                  {/*<Input
                type="text"
                onChange={event =>
                  this.setState({ reason: event.target.value })
                }
                placeholder="Write a Reason"
                name="text"
                id="exampleText"
              /> */}
                </FormGroup>

                {typeof this.state.error !== "undefined" && (
                  <p style={{ color: "red" }}>{this.state.error}</p>
                )}
                <FormGroup className="text-center mb-0">
                  <FootprintInputButton
                    style={{ color: "white" }}
                    className="btn-lg border-0"
                    onClick={() => {
                      this.submit();
                    }}
                  >
                    Submit
                  </FootprintInputButton>
                </FormGroup>
              </Form>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  overlay: state.global.passwordOverlay,
  currentPassword: state.userState && state.userState.password,
});

const mapDispatchToProps = (dispatch) => ({
  showPasswordOverlay: (show, onSuccess) =>
    dispatch(showPasswordOverlay(show, onSuccess)),
  takeABreak: (startDate, endDate, reason, onSuccess) =>
    dispatch(takeABreak(startDate, endDate, reason, onSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Break);
