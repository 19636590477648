import React, { Component } from "react";
import { CustomInput } from "reactstrap";

export default class FootprintDrop extends Component {
  static defaultProps = {
    value: undefined,
    placeholder: {
      label: "Select an item...",
      value: null,
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.defaultValue || "",
      // value: { label: props.defaultValue, value: props.defaultValue },
    };
  }
  render() {
    //
    const { data, defaultValue, id = "fp_drop" } = this.props;
    // console.log("data", data);
    //  console.log("defaultValue", defaultValue);

    return (
      <CustomInput
        id={id}
        type="select"
        name="customSelect"
        onChange={(event) => {
          if (this.props.onChange) {
            this.props.onChange(event.target.value);
          }
        }}
        defaultValue={defaultValue}
      // defaultValue={{ label: defaultValue, value: defaultValue }}
      >
        <option key={0} value="">
          --Select--
        </option>
        {data.map((item, index) => (
          <option key={index + 1} value={item.value}>
            {item.label}
          </option>
        ))}
      </CustomInput>
    );
  }
}
