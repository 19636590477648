import { createLogic } from "redux-logic";
import {
  FETCH_CONFIG,
  setLoading,
  fetchConfigSuccess,
  setError,
  FETCH_CENTER_LIST,
  fetchCenterSuccess,
  FREE_BEE_LOGIN,
  showModal,
  SAVE_TOKEN,
  saveTokenSuccess
} from "./action";
import fetchAPI from "../utils/fetch";
import Constants from "../../constants";
import { Device } from "@capacitor/device";
import { setContext } from "../utils/crashlyticsMethods";
const logDeviceInfo = async () => {
  const info = await Device.getInfo();

  // console.log(info);
  return info;
};

//const REQUEST_SUBMITTED = "Reqeust Submitted Successfully";

export const fetchConfigLogic = createLogic({
  type: FETCH_CONFIG,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${Constants.env.filePath}`;
      // const body = {
      //     values: Object.keys(DefaultConstants),
      // };
      console.log("[Constants] Calling API: ",url);
      const fetchObject = {
        url,
        method: "GET",
        noHeader: true,
        getState,
        dispatch,
        //   body,
        // body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Constants] Received response: ",response);
          if (response.status === "ok") {
            if (typeof response.response !== "undefined") {
              dispatch(fetchConfigSuccess(response.response));
            }
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response.response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Constants] Error: ",error);
          dispatch(setLoading(false));
          // failure
          // console.log("the error is ", error)
          setContext("Fetch Config failed", error);
          dispatch(showModal("Error", "Server Not Reachable...."));
          done();
        }
      );
    } catch (error) {
      console.log("[Constants] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Fetch Config failed", error);
      done();
    }
  }
});
export const saveTokenLogic = createLogic({
  type: SAVE_TOKEN,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      // dispatch(setLoading(true));
      let url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/saveToken`;
      const info = await logDeviceInfo();
      let body = {
        token: action.payload,
        email: getState().userState.email,
        app_version: Constants.env.version,
        platform: Constants.env.platform,
        web: Constants.env.web,
        parent: getState().userState.parentProfile.parentType,
        device_Modal: `${info?.manufacturer} ${info?.model}`,
        device_os: `${info?.operatingSystem} ${info?.osVersion}`,
      };
      console.log("[Save Token] Calling API: /parentapp/saveToken, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Save Token] Received response: ",response);
          dispatch(saveTokenSuccess(action.payload));
          // dispatch(setLoading(false));
          done();
        },
        error => {
          console.log("[Save Token] Error: ",error);
          // dispatch(setLoading(false));
          // failure
          setContext("Save token failed", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Save Token] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      // dispatch(setLoading(false));
      setContext("Save token failed", error);
      done();
    }
  }
});

export const fetchCenterList = createLogic({
  type: FETCH_CENTER_LIST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getCenterJSON`;
      const body = {
        //
        //
        //
        //
      };
      console.log("[Center JSON] Calling API: /parentapp/getCenterJSON");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Center JSON] Received response: ",response);
          const centerId = getState().userState.user.activeChildDetail
            .center_id;

          dispatch(
            fetchCenterSuccess(
              response.response,
              centerId,
              getState().constants.programList
            )
          );
          dispatch(setLoading(false));
          if (action.payload && action.payload.onSuccess) {
            action.payload.onSuccess();
            done();
          } else {
            done();
          }
        },
        error => {
          console.log("[Center JSON] Error: ",error);
          dispatch(fetchCenterSuccess([]));
          dispatch(setLoading(false));
          // failure
          setContext("Center List Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Center JSON] Error: ",error);
      dispatch(fetchCenterSuccess([]));
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Center List Error", error);
      done();
    }
  }
});

export const freeBeeLogin = createLogic({
  type: FREE_BEE_LOGIN,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/chargebees/login/`;
      const body = {
        id: getState().userState.user.activeChildDetail.id,
        redirect_url: getState().constants.AppUrl || Constants.env.AppUrl
      };
      console.log("[Finance][Login] Calling API: /chargebees/login, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Finance][Login] Received response: ",response);
          if (response.status === "ok") {
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess(response);
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Finance][Login] Error: ",error);
          dispatch(setLoading(false));
          // failure

          done();
        }
      );
    } catch (error) {
      console.log("[Finance][Login] Error: ",error);
      dispatch(setLoading(false));
      done();
    }
  }
});
export default [fetchConfigLogic, fetchCenterList, saveTokenLogic, freeBeeLogin];
