import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Theme from '../../theme';

export const InnerHOC = ({ heading ="Heading", children , className = '' }) => {


    return (
        <Card className="border-0 shadow  card-timeline">
            <CardHeader
                className="text-white"
                style={{
                    background: Theme.colorPrimary,
                    fontFamily: "baloo",
                    fontSize: "1.3em",
                }}
            >
                {heading}
        </CardHeader>
            <CardBody className={className}>
                {children}
            </CardBody>
        </Card>
    )

}