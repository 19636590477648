import { FETCH_TICKETS, fetchTicketsSuccess } from "./action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";

import { RAISE_AN_ISSUE } from "./action";
import { getRequestType, fetchPastRequest } from "../../Support/redux/action";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchTicketsLogic = createLogic({
  type: FETCH_TICKETS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('TICKET', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/listTickets`;
      const body = {
        email: getState().userState.email
      };
      console.log("[Tickets] Calling API: /parentapp/listTickets, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        // errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Tickets] Received response: ",response);
          if (response.status === "ok") {
            // dispatch(fetchTicketsSuccess(JSON.parse(response.response)));
            dispatch(fetchTicketsSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchTicketsSuccess([]));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('TICKET', false))
            done();
          }
        },
        error => {
          console.log("[Tickets] Error: ",error);
          dispatch(setLoading(false));
          dispatch(fetchTicketsSuccess([]));
          // failure
          dispatch(onErrLoading('TICKET', false))
          setContext("Fetch Ticket Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Tickets] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('TICKET', false))
      setContext("Fetch Ticket Error", error);
      done();
    }
  }
});

export const raiseAnIssue = createLogic({
  type: RAISE_AN_ISSUE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      const parentEmail = getState().userState.email;
      const parentPhone = getState().userState.parentProfile.parentPhone.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/createTicket`;
      const activeChildDetail = getState().userState.user.activeChildDetail;
      const body = {
        child_name: activeChildDetail.name + " " + activeChildDetail.last_name,
        branch: activeChildDetail["admission_center_name.name"],
        admissionDate: activeChildDetail.admission_date,
        email: parentEmail,
        name: parentName,
        phone: parentPhone,
        subject: action.payload.subject,
        description: action.payload.description
      };
      console.log("[Create Tickets] Calling API: /parentapp/createTicket, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Create Tickets] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate('CustomerSupport');
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));

            done();
          }
        },
        error => {
          console.log("[Create Tickets] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Raise Issue Error",error);
          done();
        }
      );
    } catch (error) {
      console.log("[Create Tickets] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Raise Issue Error", error);
      done();
    }
  }
});

export default [raiseAnIssue, fetchTicketsLogic];
