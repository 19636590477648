import globalReducer from "./reducer";
import { combineReducers } from "redux";
import newsReducer from "../../features/News/redux/reducer";
import holidayReducer from "../../features/Holidays/redux/reducer";
import blogsReducer from "../../features/Blogs/redux/reducer";
import loginReducer from "../../features/Login/redux/reducer";
import supportReducer from "../../features/Support/redux/reducer";
import cctvReducer from "../../features/Cctv/redux/reducer";
import foodReducer from "../../features/FoodMenu/redux/reducer";
import myCenterReducer from "../../features/MyCenter/redux/reducer";
import outdoorActivityReducer from "../../features/OutdoorActivity/redux/reducer";
import photoGalleryReducer from "../../features/PhotoGallery/redux/reducer";
import growthReducer from "../../features/Growth/redux/reducer";
import childTimelineReducer from "../../features/ChildTimeline/redux/reducer";
import chatReducer from "../../features/Conversation/redux/reducer";
import financeReducer from "../../features/Finance/redux/reducer";
import notificationReducer from "../../features/Notification/redux/reducer";

import surveyReducer from "../../features/Survey/redux/reducer";

import actvityReducer from "../../features/ActivityCalendar/redux/reducer";
import ticketReducer from "../../features/Tickets/redux/reducer";
import constantsReducer from "./constantsReducer";
import guardianReducer from "../../features/Guardian/redux/reducer";
import { LOGOUT_RESET_REDUX } from "./action";
import myProfileReducer from "../../features/MyProfile/redux/reducer";
import attendanceReducer from "../../features/Attendance/redux/reducer";

const appReducer = combineReducers({
  blogs: blogsReducer,
  userState: loginReducer,
  guardian: guardianReducer,
  cctv: cctvReducer,
  growth: growthReducer,
  timeline: childTimelineReducer,
  chat: chatReducer,
  notification: notificationReducer,
  myCenter: myCenterReducer,
  foodmenu: foodReducer,
  actvityCalender: actvityReducer,
  outdoor: outdoorActivityReducer,
  photos: photoGalleryReducer,
  support: supportReducer,
  ticket: ticketReducer,
  finance: financeReducer,
  news: newsReducer,
  survey: surveyReducer,
  holidays: holidayReducer,
  global: globalReducer,
  constants: constantsReducer,
  profile: myProfileReducer,
  attendance: attendanceReducer

  // pastRequest: pastRequestReducer,
  // programChange: changeProgramReducer,
  // shiftCenter: shiftCenterReducer,
  // transport: transportReducer,
  // withdraw: withdrawReducer,
});

const rootReducer = (state, action) => {
  let myState = state;
  // const constants = { ...DefaultConstants, ...action.payload };
  if (action.type === LOGOUT_RESET_REDUX) {
    return appReducer({}, action);
  }
  return appReducer(myState, action);

//   else if (action.type === LOGOUT) {

//   }
}

export default rootReducer;
