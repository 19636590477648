import React from "react";
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
import Theme from "../../theme";

const CenterHeadInfo = ({ item, teachersPath }) => {
  // console.log("Item: ", teachersPath);
  return (
    <div className="d-flex justify-content-center w-100 mb-3 m-0">
      <Card className="border-0 shadow card-timeline" style={{minWidth: "365px"}}>
        <CardHeader
          className="text-white"
          style={{
            background: Theme.colorPrimary,
            fontFamily: "baloo",
            fontSize: "1em",
          }}
        >
          Center's Head Information
        </CardHeader>
        <CardBody className="d-flex justify-content-center flex-column border border-info">
          <div
            className="teacher-img"
            style={{
              backgroundImage: "url" + "(" + teachersPath + item.image + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "250px",
            //   width: "250px",
            }}
          ></div>
          <CardTitle className="mt-3 m-0 text-center">
            <p className="m-0" style={{ fontFamily: "baloo" }}>
              {item.name}
            </p>
          </CardTitle>
          <CardTitle className="m-0 text-center">
            <p className="m-0" style={{ fontFamily: "baloo" }}>
              {item.phone}
            </p>
          </CardTitle>
        </CardBody>
      </Card>
    </div>
  );
};

export default CenterHeadInfo;
