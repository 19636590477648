export const POST_CHAGNE_PROGRAM = "POST_CHAGNE_PROGRAM";
export const CANCEL_CHANGE_PROGRAM = "CANCEL_CHANGE_PROGRAM";
export const changeProgram = (selectedDate, selectedProgram, onSuccess) => ({
  type: POST_CHAGNE_PROGRAM,
  payload: { selectedDate, selectedProgram, onSuccess }
});

export const cancelChangeProgram = request => ({
  type: CANCEL_CHANGE_PROGRAM,
  payload: request
});
