import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { GoPrimitiveDot } from 'react-icons/go'
// reactstrap components
import {
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Tooltip
} from "reactstrap";

import { connect } from "react-redux";

// import Loading from "../../global/utils/Loading";
import { withRouter } from "react-router-dom";
import ChangeLoginChildOverlay from "../ChangeLoginChildOverlay";
import { getFullPath } from "../../features/MyProfile/index";
import Theme from "../../theme";
import { changeLoggedInChild } from "../../features/Login/redux/action";
import ReloadData from "../utils/ReloadData";
// import { Capacitor } from "@capacitor/core";
import { setContext } from "../utils/crashlyticsMethods";

class HeaderHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverLay: false,
      tooltipOpen: "",
      locations: []
    };
  }

  componentDidMount() {
    setContext("Locations", [
      ...this.state.locations,
      this.props.history.location.pathname,
    ].join(","));
    this.setState({
      ...this.state,
      locations: [this.props.history.location.pathname],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.locations[this.state.locations.length - 1].toString() !== this.props.history.location.pathname.toString()
    ) {
      // console.log("location => ",this.props.history.location.pathname);
      setContext(
        "Locations",
        [...this.state.locations, this.props.history.location.pathname].join(
          ","
        )
      );
      this.setState({
        ...this.state,
        locations: [
          ...this.state.locations,
          this.props.history.location.pathname,
        ],
      });
    }
  }

  isMultipleChild = () => {
    return (
      Array.isArray(this.props.user.childList) &&
      this.props.user.childList.length > 1
    );
  };

  toggleTooltip = (item) => {
    this.setState({ tooltipOpen: item });
  };

  showChildChangeOption = () => {
    this.setState({ showOverLay: true }, () => {});
  };

  handleProfile = (ele = false) => {
    if (this.isMultipleChild()) {
      if (ele) {
        let parent_token = "";
        if (this.props.parent_type === "Mother") {
          parent_token = "mother_access_token";
        } else {
          parent_token = "father_access_token";
        }
        localStorage.setItem("auth_token", ele[parent_token]);
        this.props.changeActiveChild(ele);
      }
      if (this.props.history.location.pathname === "/myProfile") {
        // window.location.reload();
        //  this.props.history.replace("/timeline");
        this.setState({...this.state, render: Math.random()*100})
      } else this.props.history.replace("/myProfile");
    } else {
      this.props.history.replace("/myProfile");
    }
  }

  render() {
    const { child } = this.props;
    const childFullName =
      child.name + " " + (child.last_name ? child.last_name : "");
    const logo = {
      imgAlt: "Footprints-Logo",
      imgSrc: require("../../assets/Footprints-Logo.svg"),
    };
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    // console.log(JSON.parse(localStorage.getItem("persist:root")));
    // console.log("History=> ",this.props.history)
    return (
      <>
        <ReloadData history={this.props.history} />
        <Navbar
          className="navbar-top fixed-top navbar-light bg-white shadow mb-lg-5 mb-md-5 d-none d-md-block"
          expand="md"
          id="navbar-main"
        >
          <Container fluid className="d-flex flex-row">
            {/* <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/" >
              {this.props.brandText}
            </Link> */}

            {/* Brand */}
            {logo ? (
              <NavbarBrand
                className="pt-0  d-lg-inline-block"
                {...navbarBrandProps}
              >
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                  width="250px"
                />
              </NavbarBrand>
            ) : null}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <div>
                <Link to="/notification">
                  <i
                    className="icon-notification"
                    style={{ fontSize: "1.5em", color: "#777" }}
                  />
                  {this.props.showBell && (
                    <GoPrimitiveDot
                      style={{
                        color: Theme.danger,
                        position: "relative",
                        right: 19,
                        top: -13,
                      }}
                    />
                  )}
                </Link>
              </div>
              <div className="ml-3">
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media
                      style={{
                        cursor: this.isMultipleChild() ? "pointer" : "default",
                      }}
                      className="align-items-center"
                    >
                      {this.props.childList.map((ele, index) =>
                        this.props.profilePicPresent ? (
                          <div className="position-relative" key={index}>
                          <span
                            key={index}
                            className="avatar avatar-sm rounded-circle"
                            onClick={() => {
                             this.handleProfile(ele)
                            }}
                            style={{
                              backgroundImage:
                                "url" +
                                "(" +
                                getFullPath(
                                  this.props.constants,
                                  ele.child_photo
                                ) +
                                ")",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              zIndex: 9,
                            }}
                            id={"Tooltip-" + index}
                            onMouseLeave={() =>
                              this.setState({
                                showOverLay: false,
                                tooltipOpen: "",
                              })
                            }
                          >
                            {/* {console.log("\nTooltip-" + index)} */}
                            </span>
                            { ele.id == this.props?.child?.id ? <span className="position-absolute right-0 bg-success border rounded" style={{height: '10px', width: '10px', bottom: '6px'}}></span> : null}
                           <Tooltip
                              placement="top"
                              isOpen={
                                this.state.tooltipOpen === "Tooltip-" + index
                              }
                              target={"Tooltip-" + index}
                              toggle={() =>
                                this.toggleTooltip("Tooltip-" + index)
                              }
                            >
                              {ele.name + " " + ele.last_name}
                            </Tooltip>
                            </div>
                        ) : (
                          <span
                            className="avatar avatar-sm rounded-circle"
                            onClick={() => {
                             this.handleProfile()
                            }}
                            style={{
                              backgroundImage:
                                "url(" +
                                require("../../assets/left-nico.png") +
                                ")",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            id={"Tooltip-" + index}
                            onMouseLeave={() =>
                              this.setState({
                                showOverLay: false,
                                tooltipOpen: "",
                              })
                            }
                          >
                            <Tooltip
                              placement="top"
                              isOpen={
                                this.state.tooltipOpen === "Tooltip-" + index
                              }
                              target={"Tooltip-" + index}
                              toggle={() =>
                                this.toggleTooltip("Tooltip-" + index)
                              }
                              style={{ background: "rgb(25, 173, 228)" }}
                            >
                              {ele.name + " " + ele.last_name}
                            </Tooltip>
                          </span>
                        )
                      )}

                      <Media className="ml-2 d-none d-lg-block">
                        <span
                          onClick={() => {
                            if (this.isMultipleChild()) {
                              this.showChildChangeOption();
                            } else {
                            }
                          }}
                          className="mb-0 text-sm font-weight-bold text-dark"
                        >
                          {`${childFullName}`}
                          {this.isMultipleChild() && (
                            <FaAngleDown color="black" />
                          )}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </div>
            </Nav>
          </Container>

          {/* <Loading scope="sectionBar" /> */}
        </Navbar>
        {
          <ChangeLoginChildOverlay
            isOpen={this.state.showOverLay}
            toggle={(isOpen) => {
              this.setState({
                showOverLay: isOpen,
              });
            }}
            history={this.props.history}
          />
        }
      </>
    );
  }
}
const mapStateToProps = state => {
  const activeChildDetail =
    state.userState.user && state.userState.user.activeChildDetail;
  const photo =
    state.userState.user &&
    state.userState.user.activeChildDetail &&
    activeChildDetail.child_photo;
  let childList = state.userState?.user?.childList
  // if (childList.length > 1) {
  //   let childArr = childList.filter(child => child.id !== activeChildDetail.id);
  //   childList = [activeChildDetail, ...childArr];
  // }
  // console.log("PhotoList: " + childList);
  return {
    child: state.userState.user.activeChildDetail,
    user: state.userState.user,
    profilePicPresent: typeof photo === "string" && photo !== "",
    childPhotoApprovedStatus:
      activeChildDetail && activeChildDetail.child_approved_status,
    childPhoto:
      state.userState.user.activeChildDetail &&
      state.userState.user.activeChildDetail.child_photo,
    constants: state.constants,
    showBell: state.global.showBell,
    childList: childList,
    parent_type: state.userState.parentProfile.parentType,
  };
};

const mapDispatchToProps = dispatch => ({
  changeActiveChild: child=>{
    dispatch(changeLoggedInChild(child))
  }
});
const HeaderRouter = withRouter(HeaderHOC);
export default connect(mapStateToProps, mapDispatchToProps)(HeaderRouter);
//export default HeaderHOC;
