import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  ListGroup,
  ListGroupItem,
  Badge,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import className from "classnames";
import { setNavigationParams } from "../../global/redux/action";

class ChildTimelineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { items, index } = this.props;
    // console.log("Items => ",items);
    return (
      <li
        key={index}
        className={className({
          //timeline: index % 2 === 0,
          "timeline-inverted": index % 2 === 1,
        })}
      >
        <div
          className="timeline-badge"
          style={{ background: "#27ade4", zIndex: "0" }}
        ></div>
        <div className="timeline-panel">
          <Card className="border-0 shadow mb-0">
            <CardHeader style={{ borderBottom: "0" }}>
              <Badge
                color="success"
                pill
                style={{
                  fontFamily: "baloo",
                  fontSize: ".9em",
                  color: "#27ade4",
                  background: "rgba(109, 186, 218, 0.52)",
                  paddingTop: "7px",
                }}
              >
                {items.dateGroup === moment().format("DD-MM-YY")
                  ? "Today"
                  : moment(items.dateGroup, "DD-MM-YY").format(DEFAULT_FORMAT)}
              </Badge>
            </CardHeader>
            <CardBody className="pt-0">
              <ListGroup flush>
                {items.data.map((item, iIndex) => (
                  <ListGroupItem
                    key={iIndex.toString()}
                    className="px-0 d-flex justify-content-between align-items-start"
                  >
                    {item.time}-
                    {item.type === "Image"
                      ? // <img
                        //   alt="Fp_thumb"
                        //   src={
                        //     this.props.constants.TimeLineThumbUrl +
                        //     "/" +
                        //     item.message
                        //   }
                        //   style={{
                        //     width: "60px",
                        //     borderRadius: "50%",
                        //     backgroundSize: "cover",
                        //     backgroundPosition: "center",
                        //   }}
                        // />
                        null
                      : " " + item.message}
                    {item.linkImg !== "" ? (
                      <Badge
                        pill
                        style={{
                          width: "40px",
                          height: "40px",
                          paddingLeft: 0,
                        }}
                        onClick={() => {
                          if (item.navigateTo)
                            this.props.history.push(item.navigateTo);
                          if (item.navigationParams) {
                            this.props.setNavigationParams(
                              item.navigationParams
                            );
                          }
                        }}
                      >
                        {item.navigateTo ? (
                          item.type === "Image" ? (
                            <Link
                              to={item.navigateTo}
                            >
                              <img
                                alt="Fp_Linkthumb"
                                src={
                                  this.props.constants.TimeLineThumbUrl +
                                  "/" +
                                  item.message
                                }
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "100%",
                                  objectFit:'fill'
                                }}
                              />
                            </Link>
                          ) : (
                            <Link to={item.navigateTo}>
                              <img
                                alt="Fp_Linkthumb"
                                src={item.linkImg}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                            </Link>
                          )
                        ) : (
                          <img
                            alt="Fp_Linkthumb"
                            src={item.linkImg}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        )}
                        {/* <img
                            src={item.linkImg}
                            style={{ width: "30px", height: "30px" }}
                          /> */}
                      </Badge>
                    ) : (
                      <Badge
                        pill
                        className="badge-success"
                        style={{ width: "45px", height: "45px" }}
                      >
                        <i
                          className="icon-rest-child"
                          style={{ fontSize: "3em", margin: "-11px" }}
                        ></i>
                      </Badge>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      </li>
    );
  }
}
const mapStateToProps = (state) => ({
  constants: state.constants,
});

const mapDispatchToProps = (dispatch) => ({
  setNavigationParams: (params) => dispatch(setNavigationParams(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChildTimelineItem);
