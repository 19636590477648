import firebase from "firebase/app";
import 'firebase/messaging'

const VAPID_KEY = "BCCLvIU9-zzKB1r5yZrQtCW7J18NfGnX59SFKQvxiUEY8Oms64_H8NTxNWjsuKYzBgj6jeU7pkxyq-19mgINZYU"

export const initializeFirebase = (onSucess, onMessage, onError) => {
  const config = {
    apiKey: "AIzaSyBIjrPLinO3eHfA9elYbJNNjDG2eAvp1EM",
    authDomain: "parentconnect-4fe39.firebaseapp.com",
    databaseURL: "https://parentconnect-4fe39.firebaseio.com",
    projectId: "parentconnect-4fe39",
    storageBucket: "parentconnect-4fe39.appspot.com",
    messagingSenderId: "78077811192",
    appId: "1:78077811192:web:165e4515cbbc4c0b0f1e0d",
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  requestToGetNotifications(onSucess, onMessage, onError);
};

const requestToGetNotifications = (onSucess, onMessage, onError) => {
  try {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(VAPID_KEY);

    messaging
      .requestPermission()
      .then(() => {
        // console.log("Notification Permission Granted.");
        return messaging.getToken();
      })
      .then((token) => {
        // console.log("Web Token : ", token);
        if (onSucess) {
          onSucess(token);
          messaging.onMessage((payload) => {
            // console.log('Message received. ', payload);
            if (onMessage) {
              onMessage(payload.notification)
            }
          });

        }
      })
      .catch((err) => {
        if (err.code === "messaging/permission-blocked") {
          console.log("[Push Notification] Please Unblock Notification Request Manually");
          if (onError) {
            onError("Please Unblock Notification Request Manually")
          }
        } else {
          console.log("[Push Notification] Error Occurred", err);
        }
      });
  } catch (err) {
    if (err.code === "messaging/permission-blocked") {
      console.log("[Push Notification] Please Unblock Notification Request Manually");
      if (onError) {
        onError("Please Unblock Notification Request Manually")
      }
    } else {
      console.log("[Push Notification] Error Occurred", err);
    }
  }

};

