import React from "react";
import { connect } from "react-redux";
import { fetchHoliday } from "./redux/action";
import { Row } from "reactstrap";

import HolidayItem from "./item";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";

class Holidays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("[Holiday Calendar] Fetching holiday...");
    this.props.fetchHoliday();
  }
  render() {
    // console.log(this.props.holidayCalender);
    let monthList=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November','December'];
    let holidayList=[]
    let currentMonth=new Date().getMonth();
    holidayList=this.props.holidayCalender.filter((data)=> monthList.findIndex((ele)=> data.dateGroup === ele)>=currentMonth);
    let pastholidayList = this.props.holidayCalender.filter(
      (data) =>
        monthList.findIndex((ele) => data.dateGroup === ele) < currentMonth
    );
    const holidayRenderList=[...holidayList,...pastholidayList];
    // console.log(holidayRenderList);
    return (
      <InnerHOC heading="Holiday Calendar">
        {
          <React.Fragment>
            {holidayRenderList.map((monthWiseData, index) => (
              <div key={index}>
                <h3 className="pb-2 mb-4 border-bottom">
                  {monthWiseData.dateGroup}
                </h3>
                <Row>
                  {monthWiseData.data.map(item => {
                    if (item.background === -1)
                      return (
                        <HolidayItem
                          key={item.id}
                          background="#E6E6E6"
                          titleColor="#32325D"
                          item={item}
                        />
                      );
                    if (item.background === 0)
                      return (
                        <HolidayItem
                          key={item.id}
                          boxShadow="0 20px 50px 0 rgba(0,195,81,0.24)"
                          background="#00C351"
                          item={item}
                        />
                      );
                    if (item.background === 1)
                      return (
                        <HolidayItem
                          key={item.id}
                          boxShadow=" 0 20px 50px 0 rgba(0,0,0,0.10)"
                          background="#ffffff"
                          titleColor="#32325D"
                          item={item}
                        />
                      );
                    return ( <HolidayItem
                      key={item.id}
                      boxShadow="0 20px 50px 0 rgba(0,195,81,0.24)"
                      background="#00C351"
                      item={item}
                    />);
                  })}
                </Row>
              </div>
            ))}
          </React.Fragment>
        }
        {!Array.isArray(this.props.holidayCalender) ||
          (this.props.holidayCalender.length === 0 && <ListEmptyComponent heading={this.props.loading ? "Loading..." : "No Data Found"} />)}
      </InnerHOC>
    );
  }
}

const mapStateToProps = state => ({
  holidayCalender: state.holidays.holiday,
  loading: state.holidays.loading
});
const mapDispatchToProps = dispatch => ({
  fetchHoliday: () => dispatch(fetchHoliday())
});

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
