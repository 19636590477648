import React from "react";
import { connect } from "react-redux";

import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import ReactPlayer from 'react-player'

class ParentVedio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const ParentOrientationVideos = this.props.constants
      .ParentOrientationVideos;
    return (
      <InnerHOC heading="Videos">
        {Array.isArray(ParentOrientationVideos) &&
          ParentOrientationVideos.length > 0 && (
            <div>
              <div>
                {ParentOrientationVideos.map((items, index) => (
                  <ReactPlayer key={index} url={items} width="100%" controls={true} />
                ))}
              </div>
            </div>
          )}
        {!Array.isArray(ParentOrientationVideos) ||
          (ParentOrientationVideos.length === 0 && <ListEmptyComponent />)}
      </InnerHOC>
    );
  }
}
const mapStateToProps = state => ({
  constants: state.constants
});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ParentVedio);
