import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { LIMIT } from "./redux/reducer";
import { fetchPhotos } from "./redux/action";
import { Card, CardHeader, CardBody, Alert } from "reactstrap";
import Theme from "../../theme";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageDownloader from "./URLDownload";
import { Modal, ModalGateway } from "react-images";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import PhotoItem from "./item";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  FaFacebook,
  FaTwitter,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import DefaultConstants from "../../defaultConstants";
import "@ionic/react/css/core.css";

var Carousel = require('react-responsive-carousel').Carousel;

// const MIN_HEIGHT = 150;
// const MAX_HEIGHT = 200;
// let lastScrollY = 0;
// let ticking = false;
export const PHOTO_LIMIT = 50;
/*
const { HeaderClose, HeaderFullscreen } = carouselComponents;
const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
};*/

const FACEBOOK_APPID = "1663256590659601";

class PhotoGallery extends React.PureComponent {
  arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(45% - 15px)",
    width: 30,
    height: 50,
    cursor: "pointer",
    border: 0,
    background: "none",
  };
  static navigationOptions = ({ navigation }) => ({
    header: null,
  });
  constructor(props) {
    super(props);
    this.state = {
      startIndex: 0,
      endIndex: 49,
      height: window.innerHeight,
      message: "not at bottom",
    };
  }

  componentDidMount() {
    console.log("[Photo Gallery] Fetching photos...");
    // console.log(
    //   "Caption  = " +
    //     this.props.childName +
    //     " in " +
    //     this.props.class +
    //     " at " +
    //     this.props.centerName
    // );
    
    this.props.fetchPhotos(this.state.startIndex);
    //window.addEventListener("scroll", this.handleScroll());
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll());
  }

  previous = (photos) => {
    if (this.state.startIndex) {
      const startIndex = this.state.startIndex - LIMIT;
      const endIndex = this.state.endIndex - LIMIT;
      this.setState({
        startIndex: startIndex,
        endIndex: endIndex,
      });

      if (startIndex < 0) {
        this.setState({
          startIndex: 0,
        });
      }
      if (endIndex < 0) {
        this.setState({
          endIndex: 24,
        });
      }

      this.props.fetchPhotos(startIndex);
    }
  };
  next = () => {
    const startIndex = this.state.startIndex + LIMIT + 1;

    this.setState({
      startIndex,
    });
    this.props.fetchPhotos(startIndex);
  };

  shareImageFacebook = (image) => {
    let caption =
      this.props.childName +
      " in " +
      this.props.class +
      " at " +
      this.props.centerName;
    let url = "https://www.facebook.com/dialog/feed?display=popup";
    url += "&app_id=" + FACEBOOK_APPID;
    url += "&caption=";
    url += "&link=" + image;
    url += "&description=";
    url += "&title=" + caption;

    window.open(url);
  };

  getImageName = (image) => {
    let index = image.lastIndexOf("/");
    return image.substring(index + 1);
  };

  shareImageTwitter = (image) => {
    let caption =
      this.props.childName +
      " in " +
      this.props.class +
      " at " +
      this.props.centerName;
    let url = "https://twitter.com/intent/tweet?text=" + caption;
    url += "&url=" + image;

    window.open(url);
  };

  displayPhotos = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, index) => {
      // let selectedImg=this.props?.photos[dateIndex].photos[index];
      // let selIndex=this.props?.allPhotos.findIndex((ele)=>ele.message === selectedImg.src);
      // setCurrentImage(selIndex >= 0 ? selIndex : 0);
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

    const Footer = (imageToShare) => {
      //console.log("the currentImage is ", props.currentView.src);
      //const imageToShare = props.currentView.src;
      return (
        <div style={{ alignSelf: "center", flexDirection: "row" }}>
          <Link to="#" onClick={() => this.shareImageFacebook(imageToShare)}>
            <FaFacebook
              style={{ color: "#4267B2", fontSize: 30, marginLeft: 10 }}
            />
          </Link>
          <Link to="#" onClick={() => this.shareImageTwitter(imageToShare)}>
            <FaTwitter
              style={{ color: "#1DA1F2", marginLeft: 10, fontSize: 30 }}
            />
          </Link>
        </div>
      );
    };

    //console.log(this.props.photos)
    return (
      <div className="">
        {/* <Gallery
          photos={this.props.photos[0].photos}
          onClick={() => openLightbox(e, ele)}
        /> */}
        {/* <div className="d-flex flex-column">
          {this.props.photos.map((ele, index) => (
            <div className=" p-2 " key={index}>
              <p className="text-start pl-2 mb-1">
                <span>{ele.date}</span>
              </p>
              <div className="d-flex flex-wrap">
                <Example
                  item={ele.photos}
                  openLightbox={openLightbox}
                  dateIndex={index}
                />
              </div>
            </div>
          ))}
        </div> */}
        <IonGrid>
          <IonRow className="pb-3">
            {this.props.photos.map((photo, index) => (
              <IonCol size="4" key={index}>
                <IonImg
                  src={photo.src}
                  onClick={(e) => openLightbox(e, index)}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  !hasPrev ? null : (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...this.arrowStyles, left: 5 }}
                    >
                      <FaAngleLeft style={{ color: "#4267B2", fontSize: 35 }} />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  !hasNext ? null : (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...this.arrowStyles, right: 22 }}
                    >
                      <FaAngleRight
                        style={{ color: "#4267B2", fontSize: 35 }}
                      />
                    </button>
                  )
                }
                width="700px"
                selectedItem={currentImage}
                showStatus={false}
                showIndicators={false}
                showArrows={true}
                showThumbs={false}
                swipeable={true}
              >
                {this.props.photos.map((ele, index) => (
                  <div key={index} className="d-flex align-items-center h-100">
                    {/* <UrlImageDownloader imageUrl={ele.src} /> */}
                    <ImageDownloader
                      imageUrl={ele.src}
                      component={Footer(ele.src)}
                      closeLightbox={closeLightbox}
                    />
                    {/* {Footer(ele.src)} */}
                  </div>
                ))}
              </Carousel>
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  };

  render() {
    // console.log("Photos: ", this.props.photos);
    return (
      <Card
        className="border-0 shadow  card-timeline"
        style={{ padding: "0 !important" }}
      >
        <CardHeader
          className="text-white"
          style={{
            background: Theme.colorPrimary,
            fontFamily: "baloo",
            fontSize: "1.3em",
          }}
        >
          My Photos
        </CardHeader>
        <CardBody className="p-0">
        <Card body className="border-0 shadow pt-4 px-0">
          <Alert color="warning" className="mx-2 md:mx-4">
                {this.props.constants.ArchiveMessage || DefaultConstants.ArchiveMessage}
          </Alert>
          {Array.isArray(this.props.photos) && this.props.photos.length > 0 && (
            <InfiniteScroll
              dataLength={this.props.photos.length}
              next={this.next}
              hasMore={this.props.dataPresent}
            >
              <this.displayPhotos />
            </InfiniteScroll>
          )}
          {(!Array.isArray(this.props.photos) ||
            this.props.photos.length === 0) && (
            <div className="p-4">
              <ListEmptyComponent
                heading={this.props.loading ? "Loading..." : "No Data Found"}
              />
            </div>
              )}
            </Card>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  const activeChildDetail = 
    state?.userState?.user && state?.userState?.user?.activeChildDetail;
  return {
    photos: state.photos.fullScreenPhotos || [],
    allPhotos: state?.photos?.photos,
    dataPresent: state?.photos?.photosDataPresent,
    childName:
      activeChildDetail &&
      activeChildDetail.name + " " + activeChildDetail.last_name,
    class: activeChildDetail && activeChildDetail["room.name"],

    centerName:
      activeChildDetail && activeChildDetail["admission_center_name.name"],
    loading: state?.photos?.loading,
    constants: state.constants
  };
};
// const mapStateToProps = state => ({

// moreData: state.myKid.photos.moreData,
// fullScreenPhotos: state.myKid.fullScreenPhotos,
// dataPresent: state.myKid.photosDataPresent,
// });

const mapDispatchToProps = (dispatch) => ({
  // fetchPhotos: () => dispatch(fetchPhotos()),
  fetchPhotos: (startIndex) => dispatch(fetchPhotos(startIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);
