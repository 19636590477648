import React from "react";
import { connect } from "react-redux";

// import moment from "moment";
import { getRequestType } from "../Support/redux/action";
import { checkCCTV } from "./redux/action";
import { Card } from "reactstrap";
import WebView from "./FootprintWebView";
// import DefaultConstants from "../../defaultConstants";
import { fetchChildDetail } from "../Login/redux/action";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DefaultConstants from "../../defaultConstants";
class CCTV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childOnPause: false,
    };
  }

  componentDidMount() {
    // this.props.fetchChildData();
    console.log("[Live CCTV] Checking CCTV Connection...");
    if (
      this.props.user &&
      this.props.user.pause_state === 1
    ) {
      if (this.state.childOnPause === false) {
        this.setState({
          childOnPause: true,
        });
      }
    } else {
      this.props.checkCCTVConnection();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    // this.props.fetchChildData();
    if (
      typeof prevProps.user === "undefined" &&
      this.props.user &&
      this.props.user.pause_state === 1
    ) {
      if (this.state.childOnPause === false) {
        this.setState({
          childOnPause: true,
        });
      }
    } else {
      this.props.checkCCTVConnection();
    }
    /* if (
      typeof prevProps.user === "undefined" &&
      this.props.user &&
      this.props.user.id
    ) {
      this.props.checkCCTVConnection();
    }*/
  }

  render() {
    //
    //const { cctvLinks } = this.props;
  
    if (this.state.childOnPause === true) {
      return (
        <InnerHOC
          heading="Live CCTV"
          children={
            // <Alert color="warning">
              <div>
                {this.props.constants.cameraPauseTitle || DefaultConstants.cameraPauseTitle}
                <br></br>
                {this.props.constants.cameraPauseSubtitle || DefaultConstants.cameraPauseSubtitle}
              </div>
            // </Alert>
          }
        ></InnerHOC>
      );
    } else if (this.props.cctvDown === true) {
      return (
        <InnerHOC
          heading={
            <div className="d-flex justify-content-center align-items-center">
              <div>Live CCTV</div>

              <div className="ml-auto">
                <Link
                  to="#"
                  onClick={() => {
                    this.props.checkCCTVConnection();
                  }}
                >
                  <i
                    className="icon-refresh fa-spin-custom"
                    style={{ color: "#fff", fontSize: "1.2em" }}
                  ></i>
                </Link>
              </div>
            </div>
          }
          children={
            // <Alert color="warning">
            
              this.props?.constants?.cctvServerDownMessage ||
              `Oops! Internet services not working at center. We will notify you once internet is up and running. Please connect with the Center in case you have not heard from us in some time.`
            // </Alert>
          }
        ></InnerHOC>
      );
    } else if (this.props.user && this.props.user.html5_player_link) {
      // console.log(
      //   "check value of this.props.user.html5_player_link : " +
      //     this.props.user.html5_player_link
      // );

      return (
        <Card className="border-0 shadow  card-timeline mt-4 mb-4">
          <WebView url={this.props.user.html5_player_link} />
        </Card>
      );
    }
    // else if (
    //   !moment().isBetween(moment("08:45", "hh:mm"), moment("18:45", "hh:mm"))
    // ) {
    //   return <h4>{this.props.constants.cameraAvaiableTimings}</h4>;
    // }
    else {
      return <div />;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    constants: state.constants,
    cctvLinks:
      state.userState.user &&
      state.userState.user.activeChildDetail &&
      state.userState.user.activeChildDetail.cctv_links,
    user: state.userState.user && state.userState.user.activeChildDetail,
    cctvDown: state.cctv.centerCctvDown,
    allowedServices: state.support.requestType.allowedServices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkCCTVConnection: () => dispatch(checkCCTV()),
  getRequestType: () => dispatch(getRequestType()),
  fetchChildData: () => dispatch(fetchChildDetail()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CCTV);
