import React from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody } from 'reactstrap';
import ChildTimelineItem from "./item";
import { fetchChildTimeLine } from "./redux/action";
import { ITEM_PER_PAGE } from "../../global/redux/combineLogic";
import InfiniteScroll from "react-infinite-scroll-component";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import Theme from '../../theme';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { setContext } from "../../global/utils/crashlyticsMethods";

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startIndex: 0,
    };
  }
  async componentDidMount() {
    console.log("[Child TimeLine] Fetching data...");
    await this.props.fetchChildTimeLine(this.state.startIndex);
    setContext("Child Time Line", this.props.timeline);
  }

  next = () => {
    const startIndex = this.state.startIndex + ITEM_PER_PAGE;
    this.setState({ startIndex }, () => { });
    this.props.fetchChildTimeLine(startIndex);
  };
  render() {
    const { timeline, loading } = this.props;
    return (
      <Card className="border-0 shadow  card-timeline mt-4">
        <CardHeader
          className="text-white"
          style={{
            background: Theme.colorPrimary,
            fontFamily: "baloo",
            fontSize: "1.3em"
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div>
              Child Timeline
            </div>


            <div className="ml-auto">
              <Link
                to='#'
                onClick={() => {
                  this.props.fetchChildTimeLine(this.state.startIndex);
                }}
              >
                <i
                  className="icon-refresh fa-spin-custom"
                  style={{ color: "#fff", fontSize: "1.2em" }}
                ></i>
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {Array.isArray(timeline) && timeline.length > 0 && (
            <InfiniteScroll
              dataLength={timeline.length}
              next={this.next}
              hasMore={this.props.dataAvailable}
            >
              <ul className="timeline">
                {timeline.map((items, index) => (
                  <ChildTimelineItem
                    history={this.props.history}
                    items={items}
                    key={index}
                    index={index}
                  />
                ))}
              </ul>
            </InfiniteScroll>
          )}
          {!Array.isArray(timeline) ||
            (timeline.length === 0 && <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} />)}{" "}
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  let timeline = state.timeline.sectionedTimeLine;
  return { timeline, dataAvailable: state.timeline.dataAvailable, loading: state.timeline.loading };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChildTimeLine: (startIndex) => dispatch(fetchChildTimeLine(startIndex)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
