import { GET_GUARDIAN_SUCCESS } from "./action";

const initialState = {
  list: [],
};
const guardianReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GUARDIAN_SUCCESS: {
      return {
        ...state,
        list: action.payload.response,
      };
    }

    default:
      return state;
  }
};
export default guardianReducer;
