export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_VERIFY = "SIGN_IN_VERIFY";
export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_OTP_SUCCESS = "FETCH_OTP_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const VERIFY_OTP = "VERIFY_OTP";
export const SAVE_OTP = "SAVE_OTP";
export const CHANGE_LOGGEDIN_CHILD = "CHANGE_LOGGEDIN_CHILD";
export const FREE_BEE_LOGIN = "FREE_BEE_LOGIN";
export const SHUFFLE_BLOGS = "SHUFFLE_BLOGS";
export const FETCH_CHILD_DETAIL = "FETCH_CHILD_DETAIL";
export const SET_CHILD_DETAIL = "SET_CHILD_DETAIL";

export const UPDATE_PARENT_PROFILE = "UPDATE_PARENT_PROFILE";
export const UPDATE_CHILD_PHOTO = "UPDATE_CHILD_PHOTO";

export const updateChildPhoto = (url, status = "Pending For Approval") => ({
  type: UPDATE_CHILD_PHOTO,
  payload: {
    url,
    status,
  },
});

export const updateParentProfile = (url, status = "Pending For Approval") => ({
  type: UPDATE_PARENT_PROFILE,
  payload: {
    url,
    status,
  },
});

export const changeLoggedInChild = (child) => ({
  type: CHANGE_LOGGEDIN_CHILD,
  payload: child,
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchOtpSuccess = (otp) => ({
  type: FETCH_OTP_SUCCESS,
  payload: otp,
});

export const logout = () => ({
  type: LOGOUT,
});

export const freeBeeLogin = (onSuccess) => ({
  type: FREE_BEE_LOGIN,
  payload: { onSuccess },
});

export const signIn = (
  phone,
  email,
  hash,
  onSuccess,
  onFailure
) => ({
  type: SIGN_IN,
  payload: { phone,email,hash, onSuccess, onFailure },
});
export const signInVerify = (
  otp,
  onSuccess,
  onFailure
) => ({
  type: SIGN_IN,
  payload: { otp, onSuccess, onFailure },
});

export const fetchBlogs = () => ({
  type: FETCH_BLOGS,
});

export const fetchBlogsSuccess = (response) => ({
  type: FETCH_BLOGS_SUCCESS,
  payload: response,
});

export const forgotPassword = (email, onSuccess) => ({
  type: FORGOT_PASSWORD,
  payload: { email, onSuccess },
});

export const saveOTP = (otpDetails) => ({
  type: SAVE_OTP,
  payload: { otpDetails },
});

export const verifyOTP = (otp, verify, reload = false, onSuccess, onError) => ({
  type: VERIFY_OTP,
  payload: { otp, verify, reload, onSuccess, onError },
});


export const shuffleBlogs = () => ({
  type: SHUFFLE_BLOGS,
  payload: {},
});

export const fetchChildDetail = (response) => ({
  type: FETCH_CHILD_DETAIL,
  payload: { response }
});

export const setChildDetail = (response) => ({
  type: SET_CHILD_DETAIL,
  payload: { response }
});
