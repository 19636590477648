export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const FETCH_HOLIDAYS_SUCCESS = "FETCH_HOLIDAYS_SUCCESS";

export const fetchHoliday = () => ({
  type: FETCH_HOLIDAYS
});

export const fetchHolidaySuccess = holidays => ({
  type: FETCH_HOLIDAYS_SUCCESS,
  payload: {
    holidays
  }
});
