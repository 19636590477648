import { FETCH_BLOGS_SUCCESS } from "./action";

const initialState = {
  blogs: []
};

const transformBlogs = blogs => {
  const shuffleblog = [...blogs];
  //
  if (Array.isArray(shuffleblog) && shuffleblog.length > 0) {
    for (let i = shuffleblog.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffleblog[i], shuffleblog[j]] = [shuffleblog[j], shuffleblog[i]];
    }
    //
    return shuffleblog;
  }
  return [];
};

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOGS_SUCCESS: {
      return {
        ...state,
        // blogs: action.payload.blogs
        blogs: transformBlogs(action.payload.blogs)
      };
    }
    default: {
      return state;
    }
  }
};

export default blogsReducer;

//Object.assign()
