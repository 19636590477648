import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Card, CardImg, CardTitle, CardText,CardBody
} from 'reactstrap';
import style from "./style";


class BlogItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: this.props.data
    };
  }

  render() {

    const { item } = this.props;
    const img =
      item.thumbnail_images && item.thumbnail_images.full
        ? item.thumbnail_images.full.url
        : undefined;

    if (item) {
      return (
      <Card key={item.id} style={style.cardView}>
        {img ?     
        <CardImg top width="100%" src={img} alt="child img" style={style.cardChild} /> : ""}
       <CardBody>
          <CardTitle style={{fontFamily: 'Baloo'}}> {item.title}</CardTitle>
          <CardText dangerouslySetInnerHTML={{ __html: item.excerpt }}></CardText>
        </CardBody>
      </Card>
      );
    }
    return <div />;
  }
}
export default BlogItem;
