import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";

import moment from "moment";
import { POST_SHIFT_CENTER, CANCEL_SHIFT_REQUEST } from "./action";
import { getRequestType, fetchPastRequest } from "../../Support/redux/action";
import { SERVER_DATE_FORMAT } from "../../../global/redux/combineLogic";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const postShiftCenter = createLogic({
  type: POST_SHIFT_CENTER,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const parentName = getState().userState.parentProfile.parentName.toLowerCase();
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/request`;
      const body = {
        date: moment(action.payload.joiningDate).format(SERVER_DATE_FORMAT),
        end_date: moment(action.payload.attritionDate).format(
          SERVER_DATE_FORMAT
        ),
        attrition_reason: action.payload.selectedCenter.Center_Id,
        center_shift: "yes",
        program_name: action.payload.selectedProgram,
        program_change: "no",
        values: ["center_shift", "program_change"],
        // password: getState().userState.password,
        parent_name: parentName
      };
      console.log("[Center Shift] Calling API: /parentapp/request, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Center Shift] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            //  NavigationService.navigate('CustomerSupport');
            //alert(response)

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Center Shift] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Shift Center Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Center Shift] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Shift Center Error", error);
      done();
    }
  }
});

export const cancelShiftCenter = createLogic({
  type: CANCEL_SHIFT_REQUEST,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/financials/cancelCustomerRequest/`;
      const body = {
        admission_id: getState().userState.user.activeChildDetail.id,
        request_id: action.payload.request_id,
        end_date: action.payload.end_date,
        updated_by_name: getState().userState.parentProfile.parentName,
        updated_by: 0,
      };
      console.log("[Cancel Center Shift] Calling API: /financials/cancelCustomerRequest, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        successReportType: "Alert",
        errorReportType: "Alert",
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Cancel Center Shift] Received response: ",response);
          if (response.status === "ok") {
            dispatch(getRequestType());
            dispatch(fetchPastRequest());
            // NavigationService.navigate("CustomerSupport");

            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(setError(response.error, response.error));
            done();
          }
        },
        error => {
          console.log("[Cancel Center Shift] Error: ",error);
          dispatch(setLoading(false));
          // failure
          setContext("Cancel nShift Center Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Cancel Center Shift] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("Cancel Shift Center Error", error);
      done();
    }
  }
});

export default [postShiftCenter, cancelShiftCenter];
