
import _ from 'lodash';
import { transform } from '../../ChildTimeline/redux/transformTimeline';


const moment = require('moment');

export const transformLocal = (data) => {

  const unSortedData = transform(data);
  try {
    const sortedData = unSortedData.sort(function (a, b) {
      return new Date(a.momentTime).getTime() - new Date(b.momentTime).getTime();
    });
    return sortedData;
  }
  catch (error) {
    console.log("[Conversation][transform Chat] Error: ",error);
    return unSortedData;
  }
};

export const transformChat = (timeline, useDateFormat = true) => {
  try {
    if (Array.isArray(timeline)) {
      let dateGroup = _.groupBy(timeline, d => {
        if (useDateFormat) { return moment(d.date, 'YYYY-MM-DD').format('DD-MM-YY'); }
        else { return moment(d.date).format('DD-MM-YY'); }
      }
      );
      dateGroup = _.reduce(
        dateGroup,
        (acc, next, index) => {
          acc.push({
            dateGroup: index,
            data: transformLocal(next),
          });
          return acc;
        },
        [],
      );
      return dateGroup;
    }
    return [];
  } catch (error) {
    console.log("[Conversation][transform Chat] Error: ",error);
    return [];
  }
};

