import fetchAPI from "../../../global/utils/fetch";
import { FETCH_ATTENDANCE, fetchAttendanceSuccess } from "./action";
import { createLogic } from "redux-logic";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import Constants from "../../../constants";
import moment from "moment";
import { setContext } from "../../../global/utils/crashlyticsMethods";

const fetchAttendanceLogic = createLogic({
  type: FETCH_ATTENDANCE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    dispatch(onErrLoading('Attendance', true))
    try {
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/getChildAttendance`;
      const body = {
        list: moment().year()
      };
      console.log("[Attendance] Calling Api: /parentapp/getChildAttendance, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        body,
        getState,
        dispatch,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Attendance] Received Response: ",response);
          dispatch(fetchAttendanceSuccess(response.response));
          dispatch(setLoading(false));
          dispatch(onErrLoading("Attendance", false));
          if (action.payload && action.payload.onSuccess) {
            action.payload.onSuccess();
            done();
          } else {
            done();
          }

          //done();
        },
        error => {
          console.log("[Attendance] Error: ",error);
          dispatch(setLoading(false));
          dispatch(onErrLoading('Attendance', false))
          setContext("Attendance Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Attendance] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('Attendance', false))
      setContext("Attendance Error", error);
      done();
    }
  }
});

export default [fetchAttendanceLogic];
