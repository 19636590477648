import React from "react";
import { connect } from "react-redux";
import TicketItem from "./item";
import { fetchTickets } from "./redux/action";
import { Row } from "reactstrap";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("[Tickets] fetching tickets...");
    this.props.fetchTickets();
  }
  render() {
    const { tickets, loading } = this.props;
    //
    if (Array.isArray(tickets) && tickets.length > 0) {
      return (
        <Row>
          {tickets.map((item, index) => (
            <TicketItem item={item} key={index} index={index} />
          ))}
        </Row>
      );
    } else {
      return <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} />;
    }
  }
}
const mapStateToProps = state => ({
  tickets: state.ticket.tickets,
  loading: state.ticket.loading
});

const mapDispatchToProps = dispatch => ({
  fetchTickets: () => dispatch(fetchTickets())
});
export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
