import React from "react";
import { connect } from "react-redux";
import { fetchNews } from "./redux/action";

import NewsItem from "./item";
import { Row } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import { DEFAULT_FORMAT } from "../../global/redux/combineLogic";
import ListEmptyComponent from "../../global/utils/ListEmptyComponent";
import { InnerHOC } from "../../global/HOComponet/innerPage";
import Theme from "../../theme";
class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    console.log("[News] fetching news...");
    this.props.fetchNews();
  }

  openModal = (show, item) => {
    this.setState({ showModal: show, item: item });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  render() {
    const { news, loading } = this.props;
    // let notification_text = '';
    // if (this.state.item) {
    //   // console.log(this.state.item.notification_text)
    //   let htmlFile = this.state.item.notification_text;
    //   let index = htmlFile.indexOf('<style type="text/css">');
    //   let index1 = htmlFile.indexOf('</style>');
    //   let styles = '<style type="text/css"> .red-clr{color:#EA4335;} .green-clr{color:#34A853;} .orange-clr{color:#fe6500;} .blue-clr{color:#56509F;} .gray-clr{color:#666;} .bg-grey{background:#f5f5f5;}' +
    //     'table {color:#555; line-height:30px;} .news-lettertable tr td {padding:0px 10px;} @media only screen and (max-width:800px) ' +
    //     '{ .wrapper{width:100% !important; border:none !important; display:flex; justify-content:center;} .orange-clr{color:#fe6500; line-height:normal; margin-top:7px} }'
    //     //+ '@media only screen and (max-width:400px){ .wrapper{display:flex; justify-content:center; width:100% !important; border:none !important;} .orange-clr{} }'
    //     + '</style>'
    //   notification_text = htmlFile.substring(0, index) + styles + htmlFile.substring(index1 + 8);
    //   //console.log(notification_text)
    // }
    return (
      <div>
        <InnerHOC heading="News">
          {Array.isArray(news) && news.length > 0 && (
            <Row>
              {this.props.news.map((item, index) => (
                <NewsItem
                  key={index}
                  openModal={(show, newsItem) => this.openModal(show, newsItem)}
                  item={item}
                />
              ))}
            </Row>
          )}{" "}
          {!Array.isArray(news) ||
            (news.length === 0 && <ListEmptyComponent heading={loading ? "Loading..." : "No Data Found"} />)}
        </InnerHOC>
        {this.state.item && (
          <Modal
            isOpen={this.state.showModal}
            toggle={() => {
              this.setState({ showModal: !this.state.showModal });
            }}
            className="modal-xl modal-dialog-scrollable"
          >
            <ModalHeader
              toggle={() => {
                this.setState({ showModal: !this.state.showModal });
              }}
              style={{ fontFamily: "Baloo", background: Theme.colorPrimary }}
            >
              <p className="text-white m-0">
                {moment(this.state.item.newsletter_sent_on).format(
                  DEFAULT_FORMAT
                )}
              </p>
            </ModalHeader>
            <ModalBody
              dangerouslySetInnerHTML={{
                __html: this.state.item.notification_text
              }}
            ></ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  news: state.news.news,
  loading: state.news.loading
});

const mapDispatchToProps = (dispatch) => ({
  fetchNews: () => dispatch(fetchNews()),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(News);

export default connectedComponent;

// export default News
