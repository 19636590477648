import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";
import "../src/global/HOComponet/SideDrawer.css";

WebFont.load({
  google: {
    families: ["Baloo"],
  },
});
//import News from './features/News';

ReactDOM.render(<App />, document.getElementById("root"));
//serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
