import React from "react";
import Blogs from "../Blogs";
import Login from "../Login";
import Header from "../Blogs/Header";
import { Container, Row, Col } from "reactstrap";
// import moment from "moment";
import { connect } from "react-redux";
import LoginVerify from "../Login/LoginVerify";
import Constants from "../../constants";
import { logout } from "../Login/redux/action";
import { setNotification } from "../../global/redux/action";

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    const { user } = this.props;
    // console.log("USER=> ", user);
    // console.log(Constants.env.version);
    // let current_version = Constants.env.version;
    // let old_version = user.app_version;
    // console.log(
    //   "Old version: ",
    //   old_version,
    //   "Current version: ",
    //   current_version
    // );
      // if (old_version !== current_version) {
      //   console.log("Version Checked");
      //   // this.props.logout();
      //   this.props.setNotification(
      //     "Parent Connect App Update",
      //     "It seems you are using previous versions of app update the app to continue",
      //     () => {}
      //   );
      // }
  }
  
  render() {
    // console.log("Location : ",this.props.location.pathname)
    return (
      <div>
        <Header />
        <Container fluid={true} style={{ marginTop: "7em" }}>
          <Row>
            <Col
              xs="12"
              sm="12"
              md="5"
              lg="5"
              xl="3"
              className="order-md-2 mb-4"
            >
              <div className="sticky-top">
               { this.props?.location?.pathname === '/verifyUser' ? <LoginVerify history={this.props.history} /> :
                <Login history={this.props.history} />}
              </div>
            </Col>
            <Col xs="12" sm="12" md="7" lg="7" xl="9" className="order-md-1">
              <Blogs history={this.props.history} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  user: state.userState.user,
  lastLoggedIn: state.userState.lastLoggedIn
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNotification: (title, subtitle, onClick) =>
    dispatch(setNotification(title, subtitle, onClick)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
