export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_PHONE = "UPDATE_USER_PHONE";
export const UPDATE_USER_PHONE_SUCCESS = "UPDATE_USER_PHONE_SUCCESS";
export const UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS";
export const RESET_DOOR_PIN = "RESET_DOOR_PIN";
export const RESET_DOOR_PIN_SUCCESS = "RESET_DOOR_PIN_SUCCESS";

export const ADD_ADMISSION_DETAILS = "ADD_ADMISSION_DETAILS";

export const updateUserEmail = (newEmail, onSuccess) => ({
  type: UPDATE_USER_EMAIL,
  payload: {
    newEmail,
    onSuccess,
  },
});

export const updateUserPhone = (newPhone, onSuccess) => ({
  type: UPDATE_USER_PHONE,
  payload: {
    newPhone,
    onSuccess,
  },
});

export const updatePhoneSuccess = (payload) => ({
  type: UPDATE_USER_PHONE_SUCCESS,
  payload,
});

export const updateEmailSuccess = (payload) => ({
  type: UPDATE_USER_EMAIL_SUCCESS,
  payload,
});

export const addAdmissionDetail = (image, relation) => ({
  type: ADD_ADMISSION_DETAILS,
  payload: {
    image,
    relation,
  },
});
export const doorAccessPin = () => ({
  type: RESET_DOOR_PIN,
});
export const doorAccessPinSuccess = (pin) => ({
  type: RESET_DOOR_PIN_SUCCESS,
  payload: {
    pin,
  },
});
