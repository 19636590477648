import { createStore, applyMiddleware } from "redux";
import combineReducer from "./combineReducer";
import { createLogicMiddleware } from "redux-logic";
// import { composeWithDevTools } from 'redux-devtools-extension';
import combineLogic from "./combineLogic";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import Constants from "../../constants";

// const store =  createStore(combineReducer);

// export default store;

const logicMiddleware = createLogicMiddleware(combineLogic);

const isDevelopment = Constants.env.type !== 'PRODUCTION';
const middleware = isDevelopment ? [logicMiddleware, Constants.DEBUG &&logger].filter(Boolean) : [logicMiddleware].filter(Boolean);

// could compose in dev tools too

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whiteList: ["global"]
};
const persistedReducer = persistReducer(persistConfig, combineReducer);
let store = createStore(persistedReducer,applyMiddleware(...middleware));
export default () => {
  let persistor = persistStore(store);
  return { store, persistor };
};
