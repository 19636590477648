import React from "react";
import { FormGroup, Input } from "reactstrap";
import blogStyle from "../Blogs/style";
import Constants from "../../constants";

const VerifyInput = ({ OTP, setOTP }) => {
  let arrayOTP = OTP.split("");

  const handleChange = (event, index) => {
    if (!isNaN(event.target.value) && event.target.value) {
      if (event.target.value && index <= 5 && arrayOTP.length < 6) {
        arrayOTP[index] = event.target.value;
        setOTP(arrayOTP.join(""));
        const form = event.target.form;
        const idx = Array.prototype.indexOf.call(form, event.target);
        form.elements[idx + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, idx) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (event.key === "Backspace") {
      arrayOTP[index] = "";
      setOTP(arrayOTP.join(""));
      if (idx > 0) form.elements[index - 1].focus();
    }
  };

  return (
    <div className="d-flex">
      {[...Array(6).keys()].map((ele, i) => (
        <FormGroup key={i} className="m-1">
          <Input
            type={Constants.env.platform === "web" ? "text" :"number"}
            name={`OTPValue${i}`}
            maxLength={1}
            autoComplete="one-time-code"
            value={arrayOTP[i] || ""}
            onChange={(e) => handleChange(e, i)}
            className="text-center p-0"
            onKeyDown={(e) => handleKeyDown(e, i)}
            style={{
              ...blogStyle.input,
              borderRadius: "15px",
              borderBottomRightRadius: "0",
              fontSize: "1rem",
            }}
          />
        </FormGroup>
      ))}
    </div>
  );
};

export default VerifyInput;
