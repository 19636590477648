import {
  FETCH_CHILD_MEASUREMENTS,
  fetchMeasurementsSuccess,
  FETCH_CHILD_CARE,
  fetchChildCareSuccess
} from "./action";
import { setLoading, setError, onErrLoading } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

export const fetchMeasurements = createLogic({
  type: FETCH_CHILD_MEASUREMENTS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('MEASUREMENT', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl
        }/parentapp/measurement`;
      const body = {};
      console.log("[Growth][Measurement] Calling Api: /parentapp/measurement");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Growth][Measurement] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchMeasurementsSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchMeasurementsSuccess([]));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('MEASUREMENT', false))
            done();
          }
        },
        error => {
          console.log("[Growth][Measurement] Error: ",error);
          dispatch(fetchMeasurementsSuccess([]));
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('MEASUREMENT', false))
          setContext("Measurement Error", error);
          done();
        }
      );
    } catch (error) {
    console.log("[Growth][Measurement] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('MEASUREMENT', false))
      setContext("Measurement Error", error);
      done();
    }
  }
});

export const fetchChildCare = createLogic({
  type: FETCH_CHILD_CARE,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      dispatch(onErrLoading('CHILD_CARE', true))
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/childCare`;
      const body = {};
      console.log("[Growth][Child Care] Calling Api: /parentapp/childCare");
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        //   body,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Growth][Child Care] Received Response: ",response);
          if (response.status === "ok") {
            dispatch(fetchChildCareSuccess(response.response));
            dispatch(setLoading(false));
            if (action.payload && action.payload.onSuccess) {
              action.payload.onSuccess();
              done();
            } else {
              done();
            }
          } else if (response.status === "error") {
            dispatch(fetchChildCareSuccess([]));
            dispatch(setError(response.error, response.error));
            dispatch(onErrLoading('CHILD_CARE', false))
            done();
          }
        },
        error => {
          console.log("[Growth][Child Care] Error: ",error);
          dispatch(fetchChildCareSuccess([]));
          dispatch(setLoading(false));
          // failure
          dispatch(onErrLoading('CHILD_CARE', false))
          setContext("ChildCare Error", error);
          done();
        }
      );
    } catch (error) {
      console.log("[Growth][Child Care] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      dispatch(onErrLoading('CHILD_CARE', false))
      setContext("ChildCare Error", error);
      done();
    }
  }
});
export default [fetchMeasurements, fetchChildCare];
