import { createLogic } from "redux-logic";
import { FETCH_BLOGS, fetchBlogsSuccess } from "./action";
import fetchAPI from "../../../global/utils/fetch";

import { setLoading } from "../../../global/redux/action";

const fetchBlogsLogic = createLogic({
  type: FETCH_BLOGS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = getState().constants.BlogsJSONApi;
      // const url = `${Constants.AmazonAPI}/blog_posts.json`;
      console.log("[Blogs] Calling API: ",getState().constants.BlogsJSONApi);
      const fetchObject = {
        url,
        //body,
        method: "GET",
        getState,
        noHeader: true,
        dispatch,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
      console.log("[Blogs]  Received response: ",getState().constants.BlogsJSONApi);
          dispatch(setLoading(false));

          dispatch(fetchBlogsSuccess(response.posts));
          done();
        },
        error => {
      console.log("[Blogs] Error: ",error);
          done();
          dispatch(setLoading(false));
          console.error("the error is ", error);
        }
      );
    } catch (error) {
      console.log("[Blogs] Error: ",error);
     }
  }
});

export default [fetchBlogsLogic];
