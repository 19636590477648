import { FETCH_CONFIG_SUCCESS } from "./action";

//import { setConstants } from "../../constants"
import DefaultConstants from "../../constants";

const initialState = {
  ...DefaultConstants.env
};

const constantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIG_SUCCESS: {
      try {
        return {
          ...DefaultConstants,
          ...action.payload
        };
      } catch (error) {
        return {
          state
        };
      }
    }
    default: {
      return state;
    }
  }
};

export default constantReducer;

//Object.assign()
