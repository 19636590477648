import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col
} from "reactstrap";
import moment from "moment";

class HolidayItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getTittle = tittle => {
    if (!(tittle[0].toUpperCase() >= 'A' && tittle[0].toUpperCase() <= 'Z')) return tittle.substring(1);
    return tittle;
  }
  render() {
    const {
      background = "black",
      titleColor = "#fff",
      item,
      boxShadow
    } = this.props;
    //console.log(this.getTittle(item.title))
    return (
      <Col sm="3" md="6" lg="4">
        <Card
          className="mb-3 border-0"
          style={{ background, boxShadow }}
          key={item.id}
        >
          <CardHeader
            style={{ fontFamily: "Baloo", color: titleColor }}
            className="h5 card-header bg-transparent border-0 pb-0"
          >
            {moment(item.holiday_date).format("DD MMMM YYYY")}
          </CardHeader>
          <CardBody className="pt-2">
            <CardText className="mb-2" style={{ color: titleColor }}>
              {moment(item.holiday_date).format("dddd")}
            </CardText>
            <CardTitle className="m-0" style={{ color: titleColor }}>
              {this.getTittle(item.title)}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default HolidayItem;
