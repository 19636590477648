import {
  CHECK_CCTV,
  checkCCTVSuccess,
  updateCCTVConnection as updateAction,
  UPDATE_CCTV_CONNECTION
} from "./action";
import { setLoading, setError } from "../../../global/redux/action";
import { createLogic } from "redux-logic";
import fetchAPI from "../../../global/utils/fetch";
import Constants from "../../../constants";
import { setContext } from "../../../global/utils/crashlyticsMethods";

const checkCCTVLogic = createLogic({
  type: CHECK_CCTV,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      const url = `${getState().constants.cctvConnectionCheck}`;
      // const url = `${Constants.env.cctvConnectionCheck}`;
      console.log("[Check CCTV] Calling API: ",getState().constants.cctvConnectionCheck);
      // body = {};
      const fetchObject = {
        url,
        // body,
        method: "GET",
        getState,
        dispatch,
        authenticatedAPI: false,
        done,
        action,
        noHeader: true,
      };

      fetchAPI(fetchObject).then(
        (response) => {
      console.log("[Check CCTV] Received Response: ",response);
          dispatch(setLoading(false));
          if (Array.isArray(response)) {
            const userCenterId = getState().userState.user.activeChildDetail
              .center_id;
            const centerDown = response.filter(
              (ele) => parseInt(ele.Centre_id) === parseInt(userCenterId)
            );
            if (centerDown.length > 0) {
              dispatch(
                checkCCTVSuccess({
                  centerCctvDown: true,
                  centerDetail: centerDown[0],
                })
              );
              dispatch(updateAction());
              done();
            } else {
              dispatch(checkCCTVSuccess({ centerCctvDown: false }));
              done();
            }
          } else {
            dispatch(checkCCTVSuccess({ centerCctvDown: false }));
            done();
          }
        },
        error => {
          setContext("CCTV Error", error);
      console.log("[Check CCTV] Error: ",error);
          done();
        }
      );
    } catch (error) {
      console.log("[Check CCTV] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      setContext("CCTV Error", error);
      dispatch(setLoading(false));
      done();
    }
  },
});

export const updateCCTVConnection = createLogic({
  type: UPDATE_CCTV_CONNECTION,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    try {
      dispatch(setLoading(true));
      const url = `${getState().constants.AppUrl || Constants.env.AppUrl}/parentapp/updateCctvFlag`;

      // const url = `${getState().myKid.constants.lambdaApiServer}/parentapp/updateCctvFlag`;
      //const url = "http://localhost:4000/dev/parentapp/updateCctvFlag";
      const parentProfile = getState().userState.parentProfile;
      const parentType = parentProfile.parentType.toLowerCase();
      const body = {
        parent: parentType,
        admission_type: getState().userState.user.activeChildDetail
          .admission_type
      };
      console.log("[Update CCTV Flag] Calling Api: /parentapp/updateCctvFlag, payload: ",body);
      const fetchObject = {
        url,
        method: "POST",
        getState,
        dispatch,
        body,
        authenticatedAPI: false,
        done,
        action
      };

      fetchAPI(fetchObject).then(
        response => {
          console.log("[Update CCTV Flag] Received Response: ",response);
          done();
        },
        error => {
          done();
          dispatch(setLoading(false));
          setContext("CCTV Update child flag", error);
          // failure
          console.log("[Update CCTV Flag] Error: ",error);
          done();
        }
      );
    } catch (error) {
      console.log("[Update CCTV Flag] Error: ",error);
      dispatch(setError("Some Error Occured", error.message));
      dispatch(setLoading(false));
      setContext("CCTV Update child flag", error);
      done();
    }
  }
});

export default [checkCCTVLogic, updateCCTVConnection];
